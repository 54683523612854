import { connect } from 'react-redux'
import React from 'react'
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form';
import PageMenu from '../../modules/pageMenu'
import { Alert, Button, Container, Icon, Table, Tbody, Thead, Td, Tr, Title, Status, Template, Pagination } from "sme-theme"
import { bindActionCreators } from 'redux'
import { loadBadges } from '../../actions/loadBadgesAction';
import { loadPageBadge } from '../../actions/loadPageBadgeAction';
import { orderBadges } from '../../actions/orderBadgesAction'
import { hideAlert } from '../../actions/hideAlertAction'
import { HIDE_ALERT_CREATE_BADGE } from '../../actions/actionTypes';

class BadgesList extends React.Component {
  componentDidMount() {
    this.props.loadBadges();
  }

  order = attr => {
    const token = localStorage.getItem('token')
    this.props.orderBadges(token, 'badge_challenges', attr, this.props.orderType)
  }

  verifyAttrFilter = attr => {
    if(attr === this.props.selectedOrder) {
      return 'sort-title active'
    }
    return 'sort-title'
  }

  loadPage = (page) => {
    const token = localStorage.getItem('token')
    this.props.loadPageBadge(token, page, this.props.orderAttribute, this.props.orderType);
  }

  componentWillUnmount() {
    this.props.hideAlert(HIDE_ALERT_CREATE_BADGE)
  }
  render() {
    return (
      <PageMenu focus="badges" loading={this.props.busy}>
        <Container>
          <Title className="flex-align-center">Badge Challenge <Button plugin={Link} to="/badge-new" className="sm mb-0 ml-lg">NOVA BADGE</Button></Title>
          <Template condition={this.props.alertCreateBadge}>
            <Alert className="my-md" type="success" title="Badge criada com sucesso !"></Alert>
          </Template>
          <Table>
            <Thead>
              <Tr>
                <Td width={50} onClick={() => this.order('name')} className={this.verifyAttrFilter('name')}>Badge <Icon name="arrow-down"/></Td>
                <Td width={15} onClick={() => this.order('total_times_of_streamings')} className={this.verifyAttrFilter('total_times_of_streamings')}>Plays <Icon name="arrow-down"/></Td>
                <Td onClick={() => this.order('status')} className={this.verifyAttrFilter('status')}>Status<Icon name="arrow-down"/></Td>
                <Td width="210px">Actions</Td>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.badges.map((badge, i) => {
                return(
                  <Tr key={i}>
                    <Td nowrap>{badge.name}</Td>
                    <Td>
                      {badge.total_times_of_streamings}
                    </Td>
                    <Td>
                      {badge.status ?
                        <Status success>Ativo</Status> :
                        <Status error>Inativo</Status>}
                    </Td>
                    <Td>
                      <Button title="Editar" className="circle m-0" plugin={Link} to={`/badge/${badge.id}`}><Icon name="pencil" /></Button>
                      <Button title="Ver +" className="circle m-0 ml-md" plugin={Link} to={`/badge-view/${badge.id}`}><Icon name="eye" /></Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
          <Template condition={this.props.totalPages > 1 && !this.props.ordering}>
            <Pagination page={1} size={this.props.totalPages} onChange={(i)=> this.loadPage(i)} />
          </Template>
        </Container>
      </PageMenu>
    );
  }
}

const mapStateToProps = state => ({
  busy: state.LoadBadges.busy,
  alertCreateBadge: state.LoadBadges.alertCreateBadge,
  totalPages: state.LoadBadges.totalPages,
  badges: state.LoadBadges.badges,
  selectedOrder: state.LoadBadges.selectedOrder,
  orderType: state.LoadBadges.orderType,
  orderAttribute: state.LoadBadges.orderAttribute,
  ordering: state.LoadBadges.ordering
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadBadges,
  loadPageBadge,
  orderBadges,
  hideAlert
}, dispatch);

BadgesList = reduxForm({
  form: 'formBadgesList',
  enableReinitialize: true
})(BadgesList);

export default connect(mapStateToProps, mapDispatchToProps)(BadgesList);
