import {
  LOAD_USER_BADGES,
  LOAD_USER_BADGES_SUCCESS,
  LOAD_USER_BADGES_FAILED,
  LOAD_PAGE_USER_BADGES_SUCCESS,
  LOAD_PAGE_USER_BADGES_FAILED,
  ORDER_USER_BADGES_BY_ATTRIBUTE,
  ORDER_USER_BADGES_BY_ATTRIBUTE_SUCCESS,
  ORDER_USER_BADGES_BY_ATTRIBUTE_FAILED
} from '../actions/actionTypes';

const initialState = {
  userBadges: [],
  busy: false,
  totalPages: 0,
  orderType: 'desc',
  orderAttribute: 'default',
  selectedOrder: '',
  ordering: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_BADGES:
      return {
        ...state,
        busy: true
      }
    case LOAD_USER_BADGES_SUCCESS:
      function getParamsFromUrl(url) {
        url = decodeURI(url);
        if (typeof url === 'string') {
            let params = url.split('?');
            let eachParamsArr = params[1].split('&');
            let obj = {};
            if (eachParamsArr && eachParamsArr.length) {
                eachParamsArr.forEach(param => {
                    let keyValuePair = param.split('=')
                    let key = keyValuePair[0];
                    let value = keyValuePair[1];
                    obj[key] = value;
                })
            }
            return obj;
        }
      }

      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params['page[number]']

      action.payload.forEach(a => {
        if(a.relationships.user.data) {
          action.included.forEach(b => {
            if(parseInt(a.relationships.user.data.id) === parseInt(b.id)) {
              a.attributes.name = b.attributes.name
            }
          })
        } else {
          a.attributes.name = '-'
        }
      })

      return {
        ...state,
        userBadges: action.payload,
        totalPages: parseInt(totalPages),
        busy: false
      }
    case LOAD_USER_BADGES_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case ORDER_USER_BADGES_BY_ATTRIBUTE:
      return {
        ...state,
        busy: true,
        ordering: true
      }
    case ORDER_USER_BADGES_BY_ATTRIBUTE_SUCCESS:
      action.payload.forEach(a => {
        if(a.relationships.user.data) {
          action.included.forEach(b => {
            if(parseInt(a.relationships.user.data.id) === parseInt(b.id)) {
              a.attributes.name = b.attributes.name
            }
          })
        } else {
          a.attributes.name = '-'
        }
      })

      return {
        ...state,
        userBadges: action.payload,
        orderAttribute: action.attribute,
        selectedOrder: action.attribute,
        orderType: action.orderType === 'desc' ? 'asc' : 'desc',
        ordering: false,
        busy: false
      }
    case ORDER_USER_BADGES_BY_ATTRIBUTE_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case LOAD_PAGE_USER_BADGES_SUCCESS:
      action.payload.forEach(a => {
        if(a.relationships.user.data) {
          action.included.forEach(b => {
            if(parseInt(a.relationships.user.data.id) === parseInt(b.id)) {
              a.attributes.name = b.attributes.name
            }
          })
        } else {
          a.attributes.name = '-'
        }
      })

      return {
        ...state,
        userBadges: action.payload
      }
    case LOAD_PAGE_USER_BADGES_FAILED:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}
