import {
  LOAD_RESCUE_ANALYTICS,
  LOAD_RESCUE_ANALYTICS_SUCCESS,
  LOAD_RESCUE_ANALYTICS_FAILED,
  LOAD_PAGE_RESCUE_ANALYTICS_SUCCESS
} from '../actions/actionTypes';

const initialState = {
  analytics: [],
  totalPages: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_RESCUE_ANALYTICS:
      return {
        ...state,
        busy: true,
      }
    case LOAD_RESCUE_ANALYTICS_SUCCESS:
      const analytics = action.payload
      
      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params.page

      return {
        ...state,
        analytics,
        totalPages: parseInt(totalPages),
      }
    case LOAD_RESCUE_ANALYTICS_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case LOAD_PAGE_RESCUE_ANALYTICS_SUCCESS:
      const analyticsPage = action.payload

      return {
        ...state,
        analytics: analyticsPage,
      }
    default:
      return state
  }
}

function getParamsFromUrl(url) {
  url = decodeURI(url);
  if (typeof url === 'string') {
      let params = url.split('?');
      let eachParamsArr = params[1].split('&');
      let obj = {};
      if (eachParamsArr && eachParamsArr.length) {
          eachParamsArr.forEach(param => {
              let keyValuePair = param.split('=')
              let key = keyValuePair[0];
              let value = keyValuePair[1];
              obj[key] = value;
          })
      }
      return obj;
  }
}
