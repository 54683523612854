import React, { Component } from 'react';
import { Text, Title, Form, Wrap, Template, Button, Container, Alert } from "sme-theme"
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateProfileAdmin } from '../../actions/updateProfileAdminAction';
import { loadCurrentAdmin } from '../../actions/loadCurrentAdminAction';
import { logoutAdmin } from '../../actions/logoutAdminAction';
import PageMenu from '../../modules/pageMenu'
import { InputRedux, ErrorField } from '../../modules/inputRedux';

class FormProfileAdmin extends Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadCurrentAdmin(token);
    localStorage.removeItem('array_errors_profile');
    localStorage.removeItem('edit_admin_profile_ok_pass');
    localStorage.removeItem('edit_admin_profile_ok_email');
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_profile')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_profile');
    localStorage.removeItem('edit_admin_profile_ok_pass');
    localStorage.removeItem('edit_admin_profile_ok_email');
  }

  logout = () => {
    this.props.logoutAdmin()
  }

  render(){
    const { error, handleSubmit, dirty } = this.props;
    const formError = 'array_errors_profile'
    return(
      <PageMenu focus="profile" loading={this.props.busy}>
        <Container>
          <Form onSubmit={handleSubmit(updateProfileAdmin(this.props.initialValues.email))} >
              <Title>Perfil</Title>
              <Template condition={localStorage.getItem('array_errors_profile') && error}>
                <Alert className="my-md error" type="error" iconName="attention-circle-o">
                  <Text>{this.props.error_status !== 500 && 'Alguns campos precisam da sua atenção:'}</Text>
                  <ErrorField field="email" label="Email" formError={formError} />
                  <ErrorField field="password_current" label="Senha atual" formError={formError} />
                  <ErrorField field="new_password" label="Nova senha" formError={formError} />
                  <ErrorField field="new_password_confirmation" label="Confirmar nova senha" formError={formError} />
                  <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
                </Alert>
              </Template>
              <Template condition={localStorage.getItem('edit_admin_profile_ok_pass')}>
                <Alert className="my-md" type="success" iconName="check-circle-o">
                  <Text>Senha atualizada com sucesso !</Text>
                </Alert>
              </Template>
              <Template condition={localStorage.getItem('edit_admin_profile_ok_email')}>
                <Alert className="my-md" type="success" iconName="check-circle-o">
                  <Text>Email atualizado com sucesso !</Text>
                </Alert>
              </Template>
                <Wrap className="max-w-xs">
                <InputRedux
                  name="email"
                  label="Email"
                  type="text"
                  formError={formError}
                />
                <InputRedux
                  name="password_current"
                  label="Senha Atual"
                  type="password"
                  formError={formError}
                  />
                <InputRedux
                  name="new_password"
                  label="Nova senha"
                  type="password"
                  helpText="Use oito ou mais caracteres com uma combinação de letras, números e símbolos."
                  formError={formError}
                  />
                <InputRedux
                  name="new_password_confirmation"
                  label="Confirmar nova senha"
                  type="password"
                  formError={formError}
                />
              <Button className="block" type="submit" loading={this.props.busyButton} disabled={!dirty}>Salvar</Button>
            </Wrap>
          </Form>
          <Button className="block mt-lg max-w-xs" onClick={() => this.logout()}>Sair</Button>
        </Container>
      </PageMenu>
    )
  }
}

const MapStateToProps = state => ({
  initialValues: {
    email: state.LoadCurrentAdmin.email
  },
  busy: state.LoadCurrentAdmin.busy,
  busyButton: state.LoadCurrentAdmin.busyButton,
  error_status: state.LoadCurrentAdmin.error
})

const MapDispatchToProps = dispatch => bindActionCreators({
updateProfileAdmin,
loadCurrentAdmin,
logoutAdmin
}, dispatch);

FormProfileAdmin = reduxForm({
  form: 'updateProfileAdmin',
  enableReinitialize: true
})(FormProfileAdmin);

export default connect(MapStateToProps, MapDispatchToProps)(FormProfileAdmin);
