import {
  LOAD_PAGE_RESCUE_SUCCESS,
  LOAD_PAGE_RESCUE_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPageRescue = (token, page, orderAttribute, orderType) => async (dispatch) => {
  try {
    const result = await api.loadPageRescue(token, page, orderAttribute, orderType);
    dispatch({ type: LOAD_PAGE_RESCUE_SUCCESS, payload: result.data.data })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_RESCUE_FAILED, error: err })
    console.log(err)
  }
}
