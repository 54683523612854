import {
  LOAD_USERS_CAMPAIGN_SUCCESS,
  LOAD_USERS_CAMPAIGN_FAILED,
} from '../actions/actionTypes';

const initialState = {
  users: [],
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        users: action.payload
      }
    case LOAD_USERS_CAMPAIGN_FAILED:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}
