import {
  LOAD_USERS_CAMPAIGN_SUCCESS,
  LOAD_USERS_CAMPAIGN_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadUsersCampaign = (token, campaignId) => async (dispatch) => {
  try {
    const result = await api.loadUsersCampaign(token, campaignId);
    dispatch({ type: LOAD_USERS_CAMPAIGN_SUCCESS, payload: result.data.data })
  } catch(err) {
    dispatch({ type: LOAD_USERS_CAMPAIGN_FAILED, error: err })
    console.log(err)
  }
}
