import {
  LOAD_QUESTS,
  LOAD_QUESTS_SUCCESS,
  LOAD_QUESTS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadQuests = (token) => async (dispatch) => {
  dispatch({ type: LOAD_QUESTS })
  try {
    const result = await api.loadQuests(token);
    dispatch({ type: LOAD_QUESTS_SUCCESS, payload: result.data.data, lastLink: result.data.links.last })
  } catch(err) {
    dispatch({ type: LOAD_QUESTS_FAILED, error: err })
  }
}
