import {
  LOAD_SETTINGS,
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadSettings = (token) => async (dispatch) => {
  dispatch({ type: LOAD_SETTINGS })
  try {
    const result = await api.loadSettings(token);
    dispatch({ type: LOAD_SETTINGS_SUCCESS, payload: result.data })
  } catch(err) {
    dispatch({ type: LOAD_SETTINGS_FAILED, error: err })
  }
}
