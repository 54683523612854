import {
  EDIT_RESCUE,
  EDIT_RESCUE_SUCCESS,
  EDIT_RESCUE_FAILED
} from './actionTypes';

import api from '../services/api';
import { SubmissionError } from 'redux-form'

export const updateRescue = (idRescue) => async (value, dispatch) => {
  dispatch({ type: EDIT_RESCUE })
  const { uri, score, status, limited, limit_streams, priority } = value

  const data = {
    "rescue": {
      "uri": uri,
      "score": score,
      "status": status,
      "limited": limited ? true : false,
      "limit_streams": limit_streams,
      "priority": priority
    }
  }
  localStorage.removeItem('edit_rescue_ok')

  try {
    const tokenAdmin = localStorage.getItem('token')
    await api.updateRescue(tokenAdmin, idRescue, data);
    localStorage.setItem('edit_rescue_ok', 'ok')
    dispatch({ type: EDIT_RESCUE_SUCCESS })
  } catch(err) {
    dispatch({ type: EDIT_RESCUE_FAILED, error: err.response.status })
    localStorage.setItem('array_errors_edit_rescue', JSON.stringify(err.response.data))
    if(err.response.status === 500) {
      localStorage.setItem('array_errors_edit_rescue', JSON.stringify({ server_error: ['Internal Server Error'] }))
    }
    throw new SubmissionError({_error: 'error'})
  }
}