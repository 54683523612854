import {
  LOAD_USER_FULL_LOG,
  LOAD_USER_FULL_LOG_SUCCESS,
  LOAD_USER_FULL_LOG_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadUserFullLog = (token, user_id) => async (dispatch) => {
  dispatch({ type: LOAD_USER_FULL_LOG })
  try {
    const result = await api.loadUserFullLog(token, user_id);
    dispatch({ type: LOAD_USER_FULL_LOG_SUCCESS, payload: result.data.data, lastLink: result.data.links.last })
  } catch(err) {
    dispatch({ type: LOAD_USER_FULL_LOG_FAILED, error: err })
  }
}
