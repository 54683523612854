import {
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILED
} from './actionTypes';

import api from '../services/api';
import { SubmissionError } from 'redux-form'

export const updateSettings = async (value, dispatch) => {
  dispatch({ type: UPDATE_SETTINGS })

  const { title, subtitle, terms_and_conditions, invitation_quantity, 
    invitation_score, profile_completed_score, limited_gratification_score,
    show_playlist, uri_playlist, uri_playlist_cover, enabled_splash_screen,
    splash_screen_message, splash_screen_title, cookie_consent_title,
    cookie_consent_text, show_cookie_consent
  } = value

  const token = localStorage.getItem('token')

  const data = {
    "setting": {
      "title": title,
      "subtitle": subtitle,
      "terms_and_conditions": terms_and_conditions,
      "invitation_score": invitation_score,
      "invitation_quantity": invitation_quantity,
      "profile_completed_score": profile_completed_score,
      "limited_gratification_score": limited_gratification_score,
      "show_playlist": show_playlist || false,
      "uri_playlist": uri_playlist,
      "uri_playlist_cover": uri_playlist_cover,
      "enabled_splash_screen": enabled_splash_screen,
      "splash_screen_message": splash_screen_message,
      "splash_screen_title": splash_screen_title,
      "cookie_consent_title": cookie_consent_title,
      "cookie_consent_text": cookie_consent_text,
      "show_cookie_consent": show_cookie_consent
    }
  }
  localStorage.removeItem('edit_settings_ok')

  try {
    const result = await api.updateSettings(token, data);
    dispatch({ type: UPDATE_SETTINGS_SUCCESS, payload: result.data.data })
    localStorage.setItem('edit_settings_ok', 'ok')
  } catch(err) {
    dispatch({ type: UPDATE_SETTINGS_FAILED, error: err.response.status })
    localStorage.setItem('array_errors_edit_settings', JSON.stringify(err.response.data))
    if(err.response.status === 500) {
      localStorage.setItem('array_errors_edit_settings', JSON.stringify({ server_error: ['Internal Server Error'] }))
    }
    throw new SubmissionError({_error: 'error'})
  }
}
