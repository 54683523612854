import {
  LOAD_ORDERS,
  LOAD_ORDERS_SUCCESS,
  LOAD_ORDERS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadOrders = (token) => async (dispatch) => {
  dispatch({ type: LOAD_ORDERS })
  try {    
    const result = await api.loadUsersWithOrder(token)

    dispatch({
      type: LOAD_ORDERS_SUCCESS,
      payload: result.data.data,
      included: result.data.included,
      lastLink: result.data.links.last
    })

  } catch(err) {
    dispatch({ type: LOAD_ORDERS_FAILED, error: err })
  }
}
