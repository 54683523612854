import {
  LOAD_ORDER_COMMENTS_SUCCESS,
  LOAD_ORDER_COMMENTS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadOrderComments = (token, commentable_type, commentable_id) => async (dispatch) => {
  try {
    const result = await api.loadComments(token, commentable_type, commentable_id);
    dispatch({ type: LOAD_ORDER_COMMENTS_SUCCESS, payload: result.data.data, lastLink: result.data.links.last })
  } catch(err) {
    dispatch({ type: LOAD_ORDER_COMMENTS_FAILED, error: err })
  }
}
