import {
  LOAD_PAGE_USER_FULL_LOG_SUCCESS,
  LOAD_PAGE_USER_FULL_LOG_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPageUserFullLog = (token, page, user_id) => async (dispatch) => {
  try {
    const result = await api.loadPageUserFullLog(token, page, user_id);
    dispatch({ type: LOAD_PAGE_USER_FULL_LOG_SUCCESS, payload: result.data.data, page })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_USER_FULL_LOG_FAILED, error: err })
  }
}
