import {
  LOAD_PLAYLIST,
  LOAD_PLAYLIST_SUCCESS,
  LOAD_PLAYLIST_FAILED,
  UPDATE_PLAYLIST,
  UPDATE_PLAYLIST_SUCCESS,
  UPDATE_PLAYLIST_FAILED,
  UPDATE_PLAYLIST_LIST,
  UPDATE_PLAYLIST_LIST_SUCCESS,
  UPDATE_PLAYLIST_LIST_FAILED
} from '../actions/actionTypes';

const initialState = {
  name: null,
  uri: null,
  status: false,
  busy: false,
  refreshing: false,
  busyButton: false,
  tracks: [],
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PLAYLIST:
      return {
        ...state,
        busy: true
      }
    case LOAD_PLAYLIST_SUCCESS:
      const { name, status, uri } = action.payload.attributes
      const tracks = action.included

      return {
        ...state,
        name: name,
        uri: uri,
        status: status,
        tracks: tracks,
        busy: false
      }
    case LOAD_PLAYLIST_FAILED:
      return {
        ...state,
        busy: false,
        error: action.error
      }
    case UPDATE_PLAYLIST:
      return {
        ...state,
        busyButton: true
      }
    case UPDATE_PLAYLIST_SUCCESS:
      return {
        ...state,
        busyButton: false
      }
    case UPDATE_PLAYLIST_FAILED:
      return {
        ...state,
        busyButton: false,
        error: action.error
      }
    case UPDATE_PLAYLIST_LIST:
      return {
        ...state,
        refreshing: true
      }
    case UPDATE_PLAYLIST_LIST_SUCCESS:
      return {
        ...state,
        refreshing: false,
        tracks: action.tracks
      }
    case UPDATE_PLAYLIST_LIST_FAILED:
      return {
        ...state,
        refreshing: false,
        error: action.error
      }
    default:
      return state
  }
}
