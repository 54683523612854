import {
  LOAD_ORDERS,
  LOAD_ORDERS_SUCCESS,
  LOAD_ORDERS_FAILED,
  LOAD_ORDERS_OF_USER,
  LOAD_ORDERS_OF_USER_SUCCESS,
  LOAD_ORDERS_OF_USER_FAILED,
  LOAD_PAGE_ORDER_SUCCESS,
  LOAD_PAGE_ORDER_FAILED,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILED,
  SEARCH_ORDERS,
  SEARCH_ORDERS_SUCCESS,
  SEARCH_ORDERS_FAILED
} from '../actions/actionTypes';

const initialState = {
  orders: [],
  ordersOfUser: [],
  busy: false,
  busyButton: false,
  totalPages: 0,
  emptySearch: false,
  search: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ORDERS:
      return {
        ...state,
        busy: true
      }
    case LOAD_ORDERS_SUCCESS:
      function getParamsFromUrl(url) {
        url = decodeURI(url);
        if (typeof url === 'string') {
            let params = url.split('?');
            let eachParamsArr = params[1].split('&');
            let obj = {};
            if (eachParamsArr && eachParamsArr.length) {
                eachParamsArr.forEach(param => {
                    let keyValuePair = param.split('=')
                    let key = keyValuePair[0];
                    let value = keyValuePair[1];
                    obj[key] = value;
                })
            }
            return obj;
        }
      }

      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params.page

      // if(action.included !== undefined) {
      //   action.payload.forEach(order => {
      //     order.relationships.user === null ? order.user_deleted = true : order.user_deleted = false
      //     action.included.forEach(user => {
      //       if(order.relationships.user !== null && order.relationships.user.id === user.id) {
      //         order.name = user.name
      //         order.email = user.email
      //         order.id = user.id
      //       }
      //     })
      //   })
      // } else {
      //   action.payload.forEach(order => {
      //     order.user_deleted = true
      //   })
      // }

      return {
        ...state,
        orders: action.payload,
        totalPages: parseInt(totalPages),
        busy: false,
        emptySearch: false,
        search: null
      }
    case LOAD_ORDERS_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case LOAD_ORDERS_OF_USER:
      return {
        ...state,
        busy: true
      }
    case LOAD_ORDERS_OF_USER_SUCCESS:
      action.payload.forEach(order => {
        order.created_at = dateBr(order.created_at)
      })
      return {
        ...state,
        ordersOfUser: action.payload,
        busy: false
      }
    case LOAD_ORDERS_OF_USER_FAILED:
      return {
        ...state,
        busy: false
      }
    case LOAD_PAGE_ORDER_SUCCESS:
      let includedPage = action.included
      let ordersPage = action.payload

      // if(includedPage !== undefined) {
      //   ordersPage.forEach(order => {
      //     order.relationships.user.data === null ? order.user_deleted = true : order.user_deleted = false
      //     includedPage.forEach(user => {
      //       if(order.relationships.user.data !== null && order.relationships.user.data.id === user.id) {
      //         order.name = user.attributes.name
      //         order.email = user.attributes.email
      //         order.id = user.id
      //       }
      //     })
      //   })
      // } else {
      //   ordersPage.forEach(order => {
      //     order.user_deleted = true
      //   })
      // }

      return {
        ...state,
        orders: ordersPage
      }
    case LOAD_PAGE_ORDER_FAILED:
      return {
        ...state,
        error: action.error
      }
    case CREATE_ORDER:
      return {
        ...state,
        busyButton: true
      }
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        busyButton: false
      }
    case CREATE_ORDER_FAILED:
      return {
        ...state,
        busyButton: false
      }
    case SEARCH_ORDERS:
      return {
        ...state,
        busy: true
      }
    case SEARCH_ORDERS_SUCCESS:
        function getParams(url) {
          url = decodeURI(url);
          if (typeof url === 'string') {
              let params = url.split('?');
              let eachParamsArr = params[1].split('&');
              let obj = {};
              if (eachParamsArr && eachParamsArr.length) {
                  eachParamsArr.forEach(param => {
                      let keyValuePair = param.split('=')
                      let key = keyValuePair[0];
                      let value = keyValuePair[1];
                      obj[key] = value;
                  })
              }
              return obj;
          }
        }

        const lastLinkSearch = action.lastLink
        let paramsSearch = getParams(lastLinkSearch)
      
        paramsSearch = JSON.stringify(paramsSearch)
        paramsSearch = JSON.parse(paramsSearch)
        const totalPagesSearch = paramsSearch['page[number]']
        
        const includedSearch = action.includedSearch
        const ordersSearch = action.payloadSearch
        
        // ordersSearch.forEach(order => {
        //   order.relationships.user.data === null ? order.user_deleted = true : order.user_deleted = false
        //   includedSearch.forEach(user => {
        //     if(order.relationships.user.data !== null && order.relationships.user.data.id === user.id) {
        //       order.name = user.attributes.name
        //       order.email = user.attributes.email
        //       order.id = user.id
        //     }
        //   })
        // })

      return {
        ...state,
        totalPages: parseInt(totalPagesSearch),
        orders: ordersSearch,
        emptySearch: ordersSearch.length > 0 ? false : true,
        search: action.search,
        busy: false
      }
    case SEARCH_ORDERS_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    default:
      return state
  }
}

function dateBr(date) {
  var data = new Date(date),
  dia  = data.getDate().toString(),
  diaF = (dia.length === 1) ? '0'+dia : dia,
  mes  = (data.getMonth()+1).toString(),
  mesF = (mes.length === 1) ? '0'+mes : mes,
  anoF = data.getFullYear();
  return `${diaF}/${mesF}/${anoF}`
}
