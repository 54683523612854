import { connect } from 'react-redux'
import React from 'react'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom'
import { updateOrder } from '../../actions/updateOrderAction';
import { loadOrder } from '../../actions/loadOrderAction';
import { loadUser } from '../../actions/loadUserAction';
import { loadOrderComments } from '../../actions/loadOrderCommentsAction';
import { HIDE_ALERT_EDIT_ORDER } from '../../actions/actionTypes'
import { hideAlert } from '../../actions/hideAlertAction'
import { loadPageOrderComments } from '../../actions/loadPageOrderCommentsAction'
import PageMenu from '../../modules/pageMenu'
import { Form, Wrap, Text, Title, Breadcrumb, Template, Button, Container, Alert, Divider, Grid, Cell } from "sme-theme"
import { InputRedux, ErrorField } from '../../modules/inputRedux';
import AddressInfo from '../../modules/addressInfo'

class FormEditOrder extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadOrder(token, this.props.match.params.id);
    this.props.loadUser(token, this.props.match.params.user_id)
    this.props.loadOrderComments(token, 'order', this.props.match.params.id);
    localStorage.removeItem('array_errors_edit_order');
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_edit_order')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_edit_order');
    this.props.hideAlert(HIDE_ALERT_EDIT_ORDER)
  }

  loadPage = page => {
    const token = localStorage.getItem('token')
    this.props.loadPageOrderComments(token, page, 'order', this.props.match.params.id)
  }

  render() {
    const { error, handleSubmit, dirty } = this.props;
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const orderId = this.props.match.params.id
    const formError = 'array_errors_edit_order'

    return (
    <PageMenu focus="orders" loading={this.props.busy}>
      <Container>
        <Title className="mb-sm">Pedidos</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/orders">Pedidos</Button>
          <Button plugin={Link} to={`/user/${this.props.userId}`}>{this.props.userName}</Button>
          <Text>{this.props.productName}</Text>
        </Breadcrumb>
        <Template condition={localStorage.getItem('array_errors_edit_order') && error}>
          <Alert className="my-md" type="error" iconName="attention-circled">
            <Text>{this.props.error_status !== 500 && 'Alguns campos precisam da sua atenção:'}</Text>
            <ErrorField field="status" label="Entregue" formError={formError} />
            <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
          </Alert>
        </Template>
        <Template condition={this.props.alertEditSuccess}>
          <Alert className="my-md" type="success">
            <Text>Pedido atualizado com sucesso !</Text>
          </Alert>
        </Template>
        <Form onSubmit={handleSubmit(updateOrder(orderId))}>
          <Wrap className="max-w-xs">
            <InputRedux name="status" type='switch' label="Enviado" formError={'formError'} />
            <InputRedux name="tracking_code" type='text' label="Código de rastreamento" formError={'formError'} />
          </Wrap>

          <Text className="mt-xl">Informações</Text>
          <Divider className="mb-md mt-md"/>
          <Grid>
            <Cell size="3">
              <Template condition={this.props.userName}>
                <Text className="mb-sm muted">Nome</Text>
                <Text className="mb-md">{this.props.userName}</Text>
              </Template>

              <Template condition={this.props.userPlatform}>
                <Text className="mb-sm muted">Plataforma</Text>
                <Text className="mb-md">{this.props.userPlatform}</Text>
              </Template>

              <Template condition={this.props.email}>
                <Text className="mb-sm muted">E-mail</Text>
                <Text className="mb-md">{this.props.email}</Text>
              </Template>

              <Template condition={this.props.phone}>
                <Text className="mb-sm muted">Telefone</Text>
                <Text className="mb-md">{this.props.phone}</Text>
              </Template>
            </Cell>

            <Cell size="3">
              <Template condition={this.props.productName}>
                <Text className="mb-sm muted">Produto</Text>
                <Text className="mb-md">{this.props.productName}</Text>
              </Template>

              <Template condition={this.props.orderDate}>
                <Text className="mb-sm muted">Data do Pedido</Text>
                <Text className="mb-md">{this.props.orderDate}</Text>
              </Template>

              {/* <Template condition={true}>
                <Text className="mb-sm muted">Transportadora</Text>
                <Text className="mb-md">{true?'Sim':'Não'}</Text>
              </Template> */}
              {/* @TRANSPORTADORA */}
              
            </Cell>
          </Grid>

          <Text className="mt-xl">Endereço de entrega</Text>
          <Divider className="mb-md mt-md"/>
          <Wrap className="max-w-xs">
            <AddressInfo data={{
              name: this.props.hasNotAddress ? this.props.profileAddressRecipient : this.props.addressRecipient,
              cpf: this.props.hasNotAddress ? this.props.profileAddressCpf : this.props.addressCpf,
              state: this.props.hasNotAddress ? this.props.profileAddressState : this.props.addressState,
              city: this.props.hasNotAddress ? this.props.profileAddressCity : this.props.addressCity,
              cep: this.props.hasNotAddress ? this.props.profileAddressCep : this.props.addressCep,
              street: this.props.hasNotAddress ? this.props.profileAddressStreet : this.props.addressStreet,
              number: this.props.hasNotAddress ? this.props.profileAddressNumber : this.props.addressNumber,
              neighborhood: this.props.hasNotAddress ? this.props.profileAddressNeighborhood : this.props.addressNeighborhood,
              complement: this.props.hasNotAddress ? this.props.profileAddressComplement : this.props.addressComplement,
              reference: this.props.hasNotAddress ? this.props.profileAddressReference : this.props.addressReference
            }}/>
          </Wrap>
          <Button className="block mt-lg max-w-xs mb-xl" type="submit" loading={this.props.busyButton} disabled={!dirty}>Salvar</Button>
        </Form>
        {/* <FormCreateOrderComment orderId={this.props.match.params.id} /> */}
      </Container>

      {/* <Container>
        <Table className="mt-xl">
          <Thead>
            <Tr>
              <Td width={90}>Comentários</Td>
              <Td width={10}>Action</Td>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.comments.map((comment, i) => {
              return(
                <Tr key={i}>
                  <Td>{comment.attributes.body}</Td>
                  <Td>
                    <Button
                    className="sm m-0"
                    plugin={Link}
                    to={`/order/${orderId}/${this.props.userName}/${this.props.userId}/${this.props.productName}/comment/${comment.id}`}
                    >Ver +</Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
        <Template condition={!this.props.busy}>
          <Template condition={this.props.totalPagesComments > 1}>
            <Pagination page={1} size={this.props.totalPagesComments} onChange={(i)=> this.loadPage(i)} />
          </Template>
        </Template>
      </Container> */}
    </PageMenu>
  );
  }
}

const MapStateToProps = state => ({
  initialValues: {
    status: state.LoadOrder.status,
    tracking_code: state.LoadOrder.trackingCode
  },
  hasNotAddress: state.LoadOrder.hasNotAddress,
  userName: state.LoadOrder.userName,
  userPlatform: state.LoadOrder.userPlatform,
  userId: state.LoadOrder.userId,
  email: state.LoadOrder.email,
  city: state.LoadOrder.city,
  userState: state.LoadOrder.userState,
  birthdate: state.LoadOrder.birthdate,
  phone: state.LoadOrder.phone,
  orderDate: state.LoadOrder.orderDate,
  productName: state.LoadOrder.productName,
  rescueDate: state.LoadOrder.rescueDate,
  busy: state.LoadOrder.busy,
  busyButton: state.LoadOrder.busyButton,
  alertEditSuccess: state.LoadOrder.alertEditSuccess,
  comments: state.LoadOrder.comments,
  totalPagesComments: state.LoadOrder.totalPagesComments,
  addressCity: state.LoadOrder.addressCity,
  addressState: state.LoadOrder.addressState,
  addressRecipient: state.LoadOrder.recipient,
  addressCpf: state.LoadOrder.addressCpf,
  addressCep: state.LoadOrder.addressCep,
  addressStreet: state.LoadOrder.addressStreet,
  addressNumber: state.LoadOrder.addressNumber,
  addressComplement: state.LoadOrder.addressComplement,
  addressReference: state.LoadOrder.addressReference,
  addressNeighborhood: state.LoadOrder.addressNeighborhood,

  profileAddressCity: state.LoadUser.addressCity,
  profileAddressState: state.LoadUser.addressState,
  profileAddressRecipient: state.LoadUser.recipient,
  profileAddressCpf: state.LoadUser.addressCpf,
  profileAddressCep: state.LoadUser.addressCep,
  profileAddressStreet: state.LoadUser.addressStreet,
  profileAddressNumber: state.LoadUser.addressNumber,
  profileAddressComplement: state.LoadUser.addressComplement,
  profileAddressReference: state.LoadUser.addressReference,
  profileAddressNeighborhood: state.LoadUser.addressNeighborhood,

  error_status: state.LoadOrder.error
})

const MapDispatchToProps = dispatch => bindActionCreators({
  loadOrder,
  loadUser,
  loadOrderComments,
  loadPageOrderComments,
  updateOrder,
  hideAlert
}, dispatch);

FormEditOrder = reduxForm({
  form: 'formEditOrderAdmin',
  enableReinitialize: true
})(FormEditOrder);

export default connect(MapStateToProps, MapDispatchToProps)(FormEditOrder);
