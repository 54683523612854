import {
  LOAD_PAGE_ORDER_SUCCESS,
  LOAD_PAGE_ORDER_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPageOrder = (token, page, search) => async (dispatch) => {
  try {
    const result = await api.loadPageOrder(token, page, search);
    dispatch({ type: LOAD_PAGE_ORDER_SUCCESS, payload: result.data.data, included: result.data.included })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_ORDER_FAILED, error: err })
  }
}
