import {
  LOAD_CAMPAIGNS_SUCCESS,
  LOAD_CAMPAIGNS_FAILED,
  LOAD_PAGE_CAMPAIGN_SUCCESS,
  LOAD_PAGE_CAMPAIGN_FAILED,
  LOAD_CAMPAIGNS,
  FILTER_CAMPAIGNS_BY_ATTRIBUTE_SUCCESS,
  FILTER_CAMPAIGNS_BY_ATTRIBUTE_FAILED,
  FILTER_CAMPAIGNS_BY_ATTRIBUTE
} from '../actions/actionTypes';

const initialState = {
  campaigns: [],
  currentPage: null,
  busy: false,
  totalPages: 0,
  filterType: 'desc',
  filterAttribute: 'default',
  selectedFilter: '',
  filtering: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMPAIGNS:
      return {
        ...state,
        busy: true
      }
    case LOAD_CAMPAIGNS_SUCCESS:
      function getParamsFromUrl(url) {
        url = decodeURI(url);
        if (typeof url === 'string') {
            let params = url.split('?');
            let eachParamsArr = params[1].split('&');
            let obj = {};
            if (eachParamsArr && eachParamsArr.length) {
                eachParamsArr.forEach(param => {
                    let keyValuePair = param.split('=')
                    let key = keyValuePair[0];
                    let value = keyValuePair[1];
                    obj[key] = value;
                })
            }
            return obj;
        }
      }

      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params.page

      return {
        ...state,
        campaigns: action.payload.data,
        totalPages: parseInt(totalPages),
        busy: false
      }
    case LOAD_CAMPAIGNS_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case LOAD_PAGE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: action.campaigns
      }
    case LOAD_PAGE_CAMPAIGN_FAILED:
      return {
        ...state
      }
    case FILTER_CAMPAIGNS_BY_ATTRIBUTE:
      return {
        ...state,
        busy: true,
        filtering: true
      }
    case FILTER_CAMPAIGNS_BY_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
        filterAttribute: action.attribute,
        selectedFilter: action.attribute,
        filterType: action.filterType === 'desc' ? 'asc' : 'desc',
        filtering: false,
        busy: false
      }
    case FILTER_CAMPAIGNS_BY_ATTRIBUTE_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    default:
      return state
  }
}
