import {
  LOAD_QUEST_ACCOMPLISHMENTS,
  LOAD_QUEST_ACCOMPLISHMENTS_SUCCESS,
  LOAD_QUEST_ACCOMPLISHMENTS_FAILED,
} from "./actionTypes";

import api from "../services/api";

export const loadQuestAccomplishments = (token, quest_id) => async (dispatch) => {
  dispatch({ type: LOAD_QUEST_ACCOMPLISHMENTS });
  try {
    const result = await api.loadQuestAccomplishments(token, quest_id);
    dispatch({
      type: LOAD_QUEST_ACCOMPLISHMENTS_SUCCESS,
      payload: result.data.data,
      lastLink: result.data.links.last,
    });
  } catch (err) {
    dispatch({ type: LOAD_QUEST_ACCOMPLISHMENTS_FAILED, error: err });
  }
};
