import { connect } from 'react-redux'
import React from 'react'
import { verifyCode } from '../../actions/verifyCodeAction';
import { sendCodeAgain } from '../../actions/sendCodeAgainAction';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Redirect } from 'react-router-dom'
import history from '../../history'
import { Text, Button, Container, Page, Input, Title, Alert, Template } from "sme-theme"

const createRenderer = render => ({ input, meta, label, ...rest }) => (
  render(input, label, meta, rest)
)

const RenderCode = createRenderer((input, label, value, meta) =>
  <Input onChange={input.onChange} focus>{label}</Input>
);

class FormVerifyCode extends React.Component {
  constructor() {
    super();
    this.state = {value : 'show', sendCodeAgain: false}
  }

  componentDidMount() {
    localStorage.removeItem('security_code_invalid')
  }

  handleChange = (v) =>{
    this.setState({value: v});
  }
  sendCode = () => {
    this.setState({ sendCodeAgain: true })
    localStorage.removeItem('security_code_invalid')
    this.props.sendCodeAgain(localStorage.getItem('email'))
  }
  cancelVerifyCode = () => {
    localStorage.clear()
    history.push('/login')
  }

  render() {
    const { handleSubmit } = this.props;
    if(localStorage.getItem('token')) { return <Redirect to="/campaigns" /> }
    // if(!localStorage.getItem('pre_token')) { return <Redirect to="/login" /> }

    return (
      <Page name="login-auth" className="h-full flex-align-center flex-justify-center">
        <Container className="max-w-xs">
          <Title className="mb-sm">Código de segurança</Title>
          <Text className="mb-lg">Um código de verificação foi enviado para <br/>{localStorage.getItem('email')}</Text>

          <Template condition={localStorage.getItem('security_code_invalid')}>
            <Alert className="my-md" type="error" title="Código de segurança inválido.">
              <Text>- Verifique se digitou o código correto;</Text>
              <Text>- Seu código pode ter expirado, tente pedir um novo.</Text>
            </Alert>
          </Template>

          <Template condition={this.state.sendCodeAgain}>
            <Alert className="my-md" type="success" iconName="check-circle-o">
              <Text>Um novo código de segurança foi enviado para seu email.</Text>
            </Alert>
          </Template>

          <form onSubmit={handleSubmit(verifyCode)}>
            <Field name="code" label="Código" className="mb-sm" component={RenderCode} />
            <Button className="block mb-lg" type="submit">CONTINUAR</Button>
          </form>
          <Button className="link mb-md" onClick={() => this.sendCode()}>Reenviar código</Button><br/>
          <Button className="link" onClick={() => this.cancelVerifyCode()}>Cancelar</Button>
        </Container>
      </Page>
   );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ verifyCode, sendCodeAgain }, dispatch);

FormVerifyCode = reduxForm({
  form: 'formVerifyCodeAdmin'
})(FormVerifyCode);

export default connect(null, mapDispatchToProps)(FormVerifyCode);
