import {
  LOAD_CAMPAIGN_CONTENT,
  LOAD_CAMPAIGN_CONTENT_SUCCESS,
  LOAD_CAMPAIGN_CONTENT_FAILED,
  SELECT_CAMPAIGN_TAB,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILED,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAILED,
  HIDE_ALERT_CREATE_CAMPAIGN
} from '../actions/actionTypes';

const initialState = {
  name: '',
  status: null,
  date_start: '',
  date_finish: '',
  image_gift: '',
  currentTab: 'content',
  busy: false,
  busyButton: false,
  alertCreateCampaign: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMPAIGN_CONTENT:
      return {
        ...state,
        busy: true
      }
    case LOAD_CAMPAIGN_CONTENT_SUCCESS:
      const { status, name, date_start, date_finish, image_gift, enable_banner } = action.payload

      const dateStart = getDateBr(date_start)
      const dateFinish = getDateBr(date_finish)
      return {
        ...state,
        status: status,
        name: name,
        date_start: dateStart,
        date_finish: dateFinish,
        image_gift: image_gift,
        enable_banner: enable_banner,
        busy: false
      }
    case LOAD_CAMPAIGN_CONTENT_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case SELECT_CAMPAIGN_TAB:
      return {
        ...state,
        currentTab: action.tab
      }
    case CREATE_CAMPAIGN:
      return {
        ...state,
        busyButton: true
      }
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        busyButton: false,
        alertCreateCampaign: true
      }
    case CREATE_CAMPAIGN_FAILED:
      return {
        ...state,
        busyButton: false,
        alertCreateCampaign: false,
        error: action.error
      }
    case HIDE_ALERT_CREATE_CAMPAIGN:
      return {
        ...state,
        alertCreateCampaign: false
      }
    case UPDATE_CAMPAIGN:
      return {
        ...state,
        busyButton: true
      }
    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        busyButton: false
      }
    case UPDATE_CAMPAIGN_FAILED:
      return {
        ...state,
        busyButton: false,
        error: action.error
      }
    default:
      return state
  }
}

const getDateBr = date => {
  let year = '';
  let month = '';
  let day = '';

  for(var i = 0; i < date.length; i++){
    if(i <= 3) {
      year += date[i]
    }
    if(i === 5 || i === 6){
      month += date[i]
    }
    if(i === 8 || i === 9){
      day += date[i]
    }
  }
  const date_br = day + '/' + month + '/' + year;
  return date_br;
}
