import { connect } from 'react-redux'
import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form';
import PageMenu from '../../modules/pageMenu'
import { loadCurrentAdmin } from '../../actions/loadCurrentAdminAction';
import { loadCampaign } from '../../actions/loadCampaignAction';
import { updateCampaign } from '../../actions/updateCampaignAction';
import { loadUsersCampaign } from '../../actions/loadUsersCampaignAction';
import { selectCampaignTab } from '../../actions/selectCampaignTabAction';
import { Wrap, Text, Title, Breadcrumb, Template, Tab, Button, Container, Alert, Icon, Table, Tbody, Thead, Td, Tr, Tabs } from "sme-theme"
import { InputRedux, InputReduxGroup, ErrorField } from '../../modules/inputRedux';

class FormCampaign extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadCurrentAdmin(token);
    this.props.loadCampaign(token, this.props.match.params.id)
    this.props.loadUsersCampaign(token, this.props.match.params.id)
    localStorage.removeItem('array_errors_campaign')
    localStorage.removeItem('campaign_updated')
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_campaign')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_campaign')
    localStorage.removeItem('campaign_updated')
  }

  changeTab = tab => {
    this.props.selectCampaignTab(tab)
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const { error, handleSubmit, dirty } = this.props;
    const { match: { params } } = this.props;
    const idCampaign = params.id
    const formError = 'array_errors_campaign'
    localStorage.setItem('current_campaign_name', this.props.initialValues.name)
    localStorage.setItem('current_campaign_id', this.props.match.params.id)

    return (
    <PageMenu focus="campaigns" loading={this.props.busy}>
      <Container>
        <Title className="mb-sm">Artista do momento</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/campaigns">Artista do Momento</Button>
          <Text>{this.props.initialValues.name}</Text>
        </Breadcrumb>
        <Tabs className="mt-xl">
          <Button active={this.props.tab === 'content'} onClick={() => this.changeTab('content')}>Conteúdo</Button>
          <Button active={this.props.tab === 'users'} onClick={() => this.changeTab('users')}>Usuários</Button>
        </Tabs>

        <Tab render={this.props.tab === 'content'}>
          <Template condition={localStorage.getItem('array_errors_campaign') && error}>
            <Alert className="my-md" type="error" iconName="attention-circled">
              <Text>{this.props.error_status !== 500 && 'Alguns campos precisam da sua atenção:'}</Text>
              <ErrorField field="name" label="Nome do artista" formError={formError} />
              <ErrorField field="date_start" label="Início" formError={formError} />
              <ErrorField field="date_finish" label="Término" formError={formError} />
              <ErrorField field="image" label="Imagem" formError={formError} />
              <ErrorField field="campaign_errors" label="Início e término" formError={formError} />
              <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
            </Alert>
          </Template>

          <Template condition={localStorage.getItem('campaign_updated')}>
            <Alert className="my-md" type="success">
              <Text>Artista do momento foi atualizado com sucesso.</Text>
            </Alert>
          </Template>

          <Wrap className="max-w-xs">
            <form onSubmit={handleSubmit(updateCampaign(idCampaign))} >
                <InputRedux name="status" type="switch" label="Ativar" formError={formError} />
                <InputRedux name="enable_banner" type="switch" label="Mostrar Artista" formError={formError} />
                <InputRedux name="name" type="text" label="Nome do Artista" formError={formError} />
                <InputReduxGroup name="date_start" groupName="campaign_errors" mask="99/99/9999" label="Início" formError={formError} />
                <InputReduxGroup name="date_finish" groupName="campaign_errors" mask="99/99/9999" label="Término" formError={formError} />

                <InputRedux name="banner_image" type="file" buttonClass="sm" helpText="Tamanho da imagem: 180x180" label="Banner" formError={formError} />
                <InputRedux name="image" type="file" buttonClass="sm" helpText="Tamanho da imagem: 1100x196" label="Imagem" formError={formError} />
                <InputRedux name="image_medium" type="file" buttonClass="sm" helpText="Tamanho da imagem: 718x196" label="Imagem Média" formError={formError} />
                <InputRedux name="image_small" type="file" buttonClass="sm" helpText="Tamanho da imagem: 414x196" label="Imagem Pequena" formError={formError} />
              <Button className="block mt-lg" type="submit" loading={this.props.busyButton} disabled={!dirty}>SALVAR</Button>
            </form>
          </Wrap>

        </Tab>

        <Tab render={this.props.tab === 'users'}>
          <Button>DOWNLOAD</Button>
          <Table>
            <Thead>
              <Tr>
                <Td width={40}>Nome <Icon name="arrow-down"/></Td>
                <Td width={30}>Cidade <Icon name="arrow-down"/></Td>
                <Td>Pontos <Icon name="arrow-down"/></Td>
                <Td width="100px">Action</Td>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.users.map((user, i) => {
                return(
                  <Tr key={i}>
                    <Td>{user.name}</Td>
                    <Td>{user.city ? user.city.name : '' }</Td>
                    <Td>{user.total_balance}</Td>
                    <Td>
                      <Button className="circle m-0" plugin={Link} to={`/campaign-user/${user.id}`} title="Ver +" ><Icon name="eye" /></Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Tab>
      </Container>
    </PageMenu>
  );
  }
}

const MapStateToProps = state => {
  return({
  
  initialValues: {
    status: state.LoadCampaignContent.status,
    name: state.LoadCampaignContent.name,
    date_start: state.LoadCampaignContent.date_start,
    date_finish: state.LoadCampaignContent.date_finish,
    image: state.LoadCampaignContent.image_gift,
    enable_banner: state.LoadCampaignContent.enable_banner,
  },
  users: state.LoadUsersCampaign.users,
  tab: state.LoadCampaignContent.currentTab,
  busy: state.LoadCampaignContent.busy,
  busyButton: state.LoadCampaignContent.busyButton,
  error_status: state.LoadCampaignContent.error
})};

const MapDispatchToProps = dispatch => bindActionCreators({
  loadCurrentAdmin,
  loadCampaign,
  updateCampaign,
  loadUsersCampaign,
  selectCampaignTab
}, dispatch);

FormCampaign = reduxForm({
  form: 'updateCampaign',
  enableReinitialize: true
})(FormCampaign);

export default connect(MapStateToProps, MapDispatchToProps)(FormCampaign);
