import {
  UPDATE_PROFILE_ADMIN,
  UPDATE_PROFILE_ADMIN_SUCCESS,
  UPDATE_PROFILE_ADMIN_FAILED
} from './actionTypes';

import api from '../services/api';
import {change, untouch} from 'redux-form';
import { SubmissionError } from 'redux-form'

export const updateProfileAdmin = (initialEmail) => async (value, dispatch) => {
  dispatch({ type: UPDATE_PROFILE_ADMIN })
  const { email, password_current, new_password, new_password_confirmation } = value

  const resetFields = (formName, fieldsObj) => {
    Object.keys(fieldsObj).forEach(fieldKey => {
      dispatch(change(formName, fieldKey, fieldsObj[fieldKey]));
      dispatch(untouch(formName, fieldKey));
    });
  }

  const data = {
    "admin": {
      "email": email,
      "password_current": password_current,
      "new_password": new_password,
      "new_password_confirmation": new_password_confirmation
    }
  }
  localStorage.removeItem('edit_admin_profile_ok_pass')
  localStorage.removeItem('edit_admin_profile_ok_email')

  try {
    const token = localStorage.getItem('token');
    const result = await api.profileAdminUpdate(token, data)
    dispatch({ type: UPDATE_PROFILE_ADMIN_SUCCESS, payload: result })

    if(initialEmail === data.admin.email) {
      if(data.admin.new_password === data.admin.new_password_confirmation && data.admin.new_password != null) {
        localStorage.setItem('edit_admin_profile_ok_pass', true)
      } else {
        localStorage.setItem('edit_admin_profile_ok_email', true)
      }
    } else {
      localStorage.setItem('edit_admin_profile_ok_email', true)
    }

    resetFields('updateProfileAdmin', { password_current: '', new_password: '', new_password_confirmation: ''})
  } catch(err) {
    dispatch({ type: UPDATE_PROFILE_ADMIN_FAILED, error: err.response.status })
    localStorage.setItem('array_errors_profile', JSON.stringify(err.response.data))
    if(err.response.status === 500) {
      localStorage.setItem('array_errors_profile', JSON.stringify({ server_error: ['Internal Server Error'] }))
    }
    throw new SubmissionError({_error: 'error'})
  }
}
