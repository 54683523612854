import {
  LOAD_ACCOUNTS,
  LOAD_ACCOUNTS_SUCCESS,
  LOAD_ACCOUNTS_FAILED,
  LOAD_PAGE_ACCOUNT_SUCCESS,
  LOAD_PAGE_ACCOUNT_FAILED
} from '../actions/actionTypes';

const initialState = {
  accounts: [],
  busy: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ACCOUNTS:
      return {
        ...state,
        busy: true
      }
    case LOAD_ACCOUNTS_SUCCESS:
      function getParamsFromUrl(url) {
        url = decodeURI(url);
        if (typeof url === 'string') {
            let params = url.split('?');
            let eachParamsArr = params[1].split('&');
            let obj = {};
            if (eachParamsArr && eachParamsArr.length) {
                eachParamsArr.forEach(param => {
                    let keyValuePair = param.split('=')
                    let key = keyValuePair[0];
                    let value = keyValuePair[1];
                    obj[key] = value;
                })
            }
            return obj;
        }
      }

      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params.page

      return {
        ...state,
        accounts: action.payload,
        totalPages: parseInt(totalPages),
        busy: false
      }
    case LOAD_ACCOUNTS_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case LOAD_PAGE_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: action.accounts
      }
    case LOAD_PAGE_ACCOUNT_FAILED:
      return {
        ...state
      }
    default:
      return state
  }
}
