import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import React from 'react'
import { bindActionCreators } from 'redux'
import { loginAdmin } from '../../actions/loginAction';
import { Text, Button, Container, Page, Input, Title, Template, Alert } from  "sme-theme"
import { Redirect, Link } from "react-router-dom";
import Logo from '../../modules/images/logo'

const createRenderer = render => ({ input, meta, label, ...rest }) => (
  render(input, label, meta, rest)
)
const RenderLogin = createRenderer((input, label, value, meta) =>
  <Input onChange={input.onChange} focus>{label}</Input>
);
const RenderPassword = createRenderer((input, label, value, meta) =>
  <Input onChange={input.onChange} type="password" >{label}</Input>
);

class FormLogin extends React.Component {
  constructor() {
    super();
    this.state = {value : 'show'}
  }

  componentDidMount() {
    localStorage.removeItem('admin_login_error')
  }

  componentDidUpdate() {
    localStorage.removeItem('admin_login_error')
    localStorage.removeItem('new_password_sent')
  }

  handleChange = (v) =>{
    this.setState({value: v});
  }

  render() {
    const { error, handleSubmit } = this.props;
    if(localStorage.getItem('token')) { return <Redirect to="/campaigns" /> }
    return (
    <Page name="login" className="h-full flex-align-center flex-justify-center">
      <Container className="max-w-xs">

        <Logo className="mb-sm w-sm"/>
        <Title className="mb-lg">Bem-vindo!</Title>

        <Template condition={localStorage.getItem('admin_login_error') && error}>
          <Alert className="my-md" type="error">
            <Text>{ localStorage.getItem('admin_login_error') }</Text>
          </Alert>
        </Template>

        <Template condition={localStorage.getItem('new_password_sent')}>
          <Alert className="my-md" type="success" iconName="check-circle-o">
            <Text>Uma nova senha foi enviada para seu email !</Text>
          </Alert>
        </Template>

        <form onSubmit={handleSubmit(loginAdmin)}>
          <Field name="email" label="Login" component={RenderLogin} />
          <Field name="password" label="Senha" component={RenderPassword} />
          <Button className="block" type="submit">Entrar</Button>
          <Button plugin={Link} to="/new-pass" tag={Link} className="link" type="submit">Esqueci minha senha</Button>
        </form>
      </Container>
    </Page>
  );
  }
}


const mapDispatchToProps = dispatch => bindActionCreators({ loginAdmin }, dispatch);

FormLogin = reduxForm({
  form: 'formLoginAdmin'
})(FormLogin);

export default connect(null, mapDispatchToProps)(FormLogin);
