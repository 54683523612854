import {
  LOAD_BADGE,
  LOAD_BADGE_SUCCESS,
  LOAD_BADGE_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadBadge = (token, badge_id) => async (dispatch) => {
  dispatch({ type: LOAD_BADGE })
  try {
    const result = await api.loadBadge(token, badge_id);
    dispatch({ type: LOAD_BADGE_SUCCESS, payload: result.data.data })
  } catch(err) {
    dispatch({ type: LOAD_BADGE_FAILED, error: err })
  }
}
