import {
  CREATE_BADGE,
  CREATE_BADGE_SUCCESS,
  CREATE_BADGE_FAILED
} from './actionTypes';

import api from '../services/api';
import history from '../history';
import { SubmissionError } from 'redux-form'

export const createBadge = async (badge_attributes, dispatch) => {
  dispatch({ type: CREATE_BADGE })
  const { voucher, name, status, goal, description, uri, image} = badge_attributes

  let new_status = false, voucher_status = false;
  if(status) {
    new_status = true;
  }

  if(voucher === undefined || voucher === '- Selecionar -') {
    voucher_status = undefined
  } else if(voucher === 'coupons') {
    voucher_status = true
  } else if(voucher === 'badge') {
    voucher_status = false
  }

  function is_url(str) {
    var regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  let data;

  if(is_url(image) || image === undefined) {
    data = {
      "challenge": {
        "name": name,
        "status": new_status,
        "goal": goal,
        "voucher": voucher_status,
        "description": description,
        "uri": uri
      }
    }
  } else {
    const badge_image = await getBase64(image[0]);
    data = {
      "challenge": {
        "name": name,
        "status": new_status,
        "goal": goal,
        "voucher": voucher_status,
        "description": description,
        "uri": uri,
        "image": {
          "data": badge_image
        }
      }
    }
  }
  
  localStorage.removeItem('select_kind_voucher_error')

  if(voucher_status === undefined) {
    localStorage.setItem('select_kind_voucher_error', true)
    dispatch({ type: CREATE_BADGE_FAILED })
  } else {
    try {
      const token = localStorage.getItem('token')
      await api.createBadge(token, data);
      dispatch({ type: CREATE_BADGE_SUCCESS })
      history.push('/badges')
    } catch(err) {
      dispatch({ type: CREATE_BADGE_FAILED, error: err.response.status })
      localStorage.setItem('array_errors_create_badge', JSON.stringify(err.response.data))
      if(err.response.status === 500) {
        localStorage.setItem('array_errors_create_badge', JSON.stringify({ server_error: ['Internal Server Error'] }))
      }
      throw new SubmissionError({_error: 'error'})
    }
  }
}