import {
  LOAD_RESCUE,
  LOAD_RESCUE_SUCCESS,
  LOAD_RESCUE_FAILED,
  EDIT_RESCUE,
  EDIT_RESCUE_SUCCESS,
  EDIT_RESCUE_FAILED
} from '../actions/actionTypes';

const initialState = {
  uri: '',
  artists: '',
  trackName: '',
  totalUsers: '',
  totalStreamings: '',
  score: '',
  status: false,
  limited: false,
  limitStreams: '',
  busy: false,
  busyButton: false,
  playlist: null,
  infoPlaylist: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_RESCUE:
      return {
        ...state,
        busy: true,
      }
    case LOAD_RESCUE_SUCCESS:
      const { 
        uri,
        score,
        status,
        limited,
        limit_streams,
        playlist,
        info_playlist,
        artists,
        track_name,
        total_users,
        rescues_count,
        priority,
      } = action

      return {
        ...state,
        uri,
        score,
        status,
        limited,
        limitStreams: limit_streams,
        playlist,
        infoPlaylist: info_playlist,
        artists,
        trackName: track_name,
        totalUsers: total_users,
        totalStreamings: rescues_count,
        busy: false,
        priority
      }
    case LOAD_RESCUE_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case EDIT_RESCUE:
      return {
        ...state,
        busyButton: true,
      }
    case EDIT_RESCUE_SUCCESS:
      return {
        ...state,
        busyButton: false,
      }
    case EDIT_RESCUE_FAILED:
      return {
        ...state,
        busyButton: false,
        error: action.error
      }
    default:
      return state
  }
}
