import {
  LOAD_QUEST,
  LOAD_QUEST_SUCCESS,
  LOAD_QUEST_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadQuest = (token, rescue_id) => async (dispatch) => {
  dispatch({ type: LOAD_QUEST })
  try {
    const result = await api.loadQuest(token, rescue_id);
    dispatch({ type: LOAD_QUEST_SUCCESS, payload: result.data })
  } catch(err) {
    dispatch({ type: LOAD_QUEST_FAILED, error: err })
  }
}
