import { connect } from 'react-redux'
import React from 'react'
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form';
import PageMenu from '../../modules/pageMenu'
import { Alert, Text, Button, Container, Table, Tbody, Thead, Td, Tr, Title, Status, Template, Pagination, Icon } from "sme-theme"
import { bindActionCreators } from 'redux'
import { hideAlert } from '../../actions/hideAlertAction'
import { loadPlaylists } from '../../actions/loadPlaylistsAction';
import { HIDE_ALERT_CREATE_PLAYLIST } from '../../actions/actionTypes'
import { loadPagePlaylist } from '../../actions/loadPagePlaylistAction'

class JukeboxList extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadPlaylists(token);
  }

  componentWillUnmount() {
    this.props.hideAlert(HIDE_ALERT_CREATE_PLAYLIST)
  }

  loadPage = (page) => {
    const token = localStorage.getItem('token')
    this.props.loadPagePlaylist(token, page)
  }

  render() {
    return (
      <PageMenu focus="jukebox" loading={this.props.busy}>
        <Container>
          <Title className="flex-align-center">Jukebox
            <Button plugin={Link} to="/jukebox-new" className="sm mb-0 ml-lg">NOVA SELEÇÃO</Button>
          </Title>
          <Template condition={this.props.alertCreatePlaylist}>
            <Alert className="my-md" type="success">
              <Text>Parabéns!</Text>
              <Text>A playlist foi criada com sucesso. Vamos arrasar!</Text>
            </Alert>
          </Template>
          <Table>
            <Thead>
              <Tr>
                <Td width={75}>Nome</Td>
                <Td width={15}>Status</Td>
                <Td width={10}>Actions</Td>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.playlists.map((playlist, i) => {
                return(
                  <Tr key={i}>
                    <Td nowrap>{playlist.attributes.name}</Td>
                    <Td>
                      <Template condition={playlist.attributes.status}><Status success>Ativo</Status></Template>
                      <Template condition={!playlist.attributes.status}><Status error>Inativo</Status></Template>
                    </Td>
                    <Td>
                      <Button title="Editar" className="circle m-0" plugin={Link} to={`/jukebox-edit/${playlist.id}`}><Icon name="pencil" /></Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
          <Template condition={this.props.totalPages > 1}>
            <Pagination page={1} size={this.props.totalPages} onChange={(i)=> this.loadPage(i)} />
          </Template>
        </Container>
      </PageMenu>
    );
  }
}

const mapStateToProps = state => ({
  playlists: state.LoadPlaylists.playlists,
  alertCreatePlaylist: state.LoadPlaylists.alertCreatePlaylist,
  busy: state.LoadPlaylists.busy,
  totalPages: state.LoadPlaylists.totalPages
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadPlaylists,
  hideAlert,
  loadPagePlaylist
}, dispatch);

JukeboxList = reduxForm({
  form: 'formJukeboxList',
  enableReinitialize: true
})(JukeboxList);

export default connect(mapStateToProps, mapDispatchToProps)(JukeboxList);
