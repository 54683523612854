import {
  DELETE_RESCUE_SUCCESS,
  DELETE_RESCUE_FAILED
} from './actionTypes';

import api from '../services/api';

export const deleteRescue = (token, rescue) => async (dispatch) => {
  try {
    const result = await api.deleteRescue(token, rescue.id);
    console.log(result)
    dispatch({ type: DELETE_RESCUE_SUCCESS, rescue: rescue })
  } catch(err) {
    dispatch({ type: DELETE_RESCUE_FAILED })
    console.log(err)
  }
}
