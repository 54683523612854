import {
  LOAD_ORDERS_OF_USER,
  LOAD_ORDERS_OF_USER_SUCCESS,
  LOAD_ORDERS_OF_USER_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadOrdersOfUser = (token, user_id) => async (dispatch) => {
  dispatch({ type: LOAD_ORDERS_OF_USER })
  try {
    const result = await api.loadOrdersOfUser(token, user_id)
    
    dispatch({
      type: LOAD_ORDERS_OF_USER_SUCCESS,
      payload: result.data,
      included: result.data.included
    })

  } catch(err) {
    dispatch({ type: LOAD_ORDERS_OF_USER_FAILED, error: err })
  }
}
