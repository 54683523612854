import React from 'react';
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import PageMenu from '../../modules/pageMenu'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux'
import { loadSettings } from '../../actions/loadSettingsAction';
import { updateSettings } from '../../actions/updateSettingsAction';
import { Template, Alert, Container, Title, Text, Wrap, Form, Button, Divider } from "sme-theme"
import { InputRedux, ErrorField } from '../../modules/inputRedux';

class FormUpdateSettings extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadSettings(token)
    localStorage.removeItem('array_errors_edit_settings')
    localStorage.removeItem('edit_settings_ok');
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_edit_settings')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_edit_settings');
    localStorage.removeItem('edit_settings_ok');
  }

  render(){
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const { error, handleSubmit, dirty } = this.props;
    const formError = 'array_errors_edit_settings'

    return (
      <PageMenu focus="settings" loading={this.props.busy}>
        <Container>
          <Title>Settings</Title>
            <Button to="/scanner" plugin={Link} className="link mt-md mb-lg">Scanner</Button>
            <Template condition={localStorage.getItem(formError) && error}>
              <Alert className="my-md" type="error" iconName="attention-circled">
                <Text>{this.props.error_status !== 500 && 'Alguns campos precisam da sua atenção:'}</Text>
                <ErrorField field="title" label="Título" formError={formError} />
                <ErrorField field="subtitle" label="Subtítulo" formError={formError} />
                <ErrorField field="terms_and_conditions" label="Termos e condições" formError={formError} />
                <ErrorField field="invitation_score" label="Filtr Coins por convite" formError={formError} />
                <ErrorField field="invitation_quantity" label="Total de convites" formError={formError} />
                <ErrorField field="profile_completed_score" label="Pontos de perfil completo" formError={formError} />
                <ErrorField field="limited_gratification_score" label="Bônus limite hits do momento" formError={formError} />
                <ErrorField field="uri_playlist" label="URI da playlist" formError={formError} />
                <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
              </Alert>
            </Template>
            <Template condition={localStorage.getItem('edit_settings_ok') === 'ok'}>
              <Alert className="my-md" type="success">
                <Text>Settings atualizado com sucesso!</Text>
              </Alert>
            </Template>

            <Template condition={this.props.termsLoaded}>
                <Form onSubmit={handleSubmit(updateSettings)}>
                  <Wrap className="max-w-xs">
                    <InputRedux name="title" type="text" label="Título" formError={formError} />
                    <InputRedux name="subtitle" type="text" label="Subtítulo" formError={formError} />
                    <InputRedux name="invitation_quantity" type="text" label="Total de convites" formError={formError} />
                    <InputRedux name="invitation_score" type="text" label="Filtr Coins por convite" formError={formError} />
                    <InputRedux name="profile_completed_score" type="text" label="Pontos de perfil completo" formError={formError} />
                    <InputRedux name="limited_gratification_score" type="text" label="Bônus limite hits do momento" formError={formError} />
                  </Wrap>
                  <InputRedux name="terms_and_conditions" type="textarea" label="Termos e condições" formError={formError} />

                  <br />
                  <Text className="mt-xl">Playlist Filtr Game</Text>
                  <Divider className="mb-md mt-md"/>
                  <Wrap className="max-w-xs">
                    <InputRedux name="show_playlist" type="switch" label="Exibir" />
                    <InputRedux name="uri_playlist" type="text" label="URI da playlist" formError={formError} />
                    <InputRedux name="uri_playlist_cover" type="text" label="URI da capa" formError={formError} />
                  </Wrap>

                  <br />
                  <Text className="mt-xl">Splash Screen</Text>
                  <Divider className="mb-md mt-md"/>
                  <Wrap className="max-w-xs">
                    <InputRedux name="splash_screen_title" type="text" label="Título" formError={formError} />
                    <InputRedux name="splash_screen_message" type="textarea" label="Texto Splsh Screen" formError={formError} />
                    <InputRedux name="enabled_splash_screen" type="switch" label="Habilitar Spash Screen" />
                  </Wrap>

                  <br />
                  <Text className="mt-xl">Consentimento de Cookie</Text>
                  <Divider className="mb-md mt-md"/>
                  <Wrap className="max-w-xs">
                    <InputRedux name="cookie_consent_title" type="text" label="Título de consentimento de cookie" formError={formError} />
                    <InputRedux name="cookie_consent_text" type="textarea" label="Texto de consentimento de cookie" formError={formError} />
                    <InputRedux name="show_cookie_consent" type="switch" label="Habilitar consentimento de cookie" />
                  </Wrap>
                  <Button className="block mt-lg max-w-xs" type="submit" loading={this.props.busyButton} disabled={!dirty}>Salvar</Button>
                </Form>
            </Template>
        </Container>
      </PageMenu>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    title: state.LoadSettings.title,
    subtitle: state.LoadSettings.subtitle,
    terms_and_conditions: state.LoadSettings.termsAndConditions,
    invitation_score: state.LoadSettings.invitationScore,
    invitation_quantity: state.LoadSettings.invitationQuantity,
    profile_completed_score: state.LoadSettings.profileCompletedScore,
    limited_gratification_score: state.LoadSettings.limitedGratificationScore,
    show_playlist: state.LoadSettings.show_playlist,
    uri_playlist: state.LoadSettings.uri_playlist,
    uri_playlist_cover: state.LoadSettings.uri_playlist_cover,
    enabled_splash_screen: state.LoadSettings.enabled_splash_screen,
    splash_screen_message: state.LoadSettings.splash_screen_message,
    splash_screen_title: state.LoadSettings.splash_screen_title,
    cookie_consent_title: state.LoadSettings.cookie_consent_title,
    cookie_consent_text: state.LoadSettings.cookie_consent_text,
    show_cookie_consent: state.LoadSettings.show_cookie_consent,
  },
  busy: state.LoadSettings.busy,
  busyButton: state.LoadSettings.busyButton,
  termsLoaded: state.LoadSettings.termsLoaded,
  error_status: state.LoadSettings.error
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadSettings,
  updateSettings
}, dispatch);

FormUpdateSettings = reduxForm({
  form: 'formUpdateSettings',
  enableReinitialize: true
})(FormUpdateSettings);

export default connect(mapStateToProps, mapDispatchToProps)(FormUpdateSettings);
