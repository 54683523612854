import {
  CREATE_GRATIFICATION,
  CREATE_GRATIFICATION_SUCCESS,
  CREATE_GRATIFICATION_FAILED,
  LOAD_USER_FULL_LOG_SUCCESS
} from './actionTypes';

import api from '../services/api';

import {change, untouch} from 'redux-form';
import { SubmissionError } from 'redux-form'

export const createGratification = (user_id) => async (value, dispatch) => {
  dispatch({ type: CREATE_GRATIFICATION })
  const { score, score_confirmation } = value
  
  const resetFields = (formName, fieldsObj) => {
    Object.keys(fieldsObj).forEach(fieldKey => {
      dispatch(change(formName, fieldKey, fieldsObj[fieldKey]));
      dispatch(untouch(formName, fieldKey));
    });
  }
  
  const data = {
    "gratification": {
      "user_id": user_id,
      "score": parseInt(score),
      "score_confirmation": parseInt(score_confirmation)
    }
  }
  try {
    const token = localStorage.getItem('token')
    await api.createGratification(token, data);
    const result = await api.loadUserFullLog(token, user_id);

    dispatch({ type: LOAD_USER_FULL_LOG_SUCCESS, payload: result.data.data, lastLink: result.data.links.last })
    dispatch({ type: CREATE_GRATIFICATION_SUCCESS })

    resetFields('formCreateGratification', { score: '', score_confirmation: '' })
  }catch(err) {
    dispatch({ type: CREATE_GRATIFICATION_FAILED, error: err.response.status })
    localStorage.setItem('array_errors_create_gratification', JSON.stringify(err.response.data))
    if(err.response.status === 500) {
      localStorage.setItem('array_errors_create_gratification', JSON.stringify({ server_error: ['Internal Server Error'] }))
    }
    throw new SubmissionError({_error: 'error'})
  }
}
