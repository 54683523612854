import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { activateAccount } from '../actions/activateAccountAction';

const ForgetPassRedirect = (props) => {
  const { match: { params } } = props;
  props.activateAccount(params.token_reset)
  return null
}

const MapDispatchToProps = dispatch => bindActionCreators({ activateAccount }, dispatch);
export default connect(null, MapDispatchToProps)(ForgetPassRedirect);
