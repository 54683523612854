import React from 'react'

import { Wrap, Text, Template } from "sme-theme"


class UserInfo extends React.Component {

  render() {
    const {data} = this.props
    return (
      <Wrap>
        <Template condition={data.name !== undefined}>
          <Text className="mb-sm muted">Nome</Text>
          <Text className="mb-md">{data.name}</Text>
        </Template>

        <Template condition={data.platform !== undefined}>
          <Text className="mb-sm muted">Plataforma</Text>
          <Text className="mb-md">{data.platform}</Text>
        </Template>

        <Template condition={data.email !== undefined}>
          <Text className="mb-sm muted">E-mail</Text>
          <Text className="mb-md">{data.email}</Text>
        </Template>

        <Template condition={data.birthdate !== undefined}>
          <Text className="mb-sm muted">Data de nascimento</Text>
          <Text className="mb-md">{data.birthdate}</Text>
        </Template>

        <Template condition={data.stateAcronym !== undefined}>
          <Text className="mb-sm muted">Estado</Text>
          <Text className="mb-md">{data.stateAcronym}</Text>
        </Template>

        <Template condition={data.city !== undefined}>
          <Text className="mb-sm muted">Cidade</Text>
          <Text className="mb-md">{data.city}</Text>
        </Template>

        <Template condition={data.phone !== undefined}>
          <Text className="mb-sm muted">Telefone</Text>
          <Text className="mb-md">{data.phone}</Text>
        </Template>
        
        <Template condition={data.product !== undefined}>
          <Text className="mb-sm muted">Produto</Text>
          <Text className="mb-md">{data.product}</Text>
        </Template>
        
        <Template condition={data.orderDate !== undefined}>
          <Text className="mb-sm muted">Data do Pedido</Text>
          <Text className="mb-md">{data.orderDate}</Text>
        </Template>
      </Wrap>
   );
  }
}

// UserInfo.defaultProps = {
//   condition: true,
//   array: undefined
// };

export default UserInfo;
