import { connect } from 'react-redux'
import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm, formValueSelector } from 'redux-form';
import PageMenu from '../../modules/pageMenu'
import { loadPlayerPlaylists } from '../../actions/loadPlayerPlaylistsAction';
import { updateSelectedPlaylist } from '../../actions/updateSelectedPlaylistAction';
import { Form, Wrap, Text, Title, Breadcrumb, Button, Container, Icon, Table, Tbody, Thead, Td, Tr, Image } from "sme-theme"
import { InputRedux } from '../../modules/inputRedux';
import { ActionCableProvider, ActionCableConsumer } from 'react-actioncable-provider';
import { playJukebox } from '../../actions/playJukeboxAction'
import { stopJukebox } from '../../actions/stopJukeboxAction'
import { checkTrackActive } from '../../actions/checkTrackActiveAction'
import { refreshToPlay } from '../../actions/refreshToPlayAction'
import { loadJukeboxes } from '../../actions/loadJukeboxesAction'
import { loadToPlay } from '../../actions/loadToPlayAction'
import { removeJukeboxUserTrack } from '../../actions/removeJukeboxUserTrackAction'
import Smile from '../../assets/images/default-user.svg'

class JukeboxPlayer extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadPlayerPlaylists(token)
    this.props.loadJukeboxes(token)
    this.props.loadToPlay(token)
  }

  msToMinutes(ms) {
    let seconds = ms / 1000;
    let minutes = seconds / 60;

    seconds = seconds % 60;
    minutes = minutes % 60;

    minutes = Math.trunc(minutes)
    seconds = Math.trunc(seconds).toString().length === 1 ? '0' + Math.trunc(seconds) : Math.trunc(seconds)

    return `${minutes}:${seconds}`;
  }

  formatPlaylists(playlists) {
    let new_playlists_format = []
    playlists.forEach(playlist => {
      const new_playlist = { value: parseInt(playlist.id), label: playlist.attributes.name }
      new_playlists_format.push(new_playlist)
    });
    return new_playlists_format
  }

  selectPlaylist = id => {
    if(id !== this.props.initialValues.playlists && !this.props.busy && !isNaN(id)) {
      const playlist = this.props.allPlaylists.filter(item => item.id === id)
      this.props.updateSelectedPlaylist(playlist)
    }
  }

  handlePlay() {
    const token = localStorage.getItem('token')
    const status = this.props.statusJukebox
    if(status === 'waiting') {
      this.props.playJukebox(token)
    } else if(status === 'stoped'){
      this.props.playJukebox(token)
    } else if(status === 'playing') {
      this.props.stopJukebox(token)
    }
  }

  verifyStatusButton() {
    const status = this.props.statusJukebox
    if(status === 'waiting') {
      return 'PLAY'
    } else if(status === 'stoped') {
      return 'PLAY'
    } else if(status === 'playing') {
      return 'STOP'
    }
  }

  verifyActive = track => {
    if(parseInt(track.id) === parseInt(this.props.trackActive)) {
      return 'active'
    }
  }

  handleReceived = msg => {
    this.props.checkTrackActive(msg)
  }

  removeTrack = id => {
    const token = localStorage.getItem('token')
    this.props.removeJukeboxUserTrack(token, id)
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }

    return (
    <PageMenu focus="jukebox-player" loading={this.props.busy}>
      <ActionCableProvider url={`${process.env.REACT_APP_API_JUKEBOX_CABLE}/cable?kind=admin&token=${localStorage.getItem('token')}`}>
        <ActionCableConsumer
          channel="PlayingChannel"
          onReceived={this.handleReceived}
        >
          <Container>
            <Title className="mb-sm">Jukebox</Title>
            <Breadcrumb>
              <Text>Player</Text>
            </Breadcrumb>

            <Button plugin={Link} to="/jukebox-display" className="sm">Espelhar</Button>

            <Wrap>

              <Wrap className="max-w-xs">
                <Form>
                  <InputRedux
                    name="playlists"
                    label="Seleção ativa"
                    options={this.formatPlaylists(this.props.allPlaylists)}
                    type="select"
                    after={
                      <Button
                        onClick={() => this.props.refreshToPlay()}
                        className="m-0 ml-md circle"
                        loading={this.props.refreshing}
                        type="button"
                        behaviour="refresh"
                      >
                        <Icon name="refresh"/>
                      </Button>
                    }
                  />
                  {this.props.playlistChange && (
                    this.selectPlaylist(this.props.playlistChange)
                  )}
                </Form>
              </Wrap>

              <Wrap className="max-w-xs">

              <Button onClick={() => this.handlePlay()} className="block mt-lg" type="button">
                {this.verifyStatusButton()}
              </Button>

              </Wrap>
              <Title className="mb-sm mt-xl sm">Fila de seleção</Title>
              <Table className="mb-xl">
                  <Thead>
                    <Tr>
                      <Td width={30}>Usuário</Td>
                      <Td width={30}>Música</Td>
                      <Td width={30}>Artista</Td>
                      <Td width={10}>Ação</Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {this.props.tracks.map((track, i) => {
                      return(
                        <Tr key={i} className={this.verifyActive(track)}>
                          <Td className="flex-align-center"><Button className="link mr-sm m-0" href={track.userImage || Smile} target="_blanck"><Image className="rounded xs" src={track.userImage || Smile} /></Button> <Text>{track.userName}</Text></Td>
                          <Td>{track.attributes.info.name}</Td>
                          <Td>{track.attributes.info.artists}</Td>
                          <Td><Button className="sm m-0" onClick={() => this.removeTrack(track.id)}>REMOVER</Button></Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
              </Table>
            </Wrap>

          </Container>
        </ActionCableConsumer>
      </ActionCableProvider>
    </PageMenu>
  );
  }
}

const selector = formValueSelector('formPlayer')

const mapStateToProps = state => ({
  initialValues: {
    playlists: state.Player.playlistActiveId
  },
  allPlaylists: state.Player.playlists,
  selectedPlaylist: state.Player.selectedPlaylist,
  tracks: state.Player.toPlay,
  busy: state.Player.busy,
  statusJukebox: state.Player.statusJukebox,
  trackActive: state.Player.trackIdActive,
  refreshing: state.Player.refreshing,
  playlistChange: selector(state, 'playlists')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadPlayerPlaylists,
  updateSelectedPlaylist,
  playJukebox,
  stopJukebox,
  checkTrackActive,
  refreshToPlay,
  loadJukeboxes,
  loadToPlay,
  removeJukeboxUserTrack
}, dispatch);

JukeboxPlayer = reduxForm({
  form: 'formPlayer',
  enableReinitialize: true
})(JukeboxPlayer)

export default connect(mapStateToProps, mapDispatchToProps)(JukeboxPlayer);
