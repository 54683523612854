import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { formValueSelector, reduxForm } from "redux-form";
import {
  Alert,
  Breadcrumb,
  Button,
  Container,
  Form,
  Input,
  Template,
  Text,
  Title,
  Wrap,
} from "sme-theme";
import { createQuest } from "../../actions/createQuestAction";
import { selectKindQuest } from "../../actions/selectKindQuestAction";
import { showKindSpotify } from "../../actions/showKindSpotifyAction";
import {
  ErrorField,
  InputRedux,
  InputReduxGroup,
} from "../../modules/inputRedux";
import PageMenu from "../../modules/pageMenu";
import { generateRandomHash } from "../../herlpers/random";

function createExternalLinkLink(code) {
  return `${process.env.REACT_APP_URL}?code=${code}`;
}

class FormCreateQuest extends React.Component {
  constructor() {
    super();
    this.state = { tab: "content", totalFaixasQuest: null };
  }

  componentDidMount() {
    localStorage.setItem("kind_quest", "");
    localStorage.removeItem("select_kind_mission");
    localStorage.removeItem("array_errors_create_quest");
  }

  componentDidUpdate() {
    localStorage.removeItem("array_errors_create_quest");
    localStorage.removeItem("array_errors_create_quest");
  }

  componentWillUnmount() {
    localStorage.removeItem("array_errors_create_quest");
  }

  changeKindQuest = (kind) => {
    this.props.selectKindQuest(kind);
  };

  resetExternaLinkForm = () => {
    this.props.change('external_link_label', '');
    this.props.change('external_link_custom', false);
    this.props.change('external_link_code', '');
    this.props.change('external_link_link', '');
  }

  updateExternalLinkCode = (code) => {
    this.props.change('external_link_code', code);
    this.updateExternalLinkLink(code);
  };

  updateExternalLinkLink = (code) => {
    this.props.change('external_link_link', createExternalLinkLink(code));
  };

  generateExternalLinkCode = () => {
    this.updateExternalLinkCode(generateRandomHash());
  };

  handleQuestKindChange = (e, kind) => {
    if (kind === "quest_external_link") {
      this.generateExternalLinkCode();
    } else {
      this.resetExternaLinkForm();
    }
  };

  handleExternalLinkCustomChange = (e, custom) => {
    if (custom) {
      this.updateExternalLinkCode('');
    } else {
      this.generateExternalLinkCode();
    }
  }

  handleExternalLinkCodeChange = (e, code) => {
    this.updateExternalLinkLink(code);
  }

  checkUri = (uri) => {
    if (uri.includes("track")) {
      this.props.showKindSpotify(true);
    } else {
      this.props.showKindSpotify(false);
    }
  };

  render() {
    const { error, handleSubmit, dirty } = this.props;
    if (!localStorage.getItem("token")) {
      return <Redirect to="/login" />;
    }
    const formError = "array_errors_create_quest";

    return (
      <PageMenu focus="missions" loading={this.props.busy}>
        <Container>
          <Title className="mb-sm">Missões</Title>
          <Breadcrumb>
            <Button plugin={Link} to="/missions">
              Missões
            </Button>
            <Text>Criar missão</Text>
          </Breadcrumb>

          <Template
            condition={
              localStorage.getItem("array_errors_create_quest") && error
            }
          >
            <Alert className="my-md" type="error" iconName="attention-circled">
              <Text>
                {this.props.error_status !== 500 &&
                  "Alguns campos precisam da sua atenção:"}
              </Text>
              <ErrorField
                field="date_start"
                label="Início"
                formError={formError}
              />
              <ErrorField
                field="score"
                label="Filtr Coins"
                formError={formError}
              />
              <ErrorField field="uri" label="URI" formError={formError} />
              <ErrorField field="url" label="URL" formError={formError} />
              <ErrorField
                field="quest_spotify_attributes"
                label="Tipo de missão no Deezer"
                formError={formError}
              />
              <ErrorField
                field="question"
                label="Pergunta"
                formError={formError}
              />
              <ErrorField
                field="answer"
                label="Resposta"
                formError={formError}
              />
              <ErrorField
                field="quest_question_attributes"
                label="Pergunta e resposta"
                formError={formError}
              />
              <ErrorField
                field="quest_spotify.uri"
                label="URI"
                formError={formError}
              />
              <ErrorField
                field="quest_youtube_attributes"
                label="URL"
                formError={formError}
              />
              <ErrorField
                field="quest_youtube.url"
                label="URL"
                formError={formError}
              />
              <ErrorField
                field="server_error"
                label="Erro interno no servidor, aguarde alguns minutos"
                formError={formError}
              />
              <ErrorField
                field="name_artist"
                label="Nome do artista"
                formError={formError}
              />
              <ErrorField
                field="name_product"
                label="Nome da música"
                formError={formError}
              />
              <ErrorField
                field="launch_in"
                label="Horário de lançamento"
                formError={formError}
              />
            </Alert>
          </Template>

          <Wrap className="max-w-md">
            <Form
              onSubmit={handleSubmit(createQuest(this.state.totalFaixasQuest))}
            >
              <InputRedux name="status" type="switch" label="Ativar" />
              <InputRedux
                name="date_start"
                mask="99/99/9999 99:99"
                helpText="Data e horário deve ser maior que agora"
                label="Início"
                formError={formError}
              />
              {this.props.kindSelected !== "quest_playlist" && (
                <InputRedux
                  name="score"
                  type="score"
                  label="Filtr Coins"
                  formError={formError}
                />
              )}

              <InputRedux
                name="quest_kind"
                type="select"
                options={[
                  { label: "Deezer", value: "quest_spotify" },
                  { label: "Pergunta", value: "quest_question" },
                  { label: "Youtube", value: "quest_youtube" },
                  { label: "Pre-Save", value: "quest_pre_save" },
                  { label: "Opt-in", value: "quest_opt" },
                  { label: "Missão playlist", value: "quest_playlist" },
                  { label: "Link Externo", value: "quest_external_link" },
                ]}
                label="Tipo de Missão"
                formError={formError}
                onChange={this.handleQuestKindChange}
              />

              {this.props.questKind &&
                this.changeKindQuest(this.props.questKind)}

              <Template condition={this.props.kindSelected === "quest_spotify"}>
                <Title className="sm mt-xl mb-lg">Deezer</Title>
                <InputRedux name="uri" label="URI" formError={formError} />

                {this.props.kindUri && this.checkUri(this.props.kindUri)}

                <Template condition={this.props.showKind}>
                  <InputReduxGroup
                    name="quest_spotify_attributes"
                    groupName="quest_spotify_attributes"
                    type="select"
                    options={[
                      { label: "Salvar", value: "save" },
                      { label: "Escutar", value: "listen" },
                    ]}
                    label="Escolha o que você quer que o usuário faça nessa missão"
                    formError={formError}
                  />
                </Template>
              </Template>

              <Template condition={this.props.kindSelected === "quest_youtube"}>
                <Title className="sm mt-xl mb-lg">Youtube</Title>
                <InputReduxGroup
                  name="quest_youtube_attributes"
                  groupName="quest_youtube.url"
                  label="URL"
                  formError={formError}
                />
              </Template>

              <Template condition={this.props.kindSelected === "quest_opt"}>
                <Title className="sm mt-xl mb-lg">Opt-in</Title>
                <InputReduxGroup
                  name="quest_opt_description"
                  groupName="quest_opt_attributes"
                  label="Descrição"
                  rows={4}
                  type="textarea"
                  formError={formError}
                />
              </Template>

              <Template
                condition={this.props.kindSelected === "quest_question"}
              >
                <Title className="sm mt-xl mb-lg">Pergunta</Title>
                <InputReduxGroup
                  name="question"
                  groupName="quest_question_attributes"
                  label="Pergunta"
                  formError={formError}
                />
                <InputRedux name="regex" type="switch" label="Regex" />
                <InputReduxGroup
                  name="answer"
                  groupName="quest_question_attributes"
                  label="Resposta"
                  formError={formError}
                />
              </Template>

              <Template
                condition={this.props.kindSelected === "quest_pre_save"}
              >
                <Title className="sm mt-xl mb-lg">Pre-save Deezer</Title>
                <InputReduxGroup
                  name="uri"
                  groupName="quest_pre_save.uri"
                  label="URI"
                  formError={formError}
                />
                <InputReduxGroup
                  name="name_artist"
                  groupName="quest_pre_save.name_artist"
                  label="Nome do artista"
                  formError={formError}
                />
                <InputReduxGroup
                  name="name_product"
                  groupName="quest_pre_save.name_product"
                  label="Nome da música"
                  formError={formError}
                />
                <InputReduxGroup
                  name="launch_in"
                  groupName="quest_pre_save.launch_in"
                  mask="99/99/9999 99:99"
                  label="Horário de lançamento"
                  formError={formError}
                />
              </Template>

              <Template
                condition={this.props.kindSelected === "quest_playlist"}
              >
                <Title className="sm mt-xl mb-lg">Missão Playlist</Title>
                <InputReduxGroup
                  name="uri_playlist"
                  groupName="quest_playlist.uri"
                  label="URI da playlist"
                  formError={formError}
                />
                <InputReduxGroup
                  type="number"
                  name="points_for_track"
                  groupName="quest_pre_save.points"
                  label="Total de pontos por faixa"
                  formError={formError}
                />

                {/*
                  @valmy, esse componente InputReduxGroup não está pegando o name, os radios precisam do name pra poder funcionar
                  Eu tentei usar o InputRadioRedux mas deu erro

                  <InputReduxGroup type="radio" name="max_points" groupName="quest_pre_save.tracks_10" label="10 faixas" formError={formError} />
                  <InputReduxGroup type="radio" name="max_points" groupName="quest_pre_save.tracks_20" label="20 faixas" formError={formError} />

                  quando clicar em 10 pontos mostra só uma pergunta, em 20 mostra as duas

                  Me chama qualquer coisa.
                */}

                <Input
                  onChange={(e) => this.setState({ totalFaixasQuest: 10 })}
                  type="radio"
                  name="max_points"
                  groupName="quest_pre_save.tracks_10"
                  formError={formError}
                >
                  10 faixas
                </Input>
                <Input
                  onChange={(e) => this.setState({ totalFaixasQuest: 20 })}
                  type="radio"
                  name="max_points"
                  groupName="quest_pre_save.tracks_20"
                  formError={formError}
                >
                  20 faixas
                </Input>

                <Template
                  condition={
                    this.state.totalFaixasQuest === 10 ||
                    this.state.totalFaixasQuest === 20
                      ? true
                      : false
                  }
                >
                  <Text className="bold mt-lg mb-md">Pergunta 1</Text>
                  <InputReduxGroup
                    type="text"
                    name="question_playlist_1"
                    groupName="quest_pre_save"
                    label="Pergunta"
                    formError={formError}
                  />
                  <InputReduxGroup
                    type="text"
                    name="answer_playlist_1"
                    groupName="quest_pre_save"
                    label="Resposta"
                    formError={formError}
                  />
                  <InputReduxGroup
                    type="number"
                    name="points_for_question_playlist_1"
                    groupName="quest_pre_save"
                    label="Pontos pergunta"
                    formError={formError}
                  />
                </Template>

                <Template
                  condition={this.state.totalFaixasQuest === 20 ? true : false}
                >
                  <Text className="bold mt-lg mb-md">Pergunta 2</Text>
                  <InputReduxGroup
                    type="text"
                    name="question_playlist_2"
                    groupName="quest_pre_save"
                    label="Pergunta"
                    formError={formError}
                  />
                  <InputReduxGroup
                    type="text"
                    name="answer_playlist_2"
                    groupName="quest_pre_save"
                    label="Resposta"
                    formError={formError}
                  />
                  <InputReduxGroup
                    type="number"
                    name="points_for_question_playlist_2"
                    groupName="quest_pre_save"
                    label="Pontos pergunta"
                    formError={formError}
                  />
                </Template>
              </Template>

              <Template
                condition={this.props.kindSelected === "quest_external_link"}
              >
                <Title className="sm mt-xl mb-lg">Link Externo</Title>
                <InputReduxGroup
                  type="text"
                  name="external_link_label"
                  groupName="external_link"
                  label="Legenda"
                  formError={formError}
                />
                <InputRedux
                  name="external_link_custom"
                  groupName="external_link"
                  type="switch"
                  label="Código Personalizado"
                  onChange={this.handleExternalLinkCustomChange}
                />
                <InputReduxGroup
                  type="text"
                  name="external_link_code"
                  groupName="external_link"
                  label="Código"
                  formError={formError}
                  disabled={!this.props.externalLinkCustom}
                  onChange={this.handleExternalLinkCodeChange}
                />
                <Text className="mb-sm muted">Link</Text>
                <Text className="mb-lg">{this.props.externalLinkLink}</Text>
              </Template>

              <Button
                className="block"
                type="submit"
                loading={this.props.busyButton}
                disabled={!dirty}
              >
                Adicionar
              </Button>
            </Form>
          </Wrap>
        </Container>
      </PageMenu>
    );
  }
}

const selector = formValueSelector("formCreateQuest");

const MapStateToProps = (state) => ({
  busyButton: state.LoadQuests.busyButton,
  kindSelected: state.LoadQuests.kindSelectedQuest,
  kindSpotifySelected: state.LoadQuests.kindSpotifySelected,
  showKind: state.LoadQuests.showKindSpotify,
  questKind: selector(state, "quest_kind"),
  kindUri: selector(state, "uri"),
  externalLinkCustom: selector(state, "external_link_custom"),
  externalLinkLink: selector(state, "external_link_link"),
  error_status: state.LoadQuests.error,
});

const MapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createQuest,
      selectKindQuest,
      showKindSpotify,
    },
    dispatch
  );

FormCreateQuest = reduxForm({
  form: "formCreateQuest",
})(FormCreateQuest);

export default connect(MapStateToProps, MapDispatchToProps)(FormCreateQuest);
