import { connect } from 'react-redux'
import React from 'react'
import { reduxForm, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom'
import { createRescue } from '../../actions/createRescueAction';
import PageMenu from '../../modules/pageMenu'
import { Wrap, Text, Title, Breadcrumb, Template, Button, Container, Alert } from "sme-theme"
import { InputRedux, ErrorField } from '../../modules/inputRedux';

class FormNewRescueAdmin extends React.Component {
  componentDidMount() {
    localStorage.removeItem('array_errors_create_rescue')
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_create_rescue')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_create_rescue');
  }

  render() {
    const { error, handleSubmit, dirty } = this.props;
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const formError = 'array_errors_create_rescue'

    return (
    <PageMenu focus="rescues">
      <Container>
        <Title className="mb-sm">Hits do Momento</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/rescues">Hits do Momento</Button>
          <Text>Criar Hits do Momento</Text>
        </Breadcrumb>
        <Template condition={localStorage.getItem('array_errors_create_rescue') && error}>
          <Alert className="my-md" type="error" iconName="attention-circled">
            <Text>{this.props.error_status !== 500 && 'Alguns campos precisam da sua atenção:'}</Text>
            <ErrorField field="error" label="Preencha o formulário" formError={formError} />
            <ErrorField field="uri" label="URI" formError={formError} />
            <ErrorField field="score" label="Pontos de resgate" formError={formError} />
            <ErrorField field="playlist" label="URI da playlist" formError={formError} />
            <ErrorField field="priority" label="Prioridade da faixa" formError={formError} />
            <ErrorField field="limit_streams" label="Limite de plays da faixa" formError={formError} />
            <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
          </Alert>
        </Template>
        <Wrap className="max-w-xs">
          <form onSubmit={handleSubmit(createRescue)}>
            <InputRedux name="status" type="switch" label="Ativar" />
            <InputRedux name="limited" type="switch" label="Limite"/>
            <InputRedux disabled={!this.props.limited} name="limit_streams" type="number" label="Limite de plays da faixa" formError={formError} />
            <InputRedux name="priority" type="number" label="Prioridade da faixa" formError={formError} />
            <InputRedux onChange={alert} name="uri" label="URI da música" helpText="Música que o usuário deve escutar para ganhar Filtr Coins" formError={formError} />
            <InputRedux name="score" label="Filtr Coins por resgate" helpText="Filtr Coins que o usuário irá acumular ao escutar cada música" msgError={error} formError={formError} />
            <InputRedux name="playlist_checkbox" id="playlist" type="checkbox" label="Streams vindo de uma playlist?" msgError={error} formError={formError} />
            <Template condition={this.props.fromPlaylist === undefined || this.props.fromPlaylist === false ? false : true }>
              <InputRedux name="playlist" label="URI da playlist" formError={formError} />
            </Template>
          <Button className="block" type="submit" loading={this.props.busyButton} disabled={!dirty}>Adicionar</Button>
          </form>
        </Wrap>
      </Container>
    </PageMenu>
  );
  }
}

const selector = formValueSelector('formNewRescueAdmin')

const MapStateToProps = state => ({
  busyButton: state.LoadRescues.busyButton,
  fromPlaylist: selector(state, 'playlist_checkbox'),
  limited: selector(state, 'limited'),
  error_status: state.LoadRescues.error
});

const MapDispatchToProps = dispatch => bindActionCreators({
  createRescue
}, dispatch);

FormNewRescueAdmin = reduxForm({
  form: 'formNewRescueAdmin',
  enableReinitialize: true
})(FormNewRescueAdmin);

export default connect(MapStateToProps, MapDispatchToProps)(FormNewRescueAdmin);