import {
  LOAD_SETTINGS,
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_FAILED,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILED
} from '../actions/actionTypes';

const initialState = {
  title: '',
  subtitle: '',
  termsAndConditions: '',
  invitationScore: '',
  invitationQuantity: '',
  profileCompletedScore: '',
  limitedGratificationScore:'',
  termsLoaded: false,
  show_playlist: false,
  uri_playlist: '',
  uri_playlist_cover: '',
  enabled_splash_screen: true,
  splash_screen_message: '',
  splash_screen_title: '',
  cookie_consent_title: '',
  cookie_consent_text: '',
  show_cookie_consent: false,
  busy: false,
  busyButton: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SETTINGS:
      return {
        ...state,
        busy: true
      }
    case LOAD_SETTINGS_SUCCESS:
      const { 
        title, subtitle, terms_and_conditions, invitation_quantity, 
        invitation_score, profile_completed_score, limited_gratification_score,
        uri_playlist, uri_playlist_cover, show_playlist, enabled_splash_screen,
        splash_screen_message, splash_screen_title, cookie_consent_title,
        cookie_consent_text, show_cookie_consent,
      } = action.payload;

      return {
        ...state,
        title: title,
        subtitle: subtitle,
        termsAndConditions: terms_and_conditions,
        invitationScore: invitation_score,
        invitationQuantity: invitation_quantity,
        profileCompletedScore: profile_completed_score,
        limitedGratificationScore: limited_gratification_score,
        termsLoaded: true,
        uri_playlist: uri_playlist || '',
        uri_playlist_cover: uri_playlist_cover || '',
        show_playlist,
        busy: false,
        enabled_splash_screen: enabled_splash_screen,
        splash_screen_message: splash_screen_message,
        splash_screen_title: splash_screen_title,
        cookie_consent_title: cookie_consent_title,
        cookie_consent_text: cookie_consent_text,
        show_cookie_consent: show_cookie_consent,
      }
    case LOAD_SETTINGS_FAILED:
      return {
        ...state,
        busy: false,
        error: action.error
      }
    case UPDATE_SETTINGS:
      return {
        ...state,
        busyButton: true
      }
    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        busyButton: false
      }
    case UPDATE_SETTINGS_FAILED:
      return {
        ...state,
        busyButton: false,
        error: action.error
      }
    default:
      return state
  }
}
