import {
  UPDATE_SELECTED_PLAYLIST,
  UPDATE_SELECTED_PLAYLIST_SUCCESS,
  UPDATE_SELECTED_PLAYLIST_FAILED
} from './actionTypes';

import api from '../services/api';

export const updateSelectedPlaylist = (playlist) => async (dispatch) => {
  dispatch({ type: UPDATE_SELECTED_PLAYLIST })
  const data = {
    "playlist": {
      "name": playlist[0].attributes.name,
      "status": true
    }
  }
  
  try {
    const token = localStorage.getItem('token')
    await api.updatePlaylist(token, playlist[0].id, data);
    const result = await api.loadPlaylist(token, playlist[0].id)
    dispatch({ 
      type: UPDATE_SELECTED_PLAYLIST_SUCCESS, 
      tracks: result.data.included,
      id: playlist[0].id
    })
  } catch(err) {
    dispatch({ type: UPDATE_SELECTED_PLAYLIST_FAILED, error: err })
  }
}