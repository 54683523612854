import api from '../services/api';
import history from '../history';

export const logoutAdmin = () => async (dispatch) => {
  try {
    // const tokenAdmin = localStorage.getItem('token')
    // await api.logout(tokenAdmin);
    localStorage.clear()
    history.push('/login')
  } catch(err) {
    localStorage.removeItem('token')
  }
}
