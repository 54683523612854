import {
  LOAD_USER_CASHBACKS,
  LOAD_USER_CASHBACKS_SUCCESS,
  LOAD_USER_CASHBACKS_FAILED,
} from "../actions/actionTypes";

const initialState = {
  cashBacks: "0",
  busy: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_CASHBACKS:
      return {
        ...state,
        busy: true,
      };
    case LOAD_USER_CASHBACKS_SUCCESS:
      const data = action.payload;

      return {
        ...state,
        cashBacks: data,
      };
    case LOAD_USER_CASHBACKS_FAILED:
      return {
        ...state,
        busy: false,
        error: action.error,
      };
    default:
      return state;
  }
};
