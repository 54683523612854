import React from 'react';
import LogoImage from '../../assets/images/logo.svg';
import { Image } from "sme-theme"

class Logo extends React.Component {
  render() {
    return <Image className={this.props.className} src={LogoImage} />
  }
}

export default Logo;
