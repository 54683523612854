import {
  LOAD_PAGE_RESCUE_USERS_SUCCESS,
  LOAD_PAGE_RESCUE_USERS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPageRescueUsers = (token, rescue_id, page) => async (dispatch) => {
  try {
    const result = await api.loadPageRescueUsers(token, rescue_id, page);
    dispatch({ 
      type: LOAD_PAGE_RESCUE_USERS_SUCCESS, 
      payload: result.data.data,
      included: result.data.included
    })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_RESCUE_USERS_FAILED, error: err })
  }
}
