import {
  LOAD_QUEST,
  LOAD_QUEST_SUCCESS,
  LOAD_QUEST_FAILED,
  EDIT_QUEST,
  EDIT_QUEST_SUCCESS,
  EDIT_QUEST_FAILED,
  LOAD_QUEST_ACCOMPLISHMENTS,
  LOAD_QUEST_ACCOMPLISHMENTS_SUCCESS,
  LOAD_QUEST_ACCOMPLISHMENTS_FAILED,
  LOAD_PAGE_QUEST_ACCOMPLISHMENTS_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  quest: null,
  status: false,
  dateStart: '',
  score: 0,
  kindMission: '',
  uri: '',
  url: '',
  kindYoutube: '',
  kindSpotify: '',
  question: '',
  answer: '',
  regex: false,
  busy: false,
  busyButton: false,
  showSpotifyPlaylist: false,
  showSpotify: false,
  showYoutube: false,
  showQuestion: false,
  showPreSave: false,
  showOpt: false,
  optDescription: '',
  nameArtist: '',
  nameProduct: '',
  launchIn: null,
  questRelationshipId: null,
  totalCompleted: 0,
  accomplishments: [],
  accomplishmentsLoaded: false,
  totalAccomplishmentsPages: 0,
  error: null,

  playlistName: '',
  pointsForQuestion1: null,
  pointsForQuestion2: null,
  tracksCount: null,
  pointsForTrack: null,
  questionPlaylist1: '',
  questionPlaylist2: '',
  answerPlaylist1: '',
  answerPlaylist2: '',
  externalLinkLabel: '',
  externalLinkCode: '',
  externalLinkLink: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_QUEST:
      return {
        ...state,
        busy: true,
      }
    case LOAD_QUEST_SUCCESS:
      const { status, date_start, score, kind, accomplished_count } = action.payload

      let kind_mission = ''

      if(kind === 'youtube_subscribe' || kind === 'youtube_watch_video') {
        kind_mission = 'quest_youtube'
      } else if(kind ==='question') {
        kind_mission = 'quest_question'
      } else if(kind === 'pre_save'){
        kind_mission = 'quest_pre_save'
      } else if(kind === 'opt') {
        kind_mission = 'quest_opt'
      } else if(kind === 'quest_spotify_playlist') {
        kind_mission = 'quest_spotify_playlist'
      } else if (kind === 'external_link') {
        kind_mission = 'quest_external_link'
      } else {
        kind_mission = 'quest_spotify'
      }

      let questRelationshipId = null;

      let uri = ''
      let kind_spotify = ''
      if(kind_mission === 'quest_spotify') {
        uri = action.payload.quest_spotify.uri
        kind_spotify = action.payload.quest_spotify.to_listen ? 'listen' : 'save'
        questRelationshipId = action.payload.quest_spotify.id
      }

      let question = ''
      let answer = ''
      let regex = false
      if(kind_mission === 'quest_question') {
        question = action.payload.quest_question.question
        questRelationshipId = action.payload.quest_question.id
        if (action.payload.quest_question.regex_pattern) {
          regex = true
          answer = action.payload.quest_question.regex_pattern
        } else {
          answer = action.payload.quest_question.answer
        }
      }
      
      let url = ''
      let kind_youtube = ''
      if(kind_mission === 'quest_youtube') {
        url = action.payload.quest_youtube.url
        kind_youtube = action.payload.quest_youtube.kind 
        questRelationshipId = action.payload.quest_youtube.id
      }

      let name_artist, name_product, launch_in;
      if(kind_mission === 'quest_pre_save') {
        uri = action.payload.quest_pre_save.uri
        name_artist = action.payload.quest_pre_save.name_artist
        name_product = action.payload.quest_pre_save.name_product
        launch_in = action.payload.quest_pre_save.launch_in
        questRelationshipId = action.payload.quest_pre_save.id
      }

      let tracks_count, points_for_track, question_playlist_1, question_playlist_2, answer_playlist_1, 
        answer_playlist_2, points_for_question_1, points_for_question_2, playlist_name;
      if(kind_mission === 'quest_spotify_playlist') {
        playlist_name = action.payload.quest_spotify_playlist.name

        uri = action.payload.quest_spotify_playlist.uri
        points_for_track = action.payload.quest_spotify_playlist.points_for_track

        tracks_count = action.payload.quest_spotify_playlist.tracks_count
        
        points_for_question_1 = action.payload.quest_spotify_playlist.points_for_question
        points_for_question_2 = action.payload.quest_spotify_playlist.points_for_question_2

        question_playlist_1 = action.payload.quest_spotify_playlist.question
        question_playlist_2 = action.payload.quest_spotify_playlist.question_2
        answer_playlist_1 = action.payload.quest_spotify_playlist.answer
        answer_playlist_2 = action.payload.quest_spotify_playlist.answer_2

        questRelationshipId = action.payload.quest_spotify_playlist.id
      }

      let opt_description = ''
      if(kind_mission === 'quest_opt') {
        opt_description = action.payload.quest_opt.description
        questRelationshipId = action.payload.quest_opt.id
      }

      let externalLinkLabel = ''
      let externalLinkCode = ''
      let externalLinkLink = ''
      if(kind_mission === 'quest_external_link') {
        questRelationshipId = action.payload.quest_external_link.id
        externalLinkLabel = action.payload.quest_external_link.label
        externalLinkCode = action.payload.quest_external_link.code
        externalLinkLink = action.payload.quest_external_link.link
      }

      let show_spotify_playlist = false
      let show_youtube = false
      let show_question = false
      let show_spotify = false
      let show_pre_save = false
      let show_opt = false
      let show_external_link = false
      switch (kind_mission) {
        case 'quest_question':
          show_question = true
          break;
        case 'quest_spotify':
          show_spotify = true
          break;
        case 'quest_youtube':
          show_youtube = true
          break;
        case 'quest_pre_save':
          show_pre_save = true
          break;
        case 'quest_opt':
          show_opt = true
          break;
        case 'quest_spotify_playlist':
          show_spotify_playlist = true;
          break;
        case 'quest_external_link':
          show_external_link = true;
          break;
        default:
          break;
      }

      return {
        ...state,
        quest: action.payload,
        status: status,
        dateStart: dateBr(date_start),
        score: score,
        uri: uri,
        url: url,

        pointsForQuestion1: points_for_question_1,
        pointsForQuestion2: points_for_question_2,
        tracksCount: tracks_count,
        pointsForTrack: points_for_track,
        questionPlaylist1: question_playlist_1,
        questionPlaylist2: question_playlist_2,
        answerPlaylist1: answer_playlist_1,
        answerPlaylist2: answer_playlist_2,
        playlistName: playlist_name,
        externalLinkLabel: externalLinkLabel,
        externalLinkCode: externalLinkCode,
        externalLinkLink: externalLinkLink,

        showSpotifyPlaylist: show_spotify_playlist,
        showSpotify: show_spotify,
        showYoutube: show_youtube,
        showQuestion: show_question,
        showPreSave: show_pre_save,
        showOpt: show_opt,
        showExternalLink: show_external_link,

        kindMission: kind_mission,
        kindSpotify: kind_spotify,
        kindYoutube: kind_youtube,
        question: question,
        answer: answer,
        regex: regex,
        nameArtist: name_artist,
        nameProduct: name_product,
        optDescription: opt_description,
        launchIn: dateBr(launch_in),
        questRelationshipId: questRelationshipId,
        totalCompleted: accomplished_count,
        busy: false
      }
    case LOAD_QUEST_FAILED:
      return {
        ...state,
        error: action.error,
        quest: null,
        busy: false
      }
    case EDIT_QUEST:
      return {
        ...state,
        busyButton: true,
      }
    case EDIT_QUEST_SUCCESS:
      return {
        ...state,
        busyButton: false,
      }
    case EDIT_QUEST_FAILED:
      return {
        ...state,
        busyButton: false,
      }
    case LOAD_QUEST_ACCOMPLISHMENTS:
      return {
        ...state,
        accomplishmentsLoaded: false,
      }
    case LOAD_QUEST_ACCOMPLISHMENTS_SUCCESS:
      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params.page
      
      return {
        ...state,
        accomplishments: action.payload,
        totalAccomplishmentsPages: parseInt(totalPages),
        accomplishmentsLoaded: true,
      }
    case LOAD_QUEST_ACCOMPLISHMENTS_FAILED:
      return {
        ...state,
        accomplishments: [],
        accomplishmentsLoaded: false
      }
    case LOAD_PAGE_QUEST_ACCOMPLISHMENTS_SUCCESS:
      return {
        ...state,
        accomplishments: action.payload
      }
    default:
      return state
  }
}

function dateBr(date) {
  var data = new Date(date),
  dia  = data.getDate().toString(),
  diaF = (dia.length === 1) ? '0'+dia : dia,
  mes  = (data.getMonth()+1).toString(),
  mesF = (mes.length === 1) ? '0'+mes : mes,
  anoF = data.getFullYear();
  const minutes = (data.getMinutes().toString().length === 1) ? '0'+data.getMinutes() : data.getMinutes();
  const hours = (data.getHours().toString().length === 1) ? '0'+data.getHours() : data.getHours();
  return `${diaF}/${mesF}/${anoF} ${hours}:${minutes}`
}

function getParamsFromUrl(url) {
  url = decodeURI(url);
  if (typeof url === 'string') {
      let params = url.split('?');
      let eachParamsArr = params[1].split('&');
      let obj = {};
      if (eachParamsArr && eachParamsArr.length) {
          eachParamsArr.forEach(param => {
              let keyValuePair = param.split('=')
              let key = keyValuePair[0];
              let value = keyValuePair[1];
              obj[key] = value;
          })
      }
      return obj;
  }
}
