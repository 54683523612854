import {
  LOAD_RESCUE_USERS,
  LOAD_RESCUE_USERS_SUCCESS,
  LOAD_RESCUE_USERS_FAILED,
  LOAD_PAGE_RESCUE_USERS_SUCCESS,
  DOWNLOAD_RESCUE_USERS_SUCCESS,
  DOWNLOAD_RESCUE_USERS_FAILED,
  RESET_DOWNLOAD_RESCUE_USERS_ALERT,
} from '../actions/actionTypes';

const initialState = {
  users: [],
  totalPages: 0,
  usersLoaded: false,
  fileSendToEmail: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_RESCUE_USERS:
      return {
        ...state,
        usersLoaded: false
      }
    case LOAD_RESCUE_USERS_SUCCESS:
      let users = action.payload

      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params.page

      return {
        ...state,
        users,
        totalPages: parseInt(totalPages),
        usersLoaded: true
      }
    case LOAD_RESCUE_USERS_FAILED:
      return {
        ...state,
        error: action.error,
        usersLoaded: true,
        busy: false
      }
    case LOAD_PAGE_RESCUE_USERS_SUCCESS:
      let usersPage = action.payload
      return {
        ...state,
        users: usersPage,
      }
    case DOWNLOAD_RESCUE_USERS_SUCCESS:
      return {
        ...state,
        fileSendToEmail: true
      }
    case DOWNLOAD_RESCUE_USERS_FAILED:
      return {
        ...state,
        fileSendToEmail: false
      }
    case RESET_DOWNLOAD_RESCUE_USERS_ALERT:
      return {
        ...state,
        fileSendToEmail: false
      }
    default:
      return state
  }
}

function getParamsFromUrl(url) {
  url = decodeURI(url);
  if (typeof url === 'string') {
      let params = url.split('?');
      let eachParamsArr = params[1].split('&');
      let obj = {};
      if (eachParamsArr && eachParamsArr.length) {
          eachParamsArr.forEach(param => {
              let keyValuePair = param.split('=')
              let key = keyValuePair[0];
              let value = keyValuePair[1];
              obj[key] = value;
          })
      }
      return obj;
  }
}
