import {
  LOAD_ORDER,
  LOAD_ORDER_SUCCESS,
  LOAD_ORDER_FAILED,
  LOAD_ORDER_COMMENTS_SUCCESS,
  LOAD_ORDER_COMMENTS_FAILED,
  LOAD_ORDER_COMMENT,
  LOAD_ORDER_COMMENT_SUCCESS,
  LOAD_ORDER_COMMENT_FAILED,
  LOAD_PAGE_ORDER_COMMENTS_SUCCESS,
  LOAD_PAGE_ORDER_COMMENTS_FAILED,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILED,
  CREATE_ORDER_COMMENT,
  CREATE_ORDER_COMMENT_SUCCESS,
  CREATE_ORDER_COMMENT_FAILED,
  HIDE_ALERT_EDIT_ORDER
} from '../actions/actionTypes';

const initialState = {
  userName: '',
  userId: null,
  userPlatform: '',
  email: '',
  birthdate: '',
  phone: '',
  orderDate: '',
  city: '',
  userState: '',
  productName: '',
  trackingCode: null,
  status: false,
  busy: false,
  busyButton: false,
  busyButtonComment: false,
  totalPagesComments: 0,
  orderComment: '',
  alertEditSuccess: false,
  comments: [],
  addressRecipient: null,
  addressCep: null,
  addressCpf: null,
  addressCity: null,
  addressState: null,
  addressStreet: null,
  addressNumber: null,
  addressNeighborhood: null,
  addressComplement: null,
  addressReference: null,
  hasNotAddress: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ORDER:
      return {
        ...state,
        busy: true,
      }
    case LOAD_ORDER_SUCCESS:
      const userInfo = action.payload.user
      const productInfo = action.payload.product
      const addressInfo = action.payload.address
      const { created_at, sent, tracking_code } = action.payload 
      let city = '', user_state = '', user_birthdate = '';

      const { name, email, birthdate, phone, provider } = userInfo
      const user_id = userInfo.id
      const product_name = productInfo.name
      
      if(addressInfo.city) {
        city = addressInfo.city.name
        user_state = addressInfo.city.state.acronym
      }

      if(birthdate) {
        user_birthdate = dateBr(birthdate)
      }

      let address_recipient, address_city, address_cep, address_state, address_cpf, address_street,
      address_number, address_complement, address_reference, address_neighborhood;
      
      if(addressInfo) {
        address_recipient = addressInfo.recipient
        address_city = addressInfo.city.name
        address_cep = addressInfo.cep
        address_state = addressInfo.city.state.name
        address_cpf = addressInfo.cpf
        address_street = addressInfo.street
        address_number = addressInfo.number
        address_complement = addressInfo.complement
        address_reference = addressInfo.reference
        address_neighborhood = addressInfo.neighborhood
      }

      return {
        ...state,
        userName: name,
        userId: user_id,
        userPlatform: provider,
        email: email,
        birthdate: user_birthdate,
        city: city,
        phone: phone,
        orderDate: dateBr(created_at),
        productName: product_name,
        userState: user_state,
        status: sent,
        trackingCode: tracking_code,
        recipient: address_recipient,
        addressCity: address_city,
        addressCep: address_cep,
        addressState: address_state,
        addressCpf: address_cpf,
        addressStreet: address_street,
        addressNumber: address_number,
        addressComplement: address_complement,
        addressReference: address_reference,
        addressNeighborhood: address_neighborhood,
        hasNotAddress: addressInfo ? false : true,
        busy: false
      }
    case LOAD_ORDER_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case UPDATE_ORDER:
      return {
        ...state,
        busyButton: true
      }
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        busyButton: false,
        alertEditSuccess: true
      }
    case UPDATE_ORDER_FAILED:
      return {
        ...state,
        busyButton: false,
        alertEditSuccess: false,
        error: action.error
      }
    case HIDE_ALERT_EDIT_ORDER:
      return {
        ...state,
        alertEditSuccess: false
      }
    case CREATE_ORDER_COMMENT:
      return {
        ...state,
        busyButtonComment: true
      }
    case CREATE_ORDER_COMMENT_SUCCESS:
      return {
        ...state,
        comments: [action.comment, ...state.comments]
      }
    case CREATE_ORDER_COMMENT_FAILED:
      return {
        ...state,
        error: action.error
      }
    case LOAD_ORDER_COMMENTS_SUCCESS:
      function getParamsFromUrl(url) {
        url = decodeURI(url);
        if (typeof url === 'string') {
            let params = url.split('?');
            let eachParamsArr = params[1].split('&');
            let obj = {};
            if (eachParamsArr && eachParamsArr.length) {
                eachParamsArr.forEach(param => {
                    let keyValuePair = param.split('=')
                    let key = keyValuePair[0];
                    let value = keyValuePair[1];
                    obj[key] = value;
                })
            }
            return obj;
        }
      }

      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params['page[number]']

      return {
        ...state,
        totalPagesComments: parseInt(totalPages),
        comments: action.payload,
        busy: false
      }
    case LOAD_ORDER_COMMENTS_FAILED:
      return {
        ...state,
        busy: false,
        error: action.error
      }
    case LOAD_PAGE_ORDER_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: action.payload
      }
    case LOAD_PAGE_ORDER_COMMENTS_FAILED:
      return {
        ...state,
        error: action.error
      }
    case LOAD_ORDER_COMMENT:
      return {
        ...state,
        busy: true
      }
    case LOAD_ORDER_COMMENT_SUCCESS:
      return {
        ...state,
        orderComment: action.payload,
        busy: false
      }
    case LOAD_ORDER_COMMENT_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    default:
      return state
  }
}

function dateBr(date) {
  var data = new Date(date),
  dia  = data.getDate().toString(),
  diaF = (dia.length === 1) ? '0'+dia : dia,
  mes  = (data.getMonth()+1).toString(),
  mesF = (mes.length === 1) ? '0'+mes : mes,
  anoF = data.getFullYear();
  return `${diaF}/${mesF}/${anoF}`
}
