import {
  LOAD_PAGE_BADGE_SUCCESS,
  LOAD_PAGE_BADGE_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPageBadge = (token, page, orderAttribute, orderType) => async (dispatch) => {
  try {
    const result = await api.loadPageBadge(token, page, orderAttribute, orderType);
    dispatch({ type: LOAD_PAGE_BADGE_SUCCESS, badges: result.data.data })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_BADGE_FAILED, error: err })
  }
}
