import api from '../services/api';
import history from '../history';
import { SubmissionError } from 'redux-form'

export const loginAdmin = async (value, dispatch) => {
  const { email, password } = value
  try {
    const result = await api.login(email, password);
    const pre_token = result.data.pre_token

    localStorage.setItem('pre_token', pre_token)
    localStorage.setItem('email', email)

    history.push('/login-auth')
  } catch(err) {
    localStorage.setItem('admin_login_error', err.response.data.message)
    throw new SubmissionError({_error: 'error'})
  }
}
