import {
  LOAD_ACCOUNTS,
  LOAD_ACCOUNTS_SUCCESS,
  LOAD_ACCOUNTS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadAccounts = (token) => async (dispatch) => {
  dispatch({ type: LOAD_ACCOUNTS })
  try {
    const result = await api.loadAccounts(token);
    dispatch({ type: LOAD_ACCOUNTS_SUCCESS, payload: result.data.data, lastLink: result.data.links.last })
  } catch(err) {
    dispatch({ type: LOAD_ACCOUNTS_FAILED, error: err })
  }
}
