import {
  LOAD_ORDER_COMMENT,
  LOAD_ORDER_COMMENT_SUCCESS,
  LOAD_ORDER_COMMENT_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadOrderComment = (token, comment_id) => async (dispatch) => {
  dispatch({ type: LOAD_ORDER_COMMENT })
  try {
    const result = await api.loadComment(token, comment_id);
    dispatch({ type: LOAD_ORDER_COMMENT_SUCCESS, payload: result.data.data.attributes.body })
  } catch(err) {
    dispatch({ type: LOAD_ORDER_COMMENT_FAILED, error: err })
  }
}
