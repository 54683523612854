import {
  LOAD_RESCUE,
  LOAD_RESCUE_SUCCESS,
  LOAD_RESCUE_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadRescue = (token, rescue_id) => async (dispatch) => {
  dispatch({ type: LOAD_RESCUE })
  try {
    const result = await api.loadRescue(token, rescue_id);
    const {
      uri,
      score,
      status,
      playlist,
      info_playlist,
      limited,
      limit_streams,
      artists,
      name: track_name,
      total_users,
      rescues_count,
      priority,
    } = result.data

    dispatch({ 
      type: LOAD_RESCUE_SUCCESS,
      uri,
      score,
      status,
      playlist,
      info_playlist,
      limit_streams,
      limited,
      artists,
      track_name,
      total_users,
      rescues_count,
      priority
    })
  } catch(err) {
    dispatch({ type: LOAD_RESCUE_FAILED, error: err })
  }
}
