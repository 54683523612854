import {
  LOAD_RESCUE_USERS,
  LOAD_RESCUE_USERS_SUCCESS,
  LOAD_RESCUE_USERS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadRescueUsers = (token, id) => async (dispatch) => {
  dispatch({ type: LOAD_RESCUE_USERS })
  try {
    const result = await api.loadRescueUsers(token, id);

    dispatch({ 
      type: LOAD_RESCUE_USERS_SUCCESS,
      payload: result.data.data,
      included: result.data.currentPage,
      lastLink: result.data.links.last 
    })
  } catch(err) {
    dispatch({ type: LOAD_RESCUE_USERS_FAILED, error: err })
  }
}
