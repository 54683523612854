import {
  LOAD_RESCUES,
  LOAD_RESCUES_SUCCESS,
  LOAD_RESCUES_FAILED,
} from "./actionTypes";

import api from "../services/api";

export const loadRescues = (token) => async (dispatch) => {
  dispatch({ type: LOAD_RESCUES });
  try {
    const result = await api.loadRescues(token);

    const requestPromises = result.data.data.map((rescue) => {
      return api.loadTotalStreamsCashBack(token, rescue.id);
    });

    const rescuesTotalStreamsCashBack = await Promise.all(requestPromises);

    rescuesTotalStreamsCashBack.forEach((total, i) => {
      result.data.data[i].rescues_count = total.data.data;
    });

    dispatch({
      type: LOAD_RESCUES_SUCCESS,
      payload: result.data.data,
      lastLink: result.data.links.last,
    });
  } catch (err) {
    dispatch({ type: LOAD_RESCUES_FAILED, error: err });
  }
};
