import { connect } from 'react-redux'
import React from 'react'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom'
import { loadRescues } from '../../actions/loadRescuesAction';
import { deleteRescue } from '../../actions/deleteRescueAction';
import { HIDE_ALERT_CREATE_RESCUE } from '../../actions/actionTypes'
import { hideAlert } from '../../actions/hideAlertAction'
import { loadPageRescue } from '../../actions/loadPageRescueAction';
import { orderRescues } from '../../actions/orderRescuesAction'

import PageMenu from '../../modules/pageMenu'
import { Status, Alert, Text, Title, Template, Button, Container, Icon, Table, Tbody, Thead, Td, Tr, Modal, Pagination } from "sme-theme"

class FormRescueAdmin extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'content'}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadRescues(token);
    localStorage.removeItem('array_errors_form_rescues')
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_form_rescues')
  }

  componentWillUnmount() {
    this.props.hideAlert(HIDE_ALERT_CREATE_RESCUE)
  }

  deleteRescue = (rescue) => {
    const token = localStorage.getItem('token')
    this.props.deleteRescue(token, rescue)
  }

  loadPage = page => {
    const token = localStorage.getItem('token')
    this.props.loadPageRescue(token, page, this.props.orderAttribute, this.props.orderType)
  }

  order = attr => {
    const token = localStorage.getItem('token')
    this.props.orderRescues(token, 'rescues', attr, this.props.orderType)
  }

  verifyAttrOrder= attr => {
    if(attr === this.props.selectedOrder) {
      return 'sort-title active'
    }
    return 'sort-title'
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }

    return (
    <PageMenu focus="rescues" loading={this.props.busy}>
      <Container>
        <Title className="flex-align-center">Hits do momento <Button className="sm mb-0 ml-lg" plugin={Link} to="/new-rescue">NOVO HIT DO MOMENTO</Button></Title>
        <Template condition={this.props.alertCreateRescue}>
          <Alert className="my-md" type="success">
            <Text>Resgate criado com sucesso!</Text>
          </Alert>
        </Template>
        <Table className="mt-xl">
          <Thead>
            <Tr>
              <Td width={35} className={this.verifyAttrOrder('artists')}>Artista - Música</Td>
              <Td width={25} className={this.verifyAttrOrder('status')}>Playlist</Td>
              <Td width={10} className={this.verifyAttrOrder('score')}>Filtr Coins</Td>
              <Td width={15} className={this.verifyAttrOrder('rescues_count')}>Total de streans</Td>
              <Td width={10}>Limite</Td>
              <Td width={10}>Ordem</Td>
              <Td width={10}>Status</Td>
              <Td width={15}>Actions</Td>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.rescues.sort((a, b) => a.priority > b.priority ? 1 : -1).map((rescue, i) => {
              return(
                <Tr key={i}>
                  <Td nowrap>{rescue.artists} - {rescue.track_name}</Td>
                  <Td nowrap>{rescue.info_playlist ? rescue.info_playlist.name : '-'}</Td>
                  <Td>{rescue.score}</Td>
                  <Td>{rescue.rescues_count}</Td>
                  <Td>{rescue.limit_streams || '-'}</Td>
                  <Td>{rescue.priority}</Td>
                  <Td>
                      <Template condition={rescue.status}><Status success>Ativo</Status></Template>
                      <Template condition={!rescue.status}><Status error>Inativo</Status></Template>
                  </Td>
                  <Td>
                    <Button className="circle m-0" plugin={Link} to={`/edit-rescue/${rescue.id}`}><Icon name="pencil" /></Button>
                    <Button className="circle m-0 ml-md" plugin={Link} to={`/rescue/${rescue.id}`}><Icon name="eye" /></Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
        <Template condition={this.props.totalPages > 1 && !this.props.ordering}>
          <Pagination page={1} size={this.props.totalPages} onChange={(i)=> this.loadPage(i)} />
        </Template>
      </Container>
      <Modal ref="modal_delete_rescue" className="xs">
        <Text className="title lg mb-sm">Confirmar?</Text>
        <Text className="bold mb-lg">Essa ação não pode ser desfeita.</Text>
        <Button className="block outline black max-w-xs mx-auto">Sim</Button>
        <Button className="block outline black max-w-xs mx-auto" onClick={() => {this.refs.modal_delete_rescue.hide()}}>Não</Button>
      </Modal>
    </PageMenu>
  );
  }
}

const MapStateToProps = state => ({
  rescues: state.LoadRescues.rescues,
  busy: state.LoadRescues.busy,
  totalPages: state.LoadRescues.totalPages,
  alertCreateRescue: state.LoadRescues.alertCreateRescue,
  selectedOrder: state.LoadRescues.selectedOrder,
  orderType: state.LoadRescues.orderType,
  orderAttribute: state.LoadRescues.orderAttribute,
  ordering: state.LoadRescues.ordering
})

const MapDispatchToProps = dispatch => bindActionCreators({
  loadRescues,
  deleteRescue,
  loadPageRescue,
  orderRescues,
  hideAlert
}, dispatch);

FormRescueAdmin = reduxForm({
  form: 'formRescueAdmin',
  enableReinitialize: true
})(FormRescueAdmin);

export default connect(MapStateToProps, MapDispatchToProps)(FormRescueAdmin);
