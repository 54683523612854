import {
  REMOVE_GRATIFICATION,
  REMOVE_GRATIFICATION_SUCCESS,
  REMOVE_GRATIFICATION_FAILED
} from './actionTypes';

import api from '../services/api';

export const removeGratification = (gratification_id, amount) => async (dispatch) => {
  dispatch({ type: REMOVE_GRATIFICATION })
  try {
    const token = localStorage.getItem('token')
    
    await api.removeGratification(token, gratification_id)

    dispatch({ type: REMOVE_GRATIFICATION_SUCCESS, gratificationId: gratification_id, amount })
  }catch(err) {
    dispatch({ type: REMOVE_GRATIFICATION_FAILED })
  }
}