import {
  LOAD_USER_FULL_LOG,
  LOAD_USER_FULL_LOG_SUCCESS,
  LOAD_USER_FULL_LOG_FAILED,
  LOAD_PAGE_USER_FULL_LOG_SUCCESS,
  CREATE_GRATIFICATION,
  CREATE_GRATIFICATION_SUCCESS,
  CREATE_GRATIFICATION_FAILED,
  HIDE_ALERT_CREATE_GRATIFICATION,

  REMOVE_GRATIFICATION,
  REMOVE_GRATIFICATION_SUCCESS,
  REMOVE_GRATIFICATION_FAILED
} from '../actions/actionTypes';

const initialState = {
  fullLog: [],
  busy: false,
  totalPages: 0,
  showAlertGratification: false,
  removingGratification: false,
  currentPage: 1,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_FULL_LOG:
      return {
        ...state,
        busy: true
      }
    case LOAD_USER_FULL_LOG_SUCCESS:

      function getParamsFromUrl(url) {
        url = decodeURI(url);
        if (typeof url === 'string') {
            let params = url.split('?');
            let eachParamsArr = params[1].split('&');
            let obj = {};
            if (eachParamsArr && eachParamsArr.length) {
                eachParamsArr.forEach(param => {
                    let keyValuePair = param.split('=')
                    let key = keyValuePair[0];
                    let value = keyValuePair[1];
                    obj[key] = value;
                })
            }
            return obj;
        }
      }

      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params.page

      return {
        ...state,
        fullLog: action.payload,
        totalPages: parseInt(totalPages),
        currentPage: 1,
        busy: false
      }
    case LOAD_USER_FULL_LOG_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case LOAD_PAGE_USER_FULL_LOG_SUCCESS:
      return {
        ...state,
        fullLog: action.payload,
        currentPage: action.page
      }
    case CREATE_GRATIFICATION:
        return {
          ...state,
          showAlertGratification: false
        }
      case CREATE_GRATIFICATION_SUCCESS:
        return {
          ...state,
          busy: false,
          showAlertGratification: true
        }
      case CREATE_GRATIFICATION_FAILED:
        return {
          ...state,
          error: action.error,
          busy: false
        }
      case HIDE_ALERT_CREATE_GRATIFICATION:
        return {
          ...state,
          showAlertGratification: false
        }
      case REMOVE_GRATIFICATION:
        return {
          ...state,
          removingGratification: true
        }
      case REMOVE_GRATIFICATION_SUCCESS:
        const { gratificationId } = action

        const filterGratifications = state.fullLog.filter(item => 
          item.attributes.statementable && parseInt(item.attributes.statementable.id) !== parseInt(gratificationId)
        )

        return {
          ...state,
          fullLog: filterGratifications,
          removingGratification: false
        }
      case REMOVE_GRATIFICATION_FAILED:
        return {
          ...state,
          removingGratification: false
        }
    default:
      return state
  }
}
