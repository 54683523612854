import {
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILED
} from './actionTypes';

import api from '../services/api';
import { SubmissionError } from 'redux-form'

export const updateProduct = (idProduct) => async (product_attributes, dispatch) => {
  dispatch({ type: UPDATE_PRODUCT })
  const { status, image, name, value, date_start, date_finish, quantity, kind, description } = product_attributes

  let new_kind = null;
  if(kind === 'delivery' || kind === 'coupon') {
    new_kind = kind
  }

  let value_formated = null
  if(value) {
    value_formated = value.toString().replace('.','')
  }

  let date_start_standard = ''
  if(date_start) {
    date_start_standard = standardDate(date_start)
  }

  let date_finish_standard = ''
  if(date_finish) {
    date_finish_standard = standardDate(date_finish)
  }

  function is_url(str) {
    var regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  let data;

  if(is_url(image) || image === undefined) {
    data = {
      "product": {
        "status": status,
        "name": name,
        "value": value_formated,
        "date_start": date_start_standard,
        "date_finish": date_finish_standard,
        "quantity": quantity,
        "description": description,
        "kind": new_kind
      }
    }
  } else {
    const product_image = await getBase64(image[0]);
    data = {
      "product": {
        "status": status,
        "name": name,
        "value": value_formated,
        "date_start": date_start_standard,
        "date_finish": date_finish_standard,
        "quantity": quantity,
        "kind": new_kind,
        "description": description,
        "image": {
          "data": product_image
        }
      }
    }
  }

  localStorage.removeItem('edit_product_ok')
  try {
    const token = localStorage.getItem('token')
    await api.updateProduct(token, idProduct, data);
    localStorage.setItem('edit_product_ok', 'ok')
    dispatch({ type: UPDATE_PRODUCT_SUCCESS })
  } catch(err) {
    dispatch({ type: UPDATE_PRODUCT_FAILED, error: err.response.status })
    localStorage.setItem('array_errors_edit_product', JSON.stringify(err.response.data))
    if(err.response.status === 500) {
      localStorage.setItem('array_errors_edit_product', JSON.stringify({ server_error: ['Internal Server Error'] }))
    }
    throw new SubmissionError({_error: 'error'})
  }
}

function standardDate(date) {
  let year = '';
  let month = '';
  let day = '';
  let hour = '';

  for(var i = 0; i < date.length; i++){
    if(i < 2) {
      day += date[i]
    }
    if(i === 3 || i === 4){
      month += date[i]
    }
    if(i >=6 && i <= 9){
      year += date[i]
    }
    if(i >= 11 && i <= 15) {
      hour += date[i]
    }
  }
  return `${year}-${month}-${day} ${hour}`
}
