import {
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILED
} from './actionTypes';

import api from '../services/api';
import history from '../history';
import { SubmissionError } from 'redux-form'

export const createAdmin = async (value, dispatch) => {
  dispatch({ type: CREATE_ACCOUNT })
  const { email, password, password_confirmation, roles, status } = value

  let status_admin = false
  if(status) {
    status_admin = true
  }

  let admin_kind = null
  if(roles === 'admin') {
    admin_kind = 'admin'
  } else if(roles === 'master') {
    admin_kind = 'master'
  } else if(roles === 'promoter') {
    admin_kind = 'promoter'
  } else if(roles === 'logistic') {
    admin_kind = 'logistic'
  }

  const data = {
    "admin": {
      "email": email,
      "password": password,
      "password_confirmation": password_confirmation,
      "status": status_admin,
      "roles": admin_kind
    }
  }
  const tokenAdmin = localStorage.getItem('token')
  localStorage.removeItem('error_kind_admin')

  if(admin_kind != null) {
    try {
      const result = await api.createAdmin(tokenAdmin, data);
      dispatch({ type: CREATE_ACCOUNT_SUCCESS, payload: result.data.data })
      history.push('/accounts')
    } catch(err) {
      dispatch({ type: CREATE_ACCOUNT_FAILED, error: err.response.status })
      localStorage.setItem('array_errors_create_admin', JSON.stringify(err.response.data))
      if(err.response.status === 500) {
        localStorage.setItem('array_errors_create_admin', JSON.stringify({ server_error: ['Internal Server Error'] }))
      }
      throw new SubmissionError({_error: 'error'})
    }
  } else {
    dispatch({ type: CREATE_ACCOUNT_FAILED, error: 'error' })
    localStorage.setItem('error_kind_admin', true)
    localStorage.removeItem('array_errors_create_admin')
  }
}
