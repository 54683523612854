import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import PageMenu from '../../modules/pageMenu'
import { Container, Title, Text, Breadcrumb, Button, Input, Alert } from "sme-theme"

class FormRescueAdmin extends React.Component {
  constructor() {
    super();
    this.state = {
      file: null,
      busy:false
    }
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }

    return (
    <PageMenu focus="order-import" loading={this.props.busy}>
      <Container>
        <Title className="flex-align-center">Pedidos</Title>
        <Breadcrumb>
          <Text>Importar</Text>
        </Breadcrumb>
        <Alert className="my-md" type="success" iconName="attention-circle-o">
          <Text className="text-bold">Arquivo processado com sucesso!</Text>
          <Text>34.345 pedidos atualziados!</Text>
        </Alert>
        <Alert className="my-md" type="error" iconName="attention-circle-o">
          <Text>Formato inválido!</Text>
          <Text>Só são permitidos arquivos xml</Text>
        </Alert>
        <Text className="mt-xl mb-md muted">Selecione o arquivo que deseja importar</Text>
        <Input
          name="image"
          buttonClass="sm"
          type="file"
          label="Upload"
          helpText="Só são permitidos arquivos xml"
          onChange={e => this.setState({file: e.target.value})}
        />
        <Button
          disabled={!this.state.file}
          loading={this.state.busy}
          className="block max-w-xs mt-xl"
          onClick={() => this.setState({busy: true})}
        >Processar</Button>
      </Container>
    </PageMenu>
  );
  }
}

export default FormRescueAdmin;
