import {
  CREATE_RESCUE,
  CREATE_RESCUE_SUCCESS,
  CREATE_RESCUE_FAILED
} from './actionTypes';

import api from '../services/api';
import history from '../history';
import { SubmissionError } from 'redux-form'

export const createRescue = async (value, dispatch) => {
  dispatch({ type: CREATE_RESCUE })
  const { uri, score, status, playlist, playlist_checkbox, limited, limit_streams, priority } = value

  let data;

  if(playlist_checkbox) {
    data = {
      "rescue": {
        "uri": uri,
        "score": score,
        "playlist": playlist,
        "status": status ? true : false,
        "limited": limited ? true : false,
        "limit_streams": limit_streams,
        "priority": priority || 999,
      }
    }
  } else {
    data = {
      "rescue": {
        "uri": uri,
        "score": score,
        "status": status ? true : false,
        "limited": limited ? true : false,
        "limit_streams": limit_streams,
        "priority": priority || 999,
      }
    }
  }

  try {
    const tokenAdmin = localStorage.getItem('token')
    await api.createRescue(tokenAdmin, data);
    dispatch({ type: CREATE_RESCUE_SUCCESS })
    history.push('/rescues')
  } catch(err) {
    dispatch({ type: CREATE_RESCUE_FAILED, error: err.response.status })
    localStorage.setItem('array_errors_create_rescue', JSON.stringify(err.response.data))
    if(err.response.status === 500) {
      localStorage.setItem('array_errors_create_rescue', JSON.stringify({ server_error: ['Internal Server Error'] }))
    }
    throw new SubmissionError({_error: 'error'})
  }
}