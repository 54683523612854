import {
  LOAD_BADGES,
  LOAD_BADGES_SUCCESS,
  LOAD_BADGES_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadBadges = () => async (dispatch) => {
  dispatch({ type: LOAD_BADGES })
  try {
    const token = localStorage.getItem('token')
    const result = await api.loadBadges(token);
    dispatch({ type: LOAD_BADGES_SUCCESS, payload: result.data.data, lastLink: result.data.links.last })
  } catch(err) {
    dispatch({ type: LOAD_BADGES_FAILED, error: err })
  }
}
