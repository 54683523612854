import {
CREATE_CAMPAIGN,
CREATE_CAMPAIGN_SUCCESS,
CREATE_CAMPAIGN_FAILED
} from './actionTypes';

import api from '../services/api';
import history from '../history';
import { SubmissionError } from 'redux-form'

export const createCampaign = () => async (value, dispatch) => {
  dispatch({ type: CREATE_CAMPAIGN })
  const {
    name,
    status,
    date_finish,
    date_start,
    enable_banner,
    banner_image,
    image,
    image_medium,
    image_small
  } = value;

  let dateStart = ''
  let dateFinish = ''
  if(date_start) {
    dateStart = standardDate(date_start)
  }
  if(date_finish) {
    dateFinish = standardDate(date_finish)
  }

  function standardDate(date) {
    let year = '';
    let month = '';
    let day = '';

    for(var i = 0; i < date.length; i++){
      if(i < 2) {
        day += date[i]
      }
      if(i === 3 || i === 4){
        month += date[i]
      }
      if(i >=6 && i <= 9){
        year += date[i]
      }
    }
    const date_standard = year + '-' + month + '-' + day;
    return date_standard;
  }

  function is_url(str) {
    var regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  let new_status = false
  if(status) {
    new_status = true
  }

  const data = {
    "campaign": {
      "name": name,
      "date_finish": dateFinish,
      "date_start": dateStart,
      "status": new_status,
      "enable_banner": enable_banner,
      "image": {},
    }
  };

  if (banner_image && !is_url(banner_image)) {
    data.campaign.image.banner = await getBase64(banner_image[0]);
  }

  if (image && !is_url(image)) {
    data.campaign.image.image = await getBase64(image[0]);
  }

  if (image_medium && !is_url(image_medium)) {
    data.campaign.image.image_medium = await getBase64(image_medium[0]);
  }

  if (image_small && !is_url(image_small)) {
    data.campaign.image.image_small = await getBase64(image_small[0]);
  }

  const tokenAdmin = localStorage.getItem('token')

  try {
    await api.createCampaign(tokenAdmin, data)
    dispatch({ type: CREATE_CAMPAIGN_SUCCESS })
    history.push('/campaigns')
  } catch(err) {
    dispatch({ type: CREATE_CAMPAIGN_FAILED, error: err.response.status })
    localStorage.setItem('array_errors_create_campaign', JSON.stringify(err.response.data))
    if(err.response.status === 500) {
      localStorage.setItem('array_errors_create_campaign', JSON.stringify({ server_error: ['Internal Server Error'] }))
    }
    throw new SubmissionError({_error: 'error'})
  }
}
