import {
  LOAD_PAGE_ORDER_COMMENTS_SUCCESS,
  LOAD_PAGE_ORDER_COMMENTS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPageOrderComments = (token, page, commentable_type, commentable_id) => async (dispatch) => {
  try {
    const result = await api.loadPageOrderComments(token, page, commentable_type, commentable_id);
    dispatch({ type: LOAD_PAGE_ORDER_COMMENTS_SUCCESS, payload: result.data.data })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_ORDER_COMMENTS_FAILED, error: err })
  }
}
