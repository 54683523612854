import { connect } from "react-redux";
import React from "react";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import {
  Template,
  Alert,
  Wrap,
  Text,
  Title,
  Breadcrumb,
  Button,
  Container,
  Grid,
  Cell,
  Status,
  Divider,
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  Icon,
  Pagination,
} from "sme-theme";
import PageMenu from "../../modules/pageMenu";

import { loadRescue } from "../../actions/loadRescueAction";
import { loadRescueAnalytics } from "../../actions/loadRescueAnalyticsAction";
import { loadPageRescueAnalytics } from "../../actions/loadPageRescueAnalyticsAction";

import { loadRescueUsers } from "../../actions/loadRescueUsersAction";
import { loadPageRescueUsers } from "../../actions/loadPageRescueUsersAction";

import { loadUsersTotalCashbacks } from "../../actions/loadUsersTotalCashbacks";

import { loadTotalStreamsCashBack } from "../../actions/loadTotalStreamsCashBackAction";

import {
  downloadRescueUsers,
  resetDownloadAlert,
} from "../../actions/downloadRescueUsersAction";

class PageComponent extends React.Component {
  constructor() {
    super();
    this.state = { tab: "users" };
  }

  componentDidMount() {
    const token = localStorage.getItem("token");
    const { id } = this.props.match.params;

    this.props.loadRescue(token, id);
    this.props.loadRescueAnalytics(token, id);
    this.props.loadRescueUsers(token, id);
    this.props.loadUsersTotalCashbacks(token, id);
    this.props.loadTotalStreamsCashBack(token, id);
  }

  normalizeDate(str) {
    return `${str.substr(8, 2)}/${str.substr(5, 2)}/${str.substr(0, 4)}`;
  }

  loadPageAnalytics = (page) => {
    const token = localStorage.getItem("token");
    const { id } = this.props.match.params;

    this.props.loadPageRescueAnalytics(token, id, page);
  };

  loadPageUsers = (page) => {
    const token = localStorage.getItem("token");
    const { id } = this.props.match.params;

    this.props.loadPageRescueUsers(token, id, page);
  };

  downloadUsers() {
    const token = localStorage.getItem("token");
    const { id } = this.props.match.params;

    this.props.downloadRescueUsers(token, id);
  }

  componentWillUnmount() {
    this.props.resetDownloadAlert();
  }

  render() {
    return (
      <PageMenu focus="rescues">
        <Container>
          <Title className="mb-sm">Hits do momento</Title>

          <Breadcrumb>
            <Button plugin={Link} to="/rescues">
              Hits do momento
            </Button>
            <Text>{`${this.props.artists} - ${this.props.trackName}`}</Text>
          </Breadcrumb>
          <Wrap className="mt-xl">
            <Grid>
              <Cell size={3}>
                <Text className="mb-sm muted">Artista - Música</Text>
                <Text className="mb-md">{`${this.props.artists} - ${this.props.trackName}`}</Text>
              </Cell>
              <Cell size={3}>
                <Text className="mb-sm muted">Playlist</Text>
                <Text className="mb-md">
                  {this.props.infoPlaylist ? this.props.infoPlaylist.name : "-"}
                </Text>
              </Cell>
              <Cell size={3}>
                <Text className="mb-sm muted">Filtr Coins</Text>
                <Text className="mb-md">{this.props.score}</Text>
              </Cell>
            </Grid>
            <Grid className="mt-md">
              <Cell size={3}>
                <Text className="mb-sm muted">Streamings totais</Text>
                <Text className="mb-md">{this.props.totalStreamings}</Text>
              </Cell>
              <Cell size={3}>
                <Text className="mb-sm muted">Ouvintes totais</Text>
                <Text className="mb-md">{this.props.usersTotalCashbacks}</Text>
              </Cell>
              <Cell size={3}>
                <Text className="mb-sm muted">Limite</Text>
                <Text className="mb-md">{this.props.limitStreams || "-"}</Text>
              </Cell>
            </Grid>

            <Text className="mb-sm primary mt-xl">Analytics</Text>
            <Divider className="my-md" />

            <Table>
              <Thead>
                <Tr>
                  <Td width={30}>Data</Td>
                  <Td width={50}>Streamings totais</Td>
                  <Td>Ouvintes totais</Td>
                </Tr>
              </Thead>
              <Tbody>
                {this.props.analytics.map((item, i) => {
                  return (
                    <Tr key={i}>
                      <Td>{this.normalizeDate(item.created)}</Td>
                      <Td>{item.total}</Td>
                      <Td>{item.total_users}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <Template condition={this.props.totalAnalyticsPage > 1}>
              <Pagination
                page={1}
                size={this.props.totalAnalyticsPage}
                onChange={(i) => this.loadPageAnalytics(i)}
              />
            </Template>
          </Wrap>

          <Text className="mb-sm primary mt-xl">Usuários</Text>
          <Divider className="my-md" />

          {this.props.fileSendToEmail && (
            <Alert className="my-md" type="success">
              <Text>
                O arquivo será enviado para seu e-mail quando estiver pronto.
              </Text>
            </Alert>
          )}

          <Button onClick={() => this.downloadUsers()}>DOWNLOAD</Button>

          <Table>
            <Thead>
              <Tr>
                <Td width={30}>Nome</Td>
                <Td width={50}>Email</Td>
                <Td>Total</Td>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.users.map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td>{item.name}</Td>
                    <Td>{item.email}</Td>
                    <Td>{item.total_streams}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Template
            condition={this.props.usersLoaded && this.props.totalUsersPage > 1}
          >
            <Pagination
              page={1}
              size={this.props.totalUsersPage}
              onChange={(i) => this.loadPageUsers(i)}
            />
          </Template>
        </Container>
      </PageMenu>
    );
  }
}

const MapStateToProps = (state) => ({
  artists: state.LoadRescue.artists,
  trackName: state.LoadRescue.trackName,
  playlist: state.LoadRescue.playlist,
  infoPlaylist: state.LoadRescue.infoPlaylist,
  score: state.LoadRescue.score,
  totalUsers: state.LoadRescue.totalUsers,
  limitStreams: state.LoadRescue.limitStreams,

  analytics: state.LoadRescueAnalytics.analytics,
  totalAnalyticsPage: state.LoadRescueAnalytics.totalPages,

  users: state.LoadRescueUsers.users,
  totalUsersPage: state.LoadRescueUsers.totalPages,
  usersLoaded: state.LoadRescueUsers.usersLoaded,

  usersTotalCashbacks: state.LoadUsersCashbacks.cashBacks,

  totalStreamings: state.LoadTotalStreamsCashBack.totalStreamings,

  fileSendToEmail: state.LoadRescueUsers.fileSendToEmail,
});

const MapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadRescue,
      loadRescueAnalytics,
      loadPageRescueAnalytics,
      loadRescueUsers,
      loadPageRescueUsers,
      loadUsersTotalCashbacks,
      downloadRescueUsers,
      resetDownloadAlert,
      loadTotalStreamsCashBack,
    },
    dispatch
  );

export default connect(MapStateToProps, MapDispatchToProps)(PageComponent);
