import {
  UPDATE_PLAYLIST,
  UPDATE_PLAYLIST_SUCCESS,
  UPDATE_PLAYLIST_FAILED
} from './actionTypes';

import api from '../services/api';
import { SubmissionError } from 'redux-form'

export const editPlaylist = (playlist_id) => async (playlist_attributes, dispatch) => {
  dispatch({ type: UPDATE_PLAYLIST })
  const { name, status } = playlist_attributes
  
  let new_status = false
  if(status) {
    new_status = status
  }

  const data = {
    "playlist": {
      "name": name,
      "status": new_status
    }
  }

  
  localStorage.removeItem('edit_playlist_ok')
  
  try {
    const token = localStorage.getItem('token')
    await api.updatePlaylist(token, playlist_id, data);

    localStorage.setItem('edit_playlist_ok', 'ok')
    dispatch({ type: UPDATE_PLAYLIST_SUCCESS })
  } catch(err) {
    dispatch({ type: UPDATE_PLAYLIST_FAILED })
    localStorage.setItem('array_errors_edit_playlist', JSON.stringify(err.response.data))
    throw new SubmissionError({_error: 'error'})
  }
}