import { connect } from 'react-redux'
import React from 'react'
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form';
import PageMenu from '../../modules/pageMenu'
import { Alert, Text, Button, Container, Icon, Table, Tbody, Thead, Td, Tr, Title, Status, Template, Pagination } from "sme-theme"
import { HIDE_ALERT_CREATE_CAMPAIGN } from '../../actions/actionTypes'
import { hideAlert } from '../../actions/hideAlertAction'
import { bindActionCreators } from 'redux'
import { loadCurrentAdmin } from '../../actions/loadCurrentAdminAction';
import { loadCampaigns } from '../../actions/loadCampaignsAction';
import { loadPageCampaign } from '../../actions/loadPageCampaignAction';
import { filterCampaigns } from '../../actions/filterCampaignsAction';

class CampaignList extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadCurrentAdmin(token);
    this.props.loadCampaigns();
  }

  componentWillUnmount() {
    this.props.hideAlert(HIDE_ALERT_CREATE_CAMPAIGN)
  }

  filter = attr => {
    const token = localStorage.getItem('token')
    this.props.filterCampaigns(token, 'campaigns', attr, this.props.filterType)
  }

  verifyAttrFilter = attr => {
    if(attr === this.props.selectedFilter) {
      return 'sort-title active'
    }
    return 'sort-title'
  }

  loadPage = (page) => {
    const token = localStorage.getItem('token')
    this.props.loadPageCampaign(token, page, this.props.filterAttribute, this.props.filterType);
  }

  render() {
    return (
      <PageMenu focus="campaigns" loading={this.props.busy}>
        <Container>
          <Title className="flex-align-center">Artista do Momento <Button plugin={Link} to="/campaign-new" className="sm mb-0 ml-lg">NOVO ARTISTA DO MOMENTO</Button></Title>
          <Template condition={this.props.alertCreateCampaign}>
            <Alert className="my-md" type="success">
              <Text>Parabéns!</Text>
              <Text>O artista do momento foi criado com sucesso. Vamos arrasar!</Text>
            </Alert>
          </Template>
          <Table>
            <Thead>
              <Tr>
                <Td width={60} onClick={() => this.filter('name')} className={this.verifyAttrFilter('name')}>Nome <Icon name="arrow-down"/></Td>
                <Td width={30} onClick={() => this.filter('status')} className={this.verifyAttrFilter('status')}>Status <Icon name="arrow-down"/></Td>
                <Td width={10}>Actions</Td>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.campaigns.map((campaign, i) => {
                return(
                  <Tr key={i}>
                    <Td nowrap>{campaign.name}</Td>
                    <Td>
                      <Template condition={campaign.status}><Status success>Ativo</Status></Template>
                      <Template condition={!campaign.status}><Status error>Inativo</Status></Template>
                    </Td>
                    <Td>
                      <Button title="Editar" className="circle m-0" plugin={Link} to={`/campaign/${campaign.id}`}><Icon name="pencil" /></Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
          <Template condition={this.props.totalPages > 1 && !this.props.filtering}>
            <Pagination page={1} size={this.props.totalPages} onChange={(i)=> this.loadPage(i)} />
          </Template>
        </Container>
      </PageMenu>
    );
  }
}

const mapStateToProps = state => {
  return ({
    busy: state.LoadCampaigns.busy,
    totalPages: state.LoadCampaigns.totalPages,
    campaigns: state.LoadCampaigns.campaigns,
    alertCreateCampaign: state.LoadCampaignContent.alertCreateCampaign,
    selectedFilter: state.LoadCampaigns.selectedFilter,
    filterType: state.LoadCampaigns.filterType,
    filterAttribute: state.LoadCampaigns.filterAttribute,
    filtering: state.LoadCampaigns.filtering
  });
}


const mapDispatchToProps = dispatch => bindActionCreators({
  loadCurrentAdmin,
  loadCampaigns,
  loadPageCampaign,
  hideAlert,
  filterCampaigns
}, dispatch);

CampaignList = reduxForm({
  form: 'formCampaignList',
  enableReinitialize: true
})(CampaignList);

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
