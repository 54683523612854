import { connect } from 'react-redux'
import React from 'react'
import { reduxForm, formValueSelector} from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom'
import { updateRescue } from '../../actions/editRescueAction';
import { loadRescue } from '../../actions/loadRescueAction';
import PageMenu from '../../modules/pageMenu'
import { Wrap, Text, Title, Breadcrumb, Template, Button, Container, Alert, Modal } from "sme-theme"
import { InputRedux, ErrorField } from '../../modules/inputRedux';

class FormEditRescueAdmin extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'content'}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadRescue(token, this.props.match.params.id);
    localStorage.removeItem('array_errors_edit_rescue');
    localStorage.removeItem('edit_rescue_ok');
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_edit_rescue')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_edit_rescue');
    localStorage.removeItem('edit_rescue_ok');
  }

  render() {
    const { error, handleSubmit, dirty } = this.props;
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const idRescue = this.props.match.params.id
    const formError = 'array_errors_edit_rescue'

    return (
    <PageMenu focus="rescues" loading={this.props.busy}>
      <Container>
        <Title className="mb-sm">Hits do momento</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/rescues">Hits do momento</Button>
          <Text>Editar Hits do Momento</Text>
        </Breadcrumb>
        <Template condition={localStorage.getItem('array_errors_edit_rescue') && error}>
          <Alert className="my-md" type="error" iconName="attention-circled">
            <Text>{this.props.error_status !== 500 && 'Alguns campos precisam da sua atenção:'}</Text>
            <ErrorField field="uri" label="URI" formError={formError} />
            <ErrorField field="score" label="Pontos de resgate" formError={formError} />
            <ErrorField field="priority" label="Prioridade da faixa" formError={formError} />
            <ErrorField field="limit_streams" label="Limite de plays da faixa" formError={formError} />
            <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
          </Alert>
        </Template>
        <Template condition={localStorage.getItem('edit_rescue_ok') === 'ok'}>
          <Alert className="my-md" type="success">
            <Text>Resgate atualizado com sucesso !</Text>
          </Alert>
        </Template>

        <Wrap className="max-w-xs">
          <form onSubmit={handleSubmit(updateRescue(idRescue))}>
            <InputRedux name="status" type="switch" label="Status" />
            <InputRedux name="limited" type="switch" label="Limite" />
            <InputRedux disabled={!this.props.limited} name="limit_streams" type="number" label="Limite de plays da faixa" formError={formError} />
            <InputRedux name="priority" type="number" label="Prioridade da faixa" formError={formError} />
            <InputRedux name="uri" label="URI de Hit do Momento" helpText="Música que o usuário deve escutar para ganhar Filtr Coins" formError={formError} />
            <InputRedux name="score" disabled label="Filtr Coins por resgate" helpText="Filtr Coins que o usuário irá acumular ao escutar cada música" msgError={error} formError={formError} />
            <Template condition={this.props.initialValues.playlist}>
              <InputRedux name="playlist" disabled label="URI da playlist" formError={formError} />
            </Template>
            <Button className="block" type="submit" loading={this.props.busyButton} disabled={!dirty}>SALVAR</Button>
          </form>
        </Wrap>
      </Container>
      <Modal ref="modal_delete_rescue" className="xs">
        <Text className="title lg mb-sm">Confirmar?</Text>
        <Text className="bold mb-lg">Essa ação não pode ser desfeita.</Text>
        <Button className="block outline black max-w-xs mx-auto">Sim</Button>
        <Button className="block outline black max-w-xs mx-auto" onClick={() => {this.refs.modal_delete_rescue.hide()}}>Não</Button>
      </Modal>
    </PageMenu>
  );
  }
}

const selector = formValueSelector('formEditRescueAdmin')

const MapStateToProps = state => ({
  initialValues: {
    uri: state.LoadRescue.uri,
    score: state.LoadRescue.score,
    status: state.LoadRescue.status,
    limited: state.LoadRescue.limited,
    limit_streams: state.LoadRescue.limitStreams,
    playlist: state.LoadRescue.playlist,
    priority: state.LoadRescue.priority
  },
  limited: selector(state, 'limited'),
  busy: state.LoadRescue.busy,
  busyButton: state.LoadRescue.busyButton,
  error_status: state.LoadRescue.error
})

const MapDispatchToProps = dispatch => bindActionCreators({
  loadRescue,
  updateRescue
}, dispatch);

FormEditRescueAdmin = reduxForm({
  form: 'formEditRescueAdmin',
  enableReinitialize: true
})(FormEditRescueAdmin);

export default connect(MapStateToProps, MapDispatchToProps)(FormEditRescueAdmin);
