import api from '../services/api';
import history from '../history';

export const verifyCode = async (value, dispatch) => {
  const { code } = value
  const pre_token = localStorage.getItem('pre_token')
  localStorage.removeItem('security_code_invalid')

  try {
    const result = await api.verifyCode(pre_token, code);

    const { access_token, roleName: roles } = result.data

    localStorage.setItem('admin_role', roles)
    localStorage.setItem('token', access_token)

    if(roles === 'promoter') {
      history.push('/scanner')
    } else if(roles === 'logistics') {
      history.push('/orders')
    } else {
      history.push('/campaigns')
    }
  } catch(err) {
    localStorage.setItem('security_code_invalid', true)
  }
}
