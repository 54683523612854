import {
  REFRESH_PLAYLIST_PLAYER,
  REFRESH_PLAYLIST_PLAYER_SUCCESS,
  REFRESH_PLAYLIST_PLAYER_FAILED
} from './actionTypes';

import api from '../services/api';

export const refreshToPlay = () => async (dispatch) => {
  dispatch({ type: REFRESH_PLAYLIST_PLAYER })
  
  try {
    const token = localStorage.getItem('token')
    let result = await api.loadToPlay(token);
    dispatch({ 
      type: REFRESH_PLAYLIST_PLAYER_SUCCESS, 
      included: result.data.included || [],
      tracks: result.data.data
    })
  } catch(err) {
    dispatch({ type: REFRESH_PLAYLIST_PLAYER_FAILED, error: err })
  }
}