import { connect } from 'react-redux'
import React from 'react'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom'
import { loadQuests } from '../../actions/loadQuestsAction';
import { loadPageQuest } from '../../actions/loadPageQuestAction';
import { HIDE_ALERT_CREATE_QUEST } from '../../actions/actionTypes'
import { hideAlert } from '../../actions/hideAlertAction'
import PageMenu from '../../modules/pageMenu'
import { Alert, Text, Title, Template, Button, Container, Table, Tbody, Thead, Td, Tr, Modal, Status, Pagination, Icon } from "sme-theme"

class FormRescueAdmin extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'content'}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadQuests(token);
    localStorage.removeItem('array_errors_form_quests')
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_form_quests')
  }

  componentWillUnmount() {
    this.props.hideAlert(HIDE_ALERT_CREATE_QUEST)
  }

  loadPage = page => {
    const token = localStorage.getItem('token')
    this.props.loadPageQuest(token, page)
  }

  verifyKindQuest = quest => {
    const { kind } = quest
    
    switch (kind) {
      case 'spotify_follow_artist':
        return 'Deezer - Seguir artista'
      case 'spotify_follow_playlist':
        return 'Deezer - Seguir playlist'
      case 'spotify_listen_track':
        return 'Deezer - Escutar faixa'
      case 'spotify_save_track':
        return 'Deezer - Salvar faixa'
      case 'spotify_save_album':
        return 'Deezer - Salvar álbum'
      case 'question':
        return 'Pergunta'
      case 'opt':
        return 'Opt Mail'
      case 'pre_save':
        return 'Pre-save'
      case 'youtube_subscribe':
        return 'Youtube - Inscrição'
      case 'youtube_watch_video':
        return 'Youtube - Assistir vídeo'
      case 'quest_spotify_playlist':
        return 'Deezer - Escutar Playlist'
      case 'external_link':
        return 'Link Externo'
      default:
        return 'Missão'
    }
  }

  getDescription(quest) {
    const { kind } = quest
    if(!kind) return

    if(kind === 'quest_spotify_playlist') {
      const { name } = quest.quest_spotify_playlist
      return name
    }

    if(kind.includes('spotify')) {
      const { name } = quest.quest_spotify
      return name
    }

    if(kind === 'question') {
      const { question } = quest.quest_question
      return question
    }

    if(kind === 'opt') {
      const { description } = quest.quest_opt
      return description
    }

    if(kind === 'pre_save') {
      const { name_artist } = quest.quest_pre_save
      return name_artist
    }

    if(kind.includes('youtube')) {
      const { name } = quest.quest_youtube
      return name
    }

    if(kind === 'external_link') {
      return quest.quest_external_link.label;
    }
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }

    return (
    <PageMenu focus="missions" loading={this.props.busy}>
      <Container>
        <Title className="flex-align-center">Missões <Button className="sm mb-0 ml-lg" plugin={Link} to="/new-mission">NOVA MISSÃO</Button></Title>
        <Template condition={this.props.alertCreateQuest}>
          <Alert className="my-md" type="success">
            <Text>Missão criada com sucesso !</Text>
          </Alert>
        </Template>
        <Table className="mt-xl">
          <Thead>
            <Tr>
              <Td width={10} className="sort-title active">Início</Td>
              <Td width={15} className="sort-title">Tipo</Td>
              <Td width={15} className="sort-title">Descrição</Td>
              <Td width={10} className="sort-title">Status</Td>
              <Td width={10} className="sort-title">Completos</Td>
              <Td width={10}> </Td>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.quests.map((quest, i) => {
              return(
                <Tr key={i}>
                  <Td>{quest.date_start_formated}</Td>
                  <Td>{this.verifyKindQuest(quest)}</Td>
                  <Td>{this.getDescription(quest)}</Td>
                  {quest.status ?
                    <Td><Status success>Ativo</Status></Td> :
                    <Td><Status error>Inativo</Status></Td>}
                  <Td>{quest.accomplished_count || 0}</Td>
                  <Td>
                    <Button title="Editar" className="circle m-0" plugin={Link} to={`/edit-mission/${quest.id}`}><Icon name="pencil" /></Button>
                    <Button title="Editar" className="circle m-0 ml-md" plugin={Link} to={`/view-mission/${quest.id}`}><Icon name="eye" /></Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
        <Template condition={!this.props.busy}>
          <Pagination page={1} size={this.props.totalPages} onChange={(i)=> this.loadPage(i)} />
        </Template>
      </Container>
      <Modal ref="modal_delete_rescue" className="xs">
        <Text className="title lg mb-sm">Confirmar?</Text>
        <Text className="bold mb-lg">Essa ação não pode ser desfeita.</Text>
        <Button className="block outline black max-w-xs mx-auto">Sim</Button>
        <Button className="block outline black max-w-xs mx-auto" onClick={() => {this.refs.modal_delete_rescue.hide()}}>Não</Button>
      </Modal>
    </PageMenu>
  );
  }
}

const MapStateToProps = state => ({
  quests: state.LoadQuests.quests,
  busy: state.LoadQuests.busy,
  totalPages: state.LoadQuests.totalPages,
  alertCreateQuest: state.LoadQuests.alertCreateQuest
})

const MapDispatchToProps = dispatch => bindActionCreators({
  loadQuests,
  loadPageQuest,
  hideAlert
}, dispatch);

FormRescueAdmin = reduxForm({
  form: 'formListQuestAdmin',
  enableReinitialize: true
})(FormRescueAdmin);

export default connect(MapStateToProps, MapDispatchToProps)(FormRescueAdmin);
