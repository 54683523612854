import {
  LOAD_PAGE_QUEST_SUCCESS,
  LOAD_PAGE_QUEST_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPageQuest = (token, page) => async (dispatch) => {
  try {
    const result = await api.loadPageQuest(token, page);
    dispatch({ type: LOAD_PAGE_QUEST_SUCCESS, payload: result.data.data })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_QUEST_FAILED, error: err })
  }
}
