import {
  SEARCH_ORDERS,
  SEARCH_ORDERS_SUCCESS,
  SEARCH_ORDERS_FAILED
} from './actionTypes';

import api from '../services/api';

export const searchOrders = async (search_attr, dispatch) => {
  dispatch({ type: SEARCH_ORDERS })
  const { search } = search_attr
  
  try {
    const token = localStorage.getItem('token')
    let result = await api.searchOrders(token, search);

    dispatch({ 
      type: SEARCH_ORDERS_SUCCESS, 
      payloadSearch: result.data.data,
      includedSearch: result.data.included,
      lastLink: result.data.links.last,
      search: search
    })
  } catch(err) {
    dispatch({ type: SEARCH_ORDERS_FAILED, error: err })
  }
}