import {
  LOAD_PAGE_CAMPAIGN_SUCCESS,
  LOAD_PAGE_CAMPAIGN_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPageCampaign = (token, page, filterAttribute, filterType) => async (dispatch) => {
  try {
    const result = await api.loadPageCampaign(token, page, filterAttribute, filterType);
    dispatch({ type: LOAD_PAGE_CAMPAIGN_SUCCESS, campaigns: result.data.data })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_CAMPAIGN_FAILED, error: err })
    console.log(err)
  }
}
