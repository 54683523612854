import { connect } from 'react-redux'
import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form';
import PageMenu from '../../modules/pageMenu'
import { createBadge } from '../../actions/createBadgeAction';
import { Wrap, Text, Title, Breadcrumb, Template, Button, Container, Alert } from "sme-theme"
import { InputRedux, ErrorField } from '../../modules/inputRedux';

class FormBadge extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'content'}
  }

  componentDidMount() {
    localStorage.removeItem('array_errors_create_badge')
    localStorage.removeItem('select_kind_voucher_error')
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_create_badge')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_create_badge')
    localStorage.removeItem('select_kind_voucher_error')
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const { error, handleSubmit, dirty } = this.props;
    const formError = 'array_errors_create_badge'

    return (
    <PageMenu focus="badges">
      <Container>
        <Title className="mb-sm">Badge Challenge</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/badges">Badge Challenge</Button>
          <Text>Criar Badge</Text>
        </Breadcrumb>

        <Wrap>
          <Template condition={localStorage.getItem('array_errors_create_badge') && error}>
            <Alert className="my-md" type="error" iconName="attention-circled">
              <Text>{this.props.error_status !== 500 && 'Alguns campos precisam da sua atenção:'}</Text>
              <ErrorField field="name" label="Nome" formError={formError} />
              <ErrorField field="uri" label="URI" formError={formError} />
              <ErrorField field="goal" label="Meta" formError={formError} />
              <ErrorField field="description" label="Descrição" formError={formError} />
              <ErrorField field="image" label="Imagem" formError={formError} />
              <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
            </Alert>
          </Template>
          <Template condition={localStorage.getItem('select_kind_voucher_error')}>
            <Alert className="my-md" title="Alguns campos precisam da sua atenção:" type="error">
              Selecione o tipo da badge
            </Alert>
          </Template>
          <Wrap className="max-w-xs">
            <form onSubmit={handleSubmit(createBadge)} >
                <InputRedux name="status" type="switch" label="Ativar" formError={formError} />
                <InputRedux
                    name="voucher"
                    type="select"
                    options={[
                      {label: 'Coupons', value: 'coupons' },
                      {label: 'Badge', value: 'badge' }]
                    } 
                    label="Tipo"
                  />
                <InputRedux name="image" buttonClass="sm" helpText="Imagem da badge no formato 300x300" type="file" label="Foto" formError={formError} />
                <InputRedux name="name" type="text" label="Nome" formError={formError} />
                <InputRedux name="uri" type="text" label="URI" formError={formError} />
                <InputRedux name="goal" type="text" label="Meta" formError={formError} />
                <InputRedux name="description" type="textarea" className="textarea-sm" label="Descrição" formError={formError} />
                <Button className="block mt-lg" type="submit" loading={this.props.busyButton} disabled={!dirty}>ADICIONAR</Button>
            </form>
          </Wrap>

        </Wrap>

      </Container>
    </PageMenu>
  );
  }
}

const mapStateToProps = state => ({
  busyButton: state.LoadBadges.busyButton,
  error_status: state.LoadBadges.error
});

const mapDispatchToProps = dispatch => bindActionCreators({
  createBadge
}, dispatch);

FormBadge = reduxForm({
  form: 'newBadge',
  enableReinitialize: true
})(FormBadge);

export default connect(mapStateToProps, mapDispatchToProps)(FormBadge);
