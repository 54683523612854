import React, { Component, Fragment } from 'react';

import './app.scss';

import { Router, Route } from "react-router-dom";

import BadgeList from               "./pages/badges/badgeList"
import BadgeEdit from               "./pages/badges/badgeEdit"
import BadgeNew from                "./pages/badges/badgeNew"
import BadgeView from               "./pages/badges/badgeView"

import CampaignList from            "./pages/campaign/campaignList"
import CampaignEdit from            "./pages/campaign/campaignEdit"
import CampaignNew from             "./pages/campaign/campaignNew"
import CampaignUser from            "./pages/campaign/campaignUser"

import JukeboxList from             "./pages/jukebox/jukeboxList"
import JukeboxNew from              "./pages/jukebox/jukeboxNew"
import JukeboxEdit from             "./pages/jukebox/jukeboxEdit"
import JukeboxDisplay from           "./pages/jukebox/jukeboxDisplay"
import JukeboxPlayer from           "./pages/jukebox/jukeboxPlayer"

import Login from                   "./pages/login/login"
import LoginAuth from               "./pages/login/auth"
import NewPass from                 "./pages/login/newPass"
import ForgetPassRedirect from      "./modules/forgetPassRedirect"

import MissionList from             "./pages/missions/missionList"
import MissionEdit from             "./pages/missions/missionEdit"
import MissionNew from             "./pages/missions/missionNew"
import MissionView from             "./pages/missions/missionView"

import Profile from                 "./pages/profile/profile"

import RescueList from              "./pages/rescues/rescueList"
import RescueNew from               "./pages/rescues/rescueNew"
import RescueEdit from              "./pages/rescues/rescueEdit"
import RescueView from              "./pages/rescues/rescueView"

import StoreList from              "./pages/store/storeList"
import StoreNew from              "./pages/store/storeNew"
import StoreEdit from              "./pages/store/storeEdit"

import AccountList from             "./pages/accounts/accountList"
import AccountNew from              "./pages/accounts/accountNew"
import AccountEdit from             "./pages/accounts/accountEdit"

import Settings from                "./pages/settings/settings"

import UserList from                "./pages/users/userList"
import UserView from                "./pages/users/userView"
import UserLog from                 "./pages/users/userLog"
import UserFullLog from                 "./pages/users/fullLog"

import OrderList from                 "./pages/orders/orderList"
import OrdersOfUser from              "./pages/orders/ordersOfUserView"
import OrderView from                 "./pages/orders/orderView"
import OrderEdit from                 "./pages/orders/orderEdit"
import OrderCommentView from          "./pages/orders/orderCommentView"
import OrderExport from               "./pages/orders/orderExport"
import OrderImport from               "./pages/orders/orderImport"

import Scanner from                 "./pages/scanner/scanner"

import history from './history'

class AppContent extends Component {
  render() {
    return (
      <Fragment>
        <Route exact path="/" component={CampaignList} />

        <Route path="/orders" component={OrderList} />
        {/* <Route path="/order-export" component={OrderExport} />
        <Route path="/order-import" component={OrderImport} /> */}
        <Route exact path="/orders-of-user/:user_id" component={OrdersOfUser} />
        <Route path="/orders-of-user/:order_id/user/:user_id/address/:address_id" component={OrderEdit} />
        <Route exact path="/order/:id/user/:user_id" component={OrderView} />
        <Route exact path="/order/:order_id/:user/:user_id/:product/comment/:id" component={OrderCommentView} />

        <Route path="/badges" component={BadgeList} />
        <Route path="/badge/:id" component={BadgeEdit} />
        <Route path="/badge-new" component={BadgeNew} />
        <Route path="/badge-view/:id" component={BadgeView} />

        <Route path="/campaign-user/:id" component={CampaignUser} />
        <Route path="/campaign/:id" component={CampaignEdit} />
        <Route path="/campaigns" component={CampaignList} />
        <Route path="/campaign-new" component={CampaignNew} />

        <Route path="/jukebox" component={JukeboxList} />
        <Route path="/jukebox-new" component={JukeboxNew} />
        <Route path="/jukebox-edit/:id" component={JukeboxEdit} />
        <Route path="/jukebox-display" component={JukeboxDisplay} />
        <Route path="/jukebox-player" component={JukeboxPlayer} />

        <Route path="/login" component={Login} />
        <Route path="/login-auth" component={LoginAuth} />
        <Route path="/new-pass" component={NewPass} />
        <Route path="/token_reset/:token_reset" component={ForgetPassRedirect} />

        <Route path="/accounts" component={AccountList} />
        <Route path="/new-account" component={AccountNew} />
        <Route path="/edit-account/:id" component={AccountEdit} />

        <Route path="/profile" component={Profile} />

        <Route path="/missions" component={MissionList} />
        <Route path="/edit-mission/:id" component={MissionEdit} />
        <Route path="/new-mission" component={MissionNew} />
        <Route path="/view-mission/:id" component={MissionView} />

        <Route path="/store" component={StoreList} />
        <Route path="/new-store" component={StoreNew} />
        <Route path="/edit-store/:id" component={StoreEdit} />

        <Route path="/rescues" component={RescueList} />
        <Route path="/new-rescue" component={RescueNew} />
        <Route path="/rescue/:id" component={RescueView} />
        <Route path="/edit-rescue/:id" component={RescueEdit} />

        <Route path="/users" component={UserList} />
        <Route path="/user-log/:id/:campaign" component={UserLog} />
        <Route path="/full-log/:id" component={UserFullLog} />
        <Route path="/user/:id" component={UserView} />

        <Route path="/settings" component={Settings} />

        <Route path="/scanner" component={Scanner} />
      </Fragment>
    );
  };
};

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <AppContent />
      </Router>
    );
  }
}

export default App;
