import {
  UPDATE_ORDER_ADDRESS,
  UPDATE_ORDER_ADDRESS_SUCCESS,
  UPDATE_ORDER_ADDRESS_FAILED
} from './actionTypes';

import api from '../services/api';
import { SubmissionError } from 'redux-form'

export const updateOrderAddress = (address_id) => async (value, dispatch) => {
  dispatch({ type: UPDATE_ORDER_ADDRESS })
  
  const { recipient, street, neighborhood, city, complement, number, reference, cep, cpf } = value

  const data = {
    "address": {
      "recipientt": recipient,
      "street": street,
      "neighborhood": neighborhood,
      "city_id": city,
      "complement": complement,
      "number": number,
      "reference": reference,
      "cep": cep,
      "cpf": cpf
    }
  }
  
  localStorage.removeItem('update_order_address_ok')

  try {
    const token = localStorage.getItem('token')
    await api.updateOrderAddress(token, address_id, data)
    localStorage.setItem('update_order_address_ok', true)
    dispatch({ type: UPDATE_ORDER_ADDRESS_SUCCESS })
  } catch (err) {
    dispatch({ type: UPDATE_ORDER_ADDRESS_FAILED, error: err.response.status })
    localStorage.setItem('array_errors_update_order_address', JSON.stringify(err.response.data))
    if (err.response.status === 500) {
      localStorage.setItem('array_errors_update_order_address', JSON.stringify({ server_error: ['Internal Server Error'] }))
    }
    throw new SubmissionError({ _error: 'error' })
  }
}
