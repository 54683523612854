import {
  CHECK_JUKEBOX,
  JUKEBOX_STOPED
} from './actionTypes'

export const checkJukebox = (msg) => async (dispatch) => {
  if(msg.data !== null) {
    dispatch({ type: CHECK_JUKEBOX, msg: msg })
  } else if(msg.data === null) {
    dispatch({ type: JUKEBOX_STOPED })
  }
}