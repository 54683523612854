import {
  LOAD_USERS,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadUsers = (token) => async (dispatch) => {
  dispatch({ type: LOAD_USERS })
  try {
    const result = await api.loadUsers(token);
    dispatch({ type: LOAD_USERS_SUCCESS, payload: result.data.data, lastLink: result.data.links.last })
  } catch(err) {
    dispatch({ type: LOAD_USERS_FAILED, error: err })
  }
}
