import {
  LOAD_PAGE_PLAYLIST_SUCCESS,
  LOAD_PAGE_PLAYLIST_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPagePlaylist = (token, page) => async (dispatch) => {
  try {
    const result = await api.loadPagePlaylist(token, page);
    dispatch({ type: LOAD_PAGE_PLAYLIST_SUCCESS, playlists: result.data.data })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_PLAYLIST_FAILED, error: err })
  }
}
