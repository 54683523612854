import { connect } from 'react-redux'
import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form';
import PageMenu from '../../modules/pageMenu'
import { loadCurrentAdmin } from '../../actions/loadCurrentAdminAction';
import { createCampaign } from '../../actions/createCampaignAction';
import { Wrap, Text, Title, Breadcrumb, Template, Button, Container, Alert } from "sme-theme"
import { InputRedux, InputReduxGroup, ErrorField } from '../../modules/inputRedux';

class FormCampaign extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'content'}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadCurrentAdmin(token);
    localStorage.removeItem('array_errors_create_campaign')
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_create_campaign')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_create_campaign')
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const { error, handleSubmit, dirty } = this.props;
    const formError = 'array_errors_create_campaign'

    return (
    <PageMenu focus="campaigns">
      <Container>
        <Title className="mb-sm">Artista do Momento</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/campaigns">Artista do momento</Button>
          <Text>Novo artista do momento</Text>
        </Breadcrumb>

        <Wrap>
          <Template condition={localStorage.getItem('array_errors_create_campaign') && error}>
            <Alert className="my-md" type="error" iconName="attention-circled">
              <Text>{this.props.error_status !== 500 && 'Alguns campos precisam da sua atenção:'}</Text>
              <ErrorField field="name" label="Nome do artista" formError={formError} />
              <ErrorField field="date_start" label="Início" formError={formError} />
              <ErrorField field="date_finish" label="Término" formError={formError} />
              <ErrorField field="image" label="Imagem" formError={formError} />
              <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
            </Alert>
          </Template>

          <Wrap className="max-w-xs">
            <form onSubmit={handleSubmit(createCampaign())} >
                <InputRedux name="status" type="switch" label="Ativar" formError={formError} />
                <InputRedux name="enable_banner" type="switch" label="Mostrar Artista" formError={formError} />
                <InputRedux name="name" type="text" label="Nome do Artista" formError={formError} />
                <InputReduxGroup name="date_start" groupName="campaign_errors" mask="99/99/9999" label="Início" formError={formError} />
                <InputReduxGroup name="date_finish" groupName="campaign_errors" mask="99/99/9999" label="Término" formError={formError} />
                <InputRedux name="banner_image" type="file" buttonClass="sm" helpText="Tamanho da imagem: 180x180" label="Banner" formError={formError} />
                <InputRedux name="image" type="file" buttonClass="sm" helpText="Tamanho da imagem: 1100x196" label="Imagem" formError={formError} />
                <InputRedux name="image_medium" type="file" buttonClass="sm" helpText="Tamanho da imagem: 718x196" label="Imagem Média" formError={formError} />
                <InputRedux name="image_small" type="file" buttonClass="sm" helpText="Tamanho da imagem: 414x196" label="Imagem Pequena" formError={formError} />
                <Button className="block mt-lg" type="submit" loading={this.props.busyButton} disabled={!dirty}>ADICIONAR</Button>
            </form>
          </Wrap>

        </Wrap>

      </Container>
    </PageMenu>
  );
  }
}

const MapStateToProps = state => ({
  busyButton: state.LoadCampaignContent.busyButton,
  error_status: state.LoadCampaignContent.error
});

const MapDispatchToProps = dispatch => bindActionCreators({
  loadCurrentAdmin,
  createCampaign
}, dispatch);

FormCampaign = reduxForm({
  form: 'newCampaign',
  enableReinitialize: true
})(FormCampaign);

export default connect(MapStateToProps, MapDispatchToProps)(FormCampaign);
