import {
  STOP_JUKEBOX_SUCCESS,
  STOP_JUKEBOX_FAILED
} from './actionTypes'

import api from '../services/api'

export const stopJukebox = (token) => async (dispatch) => {  
  try {
    await api.playStopJukebox(token, 'stop')
    dispatch({ type: STOP_JUKEBOX_SUCCESS })
  } catch(err) {
    dispatch({ type: STOP_JUKEBOX_FAILED, error: err })
  }
}