import {
  LOAD_PLAYER_PLAYLISTS,
  LOAD_PLAYER_PLAYLISTS_SUCCESS,
  LOAD_PLAYER_PLAYLISTS_FAILED,
  UPDATE_SELECTED_PLAYLIST,
  UPDATE_SELECTED_PLAYLIST_SUCCESS,
  UPDATE_SELECTED_PLAYLIST_FAILED,
  PLAY_JUKEBOX_SUCCESS,
  PLAY_JUKEBOX_FAILED,
  STOP_JUKEBOX_SUCCESS,
  STOP_JUKEBOX_FAILED,
  CHECK_TRACK_ACTIVE,
  REFRESH_PLAYLIST_PLAYER,
  REFRESH_PLAYLIST_PLAYER_SUCCESS,
  REFRESH_PLAYLIST_PLAYER_FAILED,
  LOAD_JUKEBOXES_CHECK,
  LOAD_TO_PLAY,
  LOAD_TO_PLAY_SUCCESS,
  LOAD_TO_PLAY_FAILED,
  REMOVE_JUKEBOX_TO_PLAY
} from '../actions/actionTypes';

const initialState = {
  playlists: [],
  playlistActiveId: null,
  selectedPlaylist: null,
  tracks: [],
  toPlay: [],
  trackIdActive: null,
  statusJukebox: 'waiting',
  refreshing: false,
  busy: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_TRACK_ACTIVE:
      return {
        ...state,
        trackIdActive: action.trackId
      }
    case LOAD_PLAYER_PLAYLISTS:
      return {
        ...state,
        busy: true
      }
    case LOAD_PLAYER_PLAYLISTS_SUCCESS:
      const selected = action.playlists.filter(playlist => playlist.attributes.status)
      return {
        ...state,
        playlists: action.playlists,
        selectedPlaylist: selected,
        tracks: action.tracks,
        playlistActiveId: action.hasSelected ? selected[0].id : null,
        busy: false
      }
    case LOAD_PLAYER_PLAYLISTS_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case UPDATE_SELECTED_PLAYLIST:
      return {
        ...state,
        busy: true
      }
    case UPDATE_SELECTED_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlistActiveId: action.id,
        tracks: action.tracks,
        busy: false
      }
    case UPDATE_SELECTED_PLAYLIST_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case PLAY_JUKEBOX_SUCCESS:
      return {
        ...state,
        statusJukebox: 'playing'
      }
    case PLAY_JUKEBOX_FAILED:
      return {
        ...state,
        statusJukebox: action.status
      }
      case STOP_JUKEBOX_SUCCESS:
        return {
          ...state,
          statusJukebox: 'stoped',
          trackIdActive: null
        }
      case STOP_JUKEBOX_FAILED:
        return {
          ...state,
          statusJukebox: 'waiting'
        }
      case REFRESH_PLAYLIST_PLAYER:
        return {
          ...state,
          refreshing: true
        }
      case REFRESH_PLAYLIST_PLAYER_SUCCESS:
        let new_array = []

        action.tracks.forEach(track => {
          action.included.forEach(item => {
            if(parseInt(track.relationships.track.data.id) === parseInt(item.id)) {
              new_array.push({ 
                id: track.id,
                userName: track.attributes.user.name,
                userImage: track.attributes.user.image,
                attributes: {
                  info: {
                    name: item.attributes.info.name,
                    artists: item.attributes.info.artists,
                    duration_ms: item.attributes.info.duration_ms
                  }
                }
               })
            }
          })
        })
      return {
          ...state,
          toPlay: new_array,
          refreshing: false
        }
      case REFRESH_PLAYLIST_PLAYER_FAILED:
        return {
          ...state,
          refreshing: false,
          error: action.error
        }
      case LOAD_JUKEBOXES_CHECK:
        return {
          ...state,
          statusJukebox: action.payload ? 'playing' : state.statusJukebox,
          trackIdActive: action.payload ? parseInt(action.payload.id) : state.trackIdActive
        }
      case LOAD_TO_PLAY:
        return {
          ...state,
          busy: true
        }
      case LOAD_TO_PLAY_SUCCESS:
        let new_array_2 = []

        action.tracks.forEach(track => {
          action.included.forEach(item => {
            if(parseInt(track.relationships.track.data.id) === parseInt(item.id)) {
              new_array_2.push({ 
                id: track.id,
                userName: track.attributes.user.name,
                userImage: track.attributes.user.image,
                attributes: {
                  info: {
                    name: item.attributes.info.name,
                    artists: item.attributes.info.artists,
                    duration_ms: item.attributes.info.duration_ms
                  }
                }
               })
            }
          })
        })
        return {
          ...state,
          toPlay: new_array_2,
          busy: false
        }
      case LOAD_TO_PLAY_FAILED:
        return {
          ...state,
          error: action.error,
          busy: false
        }
      case REMOVE_JUKEBOX_TO_PLAY:
        const track_removed_to_play = state.toPlay.filter(item => item.id !== action.trackId)
        return {
          ...state,
          toPlay: track_removed_to_play
        }
    default:
      return state
  }
}
