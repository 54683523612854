import React from 'react'
import LogoImage from './images/logo'
import UserImage from './images/user'
import { Redirect, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadCurrentAdmin } from '../actions/loadCurrentAdminAction';
import { Template, Button, Container, Menubar, Icon, Navbar, Page, Text, Wrap, Collapse } from "sme-theme"

class PageMenu extends React.Component {
  componentDidMount() {
    this.props.loadCurrentAdmin(localStorage.getItem('token'))
  }

  openSubmenu(n, s) {
    var m = 'menu-'+n
    this.refs[n].setActive(s)
    localStorage.setItem(m, s)
  }

  verifyRoles(roles, item){
    if(roles === 'logistics' && item.n !== 'orders') {
      return false
    } else {
      return true
    }
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }

    const items = [
      {l:'Badge Challenge',     n:'badges'},
      {l:'Artista do momento',  n:'campaigns'},
      {l:'Contas',              n:'accounts'},
      {l:'Store',               n:'store'},
      {l:'Hits do momento',     n:'rescues'},
      {l:'Missões',             n:'missions'},
      {l:'Pedidos',             n:'orders',   i:[
        {l:'Usuários',            n:'orders'},
        {l:'Exportar',            n:'order-export'},
        {l:'Importar',            n:'order-import'}
      ]},
      {l:'Jukebox',             n:'jukebox',  i:[
        {l:'Seleção',             n:'jukebox'},
        {l:'Player',              n:'jukebox-player'}
      ]},
      {l:'Usuários',            n:'users',    i:[
        {l:'Usuários',            n:'users'},
        /* {l:'Exportar',            n:'users-export'} */
      ]},
      {l:'Settings',            n:'settings'}
    ]
    const {focus} = this.props

    return (
      <Template>
        <Menubar ref="menubar" header={
            <Template>
              <Container className="mt-lg">
                <LogoImage className="w-sm"/>
                <Button onClick={() => {this.refs.menubar.hide()}} className="unset menubar-button color-white"><Icon name="cancel" className="lg"/></Button>
              </Container>
            </Template>
          }>
          <Wrap className="">
            <Container className="pl-xl">
              {items.map((item) => {
                return(
                  <Template key={item.n} condition={this.verifyRoles(this.props.roles, item)}>
                  {!item.i &&
                    <Button onClick={() => localStorage.removeItem('menu-jukebox')} key={item.n} plugin={Link} to={"/"+item.n} active={focus === item.n}>{item.l}</Button>
                  }
                  {item.i &&
                    <Template key={item.n}>
                      <Button ref={item.n} onClick={() => this.refs['sub'+item.n].toggle()} active={localStorage.getItem('menu-'+item.n) == 'true'}>{item.l} <Icon name="arrow-down"/></Button>
                      <Collapse className="" show={localStorage.getItem('menu-'+item.n) == 'true'} ref={'sub'+item.n} onToggle={s => this.openSubmenu(item.n,s)}>
                        {item.i.map((subitem) => {
                          return <Button key={subitem.n} className="subitem" plugin={Link} to={"/"+subitem.n} active={focus === subitem.n}>{subitem.l}</Button>
                        })}
                      </Collapse>
                    </Template>
                  }
                  </Template>
                )
              })}

            </Container>
          </Wrap>
        </Menubar>
        <Navbar className="menubar-side mt-lg">
          <Container className="flex">

            <Button onClick={() => {this.refs.menubar.show()}} className="unset menubar-button color-white">
              <Icon className="md" name="menu" />
            </Button>
            <Button active={focus === 'profile'} plugin={Link} to="/profile" className="link ml-auto flex p-xs flex-align-center">
              <UserImage className="xs rounded mr-sm"/>
              <Text className="">{localStorage.getItem('email')}</Text>
            </Button>
          </Container>
        </Navbar>
        <Page className="menubar-side" loading={this.props.loading}>
          {this.props.children}
        </Page>
      </Template>
    );
  }
}

const MapStateToProps = state => ({
  roles: state.LoadCurrentAdmin.roles
})

const MapDispatchToProps = dispatch => bindActionCreators({
loadCurrentAdmin
}, dispatch);

export default connect(MapStateToProps, MapDispatchToProps)(PageMenu);
