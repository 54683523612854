import {
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadUser = (token, user_id) => async (dispatch) => {
  dispatch({ type: LOAD_USER })
  try {
    const result = await api.loadUser(token, user_id);
    dispatch({ type: LOAD_USER_SUCCESS, payload: result.data, included: result.data.included })
  } catch(err) {
    dispatch({ type: LOAD_USER_FAILED, error: err })
  }
}
