import {
  LOAD_ACCOUNT,
  LOAD_ACCOUNT_SUCCESS,
  LOAD_ACCOUNT_FAILED,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILED,
  EDIT_ACCOUNT,
  EDIT_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT_FAILED,
  HIDE_ALERT_CREATE_ACCOUNT
} from '../actions/actionTypes';

const initialState = {
  email: '',
  rolesAdmin: '',
  busy: false,
  alertCreateAccount: false,
  busyButton: false,
  status: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ACCOUNT:
      return {
        ...state,
        busy: true
      }
    case LOAD_ACCOUNT_SUCCESS:
      const admin = action.payload
      let roles_admin;

      if(admin.roles === 'admin') {
        roles_admin = 'admin'
      } else if(admin.roles === 'master'){
        roles_admin = 'master'
      } else if (admin.roles === 'promoter') {
        roles_admin = 'promoter'
      } else if (admin.roles === 'logistic') {
        roles_admin = 'logistic'
      }

      return {
        ...state,
        email: admin.email,
        rolesAdmin: roles_admin,
        status: admin.status,
        busy: false
      }
    case LOAD_ACCOUNT_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case CREATE_ACCOUNT:
      return {
        ...state,
        busyButton: true
      }
    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        busyButton: false,
        alertCreateAccount: true
      }
    case CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        busyButton: false,
        alertCreateAccount: false,
        error: action.error
      }
    case HIDE_ALERT_CREATE_ACCOUNT:
      return {
        ...state,
        alertCreateAccount: false
      }
    case EDIT_ACCOUNT:
      return {
        ...state,
        busyButton: true
      }
    case EDIT_ACCOUNT_SUCCESS:
      return {
        ...state,
        busyButton: false,
      }
    case EDIT_ACCOUNT_FAILED:
      return {
        ...state,
        busyButton: false,
        error: action.error
      }
    default:
      return state
  }
}
