import {
  PLAY_JUKEBOX_SUCCESS,
  PLAY_JUKEBOX_FAILED
} from './actionTypes'

import api from '../services/api'

export const playJukebox = (token) => async (dispatch) => {  
  try {
    await api.playStopJukebox(token, 'play')
    dispatch({ type: PLAY_JUKEBOX_SUCCESS })
  } catch(err) {
    if(err.response.status === 403) {
      dispatch({ type: PLAY_JUKEBOX_FAILED, status: 'playing' })
    }
  }
}