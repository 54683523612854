import { connect } from 'react-redux'
import React from 'react'
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom'
import { loadUser } from '../../actions/loadUserAction';
import { loadOrdersOfUser } from '../../actions/loadOrdersOfUserAction';
import PageMenu from '../../modules/pageMenu'
import { Status, Wrap, Title, Table, Tbody, Thead, Td, Tr, Breadcrumb, Button, Container, Icon, Modal, Text, Input } from "sme-theme"
import UserInfo from '../../modules/userInfo'

class OrdersOfUser extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadOrdersOfUser(token, this.props.match.params.user_id)
    this.props.loadUser(token, this.props.match.params.user_id)
    //this.refs.modal_devolucao_confirma.show()
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const user_id = this.props.match.params.user_id

    return (
    <PageMenu focus="orders" loading={this.props.busy}>
      <Container>
        <Title className="mb-sm">Pedidos</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/orders">Pedidos</Button>
          <Button plugin={Link} to={`/user/${user_id}`}>{this.props.userName}</Button>
        </Breadcrumb>

          <Wrap className="max-w-xs">
            <UserInfo data={{
              name: this.props.userName,
              platform: this.props.userPlatform,
              email: this.props.email,
              phone: this.props.phone
            }}/>
          </Wrap>

        <Table className="mt-xl" emptyLabel="Nenhum resultado encontrado">
          <Thead>
            <Tr>
              <Td width={10} className="sort-title">Código</Td>
              <Td width={15}>Produto</Td>
              <Td width={15} className="sort-title">Data do pedido</Td>
              <Td width={15}>Entregue?</Td>
              {/* <Td width={15}>Transportadora</Td> */}
              {/* @TRANSPORTADORA */}
              <Td width="210px">Actions</Td>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.ordersOfUser.map((order, i) => {
              return(
                <Tr key={i}>
                  <Td>{order.id}</Td>
                  <Td>{order.product.name}</Td>
                  <Td>{order.created_at}</Td>
                  {order.sent ?
                    <Td><Status success>Sim</Status></Td> :
                    <Td><Status error>Não</Status></Td>}
                  {/* <Td><Status success>Sim</Status></Td> */}
                  <Td>
                    <Button title="Ver" className='circle m-0' plugin={Link} to={`/order/${order.id}/user/${user_id}`}><Icon name="eye" /></Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>

      </Container>

      <Modal ref="modal_atualizacao" className="xs" close>
        <Title className="sm mb-lg">Atualização de logistica</Title>
        <Button className="block black max-w-xs mx-auto">DEVOLUÇÃO</Button>
        <Button className="block black max-w-xs mx-auto">CANCELAMENTO</Button>
        <Button className="block black max-w-xs mx-auto">TROCA</Button>
      </Modal>

      {/*DEVOLUCAO*/}
      <Modal ref="modal_devolucao" className="xs" close>
        <Title className="sm mb-lg">Devolução</Title>
        <Text className="mb-lg">O jogador quer devolver o produto.<br/> Insira o código de repostagem e clique em continuar para que o jogador seja notificado. </Text>
        <Input type="text" className="invert">Código de respostagem</Input>
        <Button className="block black max-w-xs mx-auto">CONTINUAR</Button>
      </Modal>

      <Modal ref="modal_devolucao_confirma" className="xs" close>
        <Title className="sm mb-lg">Devolução</Title>
        <Text className="mb-lg">O produto foi recebido com sucesso? Isso irá finalizar o processo de trocar e fazer o reembolso do jogador</Text>
        <Button className="block black max-w-xs mx-auto">CONTINUAR</Button>
      </Modal>

      <Modal ref="modal_devolucao_ok" className="xs" close>
        <Icon name="check-circle-o" className="xl mx-auto mb-md" />
        <Title className="sm mb-lg">Código de Devolução enviado com sucesso!</Title>
        <Text className="mb-lg">O código de repostagem foi enviado para o jogador, atualize o status desse pedido quando o produto for devolvido.</Text>
      </Modal>

      {/*TROCA*/}
      <Modal ref="modal_troca" className="xs" close>
        <Title className="sm mb-lg">Troca</Title>
        <Text className="mb-lg">O produto foi enviado incorretamente ou apresenta defeito.<br/>Insira o código de repostagem e clique em continuar para que o jogador seja notificado. </Text>
        <Input type="text" className="invert">Código de respostagem</Input>
        <Button className="block black max-w-xs mx-auto">ATUALIZAR</Button>
      </Modal>

      <Modal ref="modal_troca_confirma" className="xs" close>
        <Title className="sm mb-lg">Troca</Title>
        <Text className="mb-lg">Qual o status do pedido de troca? Essa ação irá gerar um novo envio.</Text>
        <Button className="block black max-w-xs mx-auto">INDEFERIDO</Button>
        <Button className="block black max-w-xs mx-auto">DEFERIDO</Button>
      </Modal>

      <Modal ref="modal_troca_ok" className="xs" close>
        <Icon name="check-circle-o" className="xl mx-auto mb-md" />
        <Title className="sm mb-lg">Código de troca enviado com sucesso!</Title>
        <Text className="mb-lg">O código de troca foi enviado para o jogador, atualize o status desse pedido quando o produto for devolvido.</Text>
      </Modal>


      {/*CANCELAMENTO*/}
      <Modal ref="modal_cancelamento" className="xs" close>
        <Title className="sm mb-lg">Cancelamento</Title>
        <Text className="mb-lg">O jogador se arrependeu do resgate.<br/>Clicando em cancelar o pedido será cancelado, o jogador será reembolsado e o produto voltará para a Store.</Text>
        <Button className="block black max-w-xs mx-auto">CONTINUAR</Button>
        <Button className="block black outline max-w-xs mx-auto">VOLTAR</Button>
      </Modal>

      <Modal ref="modal_cancelamento_confirma" className="xs">
        <Title className="sm mb-lg">Tem certeza que deseja cancelar esse pedido?</Title>
        <Text className="mb-lg">Essa ação não pode ser desfeita.</Text>
        <Button className="block black outline max-w-xs mx-auto">Sim</Button>
        <Button className="block black outline max-w-xs mx-auto">Não</Button>
      </Modal>

      <Modal ref="modal_cancelamento_ok" className="xs" close>
        <Icon name="check-circle-o" className="xl mx-auto mb-md" />
        <Title className="sm mb-lg">Pedido cacelado com sucesso!</Title>
        <Text className="mb-lg">O jogador foi reembolsado e o produto voltou para a Store.</Text>
      </Modal>

    </PageMenu>
  );
  }
}

const MapStateToProps = state => ({
  initialValues: {
    status: state.LoadOrder.status,
    tracking_code: state.LoadOrder.trackingCode
  },
  ordersOfUser: state.LoadOrders.ordersOfUser,
  userName: state.LoadUser.name,
  userPlatform: state.LoadUser.platform,
  email: state.LoadUser.email,
  phone: state.LoadUser.phone,
  busy: state.LoadOrders.busy
})

const MapDispatchToProps = dispatch => bindActionCreators({
  loadOrdersOfUser,
  loadUser
}, dispatch);

export default connect(MapStateToProps, MapDispatchToProps)(OrdersOfUser);
