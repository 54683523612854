import React from 'react'
import { Page, Template, Title, Image, Text } from "sme-theme"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCableProvider, ActionCableConsumer } from 'react-actioncable-provider';
import { loadJukeboxes } from '../../actions/loadJukeboxesAction'
import { checkJukebox } from '../../actions/checkJukeboxAction'
import { setNextSongs } from '../../actions/setNextSongsAction'
import Smile from '../../assets/images/default-user.svg'

class JukeboxDisplay extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadJukeboxes(token)
    document.getElementById('video').play();
  }

  changeMusic(nextSongs) {
    var c = 'changing-music';
    var e = document.getElementById("jukebox-display-container");
    var t = this
    setTimeout(function() {
      e.classList.add(c);
    },40)

    setTimeout(function() {
      e.classList.remove(c);
      t.props.setNextSongs(nextSongs)
    },1000)
  }

  handleReceived = msg => {
    if(msg.data) {
      var t = this
      this.changeMusic(msg.data.next_songs)
      setTimeout(function() {
        t.props.checkJukebox(msg)
      },500)
    } else {
      this.props.checkJukebox(msg)
    }
  }

  handleDisconected = msg => {
    console.log(msg)
  }

  handeRejected = msg => {
    console.log(msg)
  }

  render() {
    let next_image = '', next_user_image, next_user_name, next_track_name, next_music_artist;
    if(this.props.nextSongs && this.props.nextSongs[0]) {
      next_image = this.props.nextSongs[0].track_info.cover_url
      next_track_name = this.props.nextSongs[0].track_info.name
      next_music_artist = this.props.nextSongs[0].track_info.artists
      next_user_image = this.props.nextSongs[0].user_info.image || Smile
      next_user_name = this.props.nextSongs[0].user_info.name
    }

    return (
      <Page name="jukebox-display">
        <div className="jukebox-video-bg">
          <video id="video" loop autoplay muted >
            <source src="jukebox.mp4" type="video/mp4"/>
          </video>
        </div>
        <ActionCableProvider url={`${process.env.REACT_APP_API_JUKEBOX_CABLE}/cable?kind=admin&token=${localStorage.getItem('token')}`}>
          <ActionCableConsumer
            channel="PlayingChannel"
            onReceived={this.handleReceived}
            onDisconnected={this.handleDisconected}
            onRejected={this.handeRejected}
          >
            <div id="jukebox-display-container" className={`jukebox-display-container`}>
              {/*<Title className="jukebox-display-title primary">FILTR GAME</Title>*/}
              <Template condition={this.props.hasDj}>
                <div className="jukebox-display-grid">
                  <div className="jukebox-display-cell">
                    <div className="jukebox-display-dj">
                      <Text className="muted">DJ da vez</Text>
                        <div className="jukebox-display-text-flip">
                          <div className="jukebox-display-text-flip-inner">
                          <Text className="ellipsis">{this.props.djName}</Text>
                          <Text className="ellipsis">{next_user_name}</Text>
                        </div>
                      </div>
                    </div>
                    <div className="jukebox-display-current-album">
                      <div className="jukebox-display-image-flip">
                        <div className="jukebox-display-image-flip-inner">
                          <Image className="rounded" src={this.props.djImage || Smile} />
                          <Image className="rounded" src={next_user_image} />
                        </div>
                      </div>

                        <div className="jukebox-display-text-flip">
                          <div className="jukebox-display-text-flip-inner">
                            <Title className="jukebox-display-music-name ellipsis">{this.props.trackName}</Title>
                            <Title className="jukebox-display-music-name ellipsis">{next_track_name}</Title>
                          </div>
                        </div>
                        <div className="jukebox-display-text-flip">
                          <div className="jukebox-display-text-flip-inner">
                            <Title className="jukebox-display-music-artist ellipsis">{this.props.artistName}</Title>
                            <Title className="jukebox-display-music-artist ellipsis">{next_music_artist}</Title>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="jukebox-display-cell">
                    <Text>Próximas</Text>
                    <div className="jukebox-display-playlist">
                      {this.props.nextSongs && this.props.nextSongs.map((o,i) => {
                        return (
                          <div key={i} className="jukebox-display-playlist-item">
                            <Image className="rounded" src={o.user_info.image || Smile} />
                            <span>
                              <Text className="ellipsis">{o.user_info.name}</Text>
                              <Text className="ellipsis">{o.track_info.artists}</Text>
                              <Text className="ellipsis muted">{o.track_info.name}</Text>
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Template>
            </div>
          </ActionCableConsumer>
        </ActionCableProvider>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  djName: state.LoadJukeboxes.djName,
  djImage: state.LoadJukeboxes.djImage,
  trackImage: state.LoadJukeboxes.trackImage,
  trackName: state.LoadJukeboxes.trackName,
  artistName: state.LoadJukeboxes.artistName,
  nextSongs: state.LoadJukeboxes.nextSongs,
  hasDj: state.LoadJukeboxes.hasDj
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadJukeboxes,
  checkJukebox,
  setNextSongs
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JukeboxDisplay);
