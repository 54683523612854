import {
UPDATE_ORDER,
UPDATE_ORDER_SUCCESS,
UPDATE_ORDER_FAILED
} from './actionTypes';

import api from '../services/api';
import { SubmissionError } from 'redux-form'

export const updateOrder = (order_id) => async (value, dispatch) => {
  dispatch({ type: UPDATE_ORDER })

  let sent = false;

  const { status, tracking_code } = value

  if(status) {
    sent = status
  }

  const data = {
    "order": {
      "sent": sent,
      "tracking_code": tracking_code
    }
  }

  try {
    const token = localStorage.getItem('token')
    await api.updateOrder(token, order_id, data)
    dispatch({ type: UPDATE_ORDER_SUCCESS })
  } catch(err) {
    dispatch({ type: UPDATE_ORDER_FAILED, error: err.response.status })
    localStorage.setItem('array_errors_edit_order', JSON.stringify(err.response.data))
    if(err.response.status === 500) {
      localStorage.setItem('array_errors_edit_order', JSON.stringify({ server_error: ['Internal Server Error'] }))
    }
    throw new SubmissionError({_error: 'error'})
  }
}
