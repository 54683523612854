import {
  LOAD_PAGE_PRODUCT_SUCCESS,
  LOAD_PAGE_PRODUCT_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPageProduct = (token, page, orderAttribute, orderType) => async (dispatch) => {
  try {
    const result = await api.loadPageProduct(token, page, orderAttribute, orderType);
    dispatch({ type: LOAD_PAGE_PRODUCT_SUCCESS, products: result.data.data })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_PRODUCT_FAILED, error: err })
  }
}
