import {
  REMOVE_JUKEBOX_TO_PLAY
} from './actionTypes';

import api from '../services/api';

export const removeJukeboxUserTrack = (token, track_id) => async (dispatch) => {
    await api.removeJukeboxUserTrack(token, track_id)
    dispatch({ type: REMOVE_JUKEBOX_TO_PLAY, trackId: track_id })
}
