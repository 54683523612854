import { connect } from 'react-redux'
import React from 'react'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom'
import { updateQuest } from '../../actions/editQuestAction';
import { loadQuest } from '../../actions/loadQuestAction';
import PageMenu from '../../modules/pageMenu'
import { Form, Wrap, Text, Title, Breadcrumb, Template, Button, Container, Alert, Input } from "sme-theme"
import { InputRedux, InputReduxGroup, ErrorField } from '../../modules/inputRedux';

class FormEditQuestAdmin extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'content'}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadQuest(token, this.props.match.params.id);
    localStorage.removeItem('array_errors_edit_quest');
    localStorage.removeItem('edit_quest_ok');
    localStorage.removeItem('edit_quest_server_error')
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_edit_quest')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_edit_quest');
    localStorage.removeItem('edit_quest_ok');
    localStorage.removeItem('edit_quest_server_error')
  }

  render() {
    const { error, handleSubmit, dirty } = this.props;
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const idQuest = this.props.match.params.id
    const formError = 'array_errors_edit_quest'

    return (
    <PageMenu focus="missions" loading={this.props.busy}>
      <Container>
        <Title className="mb-sm">Missões</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/missions">Missões</Button>
          <Text>Editar missão</Text>
        </Breadcrumb>
        <Template condition={localStorage.getItem('array_errors_edit_quest') && error}>

            <Alert className="my-md" type="error" iconName="attention-circled">
              <Text>Alguns campos precisam da sua atenção:</Text>
              {/* <ErrorField field="date_start" label="Início" formError={formError} />
              <ErrorField field="score" label="Filtr Coins" formError={formError} />
              <ErrorField field="uri" label="URI" formError={formError} />
              <ErrorField field="question" label="Pergunta" formError={formError} />
              <ErrorField field="answer" label="Resposta" formError={formError} />
              <ErrorField field="quest_spotify.uri" label="URI" formError={formError} />
              <ErrorField field="quest_question.question" label="Pergunta" formError={formError} />
              <ErrorField field="quest_question.answer" label="Resposta" formError={formError} /> */}
              <ErrorField field="quest_pre_save.uri" label="Uri" formError={formError} />
              <ErrorField field="quest_pre_save.name_product" label="Nome da música" formError={formError} />
              <ErrorField field="quest_pre_save.name_artist" label="Nome do artista" formError={formError} />
              <ErrorField field="quest_pre_save.launch_in" label="Horário de lançamento" formError={formError} />
            </Alert>

        </Template>
        <Template condition={localStorage.getItem('edit_quest_ok') === 'ok'}>
          <Alert className="my-md" type="success">
            <Text>Missão atualizada com sucesso !</Text>
          </Alert>
        </Template>

        <Template condition={localStorage.getItem('edit_quest_server_error')}>
          <Alert className="my-md" type="error" iconName="attention-circled">
            <Text>Erro interno no servidor, aguarde alguns minutos</Text>
          </Alert>
        </Template>

        <Wrap className="max-w-md">
          <Form onSubmit={handleSubmit(updateQuest(idQuest, this.props.questRelationshipId))}>
            <InputRedux name="status" type="switch" label="Ativar" />
            <InputRedux name="date_start" label="Íncio" mask="99/99/9999 99:99" disabled={true} formError={formError} />
            
            {!this.props.show_spotify_playlist &&
            <InputRedux name="score" type="number" label="Filtr Coins" disabled={true} formError={formError} />}

            <InputRedux
              name="quest_kind"
              type="select"
              options={[
                {label: 'Deezer', value: 'quest_spotify'},
                {label: 'Pergunta', value: 'quest_question'},
                {label: 'Youtube', value: 'quest_youtube'},
                {label: 'Pre-save', value: 'quest_pre_save'},
                {label: 'Opt-in', value: 'quest_opt'},
                {label: 'Missão playlist', value: 'quest_spotify_playlist'},
                {label: 'Link Externo', value: 'quest_external_link'},
              ]}
              label="Tipo de Missão"
              disabled={true}
              formError={formError}
            />

            <Template condition={this.props.show_spotify}>
              <Title className="sm mt-xl mb-lg">Deezer</Title>
              <InputReduxGroup name="uri" groupName="quest_spotify.uri" label="Uri" disabled={true} formError={formError} />
              <InputReduxGroup
                name="kind_spotify"
                groupName="quest_spotify.name"
                type="select"
                options={[{label: 'Salvar', value: 'save'}, {label: 'Escutar', value: 'listen'}]}
                label="Escolha o que você quer que o usuário faça nessa missão"
                disabled={true}
                formError={formError}
              />
            </Template>

            <Template condition={this.props.show_opt}>
              <Title className="sm mt-xl mb-lg">Opt-in</Title>
              <InputReduxGroup name="description" groupName="quest_opt.description" label="Descrição" rows={4} type="textarea" formError={formError} />
            </Template>

            <Template condition={this.props.show_pre_save}>
              <Title className="sm mt-xl mb-lg">Pre-save</Title>
              <InputReduxGroup name="uri" groupName="quest_pre_save.uri" label="Uri" disabled={true} formError={formError} />
              <InputReduxGroup name="name_artist" groupName="quest_pre_save.name_artist" label="Nome do artista" formError={formError} />
              <InputReduxGroup name="name_product" groupName="quest_pre_save.name_product" label="Nome da música" formError={formError} />
              <InputReduxGroup name="launch_in" label="Horário de lançamento" groupName="quest_pre_save.launch_in" mask="99/99/9999 99:99" formError={formError} />
            </Template>

            <Template condition={this.props.show_youtube}>
              <Title className="sm mt-xl mb-lg">Youtube</Title>
              <InputReduxGroup name="url" label="URL" disabled={true} formError={formError} />
              <InputReduxGroup
                name="kind_youtube"
                groupName="quest_youtube.name"
                type="select"
                options={[{label: 'Salvar', value: 'channel'}, {label: 'Assistir', value: 'watch'}]}
                label="Tipo de missão"
                disabled={true}
                formError={formError}
              />
            </Template>

            <Template condition={this.props.show_question}>
              <Title className="sm mt-xl mb-lg">Pergunta</Title>
              <InputReduxGroup name="question" groupName="quest_question.question" disabled={true} label="Pergunta" formError={formError} />
              <InputReduxGroup name="regex" type="switch" groupName="quest_question.regex" label="Regex" disabled={true} />
              <InputReduxGroup name="answer" groupName="quest_question.answer" disabled={true} label="Resposta" formError={formError} />
            </Template>

            <Template condition={this.props.show_spotify_playlist}>
              <Title className="sm mt-xl mb-lg">Missão Playlist</Title>
              <InputReduxGroup name="uri" groupName="quest_playlist.uri" disabled={true} label="URI da playlist" formError={formError} />
              <InputReduxGroup type="number" name="points_for_track" disabled={true} groupName="quest_pre_save.points" label="Total de pontos por faixa" formError={formError} />

              <Input
                checked={this.props.tracksCount === 10}
                type="radio" 
                name="max_points" 
                disabled={true}
                groupName="quest_pre_save.tracks_10" 
                formError={formError}>10 faixas</Input>

              <Input
                checked={this.props.tracksCount === 20}
                type="radio" 
                name="max_points" 
                disabled={true} 
                groupName="quest_pre_save.tracks_20" 
                formError={formError}>20 faixas</Input>

              <Template condition={this.props.tracksCount <= 20}>
                <Text className="bold mt-lg mb-md">Pergunta 1</Text>
                <InputReduxGroup disabled={true} type="text" groupName="answer" name="question_1" label="Pergunta" formError={formError} />
                <InputReduxGroup disabled={true} type="text" groupName="question" name="answer_playlist_1" label="Resposta" formError={formError} />
                <InputReduxGroup disabled={true} type="number" name="points_for_question_1" label="Pontos pergunta" formError={formError} />
              </Template>

              <Template condition={this.props.tracksCount === 20}>
                <Text className="bold mt-lg mb-md">Pergunta 2</Text>
                <InputReduxGroup disabled={true} type="text" groupName="answer" name="question_2" label="Pergunta" formError={formError} />
                <InputReduxGroup disabled={true} type="text" groupName="question" name="answer_playlist_2" label="Resposta" formError={formError} />
                <InputReduxGroup disabled={true} type="number" name="points_for_question_2" label="Pontos pergunta" formError={formError} />
              </Template>
            </Template>

            <Template condition={this.props.show_external_link} >
              <Title className="sm mt-xl mb-lg">Link Externo</Title>
              <InputReduxGroup
                type="text"
                name="external_link_label"
                groupName="external_link.label"
                label="Legenda"
              />
              <InputReduxGroup
                type="text"
                name="external_link_code"
                groupName="external_link.link"
                label="Código"
                disabled={true}
              />
              <Text className="mb-sm muted">Link</Text>
              <Text className="mb-lg">{this.props.externalLinkLink}</Text>
            </Template>

            <Button className="block" type="submit" loading={this.props.busyButton} disabled={!dirty}>Salvar</Button>
          </Form>
        </Wrap>
      </Container>
    </PageMenu>
  );
  }
}

const MapStateToProps = state => ({
  initialValues: {
    status: state.LoadQuest.status,
    date_start: state.LoadQuest.dateStart,
    score: state.LoadQuest.score,
    quest_kind: state.LoadQuest.kindMission,
    uri: state.LoadQuest.uri,
    url: state.LoadQuest.url,
    kind_spotify: state.LoadQuest.kindSpotify,
    kind_youtube: state.LoadQuest.kindYoutube,
    question: state.LoadQuest.question,
    answer: state.LoadQuest.answer,
    regex: state.LoadQuest.regex,
    name_artist: state.LoadQuest.nameArtist,
    name_product: state.LoadQuest.nameProduct,
    launch_in: state.LoadQuest.launchIn,
    description: state.LoadQuest.optDescription,
    points_for_track: state.LoadQuest.pointsForTrack,
    question_1: state.LoadQuest.questionPlaylist1,
    question_2: state.LoadQuest.questionPlaylist2,
    answer_playlist_1: state.LoadQuest.answerPlaylist1,
    answer_playlist_2: state.LoadQuest.answerPlaylist2,
    points_for_question_1: state.LoadQuest.pointsForQuestion1,
    points_for_question_2: state.LoadQuest.pointsForQuestion2,
    external_link_label: state.LoadQuest.externalLinkLabel,
    external_link_code: state.LoadQuest.externalLinkCode,
  },
  tracksCount: state.LoadQuest.tracksCount,
  questRelationshipId: state.LoadQuest.questRelationshipId,
  externalLinkLink: state.LoadQuest.externalLinkLink,
  show_spotify_playlist: state.LoadQuest.showSpotifyPlaylist,
  show_spotify: state.LoadQuest.showSpotify,
  show_question: state.LoadQuest.showQuestion,
  show_youtube: state.LoadQuest.showYoutube,
  show_pre_save: state.LoadQuest.showPreSave,
  show_opt: state.LoadQuest.showOpt,
  show_external_link: state.LoadQuest.showExternalLink,
  busy: state.LoadQuest.busy,
  busyButton: state.LoadQuest.busyButton
})

const MapDispatchToProps = dispatch => bindActionCreators({
  loadQuest,
  updateQuest
}, dispatch);

FormEditQuestAdmin = reduxForm({
  form: 'formEditQuestAdmin',
  enableReinitialize: true
})(FormEditQuestAdmin);

export default connect(MapStateToProps, MapDispatchToProps)(FormEditQuestAdmin);
