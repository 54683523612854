import {
  LOAD_RESCUE_ANALYTICS,
  LOAD_RESCUE_ANALYTICS_SUCCESS,
  LOAD_RESCUE_ANALYTICS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadRescueAnalytics = (token, id) => async (dispatch) => {
  dispatch({ type: LOAD_RESCUE_ANALYTICS })
  try {
    const result = await api.loadRescueAnalytics(token, id);

    dispatch({ 
      type: LOAD_RESCUE_ANALYTICS_SUCCESS,
      payload: result.data.data, 
      lastLink: result.data.links.last 
    })
  } catch(err) {
    dispatch({ type: LOAD_RESCUE_ANALYTICS_FAILED, error: err })
  }
}
