import { connect } from 'react-redux'
import React from 'react'
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'
import { loadUser } from '../../actions/loadUserAction';
import { Wrap, Text, Title, Breadcrumb, Button, Container, Icon, Table, Tbody, Thead, Td, Tr } from "sme-theme"
import PageMenu from '../../modules/pageMenu'
import UserInfo from '../../modules/userInfo'

class PageComponent extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'users'}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    const user_id = this.props.match.params.id
    this.props.loadUser(token, user_id)
  }

  render() {
    const user_id = this.props.match.params.id
    const campaignName = this.props.match.params.campaign

    return (

      <PageMenu focus="users">
          <Container>
            <Title className="mb-sm">Usuários</Title>
            <Breadcrumb>
              <Button plugin={Link} to="/users">Usuários</Button>
              <Button plugin={Link} to={`/user/${user_id}`}>{this.props.name}</Button>
              <Text>{campaignName}</Text>
            </Breadcrumb>
            <Wrap className="mt-xl">
              <UserInfo data={this.props}/>
              <Table>
                <Thead>
                  <Tr>
                    <Td width={60}>Campanha <Icon name="arrow-down"/></Td>
                    <Td>Pontos <Icon name="arrow-down"/></Td>
                    <Td width={"110px"}>Action</Td>
                  </Tr>
                </Thead>
                <Tbody>

                  {this.props.campaigns.map((campaign, i) => {
                    return(
                      <Tr key={i}>
                        <Td>{campaign.name}</Td>
                        <Td>{campaign.score}</Td>
                        <Td>
                          <Button className="sm m-0"Wx>Log</Button>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </Wrap>

          </Container>
      </PageMenu>
   );
  }
}

const MapStateToProps = state => ({
  name: state.LoadUser.name,
  email: state.LoadUser.email,
  birthdate: state.LoadUser.birthdate,
  phone: state.LoadUser.phone,
  city: state.LoadUser.city,
  state: state.LoadUser.state,
  stateAcronym: state.LoadUser.stateAcronym,
  campaigns: state.LoadUser.campaigns
});

const MapDispatchToProps = dispatch => bindActionCreators({ loadUser }, dispatch);

export default connect(MapStateToProps, MapDispatchToProps)(PageComponent);
