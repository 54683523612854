import {
  LOAD_CAMPAIGNS,
  LOAD_CAMPAIGNS_SUCCESS,
  LOAD_CAMPAIGNS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadCampaigns = () => async (dispatch) => {
  dispatch({ type: LOAD_CAMPAIGNS })
  try {
    const tokenAdmin = localStorage.getItem('token')
    const result = await api.loadCampaigns(tokenAdmin);
    dispatch({ type: LOAD_CAMPAIGNS_SUCCESS, payload: result.data, lastLink: result.data.links.last })
  } catch(err) {
    dispatch({ type: LOAD_CAMPAIGNS_FAILED, error: err })
  }
}
