import { connect } from 'react-redux'
import React from 'react'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Form, Wrap, Button } from "sme-theme"
import { InputRedux } from '../../modules/inputRedux';
import { searchUsers } from '../../actions/searchUsersAction';

class FormSearchUsers extends React.Component {

  render() {
    const { handleSubmit, dirty } = this.props;

    return (
      <Wrap className="max-w-sm">
        <Form onSubmit={handleSubmit(searchUsers)}>
          <InputRedux
            name="search"
            type="text"
            label="Busque por nome, email ou cpf"
            formError={'formError'}
            after={
              <Button
                className="m-0 ml-md sm"
                type="submit"
                disabled={!dirty}
                loading={false}
              >
                BUSCAR
              </Button>
            }
          />
        </Form>
      </Wrap>
    );
  }
}

const MapDispatchToProps = dispatch => bindActionCreators({
  searchUsers
}, dispatch);

FormSearchUsers = reduxForm({
  form: 'formSearchUsers'
})(FormSearchUsers);

export default connect(null, MapDispatchToProps)(FormSearchUsers);
