import { connect } from 'react-redux'
import React from 'react'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom'
import { loadProducts } from '../../actions/loadProductsAction';
import { loadPageProduct } from '../../actions/loadPageProductAction';
import { HIDE_ALERT_CREATE_PRODUCT } from '../../actions/actionTypes'
import { hideAlert } from '../../actions/hideAlertAction'
import { orderProducts } from '../../actions/orderProductsAction'
import PageMenu from '../../modules/pageMenu'
import { Alert, Title, Template, Button, Container, Table, Tbody, Thead, Td, Tr, Status, Pagination, Icon } from "sme-theme"

class ListProducts extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadProducts(token);
  }

  componentWillUnmount() {
    this.props.hideAlert(HIDE_ALERT_CREATE_PRODUCT)
  }

  loadPage = page => {
    const token = localStorage.getItem('token')
    this.props.loadPageProduct(token, page, this.props.orderAttribute, this.props.orderType)
  }

  dateBr = date => {
    var data = new Date(date),
    dia  = data.getDate().toString(),
    diaF = (dia.length === 1) ? '0'+dia : dia,
    mes  = (data.getMonth()+1).toString(),
    mesF = (mes.length === 1) ? '0'+mes : mes,
    anoF = data.getFullYear();
    const minutes = (data.getMinutes().toString().length === 1) ? '0'+data.getMinutes() : data.getMinutes();
    return `${diaF}/${mesF}/${anoF} ${data.getHours()}:${minutes}`
  }

  order = attr => {
    const token = localStorage.getItem('token')
    this.props.orderProducts(token, 'products', attr, this.props.orderType)
  }

  verifyAttrOrder= attr => {
    if(attr === this.props.selectedOrder) {
      return 'sort-title active'
    }
    return 'sort-title'
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }

    return (
    <PageMenu focus="store" loading={this.props.busy}>
      <Container>
        <Title className="flex-align-center">Store <Button className="sm mb-0 ml-lg" plugin={Link} to="/new-store">NOVO PRODUTO</Button></Title>
        <Template condition={this.props.alertCreateProduct}>
          <Alert className="my-md" type="success" title="Produto criado com sucesso !"></Alert>
        </Template>
        <Table className="mt-xl">
          <Thead>
            <Tr>
              <Td width={40} onClick={() => this.order('name')} className={this.verifyAttrOrder('name')}>Nome <Icon name="arrow-down"/></Td>
              <Td width={10} onClick={() => this.order('value')} className={this.verifyAttrOrder('value')}>Valor <Icon name="arrow-down"/></Td>
              <Td width={20} onClick={() => this.order('date_start')} className={this.verifyAttrOrder('date_start')}>Início <Icon name="arrow-down"/></Td>
              <Td width={10}>Quantidade</Td>
              <Td width={10} onClick={() => this.order('status')} className={this.verifyAttrOrder('status')}>Status <Icon name="arrow-down"/></Td>
              <Td width={10} >Action</Td>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.products.map((product, i) => {
              return(
                <Tr key={i}>
                  <Td nowrap>{product.name}</Td>
                  <Td>{parseInt(product.value).toLocaleString('pt-BR')}</Td>
                  <Td>{this.dateBr(product.date_start)}</Td>
                  <Td>{product.quantity}</Td>
                  <Td>
                    {product.status ?
                      <Status success>Ativo</Status> :
                      <Status error>Inativo</Status>}
                  </Td>
                  <Td>
                    <Button title="Editar" className="circle m-0" plugin={Link} to={`/edit-store/${product.id}`}><Icon name="pencil" /></Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
        <Template condition={this.props.totalPages > 1 && !this.props.ordering}>
          <Pagination page={1} size={this.props.totalPages} onChange={(i)=> this.loadPage(i)} />
        </Template>
      </Container>
    </PageMenu>
  );
  }
}

const MapStateToProps = state => ({
  products: state.LoadProducts.products,
  busy: state.LoadProducts.busy,
  totalPages: state.LoadProducts.totalPages,
  alertCreateProduct: state.LoadProducts.alertCreateProduct,
  selectedOrder: state.LoadProducts.selectedOrder,
  orderType: state.LoadProducts.orderType,
  orderAttribute: state.LoadProducts.orderAttribute,
  ordering: state.LoadProducts.ordering
})

const MapDispatchToProps = dispatch => bindActionCreators({
  loadProducts,
  loadPageProduct,
  orderProducts,
  hideAlert
}, dispatch);

ListProducts = reduxForm({
  form: 'formListProducts',
  enableReinitialize: true
})(ListProducts);

export default connect(MapStateToProps, MapDispatchToProps)(ListProducts);
