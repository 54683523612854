import {
  LOAD_PRODUCT,
  LOAD_PRODUCT_SUCCESS,
  LOAD_PRODUCT_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadProduct = (token, product_id) => async (dispatch) => {
  dispatch({ type: LOAD_PRODUCT })
  try {
    const result = await api.loadProduct(token, product_id)
    dispatch({ type: LOAD_PRODUCT_SUCCESS, payload: result.data })
  } catch(err) {
    dispatch({ type: LOAD_PRODUCT_FAILED, error: err })
  }
}
