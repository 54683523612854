import {
  RESET_MODAL_VALIDATE_COUPON_ERROR,
  HIDE_MODAL_VALIDATE_COUPON_SUCCESS
} from './actionTypes';

export const handleScannerModal = (handle) => async (dispatch) => {
  switch (handle) {
    case RESET_MODAL_VALIDATE_COUPON_ERROR:
      dispatch({ type: RESET_MODAL_VALIDATE_COUPON_ERROR })
      break;
    case HIDE_MODAL_VALIDATE_COUPON_SUCCESS:
      dispatch({ type: HIDE_MODAL_VALIDATE_COUPON_SUCCESS })
      break;
    default:
      break;
  }
}
