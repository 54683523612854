import { connect } from 'react-redux'
import React from 'react'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom'
import { loadOrders } from '../../actions/loadOrdersAction';
import { loadPageOrder } from '../../actions/loadPageOrderAction';
import FormSearch from './searchOrdersForm'
import PageMenu from '../../modules/pageMenu'
import { Title, Template, Button, Container, Table, Tbody, Thead, Td, Tr, Pagination, Icon } from "sme-theme"

class FormRescueAdmin extends React.Component {
  constructor() {
    super();
    this.state = {alert: false}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadOrders(token);
  }

  loadPage = page => {
    const token = localStorage.getItem('token')
    this.props.loadPageOrder(token, page, this.props.search)
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }

    return (
    <PageMenu focus="orders" loading={this.props.busy}>
      <Container>
        <Title className="flex-align-center">Pedidos</Title>
        <FormSearch />

        {this.props.emptySearch && <Tr className="empty-label"><Td>Nenhum resultado encontrado</Td></Tr>}
        
        <Table className="mt-xl" emptyLabel="Nenhum resultado encontrado">
          <Thead>
            <Tr>
              <Td width={10} className="sort-title">Código</Td>
              <Td width={15} className="sort-title">Plataforma</Td>
              <Td width={30} className="sort-title">Nome</Td>
              <Td width={30} className="sort-title">Email</Td>
              <Td width="110px">Action</Td>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.orders.map((order, i) => {
              return(
                <Tr key={i}>
                  <Td>{order.user_deleted ? '' : order.id}</Td>
                  <Td>{order.platform}</Td>
                  <Td>{order.name}</Td>
                  <Td>{order.email}</Td>
                  <Td>
                    <Button disabled={order.user_deleted} title="Ver +" className='circle m-0' plugin={Link} to={`/orders-of-user/${order.id}`}><Icon name="eye" /></Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
        <Template condition={!this.props.busy}>
          <Template condition={this.props.totalPages > 1}>
            <Pagination page={1} size={this.props.totalPages} onChange={(i)=> this.loadPage(i)} />
          </Template>
        </Template>
      </Container>
    </PageMenu>
  );
  }
}

const MapStateToProps = state => ({
  orders: state.LoadOrders.orders,
  busy: state.LoadOrders.busy,
  totalPages: state.LoadOrders.totalPages,
  alertEditSuccess: state.LoadOrder.alertEditSuccess,
  emptySearch: state.LoadOrders.emptySearch,
  search: state.LoadOrders.search
})

const MapDispatchToProps = dispatch => bindActionCreators({
  loadOrders,
  loadPageOrder
}, dispatch);

FormRescueAdmin = reduxForm({
  form: 'formListOrderAdmin',
  enableReinitialize: true
})(FormRescueAdmin);

export default connect(MapStateToProps, MapDispatchToProps)(FormRescueAdmin);
