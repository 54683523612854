import {
  LOAD_USER_BADGES,
  LOAD_USER_BADGES_SUCCESS,
  LOAD_USER_BADGES_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadBadgeUsers = (token, badge_id) => async (dispatch) => {
  dispatch({ type: LOAD_USER_BADGES })
  try {
    const result = await api.loadBadgeUsers(token, badge_id);
    dispatch({ 
      type: LOAD_USER_BADGES_SUCCESS, 
      payload: result.data.data,
      included: result.data.included,
      lastLink: result.data.links.last 
    })
  } catch(err) {
    dispatch({ type: LOAD_USER_BADGES_FAILED, error: err })
  }
}
