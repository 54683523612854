import React from 'react'

import { Wrap, Text, Template } from "sme-theme"


class AddressInfo extends React.Component {

  render() {
    const {data} = this.props
    return (
      <Wrap>
        <Template condition={data.name}>
          <Text className="mb-sm muted">Destinatário</Text>
          <Text className="mb-md">{data.name}</Text>
        </Template>

        <Template condition={data.cpf}>
          <Text className="mb-sm muted">CPF</Text>
          <Text className="mb-md">{data.cpf}</Text>
        </Template>

        <Template condition={data.state}>
          <Text className="mb-sm muted">Estado</Text>
          <Text className="mb-md">{data.state}</Text>
        </Template>

        <Template condition={data.city}>
          <Text className="mb-sm muted">Cidade</Text>
          <Text className="mb-md">{data.city}</Text>
        </Template>

        <Template condition={data.cep}>
          <Text className="mb-sm muted">CEP</Text>
          <Text className="mb-md">{data.cep}</Text>
        </Template>

        <Template condition={data.street}>
          <Text className="mb-sm muted">Rua</Text>
          <Text className="mb-md">{data.street}</Text>
        </Template>
        
        <Template condition={data.number}>
          <Text className="mb-sm muted">Número</Text>
          <Text className="mb-md">{data.number}</Text>
        </Template>

        <Template condition={data.neighborhood}>
          <Text className="mb-sm muted">Bairro</Text>
          <Text className="mb-md">{data.neighborhood}</Text>
        </Template>

        <Template condition={data.complement}>
          <Text className="mb-sm muted">Complemento</Text>
          <Text className="mb-md">{data.complement}</Text>
        </Template>

        <Template condition={data.reference}>
          <Text className="mb-sm muted">Ponto de referência</Text>
          <Text className="mb-md">{data.reference}</Text>
        </Template>
      </Wrap>
   );
  }
}


export default AddressInfo;
