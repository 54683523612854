import React from 'react';
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import PageMenu from '../../modules/pageMenu'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux'
import { editAdmin } from '../../actions/editAdminAction';
import { loadAccount } from '../../actions/loadAccountAction';
import { Template, Alert, Container, Title, Breadcrumb, Text, Wrap, Form, Button } from "sme-theme"
import { InputRedux, ErrorField } from '../../modules/inputRedux';

class FormEditAdmin extends React.Component {
  constructor() {
    super();
    this.state = {}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    const admin_id = this.props.match.params.id
    this.props.loadAccount(token, admin_id)
    localStorage.removeItem('array_errors_edit_admin');
    localStorage.removeItem('edit_admin_ok');
    localStorage.removeItem('error_kind_edit_admin');
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_edit_admin')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_edit_admin');
    localStorage.removeItem('edit_admin_ok');
    localStorage.removeItem('error_kind_edit_admin');
  }

  render(){
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const { error, handleSubmit, dirty } = this.props;
    const { match: { params } } = this.props;
    const idAdmin = params.id
    const formError = 'array_errors_edit_admin'

    return (
      <PageMenu focus="accounts" loading={this.props.busy}>
          <Container>
            <Title className="mb-sm">Contas</Title>
            <Breadcrumb>
              <Button plugin={Link} to="/accounts">Contas</Button>
              <Text>Editar conta</Text>
            </Breadcrumb>
            <Template condition={localStorage.getItem('array_errors_edit_admin') && error}>
              <Alert className="my-md" type="error" iconName="attention-circle-o">
                <Text>{this.props.error_status !== 500 && 'Alguns campos precisam da sua atenção:'}</Text>
                <ErrorField field="email" label="Email" formError={formError} />
                <ErrorField field="password" label="Senha" formError={formError} />
                <ErrorField field="password_confirmation" label="Repetir senha" formError={formError} />
                <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
              </Alert>
            </Template>
            <Template condition={localStorage.getItem('edit_admin_ok')}>
              <Alert className="my-md" type="success">
                <Text>Conta atualizada com sucesso !</Text>
              </Alert>
            </Template>
            <Template condition={localStorage.getItem('error_kind_edit_admin')}>
              <Alert className="my-md" type="error" iconName="attention-circle-o">
                <Text>Selecione o tipo de conta !</Text>
              </Alert>
            </Template>
              <Template condition={!this.props.busy}>
                <Wrap className="max-w-xs">
                  <Form onSubmit={handleSubmit(editAdmin(idAdmin))}>
                    <InputRedux name="status" type="switch" label="ativar" formError={formError} />
                    <InputRedux
                      name="roles"
                      type="select"
                      formError={formError}
                      options={[
                        {label: 'Admin', value: 'admin'},
                        {label: 'Master', value: 'master'},
                        {label: 'Promoter', value: 'promoter'},
                        {label: 'Logistic', value: 'logistic'}]
                      }
                      label="Tipo de conta"
                    />
                    <InputRedux name="email" type="text" label="Email" formError={formError} />
                    <InputRedux name="password" type="password" label="Senha" formError={formError} helpText="Use oito ou mais caracteres com uma combinação de letras, números e símbolos." />
                    <InputRedux name="password_confirmation" type="password" label="Repetir senha" formError={formError} />
                    <Button className="block mt-lg" type="submit" loading={this.props.busyButton} disabled={!dirty}>Salvar</Button>
                  </Form>
                </Wrap>
              </Template>
          </Container>
      </PageMenu>
    );
  }
}

const MapStateToProps = state => ({
  initialValues: {
    email: state.LoadAccount.email,
    status: state.LoadAccount.status,
    roles: state.LoadAccount.rolesAdmin
  },
  busy: state.LoadAccount.busy,
  busyButton: state.LoadAccount.busyButton,
  error_status: state.LoadAccount.error
})

const MapDispatchToProps = dispatch => bindActionCreators({
  editAdmin,
  loadAccount
}, dispatch);

FormEditAdmin = reduxForm({
  form: 'formEditAdmin',
  enableReinitialize: true
})(FormEditAdmin);

export default connect(MapStateToProps, MapDispatchToProps)(FormEditAdmin);
