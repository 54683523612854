import {
  LOAD_ORDER,
  LOAD_ORDER_SUCCESS,
  LOAD_ORDER_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadOrder = (token, id) => async (dispatch) => {
  dispatch({ type: LOAD_ORDER })
  try {
    const result = await api.loadOrder(token, id);
    dispatch({ type: LOAD_ORDER_SUCCESS, payload: result.data })
  } catch(err) {
    dispatch({ type: LOAD_ORDER_FAILED, error: err })
  }
}
