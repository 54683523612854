import {
  HIDE_ALERT_EDIT_ORDER,
  HIDE_ALERT_CREATE_CAMPAIGN,
  HIDE_ALERT_CREATE_ACCOUNT,
  HIDE_ALERT_CREATE_PRODUCT,
  HIDE_ALERT_CREATE_RESCUE,
  HIDE_ALERT_CREATE_QUEST,
  HIDE_ALERT_CREATE_GRATIFICATION,
  HIDE_ALERT_CREATE_PLAYLIST,
  HIDE_ALERT_CREATE_BADGE
} from './actionTypes'

export const hideAlert = (alertName) => async (dispatch) => {
  switch (alertName) {
    case HIDE_ALERT_EDIT_ORDER:
      dispatch({ type: HIDE_ALERT_EDIT_ORDER })
      break;
    case HIDE_ALERT_CREATE_CAMPAIGN:
      dispatch({ type: HIDE_ALERT_CREATE_CAMPAIGN })
      break;
    case HIDE_ALERT_CREATE_ACCOUNT:
      dispatch({ type: HIDE_ALERT_CREATE_ACCOUNT })
      break;
    case HIDE_ALERT_CREATE_PRODUCT:
      dispatch({ type: HIDE_ALERT_CREATE_PRODUCT })
      break;
    case HIDE_ALERT_CREATE_RESCUE:
      dispatch({ type: HIDE_ALERT_CREATE_RESCUE })
      break;
    case HIDE_ALERT_CREATE_QUEST:
      dispatch({ type: HIDE_ALERT_CREATE_QUEST })
      break;
    case HIDE_ALERT_CREATE_GRATIFICATION:
      dispatch({ type: HIDE_ALERT_CREATE_GRATIFICATION })
      break;
    case HIDE_ALERT_CREATE_PLAYLIST:
      dispatch({ type: HIDE_ALERT_CREATE_PLAYLIST })
      break;
    case HIDE_ALERT_CREATE_BADGE:
        dispatch({ type: HIDE_ALERT_CREATE_BADGE })
        break;
    default:

  }
}
