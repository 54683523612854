import { connect } from 'react-redux'
import React from 'react'
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'
import {
  Wrap, Text, Title, Breadcrumb, Button,
  Container, Grid, Cell, Status, Divider,
  Table, Td, Tr, Thead, Tbody, Pagination,
  Template
} from "sme-theme"

import PageMenu from '../../modules/pageMenu'

import { loadQuest } from '../../actions/loadQuestAction';
import { loadQuestAccomplishments } from '../../actions/loadQuestAccomplishments';
import { loadPageQuestAccomplishments } from '../../actions/loadPageQuestAccomplishments'

import api from '../../services/api';

class PageComponent extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'users'}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    const quest_id = this.props.match.params.id

    this.props.loadQuest(token, quest_id)
    this.props.loadQuestAccomplishments(token, quest_id)
  }

  getKind = () => {
    const { quest } = this.props
    if(!quest) return ''
    
    const { kind } = quest
    
    if(!kind) return ''

    switch (kind) {
      case 'spotify_follow_artist':
        return 'Deezer - Seguir artista'
      case 'spotify_follow_playlist':
        return 'Deezer - Seguir playlist'
      case 'spotify_listen_track':
        return 'Deezer - Escutar faixa'
      case 'spotify_save_track':
        return 'Deezer - Salvar faixa'
      case 'spotify_save_album':
        return 'Deezer - Salvar álbum'
      case 'question':
        return 'Pergunta'
      case 'opt':
        return 'Opt Mail'
      case 'pre_save':
        return 'Pre-save'
      case 'youtube_subscribe':
        return 'Youtube - Inscrição'
      case 'youtube_watch_video':
        return 'Youtube - Assistir vídeo'
      case 'quest_spotify_playlist':
        return 'Deezer - Escutar Playlist'
      case 'external_link':
        return 'Link Externo'
      default:
        return 'Missão'
    }
  }

  // componentWillUnmount() {
  //   this.props.hideAlert(HIDE_ALERT_CREATE_GRATIFICATION)
  // }

  normalizeDate(str) {
    return `${str.substr(0,10)}`
  }

  async downloadUsers() {
    const token = localStorage.getItem('token')
    const quest_id = this.props.match.params.id

    const result = await api.downloadUsersInQuest(token, quest_id)
    const { data } = result

    if(!data) return

    const json = this.convertToJson(data)
    
    const { Parser } = require('json2csv');
    
    const fields = ['id', 'name', 'email'];

    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(json);

    var downloadLink = document.createElement("a");

    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);

    downloadLink.href = url;
    downloadLink.download = `${this.normalizeFileName()}_.csv`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  normalizeFileName() {
    let kind = this.getKind()
    let description = this.getDescription()

    kind = kind.replace(/ /g, '-')
    description = description.replace(/ /g, '-')

    kind = this.accentsTidy(kind)
    description = this.accentsTidy(description)

    return `${kind}-${description}`
  }

  accentsTidy(s){
    var r = s.toLowerCase();
    var non_asciis = {'a': '[àáâãäå]', 'ae': 'æ', 'c': 'ç', 'e': '[èéêë]', 'i': '[ìíîï]', 'n': 'ñ', 'o': '[òóôõö]', 'oe': 'œ', 'u': '[ùúûűü]', 'y': '[ýÿ]'};
    for (var i in non_asciis) { r = r.replace(new RegExp(non_asciis[i], 'g'), i); }
    return r;
  }

  convertToJson(str) {    
    let cells = str.split('\n')
    
    cells.shift()

    const normalizedCells = cells.map(e => {
      const item = e.split(',')

      if(!item[0].length || !item[1].length || !item[2].length) return

      const getEmail = e => {
        const email = e.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
        return email ? email[0] : ''
      }

      const getName = e => {
        const totalLength = e.length

        const commaIndex = e.indexOf(',')
        const idAndCommaLength = e.substr(0, commaIndex).length

        const email = getEmail(e)
        const emailLength = email.length

        return e.substr((commaIndex + 1), (totalLength - (idAndCommaLength + emailLength + 2)))
      }

      return {
        id: item[0],
        name: getName(e),
        email: getEmail(e)
      }
    })

    return normalizedCells;
  }

  getDescription() {
    const { quest } = this.props
    if(!quest) return ''

    const { kind } = quest

    if(!kind) return ''

    if(kind === 'quest_spotify_playlist') {
      const { question, question_2 } = quest.quest_spotify_playlist
      return `${question} ${question_2 ? `/ ${question_2}` : ''}`
    }

    if(kind.includes('spotify')) {
      const { name } = quest.quest_spotify
      return name
    }

    if(kind === 'question') {
      const { question } = quest.quest_question
      return question
    }

    if(kind === 'opt') {
      const { description } = quest.quest_opt
      return description
    }

    if(kind === 'pre_save') {
      const { name_artist } = quest.quest_pre_save
      return name_artist
    }

    if(kind.includes('youtube')) {
      const { name } = quest.quest_youtube
      return name
    }

    if(kind === 'external_link') {
      return quest.quest_external_link.label
    }
  }

  loadPage = page => {
    const token = localStorage.getItem('token')
    const quest_id = this.props.match.params.id

    this.props.loadPageQuestAccomplishments(token, quest_id, page)
  }

  render() {
    const { quest } = this.props;

    return (

      <PageMenu focus="missions" loading={this.props.busy}>
          <Container>
            <Title className="mb-sm">Missões</Title>

            <Breadcrumb>
              <Button plugin={Link} to="/missions">Missões</Button>
              <Text>{`${this.getKind()} - ${this.getDescription()}`}</Text>
            </Breadcrumb>

            <Wrap className="mt-xl">
              <Grid>
                <Cell size={3}>
                  <Text className="mb-sm muted">Tipo</Text>
                  <Text className="mb-lg">{this.getKind()}</Text>
                </Cell>

                {quest?.kind !== 'quest_spotify_playlist' &&
                <Cell size={3}>
                  <Text className="mb-sm muted">Descrição</Text>
                  <Text className="mb-lg">{this.getDescription()}</Text>
                </Cell>}

                {quest?.kind === 'quest_spotify_playlist' && 
                <Cell size={3}>
                  <Text className="mb-sm muted">Nome</Text>
                  <Text className="mb-lg">{this.props.playlistName}</Text>
                </Cell>}

                {quest?.kind !== 'quest_spotify_playlist' &&
                <Cell size={6}>
                  <Text className="mb-sm muted">Filtr Coins</Text>
                  <Text className="mb-lg">{this.props.filtrCoins}</Text>
                </Cell>}

                {quest?.kind === 'quest_spotify_playlist' &&
                <Cell size={6}>
                  <Text className="mb-sm muted">Total de pontos por faixa</Text>
                  <Text className="mb-lg">{this.props.pointsForTrack}</Text>
                </Cell>}

                <Cell size={3}>
                  <Text className="mb-sm muted">Data</Text>
                  <Text className="mb-lg">{this.normalizeDate(this.props.date)}</Text>
                </Cell>

                <Cell size={3}>
                  <Text className="mb-sm muted">Status</Text>
                  <Text className="mb-lg">{this.props.status ? <Status success>Ativo</Status> :
                  <Status error>Inativo</Status>}</Text>
                </Cell>

                <Cell size={3}>
                  <Text className="mb-sm muted">Completos</Text>
                  <Text className="mb-md">{this.props.totalCompleted}</Text>
                </Cell>

                {quest && quest.kind === 'external_link' && (
                  <>
                    <Cell size={12}>
                      <Text className="mb-sm primary mt-xl">Link Externo</Text>
                      <Divider className="my-md" />
                    </Cell>
                    <Cell size={6}>
                      <Text className="mb-sm muted">Legenda</Text>
                      <Text className="mb-md">{quest.quest_external_link.label}</Text>
                    </Cell>
                    <Cell size={6}>
                      <Text className="mb-sm muted">Código</Text>
                      <Text className="mb-md">{quest.quest_external_link.code}</Text>
                    </Cell>
                    <Cell size={12}>
                      <Text className="mb-sm muted">Link</Text>
                      <Text className="mb-md">{quest.quest_external_link.link}</Text>
                    </Cell>
                  </>
                )}
              </Grid>

              <Text className="mb-sm primary mt-xl">Usuários</Text>
              <Divider className="my-md" />
              <Button onClick={() => this.downloadUsers()}>Download</Button>

              <Table>
                <Thead>
                  <Tr>
                    <Td width={22}>Nome</Td>
                    <Td width={28}>Email</Td>
                    {quest?.kind === 'question' && (
                      <Td width={50}>Resposta</Td>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {this.props.accomplishments.map((accomplished, i) => {
                    return(
                      <Tr key={i}>
                        <Td>{accomplished.user.name}</Td>
                        <Td>{accomplished.user.email}</Td>
                        {quest?.kind === 'question' && (
                          <Td>{accomplished?.user_quest_questions?.answer}</Td>
                        )}
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
              <Template condition={this.props.usersLoaded && this.props.totalAccomplishmentsPages > 1}>
                <Pagination page={1} size={this.props.totalAccomplishmentsPages} onChange={(i)=> this.loadPage(i)} />
              </Template>
            </Wrap>
          </Container>
      </PageMenu>
   );
  }
}

const MapStateToProps = state => ({
  quest: state.LoadQuest.quest,
  pointsForTrack: state.LoadQuest.pointsForTrack,
  playlistName: state.LoadQuest.playlistName,
  filtrCoins: state.LoadQuest.score,
  date: state.LoadQuest.dateStart,
  status: state.LoadQuest.status,
  totalCompleted: state.LoadQuest.totalCompleted,
  accomplishments: state.LoadQuest.accomplishments,
  totalAccomplishmentsPages: state.LoadQuest.totalAccomplishmentsPages,
  accomplishmentsLoaded: state.LoadQuest.accomplishmentsLoaded
});

const MapDispatchToProps = dispatch => bindActionCreators({
  loadQuest,
  loadQuestAccomplishments,
  loadPageQuestAccomplishments
}, dispatch);

export default connect(MapStateToProps, MapDispatchToProps)(PageComponent);
