import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import PageMenu from '../../modules/pageMenu'
import { Container, Title, Text, Breadcrumb, Button } from "sme-theme"

class FormRescueAdmin extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }

    return (
    <PageMenu focus="order-export" loading={this.props.busy}>
      <Container>
        <Title className="flex-align-center">Pedidos</Title>
        <Breadcrumb>
          <Text>Exportar</Text>
        </Breadcrumb>
        <Text className="mt-xl mb-md muted">Serão exportados todos os pedidos em aberto.</Text>
        <Button>EXPORTAR</Button>
      </Container>
    </PageMenu>
  );
  }
}

export default FormRescueAdmin;
