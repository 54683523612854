import {
  LOAD_JUKEBOXES,
  LOAD_JUKEBOXES_SUCCESS,
  LOAD_JUKEBOXES_FAILED,
  CHECK_JUKEBOX,
  JUKEBOX_STOPED,
  SET_NEXT_SONGS
} from '../actions/actionTypes';

const initialState = {
  nextSongs: [],
  hasDj: false,
  djName: null,
  djImage: null,
  trackName: null,
  trackImage: null,
  artistName: null,
  busy: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEXT_SONGS:
      return {
        ...state,
        nextSongs: action.songs
      }
    case JUKEBOX_STOPED:
      return {
        ...state,
        hasDj: false,
        nextSongs: []
      }
    case CHECK_JUKEBOX:
      return {
        ...state,
        hasDj: true,
        djName: action.msg.data.user.name,
        djImage: action.msg.data.user.image,
        trackImage: action.msg.data.track.cover_url,
        trackName: action.msg.data.track.info.name,
        artistName: action.msg.data.track.info.artists,
        nextSongs: state.nextSongs
      }
    case LOAD_JUKEBOXES:
      return {
        ...state,
        busy: true
      }
    case LOAD_JUKEBOXES_SUCCESS:
      let has_dj = false, track_id, next_songs;
      let dj_name, dj_image, track_image, track_name, artist_name;

      if(action.payload) {
        has_dj = true
        next_songs = action.payload.relationships.next_songs.data
        dj_name = action.payload.attributes.user.name
        dj_image = action.payload.attributes.user.image
        track_id = action.payload.relationships.track.data.id
        action.included.forEach(track => {
          if(track.id === track_id) {
            track_image = track.attributes.cover_url
            track_name = track.attributes.info.name
            artist_name = track.attributes.info.artists
          }
        })
      }
      return {
        ...state,
        hasDj: has_dj,
        nextSongs: next_songs,
        djName: dj_name,
        djImage: dj_image,
        trackName: track_name,
        trackImage: track_image,
        artistName: artist_name,
        busy: false
      }
    case LOAD_JUKEBOXES_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    default:
      return state
  }
}
