import { connect } from 'react-redux'
import React from 'react'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom'
import { createProduct } from '../../actions/createProductAction';
import PageMenu from '../../modules/pageMenu'
import { Form, Wrap, Text, Title, Breadcrumb, Template, Button, Container, Alert } from "sme-theme"
import { InputRedux, ErrorField } from '../../modules/inputRedux';

class NewProduct extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'content'}
  }

  componentDidMount() {
    localStorage.removeItem('array_errors_create_product');
    localStorage.removeItem('create_product_ok');
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_create_product')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_create_product');
    localStorage.removeItem('create_product_ok');
  }

  render() {
    const { error, handleSubmit, dirty } = this.props;
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const formError = 'array_errors_create_product'

    return (
    <PageMenu focus="store" loading={this.props.busy}>
      <Container>
        <Title className="mb-sm">Store</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/store">Store</Button>
          <Text>Criar produto</Text>
        </Breadcrumb>
        <Template condition={localStorage.getItem('array_errors_create_product') && error}>
          <Alert className="my-md" type="error" iconName="attention-circled">
            <Text>{this.props.error_status !== 500 && 'Alguns campos precisam da sua atenção:'}</Text>
            <ErrorField field="image" label="Imagem" formError={formError} />
            <ErrorField field="name" label="Nome" formError={formError} />
            <ErrorField field="description" label="Detalhes" formError={formError} />
            <ErrorField field="value" label="Valor" formError={formError} />
            <ErrorField field="date_start" label="Data de início" formError={formError} />
            <ErrorField field="date_finish" label="Data de fim" formError={formError} />
            <ErrorField field="quantity" label="Quantidade" formError={formError} />
            <ErrorField field="kind" label="Tipo" formError={formError} />
            <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
          </Alert>
        </Template>

        <Wrap className="max-w-xs">
          <Form onSubmit={handleSubmit(createProduct)}>
            <InputRedux name="status" type="switch" label="Ativar" formError={formError} />
            {/* <InputRedux name="delivery" type="switch" label="Entrega por transportadora" formError={formError} /> */}
            {/* @TRANSPORTADORA */}
            <InputRedux name="image" helpText="Imagem do produto no formato 300x300" buttonClass="sm" type="file" label="Foto" formError={formError} />
            <InputRedux
              name="kind"
              type="select"
              options={[
                {label: 'Pedido', value: 'delivery'},
                {label: 'Cupom', value: 'coupon'}]
              }
              label="Tipo"
              formError={formError}
            />
            <InputRedux name="name" type="text" label="Nome" formError={formError} />
            <InputRedux name="description" type="textarea" label="Detalhes" formError={formError} />
            <InputRedux name="value" label="Valor" formError={formError} />
            <InputRedux name="date_start" mask="99/99/9999 99:99" label="Data de início" formError={formError} />
            <InputRedux name="date_finish" mask="99/99/9999 99:99" label="Data de fim" formError={formError} />
            <InputRedux name="quantity" label="Quantidade" formError={formError} />
            <Button className="block" type="submit" loading={this.props.busyButton} disabled={!dirty}>Adicionar</Button>
          </Form>
        </Wrap>
      </Container>
    </PageMenu>
  );
  }
}

const MapStateToProps = state => ({
  busyButton: state.LoadProducts.busyButton,
  error_status: state.LoadProducts.error
})

const MapDispatchToProps = dispatch => bindActionCreators({
  createProduct
}, dispatch);

NewProduct = reduxForm({
  form: 'formNewProductAdmin',
  enableReinitialize: true
})(NewProduct);

export default connect(MapStateToProps, MapDispatchToProps)(NewProduct);
