import { connect } from 'react-redux'
import React from 'react'
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form';
import PageMenu from '../../modules/pageMenu'
import { Text, Button, Container, Icon, Table, Tbody, Thead, Td, Tr, Title, Template, Pagination, Breadcrumb } from "sme-theme"
import { bindActionCreators } from 'redux'
import { loadBadgeUsers } from '../../actions/loadBadgeUsersAction';
import { orderBadgeUsers } from '../../actions/orderBadgeUsersAction'
import { loadPageBadgeUsers } from '../../actions/loadPageBadgeUsersAction'

class BadgeUsers extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadBadgeUsers(token, this.props.match.params.id);
  }

  order = attr => {
    const token = localStorage.getItem('token')
    const badge_id = this.props.match.params.id
    this.props.orderBadgeUsers(token, badge_id, attr, this.props.orderType)
  }

  verifyAttrFilter = attr => {
    if(attr === this.props.selectedOrder) {
      return 'sort-title active'
    }
    return 'sort-title'
  }

  loadPage = (page) => {
    const token = localStorage.getItem('token')
    const badge_id = this.props.match.params.id
    this.props.loadPageBadgeUsers(token, badge_id, page, this.props.orderAttribute, this.props.orderType);
  }

  render() {
    return (
      <PageMenu focus="badges" loading={this.props.busy}>
        <Container>
          <Title className="flex-align-center">Badge Challenge</Title>
          <Breadcrumb>
            <Button plugin={Link} to="/badges">Badge Challenge</Button>
            <Text>Rock in Rio</Text>
          </Breadcrumb>
           <Button onClick={() => alert()} className="sm">Download</Button>
          <Table>
            <Thead>
              <Tr>
                <Td width={60} onClick={() => this.order('user_name')} className={this.verifyAttrFilter('user_name')}>Usuário <Icon name="arrow-down"/></Td>
                <Td width={40} onClick={() => this.order('times')} className={this.verifyAttrFilter('times')}>Plays <Icon name="arrow-down"/></Td>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.userBadges.map((badge, i) => {
                return(
                  <Tr key={i}>
                    <Td>{badge.attributes.name}</Td>
                    <Td>
                      {badge.attributes.times}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
          <Template condition={this.props.totalPages > 1 && !this.props.ordering}>
            <Pagination page={1} size={this.props.totalPages} onChange={(i)=> this.loadPage(i)} />
          </Template>
        </Container>
      </PageMenu>
    );
  }
}

const mapStateToProps = state => ({
  userBadges: state.LoadBadgeUsers.userBadges,
  totalPages: state.LoadBadgeUsers.totalPages,
  busy: state.LoadBadgeUsers.busy,
  selectedOrder: state.LoadBadgeUsers.selectedOrder,
  orderType: state.LoadBadgeUsers.orderType,
  orderAttribute: state.LoadBadgeUsers.orderAttribute,
  ordering: state.LoadBadgeUsers.ordering
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadBadgeUsers,
  orderBadgeUsers,
  loadPageBadgeUsers
}, dispatch);

BadgeUsers = reduxForm({
  form: 'formBadgeUsers',
  enableReinitialize: true
})(BadgeUsers);

export default connect(mapStateToProps, mapDispatchToProps)(BadgeUsers);
