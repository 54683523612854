import {
  LOAD_PLAYER_PLAYLISTS,
  LOAD_PLAYER_PLAYLISTS_SUCCESS,
  LOAD_PLAYER_PLAYLISTS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPlayerPlaylists = (token) => async (dispatch) => {
  dispatch({ type: LOAD_PLAYER_PLAYLISTS })
  try {
    const result = await api.loadPlaylists(token)
    const playlist = result.data.data.filter(item => item.attributes.status)
    
    let tracks = [], has_selected = false;
    if(playlist[0]) {
       has_selected = true
       tracks = await api.loadPlaylist(token, playlist[0].id)
    }
    
    dispatch({ 
      type: LOAD_PLAYER_PLAYLISTS_SUCCESS, 
      playlists: result.data.data,
      included: result.data.included,
      tracks: has_selected ? tracks.data.included : [],
      hasSelected: has_selected
    })
  } catch(err) {
    dispatch({ type: LOAD_PLAYER_PLAYLISTS_FAILED, error: err })
  }
}
