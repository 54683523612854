import {
  ORDER_BADGES_BY_ATTRIBUTE,
  ORDER_BADGES_BY_ATTRIBUTE_SUCCESS,
  ORDER_BADGES_BY_ATTRIBUTE_FAILED
} from './actionTypes';

import api from '../services/api';

export const orderBadges = (token, list, attribute, orderType) => async (dispatch) => {
  dispatch({ type: ORDER_BADGES_BY_ATTRIBUTE })
  const order = orderType === 'asc' ? 'desc' : 'asc'
  try {
    const result = await api.filterList(token, list, attribute, order)
    dispatch({
      type: ORDER_BADGES_BY_ATTRIBUTE_SUCCESS,
      payload: result.data.data,
      attribute: attribute,
      orderType: orderType,
      lastLink: result.data.links.last
    })
  } catch(err) {
    dispatch({ type: ORDER_BADGES_BY_ATTRIBUTE_FAILED, error: err })
  }
}
