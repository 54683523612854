import { combineReducers } from "redux";
import todos from "./todos";
import visibilityFilter from "./visibilityFilter";
import LoadCampaigns from "./loadCampaignsReducer";
import LoadCurrentAdmin from "./loadCurrentAdminReducer";
import LoadCampaignContent from "./loadCampaignContentReducer";
import LoadUsersCampaign from "./loadUsersCampaignReducer";
import LoadUsers from "./loadUsersReducer";
import LoadRescues from "./loadRescuesReducer";
import LoadRescue from "./loadRescueReducer";
import LoadRescueAnalytics from "./loadRescueAnalyticsReducer";
import LoadRescueUsers from "./loadRescueUsersReducer";
import LoadUser from "./loadUserReducer";
import LoadUserFullLog from "./loadUserFullLogReducer";
import LoadAccounts from "./loadAccountsReducer";
import LoadAccount from "./loadAccountReducer";
import LoadSettings from "./loadSettingsReducer";
import LoadQuests from "./loadQuestsReducer";
import LoadQuest from "./loadQuestReducer";
import LoadProducts from "./loadProductsReducer";
import LoadProduct from "./loadProductReducer";
import LoadOrders from "./loadOrdersReducer";
import LoadOrder from "./loadOrderReducer";
import ValidateCoupon from "./validateCouponReducer";
import LoadPlaylists from "./loadPlaylistsReducer";
import LoadPlaylist from "./loadPlaylistReducer";
import LoadJukeboxes from "./loadJukeboxesReducer";
import Player from "./playerReducer";
import LoadBadges from "./loadBadgesReducer";
import LoadBadge from "./loadBadgeReducer";
import LoadBadgeUsers from "./loadBadgeUsersReducer";
import LoadOrderAddress from "./loadOrderAddressReducer";
import LoadStates from "./loadStatesReducer";
import LoadCities from "./loadCitiesReducer";
import LoadUsersCashbacks from "./loadUserCashbacksReducer";
import LoadTotalStreamsCashBack from "./loadTotalStreamsCashBackReducer";

import { reducer as formReducer } from "redux-form";

export default combineReducers({
  todos,
  visibilityFilter,
  LoadCampaigns,
  LoadCurrentAdmin,
  LoadCampaignContent,
  LoadUsersCampaign,
  LoadUsers,
  LoadRescues,
  LoadRescue,
  LoadRescueAnalytics,
  LoadRescueUsers,
  LoadUser,
  LoadUserFullLog,
  LoadAccounts,
  LoadAccount,
  LoadSettings,
  LoadQuests,
  LoadQuest,
  LoadProducts,
  LoadProduct,
  LoadOrders,
  LoadOrder,
  LoadOrderAddress,
  ValidateCoupon,
  LoadPlaylists,
  LoadPlaylist,
  LoadJukeboxes,
  Player,
  LoadBadges,
  LoadBadge,
  LoadBadgeUsers,
  LoadStates,
  LoadCities,
  LoadUsersCashbacks,
  LoadTotalStreamsCashBack,
  form: formReducer,
});
