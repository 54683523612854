import {
  LOAD_RESCUES,
  LOAD_RESCUES_SUCCESS,
  LOAD_RESCUES_FAILED,
  LOAD_PAGE_RESCUE_SUCCESS,
  LOAD_PAGE_RESCUE_FAILED,
  DELETE_RESCUE_SUCCESS,
  DELETE_RESCUE_FAILED,
  CREATE_RESCUE,
  CREATE_RESCUE_SUCCESS,
  CREATE_RESCUE_FAILED,
  HIDE_ALERT_CREATE_RESCUE,
  ORDER_RESCUES_BY_ATTRIBUTE,
  ORDER_RESCUES_BY_ATTRIBUTE_SUCCESS,
  ORDER_RESCUES_BY_ATTRIBUTE_FAILED
} from '../actions/actionTypes';

const initialState = {
  rescues: [],
  busy: false,
  busyButton: false,
  totalPages: 0,
  alertCreateRescue: false,
  orderType: 'desc',
  orderAttribute: 'default',
  selectedOrder: '',
  ordering: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_RESCUES:
      return {
        ...state,
        busy: true
      }
    case LOAD_RESCUES_SUCCESS:

      function getParamsFromUrl(url) {
        url = decodeURI(url);
        if (typeof url === 'string') {
            let params = url.split('?');
            let eachParamsArr = params[1].split('&');
            let obj = {};
            if (eachParamsArr && eachParamsArr.length) {
                eachParamsArr.forEach(param => {
                    let keyValuePair = param.split('=')
                    let key = keyValuePair[0];
                    let value = keyValuePair[1];
                    obj[key] = value;
                })
            }
            return obj;
        }
      }

      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params.page

      return {
        ...state,
        rescues: action.payload,
        rescuesLoaded: true,
        totalPages: parseInt(totalPages),
        busy: false
      }
    case LOAD_RESCUES_FAILED:
      return {
        ...state,
        error: action.error
      }
    case LOAD_PAGE_RESCUE_SUCCESS:
      return {
        ...state,
        rescues: action.payload
      }
    case LOAD_PAGE_RESCUE_FAILED:
      return {
        ...state,
        error: action.error
      }
    case CREATE_RESCUE:
      return {
        ...state,
        busyButton: true,
      }
    case CREATE_RESCUE_SUCCESS:
      return {
        ...state,
        busyButton: false,
        alertCreateRescue: true
      }
    case CREATE_RESCUE_FAILED:
      return {
        ...state,
        busyButton: false,
        alertCreateRescue: false,
        error: action.error
      }
    case HIDE_ALERT_CREATE_RESCUE:
      return {
        ...state,
        alertCreateRescue: false
      }
    case DELETE_RESCUE_SUCCESS:
      const indexToRemove = state.rescues.indexOf(action.rescue)
      const newRescues = [...state.rescues];
      newRescues.splice(indexToRemove, 1);
      return {
        ...state,
        rescues: newRescues
      }
     case DELETE_RESCUE_FAILED:
      return {
        ...state
      }
    case ORDER_RESCUES_BY_ATTRIBUTE:
      return {
        ...state,
        busy: true,
        ordering: true
      }
    case ORDER_RESCUES_BY_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        rescues: action.payload,
        orderAttribute: action.attribute,
        selectedOrder: action.attribute,
        orderType: action.orderType === 'desc' ? 'asc' : 'desc',
        ordering: false,
        busy: false
      }
    case ORDER_RESCUES_BY_ATTRIBUTE_FAILED:
        return {
          ...state,
          error: action.error,
          busy: false
        }
    default:
      return state
  }
}
