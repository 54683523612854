import {
  EDIT_ACCOUNT,
  EDIT_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT_FAILED
} from './actionTypes';

import api from '../services/api';
import { SubmissionError } from 'redux-form'

export const editAdmin = (idAdmin) => async (value, dispatch) => {
  dispatch({ type: EDIT_ACCOUNT })
  const { email, password, password_confirmation, status, roles } = value

  let status_admin = false

  let admin_kind = null
  if(roles === 'admin') {
    admin_kind = 'admin'
  } else if(roles === 'master') {
    admin_kind = 'master'
  } else if(roles === 'promoter') {
    admin_kind = 'promoter'
  } else if(roles === 'logistic') {
    admin_kind = 'logistic'
  }

  if(status) {
    status_admin = true
  }

  const data = {
    "admin": {
      "email": email,
      "password": password,
      "password_confirmation": password_confirmation,
      "roles": admin_kind,
      "status": status_admin
    }
  }
  const tokenAdmin = localStorage.getItem('token')
  localStorage.removeItem('edit_admin_ok')
  localStorage.removeItem('error_kind_edit_admin')

  if(admin_kind != null) {
    try {
      await api.updateAdmin(tokenAdmin, idAdmin, data);
      localStorage.setItem('edit_admin_ok', true)
      dispatch({ type: EDIT_ACCOUNT_SUCCESS })
    } catch(err) {
      dispatch({ type: EDIT_ACCOUNT_FAILED, error: err.response.status })
      localStorage.setItem('array_errors_edit_admin', JSON.stringify(err.response.data))
      if(err.response.status === 500) {
        localStorage.setItem('array_errors_edit_admin', JSON.stringify({ server_error: ['Internal Server Error'] }))
      }
      throw new SubmissionError({_error: 'error'})
    }
  } else {
    dispatch({ type: EDIT_ACCOUNT_FAILED, error: 'error' })
    localStorage.setItem('error_kind_edit_admin', true)
    localStorage.removeItem('array_errors_edit_admin')
  }

}
