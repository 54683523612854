import React from 'react'
import { Page, Container, Titlebar, Title, Button, Text, Modal, Icon, Wrap, Alert } from  "sme-theme"
import QrReader from 'react-qr-reader'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { logoutAdmin } from '../../actions/logoutAdminAction';
import { validateCoupon } from '../../actions/validateCouponAction'
import { handleScannerModal } from '../../actions/handleScannerModals'
import { RESET_MODAL_VALIDATE_COUPON_ERROR } from '../../actions/actionTypes'
import { HIDE_MODAL_VALIDATE_COUPON_SUCCESS } from '../../actions/actionTypes'

class Scanner extends React.Component {
  state = {
    result: '',
    error: false
  }

  logout = () => {
    this.props.logoutAdmin()
  }

  showError() {
    var t = this
    this.setState({
      error: true
    })
    setTimeout(function() {
      t.setState({
        error: false
      })
    }, 3000)
  }
  
  handleScan = data => {
    if (data && !this.props.validated) {
      this.props.validateCoupon(data)
    }
  }

  componentDidUpdate() {
    const showCouponValidated = this.props.showCouponValidated
    const showNotAuthorized = this.props.showNotAuthorized

    if(showCouponValidated) {
      this.refs.coupon_validated.show()
    }

    if(showNotAuthorized) {
      this.showError()
      this.props.handleScannerModal(RESET_MODAL_VALIDATE_COUPON_ERROR)
    }
  }
  handleError = err => {
    console.error('erro aqui')
  }

  hideModalCouponValidated() {
    this.refs.coupon_validated.hide()
    this.props.handleScannerModal(HIDE_MODAL_VALIDATE_COUPON_SUCCESS)
  }
  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    
    return (
    <Page name="scanner">
      <Titlebar className="mb-xl">
        <Container className="max-w-xs center">
          <Title className="sm m-0 left">Scanner</Title>
            <Button onClick={() => this.logout()} className="unset color-primary">
              Logout
            </Button>
        </Container>
      </Titlebar>
      <Container className="max-w-xs center">
      <Text className="mb-xl">Aponte para o qr-code para fazer o scan e validar o resgate.</Text>
      <Wrap className="qr-reader">
        <QrReader
            delay={600}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%' }}
          />
      </Wrap>
      {this.state.error &&
        <Alert className="my-md" type="error">
          <Text>Retirada não autorizada.</Text>
        </Alert>
      }
      </Container>


      <Modal className="sm" ref="coupon_validated">
        <Icon name="check" className="xl mx-auto mb-lg" />
        <Title className="outline center mb-xl">AUTORIZADO</Title>
        <Button className="block outline black mt-lg mb-0 max-w-xs mx-auto" onClick={() => {
          this.hideModalCouponValidated()
        }}>Fechar</Button>
      </Modal>
    </Page>
  );
  }
}

const mapStateToProps = state => ({
  showCouponValidated: state.ValidateCoupon.showCouponValidated,
  showNotAuthorized: state.ValidateCoupon.showNotAuthorized,
  validated: state.ValidateCoupon.validated
});
const mapDispatchToProps = dispatch => bindActionCreators({
logoutAdmin,
validateCoupon,
handleScannerModal
}, dispatch);
  
export default connect(mapStateToProps, mapDispatchToProps)(Scanner);

