import {
  EDIT_QUEST,
  EDIT_QUEST_FAILED,
  EDIT_QUEST_SUCCESS,
} from "./actionTypes";

import { SubmissionError } from "redux-form";
import api from "../services/api";

export const updateQuest =
  (idQuest, questRelationshipId) => async (value, dispatch) => {
    dispatch({ type: EDIT_QUEST });
    const {
      status,
      date_start,
      score,
      quest_kind,
      uri,
      kind_spotify,
      url,
      kind_youtube,
      question,
      answer,
      regex,
      name_artist,
      name_product,
      description,
      launch_in,
      external_link_label,
    } = value;

    let data = null;

    let date_start_standard = "";
    if (date_start) {
      date_start_standard = standardDate(date_start);
    }

    let launch_in_standard = "";
    if (launch_in) {
      launch_in_standard = standardDate(launch_in);
    }

    let to_listen = false;
    if (kind_spotify === "listen") {
      to_listen = true;
    }

    if (quest_kind === "quest_spotify") {
      data = {
        quest: {
          date_start: date_start_standard,
          score: score,
          status: status,
          quest_spotify_attributes: {
            id: questRelationshipId,
            uri: uri,
            to_listen: to_listen,
          },
        },
      };
    } else if (quest_kind === "quest_question") {
      data = {
        quest: {
          date_start: date_start_standard,
          score: score,
          status: status,
          quest_question_attributes: {
            id: questRelationshipId,
            question: question,
            answer: answer,
            regex: regex,
          },
        },
      };
    } else if (quest_kind === "quest_youtube") {
      data = {
        quest: {
          date_start: date_start_standard,
          score: score,
          status: status,
          quest_youtube_attributes: {
            id: questRelationshipId,
            url: url,
            kind: kind_youtube,
          },
        },
      };
    } else if (quest_kind === "quest_pre_save") {
      data = {
        quest: {
          date_start: date_start_standard,
          score: score,
          status: status,
          quest_pre_save_attributes: {
            id: questRelationshipId,
            name_artist: name_artist,
            name_product: name_product,
            launch_in: launch_in_standard,
            uri: uri,
          },
        },
      };
    } else if (quest_kind === "quest_opt") {
      data = {
        quest: {
          date_start: date_start_standard,
          score: score,
          status: status,
          quest_opt_attributes: {
            id: questRelationshipId,
            description: description,
          },
        },
      };
    } else if (quest_kind === "quest_spotify_playlist") {
      data = {
        quest: {
          date_start: date_start_standard,
          score: score,
          status: status,
          quest_spotify_playlist_attributes: {
            id: questRelationshipId,
          },
        },
      };
    } else if (quest_kind === "quest_external_link") {
      data = {
        quest: {
          date_start: date_start_standard,
          score: score,
          status: status,
          quest_external_link_attributes: {
            id: questRelationshipId,
            label: external_link_label,
          },
        },
      };
    }

    localStorage.removeItem("edit_quest_ok");
    localStorage.removeItem("edit_quest_server_error");
    try {
      const token = localStorage.getItem("token");
      await api.updateQuest(token, idQuest, data);
      localStorage.setItem("edit_quest_ok", "ok");
      dispatch({ type: EDIT_QUEST_SUCCESS });
    } catch (err) {
      dispatch({ type: EDIT_QUEST_FAILED });
      localStorage.setItem(
        "array_errors_edit_quest",
        JSON.stringify(err.response.data)
      );
      if (err.response.status === 500) {
        localStorage.setItem("edit_quest_server_error", true);
      }
      throw new SubmissionError({ _error: "error" });
    }
  };

function standardDate(date) {
  let year = "";
  let month = "";
  let day = "";
  let hour = "";

  for (var i = 0; i < date.length; i++) {
    if (i < 2) {
      day += date[i];
    }
    if (i === 3 || i === 4) {
      month += date[i];
    }
    if (i >= 6 && i <= 9) {
      year += date[i];
    }
    if (i >= 11 && i <= 15) {
      hour += date[i];
    }
  }
  return `${year}-${month}-${day} ${hour}`;
}
