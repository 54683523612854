import React from 'react';
import UserImage from '../../assets/images/default-user.jpg';
import { Image } from "sme-theme"

class User extends React.Component {
  render() {
    return <Image className={this.props.className} src={UserImage} />
  }
}

export default User;
