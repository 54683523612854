import { connect } from 'react-redux'
import React from 'react'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom'
import { loadOrderComment } from '../../actions/loadOrderCommentAction';
import PageMenu from '../../modules/pageMenu'
import { Form, Container, Title, Breadcrumb, Button, Text, Wrap } from "sme-theme"
import { InputRedux } from '../../modules/inputRedux';

class FormViewOrderComment extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadOrderComment(token, this.props.match.params.id);
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const orderId = this.props.match.params.order_id
    const userName = this.props.match.params.user
    const userId = this.props.match.params.user_id
    const productName = this.props.match.params.product


    return (
      <PageMenu focus="orders" loading={this.props.busy}>
        <Title className="mb-sm">Pedidos</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/orders">Pedidos</Button>
          <Button plugin={Link} to={`/user/${userId}`}>{userName}</Button>
          <Button plugin={Link} to={`/order/${orderId}/user/${userId}`}>{productName}</Button>
          <Text>comentário</Text>
        </Breadcrumb>
        <Wrap className="mt-xl">
          <Container>
            <Form>
              <InputRedux
                name="body"
                type="textarea"
                label=""
                disabled={true}
              />
            </Form>
          </Container>
        </Wrap>
      </PageMenu>
    );
  }
}

const MapStateToProps = state => ({
  initialValues: {
    body: state.LoadOrder.orderComment
  },
  busy: state.LoadOrder.busy
})

const MapDispatchToProps = dispatch => bindActionCreators({
  loadOrderComment
}, dispatch);

FormViewOrderComment = reduxForm({
  form: 'formViewOrderComment',
  enableReinitialize: true
})(FormViewOrderComment);

export default connect(MapStateToProps, MapDispatchToProps)(FormViewOrderComment);
