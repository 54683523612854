import api from '../services/api';
import { SubmissionError } from 'redux-form'

export const forgetPassword = async (value, dispatch) => {
  const { email } = value

  const data = {
    "admin": {
      "email": email
    }
  }

  localStorage.removeItem('forget_password_ok')

  try {
    await api.forgetPassword(data);
    localStorage.setItem('forget_password_ok', 'ok')
  } catch(err) {
    localStorage.setItem('array_errors_forget_password', JSON.stringify(err.response.data))
    throw new SubmissionError({_error: 'error'})
  }
}
