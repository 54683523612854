import {
  LOAD_STATES,
  LOAD_STATES_SUCCESS,
  LOAD_STATES_FAILED
} from '../actions/actionTypes';

const initialState = {
  states: [],
  busy: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_STATES:
      return {
        ...state,
        busy: true
      }
    case LOAD_STATES_SUCCESS:
      const { data } = action.payload
      
      return {
        ...state,
        states: data
      }
    case LOAD_STATES_FAILED:
      return {
        ...state,
        busy: false,
        error: action.error
      }
    default:
      return state
  }
}