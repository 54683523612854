import {
  LOAD_CURRENT_ADMIN,
  LOAD_CURRENT_ADMIN_SUCCESS,
  LOAD_CURRENT_ADMIN_FAILED,
  UPDATE_PROFILE_ADMIN,
  UPDATE_PROFILE_ADMIN_SUCCESS,
  UPDATE_PROFILE_ADMIN_FAILED,
} from '../actions/actionTypes';

const initialState = {
  email: '',
  roles: null,
  busy: false,
  busyButton: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CURRENT_ADMIN:
      return {
        ...state,
        busy: true
      }
    case LOAD_CURRENT_ADMIN_SUCCESS:
      const { email, roles } = action.payload
      return {
        ...state,
        email: email,
        roles: roles,
        busy: false
      }
    case LOAD_CURRENT_ADMIN_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case UPDATE_PROFILE_ADMIN:
      return {
        ...state,
        busyButton: true
      }
    case UPDATE_PROFILE_ADMIN_SUCCESS:
      return {
        ...state,
        busyButton: false
      }
    case UPDATE_PROFILE_ADMIN_FAILED:
      return {
        ...state,
        busyButton: false,
        error: action.error
      }
    default:
      return state
  }
}
