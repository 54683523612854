import {
  VALIDATE_COUPON,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAILED
} from './actionTypes'

import api from '../services/api';

export const validateCoupon = (token_coupon) => async (dispatch) => {
  dispatch({ type: VALIDATE_COUPON })
  const token = localStorage.getItem('token')
  try {
   await api.validateCoupon(token, token_coupon)
   dispatch({ type: VALIDATE_COUPON_SUCCESS })
  } catch(err) {
    dispatch({ type: VALIDATE_COUPON_FAILED })
  }
}
