import {
  LOAD_ORDER_ADDRESS,
  LOAD_ORDER_ADDRESS_SUCCESS,
  LOAD_ORDER_ADDRESS_FAILED,
  UPDATE_ORDER_ADDRESS,
  UPDATE_ORDER_ADDRESS_SUCCESS,
  UPDATE_ORDER_ADDRESS_FAILED
} from '../actions/actionTypes';

const initialState = {
  tracking_code: null,
  recipient: null,
  cpf: null,
  cep: null,
  stateId: null,
  city: null,
  street: null,
  number: null,
  complement: null,
  neighborhood: null,
  reference: null,
  busy: false,
  busyButton: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ORDER_ADDRESS:
      return {
        ...state,
        busy: true
      }
    case LOAD_ORDER_ADDRESS_SUCCESS:
      const { recipient, street, neighborhood, complement, number, reference,
      cep, cpf } = action.payload
      
      const state_id = action.payload.city.state.id
      const city_id = action.payload.city.id

      return {
        ...state,
        recipient: recipient,
        cpf: cpf,
        cep: cep,
        stateId: state_id,
        city: city_id,
        street: street,
        number: number,
        complement: complement,
        neighborhood: neighborhood,
        reference: reference,
        busy: false
      }
    case LOAD_ORDER_ADDRESS_FAILED:
      return {
        ...state,
        busy: false,
        error: action.error
      }
    case UPDATE_ORDER_ADDRESS:
      return {
        ...state,
        busyButton: true
      }
    case UPDATE_ORDER_ADDRESS_SUCCESS:
      return {
        ...state,
        busyButton: false
      }
    case UPDATE_ORDER_ADDRESS_FAILED:
      return {
        ...state,
        busyButton: false,
        error: action.error
      }
    default:
      return state
  }
}
