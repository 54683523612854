import {
  FILTER_USERS_BY_ATTRIBUTE,
  FILTER_USERS_BY_ATTRIBUTE_SUCCESS,
  FILTER_USERS_BY_ATTRIBUTE_FAILED
} from './actionTypes';

import api from '../services/api';

export const filterUsers = (token, list, attribute, filterType) => async (dispatch) => {
  dispatch({ type: FILTER_USERS_BY_ATTRIBUTE })
  const filter = filterType === 'asc' ? 'desc' : 'asc'
  try {
    const result = await api.filterList(token, list, attribute, filter)
    dispatch({
      type: FILTER_USERS_BY_ATTRIBUTE_SUCCESS,
      payload: result.data.data,
      attribute: attribute,
      filterType: filterType,
      lastLink: result.data.links.last
    })
  } catch(err) {
    dispatch({ type: FILTER_USERS_BY_ATTRIBUTE_FAILED, error: err })
  }
}
