import {
  CREATE_PLAYLIST,
  CREATE_PLAYLIST_SUCCESS,
  CREATE_PLAYLIST_FAILED,
  LOAD_PLAYLISTS,
  LOAD_PLAYLISTS_SUCCESS,
  LOAD_PLAYLISTS_FAILED,
  HIDE_ALERT_CREATE_PLAYLIST,
  LOAD_PAGE_PLAYLIST_SUCCESS,
  LOAD_PAGE_PLAYLIST_FAILED
} from '../actions/actionTypes';

const initialState = {
  playlists: [],
  alertCreatePlaylist: false,
  totalPages: 0,
  busy: false,
  busyButton: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PLAYLIST:
      return {
        ...state,
        busyButton: true
      }
    case CREATE_PLAYLIST_SUCCESS:
      return {
        ...state,
        busyButton: false,
        alertCreatePlaylist: true
      }
    case CREATE_PLAYLIST_FAILED:
      return {
        ...state,
        busyButton: false,
        error: action.error
      }
    case LOAD_PLAYLISTS:
      return {
        ...state,
        busy: true
      }
    case LOAD_PLAYLISTS_SUCCESS:
        function getParamsFromUrl(url) {
          url = decodeURI(url);
          if (typeof url === 'string') {
              let params = url.split('?');
              let eachParamsArr = params[1].split('&');
              let obj = {};
              if (eachParamsArr && eachParamsArr.length) {
                  eachParamsArr.forEach(param => {
                      let keyValuePair = param.split('=')
                      let key = keyValuePair[0];
                      let value = keyValuePair[1];
                      obj[key] = value;
                  })
              }
              return obj;
          }
        }

        const lastLink = action.lastLink
        let params = getParamsFromUrl(lastLink)
        params = JSON.stringify(params)
        params = JSON.parse(params)
        const totalPages = params['page[number]']

      return {
        ...state,
        playlists: action.payload,
        totalPages: parseInt(totalPages),
        busy: false
      }
    case LOAD_PLAYLISTS_FAILED:
      return {
        ...state,
        busy: false,
        error: action.error
      }
    case HIDE_ALERT_CREATE_PLAYLIST:
      return {
        ...state,
        alertCreatePlaylist: false
      }
    case LOAD_PAGE_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlists: action.playlists
      }
    case LOAD_PAGE_PLAYLIST_FAILED:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}
