import {
  LOAD_PRODUCTS,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadProducts = (token) => async (dispatch) => {
  dispatch({ type: LOAD_PRODUCTS })
  try {
    const result = await api.loadProducts(token);
    dispatch({ type: LOAD_PRODUCTS_SUCCESS, payload: result.data.data, lastLink: result.data.links.last })
  } catch(err) {
    dispatch({ type: LOAD_PRODUCTS_FAILED, error: err })
  }
}
