import {
  LOAD_STATES,
  LOAD_STATES_SUCCESS,
  LOAD_STATES_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadStates = (token) => async (dispatch) => {
  dispatch({ type: LOAD_STATES })
  try {
    const result = await api.loadStates(token);
    dispatch({ type: LOAD_STATES_SUCCESS, payload: result.data })
  } catch(err) {
    dispatch({ type: LOAD_STATES_FAILED, error: err })
  }
}
