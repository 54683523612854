import { connect } from 'react-redux'
import React from 'react'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { searchOrders } from '../../actions/searchOrdersAction';
import { cleanSearchOrders } from '../../actions/cleanSearchOrdersAction';
import { Form, Wrap, Button, Icon, Template } from "sme-theme"
import { InputRedux } from '../../modules/inputRedux';

class FormSearchOrders extends React.Component {
  clean() {
    this.props.cleanSearchOrders(localStorage.getItem('token'));
  }

  render() {
    const { handleSubmit, dirty } = this.props;

    return (
      <Wrap className="max-w-sm">
        <Form onSubmit={handleSubmit(searchOrders)}>
          <InputRedux
            name="search"
            label="Busque por email, cep, nome do usuário ou produto"
            after={
              <Button type="submit" disabled={!dirty} className="sm ml-md mb-0">Buscar</Button>
            }

            append={
              <Template condition={dirty}>
                <Button type="button" disabled={!dirty} onClick={() => this.clean()} className="unset mb-0"><Icon className="mt-sm white xs" name="close"/></Button>
              </Template>
            }
          />
        </Form>
      </Wrap>
    );
  }
}

const MapStateToProps = state => ({

})

const MapDispatchToProps = dispatch => bindActionCreators({
  searchOrders,
  cleanSearchOrders
}, dispatch);

FormSearchOrders = reduxForm({
  form: 'formSearchOrders'
})(FormSearchOrders);

export default connect(MapStateToProps, MapDispatchToProps)(FormSearchOrders);
