import {
  DOWNLOAD_RESCUE_USERS_SUCCESS,
  DOWNLOAD_RESCUE_USERS_FAILED,
  RESET_DOWNLOAD_RESCUE_USERS_ALERT
} from './actionTypes';

import api from '../services/api';

export const downloadRescueUsers = (token, id_rescue) => async (dispatch) => {
  try {
    await api.downloadRescueUsers(token, id_rescue);
    
    dispatch({ type: DOWNLOAD_RESCUE_USERS_SUCCESS })
  } catch(err) {
    dispatch({ type: DOWNLOAD_RESCUE_USERS_FAILED })
  }
}

export const resetDownloadAlert = () => async (dispatch) => {
  dispatch({ type: RESET_DOWNLOAD_RESCUE_USERS_ALERT })
}
