import {
  LOAD_CITIES,
  LOAD_CITIES_SUCCESS,
  LOAD_CITIES_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadCities = (state_id, token) => async (dispatch) => {
  dispatch({ type: LOAD_CITIES })
  try {
    const result = await api.loadCities(state_id, token);
    dispatch({ type: LOAD_CITIES_SUCCESS, payload: result.data })
  } catch(err) {
    dispatch({ type: LOAD_CITIES_FAILED, error: err })
  }
}
