import { connect } from 'react-redux'
import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form';
import PageMenu from '../../modules/pageMenu'
import { loadPageUser } from '../../actions/loadPageUserAction';
import { loadUsers } from '../../actions/loadUsersAction';
import { filterUsers } from '../../actions/filterUsersAction';
import { Title, Button, Grid, Container, Icon, Table, Tbody, Thead, Td, Tr, Template, Pagination } from "sme-theme"
import FormUserSearch from './formUserSearch';

class ListUsers extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadUsers(token)
  }

  loadPage = page => {
    const token = localStorage.getItem('token')
    const { search, filterType, filterAttribute} = this.props
    
    this.props.loadPageUser(token, page, filterAttribute, filterType, search)
  }

  /* filter = attr => {
    const token = localStorage.getItem('token')
    this.props.filterUsers(token, 'users', attr, this.props.filterType)
  }

  verifyAttrFilter = attr => {
    if(attr === this.props.selectedFilter) {
      return 'sort-title active'
    }
    return 'sort-title'
  } */

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }

    return (
    <PageMenu focus="users" loading={this.props.busy}>
        <Container>
          <Title>Usuários</Title>
          <Grid className="my-lg">
           <FormUserSearch/>
          </Grid>
          <Table>
            <Thead>
              <Tr>
                <Td width={40}>Nome</Td>
                <Td width={40}>Email</Td>
                <Td width={"100px"} >Action</Td>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.users.map((user, i) => {
                return(
                  <Tr  key={i}>
                    <Td nowrap>{user.name}</Td>
                    <Td nowrap>{user.email}</Td>
                    <Td>
                      <Button className="circle m-0" plugin={Link} to={`/user/${user.id}`} title="Ver +" ><Icon name="eye" /></Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
          <Template condition={this.props.totalPages > 1 && !this.props.busy && !this.props.searching}>
            <Pagination page={1} size={this.props.totalPages} onChange={(i)=> this.loadPage(i)} />
          </Template>
        </Container>
    </PageMenu>
  );
  }
}

const MapStateToProps = state => ({
  totalPages: state.LoadUsers.totalPages,
  busy: state.LoadUsers.busy,
  users: state.LoadUsers.users,
  selectedFilter: state.LoadUsers.selectedFilter,
  filterType: state.LoadUsers.filterType,
  filterAttribute: state.LoadUsers.filterAttribute,
  filtering: state.LoadUsers.filtering,
  searching: state.LoadUsers.searching,
  search: state.LoadUsers.search
});

const MapDispatchToProps = dispatch => bindActionCreators({
  loadUsers,
  loadPageUser,
  filterUsers
}, dispatch);

ListUsers = reduxForm({
  form: 'listUsers'
})(ListUsers);

export default connect(MapStateToProps, MapDispatchToProps)(ListUsers);
