import {
  LOAD_TO_PLAY,
  LOAD_TO_PLAY_SUCCESS,
  LOAD_TO_PLAY_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadToPlay = (token) => async (dispatch) => {
  dispatch({ type: LOAD_TO_PLAY })
  try {
    const result = await api.loadToPlay(token);
    dispatch({ 
      type: LOAD_TO_PLAY_SUCCESS,
      included: result.data.included || [],
      tracks: result.data.data
    })
  } catch(err) {
    dispatch({ type: LOAD_TO_PLAY_FAILED, error: err })
  }
}
