import { connect } from 'react-redux'
import React from 'react'
import { forgetPassword } from '../../actions/forgetPasswordAction';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom'
import { InputRedux } from '../../modules/inputRedux';
import { Template, Alert, Text, Button, Container, Page, Title } from "sme-theme"

class FormForgetPass extends React.Component {
  constructor() {
    super();
    this.state = {value : 'show'}
  }

  componentDidMount() {
    localStorage.removeItem('array_errors_forget_password')
    localStorage.removeItem('forget_password_ok');
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_forget_password')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_forget_password');
    localStorage.removeItem('forget_password_ok');
  }

  render() {
    const { error, handleSubmit } = this.props;
    return (
      <Page name="login-auth" className="h-full flex-align-center flex-justify-center">
        <Container className="max-w-xs">
          <Title className="mb-sm">Informe seu Email</Title>
            <Template condition={localStorage.getItem('array_errors_forget_password') && error}>
              <Alert className="my-md" type="error" iconName="attention-circle-o">
                 Não conseguimos encontrar esse email no nosso sistema.
              </Alert>
            </Template>
            <Template condition={localStorage.getItem('forget_password_ok') === 'ok'}>
              <Alert className="my-md" type="success" iconName="check-circle-o">
                <Text>As instruções de recuperação de senha foram enviadas para o seu email.</Text>
              </Alert>
            </Template>
          <Text className="mb-lg">Enviaremos um link de recuperação de senha para seu email</Text>
          <form onSubmit={handleSubmit(forgetPassword)}>
            <InputRedux
              name="email"
              label="Email"
              type="text"
            />
            <Button className="block mt-lg mb-lg" type="submit">CONTINUAR</Button>
          </form>
          <Button className="link" plugin={Link} to="/login">Cancelar</Button>
        </Container>
      </Page>
   );
  }
}

const MapDispatchToProps = dispatch => bindActionCreators({
  forgetPassword
}, dispatch);

FormForgetPass = reduxForm({
  form: 'forgetPassword'
})(FormForgetPass);

export default connect(null, MapDispatchToProps)(FormForgetPass);
