import { connect } from 'react-redux'
import React from 'react'
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'
import {
  Template, Alert, Wrap, Text, Title, Breadcrumb, Button,
  Container, Grid, Cell, Status, Divider,
  Table, Td, Tr, Thead, Tbody, Icon, Pagination
} from "sme-theme"

import { hideAlert } from '../../actions/hideAlertAction';
import { HIDE_ALERT_CREATE_GRATIFICATION } from '../../actions/actionTypes';

import PageMenu from '../../modules/pageMenu'
import FormGratification from './formUserGratification';

import { removeGratification } from '../../actions/removeGratificationAction'
import { loadPageUserFullLog } from '../../actions/loadPageUserFullLogAction'

import { loadUser } from '../../actions/loadUserAction';
import { loadUserFullLog } from '../../actions/loadUserFullLogAction'

class PageComponent extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'users'}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    const user_id = this.props.match.params.id
    this.props.loadUser(token, user_id)
    this.props.loadUserFullLog(token, user_id)
  }

  componentWillUnmount() {
    this.props.hideAlert(HIDE_ALERT_CREATE_GRATIFICATION)
  }

  removeGratification(gratification) {
    const { id, amount } = gratification

    this.props.removeGratification(id, amount)
  }

  getType(log) {
    // const { statementable } = log
    switch (log.statementable_type) {
      case 'UserGratification':
        return 'Bônus'
      case 'CashBack':
        return 'Hits do Momento'
      case 'Quest': 
        return 'Missão' //this.handleCaseQuest(statementable)
      case 'Product':
        return 'Compra'
      case 'User':
        return 'Perfil completo'
      default:
        return log.statementable_type
    } 
  }

  handleCaseQuest(statementable) {
    const { kind } = statementable.data
    
    switch (kind) {
      case 'spotify_follow_artist':
        return 'Missão - Deezer - Seguir artista'
      case 'spotify_follow_playlist':
        return 'Missão - Deezer - Seguir playlist'
      case 'spotify_listen_track':
        return 'Missão - Deezer - Escutar faixa'
      case 'spotify_save_track':
        return 'Missão - Deezer - Salvar faixa'
      case 'spotify_save_album':
        return 'Missão - Deezer - Salvar álbum'
      case 'question':
        return 'Missão - Pergunta'
      case 'opt':
        return 'Missão - Opt Mail'
      case 'pre_save':
        return 'Missão - Pre-save'
      case 'youtube_subscribe':
        return 'Missão - Youtube - Inscrição'
      case 'youtube_watch_video':
        return 'Missão - Youtube - Assistir vídeo'
      default:
        return 'Missão'
    }
  }

  checkConditionToShowRemoveBonus(log) {
    // if(!log.statementable) return false

    // const { type } = log.statementable
    const { expiration_date } = log

    const expirationDate = new Date(expiration_date)
    const today = new Date()
    
    if(log.statementable_type === 'UserGratification' && (expirationDate > today)) return true
    return false;
  }

  loadPage = page => {
    const token = localStorage.getItem('token')
    this.props.loadPageUserFullLog(token, page, this.props.match.params.id)
  }

  getDescription(log) {
    if(!log.statementable) return null
    
    const { statementable } = log
    
    const { type } = statementable
    
    switch (log.statementable_type) {
      case 'Quest':
        return this.handleDescriptionQuest(statementable)
      case 'UserGratification':
        return '-'
      case 'CashBack':
        const { name, artists } = statementable.data
        return `${name} - ${artists}`
      case 'Product':
        return statementable.data.name || '-'
      default:
        return '-'
    }
  }

  handleDescriptionQuest(statementable) {
    const { kind } = statementable.data

    switch (kind) {
      case 'spotify_follow_artist':
        return statementable.data.name
      case 'spotify_follow_playlist':
        return statementable.data.name
      case 'spotify_listen_track':
        return statementable.data.name
      case 'spotify_save_track':
        return statementable.data.name
      case 'spotify_save_album':
        return statementable.data.name
      case 'question':
        return statementable.data.question
      case 'opt':
        return '-'
      case 'pre_save':
        return `${statementable.data.name_artist} - ${statementable.data.name_product}`
      case 'youtube_subscribe':
        return '-'
      case 'youtube_watch_video':
        return '-'
      default:
        return '-'
    }
  }

  render() {
    const user_id = this.props.match.params.id

    return (

      <PageMenu focus="users" loading={this.props.busy}>
          <Container>
            <Title className="mb-sm">Usuários</Title>

            <Template condition={this.props.showAlertGratification}>
              <Alert className="my-md" type="success">
                <Text>Bônus adicionado com sucesso!</Text>
              </Alert>
            </Template>

            <Breadcrumb>
              <Button plugin={Link} to="/users">Usuários</Button>
              <Text>{this.props.name}</Text>
            </Breadcrumb>
            <Wrap className="mt-xl">

              <Grid>
                <Cell size={3}>
                  <Text className="mb-sm muted">Nome</Text>
                  <Text className="mb-md">{this.props.name}</Text>
                </Cell>
                <Cell size={3}>
                  <Text className="mb-sm muted">Email</Text>
                  <Text className="mb-md">{this.props.email}</Text>
                </Cell>
                <Cell size={3}>
                  <Text className="mb-sm muted">Telefone</Text>
                  <Text className="mb-md">{this.props.phone}</Text>
                </Cell>
                <Cell size={3}>
                  <Text className="mb-sm muted">CPF</Text>
                  <Text className="mb-md">{this.props.cpf}</Text>
                </Cell>
                <Cell size={3}>
                  <Text className="mb-sm muted">Optin</Text>
                  <Text className="mb-md">{this.props.optIn ? <Status success>Aceito</Status> :
                  <Status error>Recusado</Status>}</Text>
                </Cell>
                <Cell size={3}>
                  <Text className="mb-sm muted">Termos e condições</Text>
                  <Text className="mb-md">{this.props.acceptTerms ? <Status success>Aceito</Status> :
                  <Status error>Recusado</Status>}</Text>
                </Cell>
                <Cell size={3}>
                  <Text className="mb-sm muted">Situação</Text>
                  <Text className="mb-md">{this.props.situation == 'active' ? <Status success>Ativo</Status> :
                  <Status error>Inativo</Status>}</Text>
                </Cell>
              </Grid>

              <Text className="mb-sm primary mt-xl">Endereço</Text>
              <Divider className="my-md" />
              <Grid>
                <Cell size={3}>
                  <Text className="mb-sm muted">Estado</Text>
                  <Text className="mb-md">{this.props.stateAcronym}</Text>
                </Cell>
                <Cell size={3}>
                  <Text className="mb-sm muted">Cidade</Text>
                  <Text className="mb-md">{this.props.city}</Text>
                </Cell>
                <Cell size={6}>
                  <Text className="mb-sm muted">CEP</Text>
                  <Text className="mb-md">{this.props.cep}</Text>
                </Cell>
                <Cell size={3}>
                  <Text className="mb-sm muted">Rua</Text>
                  <Text className="mb-md">{this.props.street}</Text>
                </Cell>
                <Cell size={3}>
                  <Text className="mb-sm muted">Número</Text>
                  <Text className="mb-md">{this.props.number}</Text>
                </Cell>
                <Cell size={3}>
                  <Text className="mb-sm muted">Bairro</Text>
                  <Text className="mb-md">{this.props.neighborhood}</Text>
                </Cell>
                <Cell size={3}>
                  <Text className="mb-sm muted">Complemento</Text>
                  <Text className="mb-md">{this.props.complement}</Text>
                </Cell>
              </Grid>

              <Text className="mb-sm primary mt-xl">Extrato</Text>
              <Divider className="my-md" />
              <Grid>
                <Cell size={12}>
                  <Text className="mb-sm muted">Filtr coins</Text>
                  <Text className="mb-xl">{this.props.totalBalance}</Text>
                </Cell>
              </Grid>
              <FormGratification user_id={user_id} />
              
              <Wrap>
              <Table>
                <Thead>
                  <Tr>
                    <Td width={30}>Tipo</Td>
                    <Td width={50}>Descrição</Td>
                    <Td>Filtr Coins</Td>
                    <Td width={"110px"}> </Td>
                  </Tr>
                </Thead>
                <Tbody>

                  {this.props.fullLog.map((log, i) => {
                    const type = log.statementable_type ?? null
                    return(
                      type &&
                      <Tr key={i}>
                        <Td>{this.getType(log)}</Td>
                        <Td>{log.description}</Td>
                        <Td>{parseInt(log.amount).toLocaleString('pt-BR')}</Td>
                        <Td>
                          {this.checkConditionToShowRemoveBonus(log) && <Button
                            className="circle m-0 error sm" 
                            title="Ver +"
                            onClick={() => this.removeGratification(log)}><Icon name="close" />
                          </Button>}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
              <Template condition={this.props.totalPages > 1}>
                <Pagination 
                  page={this.props.currentPage} 
                  size={this.props.totalPages} 
                  onChange={(i)=> this.loadPage(i)} 
                />
              </Template>
            </Wrap>
            </Wrap>

          </Container>
      </PageMenu>
   );
  }
}

const MapStateToProps = state => ({
  name: state.LoadUser.name,
  email: state.LoadUser.email,
  birthdate: state.LoadUser.birthdate,
  phone: state.LoadUser.phone,
  city: state.LoadUser.city,
  totalBalance: state.LoadUser.totalBalance,

  street: state.LoadUser.addressStreet,
  number: state.LoadUser.addressNumber,
  cep: state.LoadUser.addressCep,
  neighborhood: state.LoadUser.addressNeighborhood,
  complement: state.LoadUser.addressComplement,
  cpf: state.LoadUser.addressCpf,

  state: state.LoadUser.state,
  stateAcronym: state.LoadUser.stateAcronym,
  optIn: state.LoadUser.optIn,
  acceptTerms: state.LoadUser.acceptTerms,
  situation: state.LoadUser.situation,

  //campaigns: state.LoadUser.campaigns,

  showAlertGratification: state.LoadUserFullLog.showAlertGratification,

  busy: state.LoadUser.busy,

  fullLog: state.LoadUserFullLog.fullLog,
  totalPages: state.LoadUserFullLog.totalPages,
  currentPage: state.LoadUserFullLog.currentPage
});

const MapDispatchToProps = dispatch => bindActionCreators({
  loadUser,
  loadUserFullLog,
  hideAlert,
  removeGratification,
  loadPageUserFullLog
}, dispatch);

export default connect(MapStateToProps, MapDispatchToProps)(PageComponent);
