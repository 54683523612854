import {
  LOAD_BADGE,
  LOAD_BADGE_SUCCESS,
  LOAD_BADGE_FAILED,
  UPDATE_BADGE,
  UPDATE_BADGE_SUCCESS,
  UPDATE_BADGE_FAILED
} from '../actions/actionTypes';

const initialState = {
  name: null,
  status: false,
  voucher: null,
  goal: null,
  description: null,
  image: null,
  busy: false,
  busyButton: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BADGE:
      return {
        ...state,
        busy: true,
      }
    case LOAD_BADGE_SUCCESS:
      const { name, status, voucher, goal, description, image, uri } = action.payload.attributes
      return {
        ...state,
        name: name,
        status: status,
        voucher: voucher ? 'coupons' : 'badge',
        goal: goal,
        description: description,
        image: image,
        uri: uri,
        busy: false,
      }
    case LOAD_BADGE_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false,
      }
    case UPDATE_BADGE:
      return {
        ...state,
        busyButton: true
      }
    case UPDATE_BADGE_SUCCESS:
      return {
        ...state,
        busyButton: false
      }
    case UPDATE_BADGE_FAILED:
      return {
        ...state,
        busyButton: false,
        error: action.error
      }
    default:
      return state
  }
}
