import { connect } from 'react-redux'
import React from 'react'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { createGratification } from '../../actions/createGratificationAction';
import { Form, Wrap, Button, Icon } from "sme-theme"
import { InputRedux } from '../../modules/inputRedux';

class FormCreateGratification extends React.Component {
  componentDidUpdate() {
    localStorage.removeItem('array_errors_create_gratification')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_create_gratification');
  }

  render() {
    const { handleSubmit, dirty } = this.props;
    const user_id = this.props.user_id
    const formError = 'array_errors_create_gratification'
    return (
      <Form onSubmit={handleSubmit(createGratification(user_id))}>
        <Wrap className="max-w-xs">
          <InputRedux name="score" type="number" label="Bônus" formError={formError} />
          <InputRedux 
            name="score_confirmation"
            type="number" 
            label="Confirmar bônus"
            formError={formError}
            after={<Button className="m-0 ml-md circle" type="submit" disabled={!dirty}><Icon name="plus"/></Button>}
          />
        </Wrap>
      </Form>
    );
  }
}

const MapDispatchToProps = dispatch => bindActionCreators({
  createGratification
}, dispatch);

FormCreateGratification = reduxForm({
  form: 'formCreateGratification'
})(FormCreateGratification);

export default connect(null, MapDispatchToProps)(FormCreateGratification);


// form com validade dos pontos (futura implementação)

/* import { connect } from 'react-redux'
import React from 'react'
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { createGratification } from '../../actions/createGratificationAction';
import { Form, Text, Wrap, Button, Icon, Grid, Cell } from "sme-theme"
import { InputRedux } from '../../modules/inputRedux';

class FormCreateGratification extends React.Component {
  componentDidUpdate() {
    localStorage.removeItem('array_errors_create_gratification')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_create_gratification');
  }

  render() {
    const { handleSubmit, dirty } = this.props;
    const user_id = this.props.user_id
    const formError = 'array_errors_create_gratification'
    return (
      <Form onSubmit={handleSubmit(createGratification(user_id))}>
        <Text>Adicionar bônus</Text>
        <Grid>
          <Cell size={3}>
            <InputRedux name="score" type="number" label="Valor" formError={formError} />
          </Cell>
          <Cell size={3}>
            <InputRedux
              name="score_confirmation"
              type="number"
              label="Validade em dias"
              formError={formError}
              after={<Button className="m-0 ml-md circle" type="submit" disabled={!dirty}><Icon name="plus"/></Button>}
            />
          </Cell>
        </Grid>
        <Wrap className="max-w-xs">
        </Wrap>
      </Form>
    );
  }
}

const MapDispatchToProps = dispatch => bindActionCreators({
  createGratification
}, dispatch);

FormCreateGratification = reduxForm({
  form: 'formCreateGratification'
})(FormCreateGratification);

export default connect(null, MapDispatchToProps)(FormCreateGratification);
 */