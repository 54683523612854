import {
  LOAD_USER_TOTAL_CASHBACKS,
  LOAD_USER_TOTAL_CASHBACKS_SUCCESS,
  LOAD_USER_TOTAL_CASHBACKS_FAILED,
} from "./actionTypes";

import api from "../services/api";

export const loadTotalStreamsCashBack =
  (token, rescueId) => async (dispatch) => {
    dispatch({ type: LOAD_USER_TOTAL_CASHBACKS });
    try {
      const result = await api.loadTotalStreamsCashBack(token, rescueId);
      dispatch({
        type: LOAD_USER_TOTAL_CASHBACKS_SUCCESS,
        payload: result.data.data,
      });
    } catch (err) {
      dispatch({ type: LOAD_USER_TOTAL_CASHBACKS_FAILED, error: err });
    }
  };
