import {
  UPDATE_PLAYLIST_LIST,
  UPDATE_PLAYLIST_LIST_SUCCESS,
  UPDATE_PLAYLIST_LIST_FAILED
} from './actionTypes';

import api from '../services/api';

export const updatePlaylistList = (playlist_id) => async (dispatch) => {
  dispatch({ type: UPDATE_PLAYLIST_LIST })
  
  try {
    const token = localStorage.getItem('token')
    let result = await api.updatePlaylistList(token, playlist_id);
    result = await api.updatePlaylistList(token, playlist_id);
    dispatch({ type: UPDATE_PLAYLIST_LIST_SUCCESS, tracks: result.data.included })
  } catch(err) {
    dispatch({ type: UPDATE_PLAYLIST_LIST_FAILED })
  }
}