import { connect } from 'react-redux'
import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form';
import PageMenu from '../../modules/pageMenu'
import { loadCurrentAdmin } from '../../actions/loadCurrentAdminAction';
import { createPlaylist } from '../../actions/createPlaylistAction';
import { Wrap, Text, Title, Breadcrumb, Template, Button, Container, Alert, Icon } from "sme-theme"
import { InputRedux, ErrorField } from '../../modules/inputRedux';

class FormNewPlaylist extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'content'}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadCurrentAdmin(token);
    localStorage.removeItem('array_errors_create_playlist')
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_create_playlist')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_create_playlist')
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const { error, handleSubmit, dirty } = this.props;
    const formError = 'array_errors_create_playlist'

    return (
    <PageMenu focus="jukebox">
      <Container>
        <Title className="mb-sm">Jukebox</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/jukebox">Jukebox</Button>
          <Text>Nome da seleção</Text>
        </Breadcrumb>

        <Wrap>
          <Template condition={localStorage.getItem('array_errors_create_playlist') && error}>
            <Alert className="my-md" type="error" iconName="attention-circled">
              <Text>{this.props.error_status !== 500 && 'Alguns campos precisam da sua atenção:'}</Text>
              <ErrorField field="name" label="Nome da seleção" formError={formError} />
              <ErrorField field="uri" label="URI da playlist" formError={formError} />
              <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
            </Alert>
          </Template>

          <form onSubmit={handleSubmit(createPlaylist)} >
            <Wrap className="max-w-xs">
              <InputRedux name="status" type="switch" label="Ativar" formError={formError} />
              <InputRedux name="name" type="text" label="Nome da seleção" formError={formError} />
              <InputRedux
                name="uri"
                type="text"
                label="URI da playlist"
                formError={formError}
                after={
                  <Button
                    className="m-0 ml-md circle"
                    type="submit"
                    disabled={!dirty}
                    loading={this.props.busyButton}
                  >
                      <Icon name="plus"/>
                    </Button>
                }
               />
            </Wrap>
          </form>
        </Wrap>
      </Container>
    </PageMenu>
  );
  }
}

const mapStateToProps = state => ({
  busyButton: state.LoadPlaylists.busyButton,
  error_status: state.LoadPlaylists.error
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadCurrentAdmin,
  createPlaylist
}, dispatch);

FormNewPlaylist = reduxForm({
  form: 'formNewPlaylist',
  enableReinitialize: true
})(FormNewPlaylist);

export default connect(mapStateToProps, mapDispatchToProps)(FormNewPlaylist);
