import { connect } from 'react-redux'
import React from 'react'
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'
import { loadUser } from '../../actions/loadUserAction';
import { Text, Title, Breadcrumb, Button, Container } from "sme-theme"
import PageMenu from '../../modules/pageMenu'
import UserInfo from '../../modules/userInfo'

class PageComponent extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'users'}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    const user_id = this.props.match.params.id
    this.props.loadUser(token, user_id)
  }

  render() {
    const currentCampaignName = localStorage.getItem('current_campaign_name')
    const currentCampaignId = localStorage.getItem('current_campaign_id')
    return (

      <PageMenu focus="campaigns">
          <Container>
            <Title className="mb-sm">Campanhas</Title>
            <Breadcrumb>
              <Button plugin={Link} to="/campaigns">Campanhas</Button>
              <Button plugin={Link} to={`/campaign/${currentCampaignId}`}>{currentCampaignName}</Button>
              <Text>{this.props.name}</Text>
            </Breadcrumb>
            <Button className="mb-lg sm" plugin={Link} to={`/campaign/${currentCampaignId}`}>Voltar</Button>

            <UserInfo data={this.props}/>

          </Container>
      </PageMenu>
   );
  }
}

const MapStateToProps = state => {
  return ({
    name: state.LoadUser.name,
    email: state.LoadUser.email,
    birthdate: state.LoadUser.birthdate,
    phone: state.LoadUser.phone,
    city: state.LoadUser.cityName,
    state: state.LoadUser.state,
    stateAcronym: state.LoadUser.stateAcronym,
    campaigns: state.LoadUser.campaigns
  });
}


const MapDispatchToProps = dispatch => bindActionCreators({ loadUser }, dispatch);

export default connect(MapStateToProps, MapDispatchToProps)(PageComponent);
