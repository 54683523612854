import {
  LOAD_USER_CASHBACKS,
  LOAD_USER_CASHBACKS_SUCCESS,
  LOAD_USER_CASHBACKS_FAILED,
} from "./actionTypes";

import api from "../services/api";

export const loadUsersTotalCashbacks =
  (token, rescueId) => async (dispatch) => {
    dispatch({ type: LOAD_USER_CASHBACKS });
    try {
      const result = await api.loadUserCashbacks(token, rescueId);
      dispatch({
        type: LOAD_USER_CASHBACKS_SUCCESS,
        payload: result.data.data,
      });
    } catch (err) {
      dispatch({ type: LOAD_USER_CASHBACKS_FAILED, error: err });
    }
  };
