import {
  LOAD_JUKEBOXES,
  LOAD_JUKEBOXES_SUCCESS,
  LOAD_JUKEBOXES_FAILED,
  LOAD_JUKEBOXES_CHECK
} from './actionTypes';

import api from '../services/api';

export const loadJukeboxes = (token) => async (dispatch) => {
  dispatch({ type: LOAD_JUKEBOXES })
  try {
    const result = await api.loadJukeboxes(token);
    dispatch({ 
      type: LOAD_JUKEBOXES_SUCCESS, 
      payload: result.data.data,
      included: result.data.included
    })
    dispatch({ type: LOAD_JUKEBOXES_CHECK, payload: result.data.data })
  } catch(err) {
    dispatch({ type: LOAD_JUKEBOXES_FAILED, error: err })
  }
}
