import { connect } from 'react-redux'
import React from 'react'
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom'
import { loadUser } from '../../actions/loadUserAction';
import { loadUserFullLog } from '../../actions/loadUserFullLogAction'
import { loadPageUserFullLog } from '../../actions/loadPageUserFullLogAction'
import { hideAlert } from '../../actions/hideAlertAction';
import { HIDE_ALERT_CREATE_GRATIFICATION } from '../../actions/actionTypes';
import { Alert, Wrap, Text, Title, Template, Pagination, Breadcrumb, Button, Container, Icon, Table, Tbody, Thead, Td, Tr } from "sme-theme"
import PageMenu from '../../modules/pageMenu'
import UserInfo from '../../modules/userInfo'
import FormGratification from './formUserGratification';

class ListFullLog extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'users'}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    const user_id = this.props.match.params.id
    this.props.loadUser(token, user_id)
    this.props.loadUserFullLog(token, user_id)
  }

  loadPage = page => {
    const token = localStorage.getItem('token')
    this.props.loadPageUserFullLog(token, page, this.props.match.params.id)
  }

  dateBr = date => {
    var data = new Date(date),
    dia  = data.getDate().toString(),
    diaF = (dia.length === 1) ? '0'+dia : dia,
    mes  = (data.getMonth()+1).toString(),
    mesF = (mes.length === 1) ? '0'+mes : mes,
    anoF = data.getFullYear();
    const minutes = (data.getMinutes().toString().length === 1) ? '0'+data.getMinutes() : data.getMinutes();
    return `${diaF}/${mesF}/${anoF} ${data.getHours()}:${minutes}`
  }

  componentWillUnmount() {
    this.props.hideAlert(HIDE_ALERT_CREATE_GRATIFICATION)
  }

  render() {
    const user_id = this.props.match.params.id

    return (

      <PageMenu focus="users" loading={this.props.busy}>
          <Container>
            <Title className="mb-sm">Usuários</Title>
            <Breadcrumb>
              <Button plugin={Link} to="/users">Usuários</Button>
              <Button plugin={Link} to={`/user/${user_id}`}>{this.props.name}</Button>
              <Text>Log Completo</Text>
            </Breadcrumb>
            <Template condition={this.props.showAlertGratification}>
              <Alert className="my-md" type="success">
                <Text>Bônus adicionado com sucesso!</Text>
              </Alert>
            </Template>
            <Template condition={this.props.error_status === 500}>
              <Alert className="my-md" type="error" iconName="attention-circled">
                <Text>Erro interno no servidor, aguarde alguns minutos</Text>
              </Alert>
            </Template>
            <Wrap className="mt-xl">
              <UserInfo data={this.props}/>
              <FormGratification user_id={user_id} />
              <Table>
                <Thead>
                  <Tr>
                    <Td>Categoria <Icon name="arrow-down"/></Td>
                    <Td>Data</Td>
                    <Td>Saldo <Icon name="arrow-down"/></Td>
                    <Td>Entrada/Saída</Td>
                  </Tr>
                </Thead>
                <Tbody>

                  {this.props.fullLog.map((log, i) => {
                    return(
                      <Tr key={i}>
                        <Td>{log.attributes.statementable.type}</Td>
                        <Td>{this.dateBr(log.attributes.created_at)}</Td>
                        <Td>{parseInt(log.attributes.balance).toLocaleString('pt-BR')}</Td>
                        <Td>{parseInt(log.attributes.amount).toLocaleString('pt-BR')}</Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
              <Template condition={!this.props.busy}>
                <Template condition={this.props.totalPages > 1}>
                  <Pagination page={1} size={this.props.totalPages} onChange={(i)=> this.loadPage(i)} />
                </Template>
              </Template>
            </Wrap>
          </Container>
      </PageMenu>
   );
  }
}

const MapStateToProps = state => ({
  name: state.LoadUser.name,
  email: state.LoadUser.email,
  birthdate: state.LoadUser.birthdate,
  phone: state.LoadUser.phone,
  city: state.LoadUser.city,
  state: state.LoadUser.state,
  stateAcronym: state.LoadUser.stateAcronym,
  campaigns: state.LoadUser.campaigns,
  fullLog: state.LoadUserFullLog.fullLog,
  busy: state.LoadUserFullLog.busy,
  totalPages: state.LoadUserFullLog.totalPages,
  showAlertGratification: state.LoadUserFullLog.showAlertGratification,
  error_status: state.LoadUserFullLog.error
});

const MapDispatchToProps = dispatch => bindActionCreators({
  loadUser,
  loadUserFullLog,
  loadPageUserFullLog,
  hideAlert
}, dispatch);

ListFullLog = reduxForm({
  form: 'formlistFullLog'
})(ListFullLog)
export default connect(MapStateToProps, MapDispatchToProps)(ListFullLog);
