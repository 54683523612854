import {
  LOAD_USERS,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAILED,

  LOAD_PAGE_USER,
  LOAD_PAGE_USER_SUCCESS,
  LOAD_PAGE_USER_FAILED,

  FILTER_USERS_BY_ATTRIBUTE_SUCCESS,
  FILTER_USERS_BY_ATTRIBUTE_FAILED,
  FILTER_USERS_BY_ATTRIBUTE,

  SEARCH_USERS,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILED
} from '../actions/actionTypes';

const initialState = {
  users: [],
  busy: false,
  totalPages: 0,
  filterType: 'desc',
  filterAttribute: 'balance',
  selectedFilter: '',
  filtering: false,
  search: null,
  searching: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERS:
      return {
        ...state,
        busy: true
      }
    case LOAD_USERS_SUCCESS:
      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params.page

      return {
        ...state,
        users: action.payload,
        totalPages: parseInt(totalPages),
        busy: false
      }
    case LOAD_USERS_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case LOAD_PAGE_USER:
      return {
        ...state,
      }
    case LOAD_PAGE_USER_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }
    case LOAD_PAGE_USER_FAILED:
      return {
        ...state,
      }
    case FILTER_USERS_BY_ATTRIBUTE:
      return {
        ...state,
        busy: true,
        filtering: true
      }
    case FILTER_USERS_BY_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        users: action.payload,
        filterAttribute: action.attribute,
        selectedFilter: action.attribute,
        filterType: action.filterType === 'desc' ? 'asc' : 'desc',
        filtering: false,
        busy: false
      }
    case FILTER_USERS_BY_ATTRIBUTE_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case SEARCH_USERS:
      return {
        ...state,
        searching: true,
        busy: true
      }
    case SEARCH_USERS_SUCCESS:
      const lastLinkSearch = action.lastLink
      let paramsSearch = getParamsFromUrl(lastLinkSearch)
      paramsSearch = JSON.stringify(paramsSearch)
      paramsSearch = JSON.parse(paramsSearch)
      const totalPagesSearch = paramsSearch['page[number]']

      return {
        ...state,
        users: action.payload,
        totalPages: parseInt(totalPagesSearch),
        search: action.search,
        searching: false,
        busy: false
      }
    case SEARCH_USERS_FAILED:
      return {
        ...state,
        searching: false,
        busy: false
      }
    default:
      return state
  }
}

function getParamsFromUrl(url) {
  url = decodeURI(url);
  if (typeof url === 'string') {
      let params = url.split('?');
      let eachParamsArr = params[1].split('&');
      let obj = {};
      if (eachParamsArr && eachParamsArr.length) {
          eachParamsArr.forEach(param => {
              let keyValuePair = param.split('=')
              let key = keyValuePair[0];
              let value = keyValuePair[1];
              obj[key] = value;
          })
      }
      return obj;
  }
}
