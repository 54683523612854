import {
  SEARCH_USERS,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILED
} from './actionTypes';

import api from '../services/api';

export const searchUsers = async (search_attr, dispatch) => {
  dispatch({ type: SEARCH_USERS })
  const { search } = search_attr
  
  try {
    const token = localStorage.getItem('token')

    let result = await api.searchUsers(token, search);

    dispatch({ 
      type: SEARCH_USERS_SUCCESS, 
      payload: result.data.data,
      search,
      lastLink: result.data.links.last
    });
    
  } catch(err) {
    dispatch({ type: SEARCH_USERS_FAILED, error: err })
  }
}