import { connect } from 'react-redux'
import React from 'react'
import { reduxForm, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom'
import { loadUser } from '../../actions/loadUserAction';
import { loadOrderAddress } from '../../actions/loadOrderAddressAction';
import { updateOrderAddress } from '../../actions/updateOrderAddressAction';
import { loadStates } from '../../actions/loadStatesAction';
import { loadCities } from '../../actions/loadCitiesAction';
import PageMenu from '../../modules/pageMenu'
import { Title, Button, Container, Breadcrumb, Wrap, Form, Template, Alert, Text } from "sme-theme";
import { InputRedux, ErrorField } from '../../modules/inputRedux';

class FormEditOrderAddress extends React.Component {
  state = { stateSelected: '' }

  componentDidMount() {
    const token = localStorage.getItem('token')
    
    this.props.loadOrderAddress(token, this.props.match.params.address_id);
    this.props.loadStates(token)
    this.props.loadUser(token, this.props.match.params.user_id)
    localStorage.removeItem('array_errors_update_order_address')
    localStorage.removeItem('update_order_address_ok')
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_update_order_address')
  }

  formatCities(cities) {
    let new_cities = []
    cities.forEach(city => {
      const new_city = { value: parseInt(city.id), label: city.attributes.name }
      new_cities.push(new_city)
    });
    return new_cities
  }

  formatStates(states) {
    let new_states_format = []
    states.forEach(state => {
      const new_state = { value: parseInt(state.id), label: state.attributes.acronym }
      new_states_format.push(new_state)
    });
    return new_states_format
  }

  loadCities = (state_id) => {
    if(state_id !== this.state.stateSelected) {
      this.setState({ stateSelected: state_id })
      this.props.loadCities(state_id, localStorage.getItem('token'))
    }
  }

  render() {
    const { error, handleSubmit } = this.props;
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const formError = 'array_errors_update_order_address'

    return (
    <PageMenu focus="order-edit" loading={this.props.busy}>
      <Container>
        <Title className="flex-align-center">Pedidos</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/orders">Pedidos</Button>
          <Button plugin={Link} to={`/user/${this.props.match.params.user_id}`}>{this.props.userName}</Button>
          <Button plugin={Link} to={`/order/${this.props.match.params.order_id}/user/${this.props.match.params.user_id}`}>{this.props.match.params.order_id}</Button>
        </Breadcrumb>

        <Template condition={localStorage.getItem('array_errors_update_order_address') && error}>
          <Alert className="my-md" type="error" iconName="attention-circled">
            <Text>Alguns campos precisam da sua atenção:</Text>
            <ErrorField field="recipient" label="Destinatário" formError={formError} />
            <ErrorField field="cpf" label="CPF" formError={formError} />
            <ErrorField field="cep" label="Cep" formError={formError} />
            <ErrorField field="city" label="Cidade" formError={formError} />
            <ErrorField field="street" label="rua" formError={formError} />
            <ErrorField field="number" label="Número" formError={formError} />
            <ErrorField field="complement" label="Complemento" formError={formError} />
            <ErrorField field="neighborhood" label="Bairro" formError={formError} />
            <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
          </Alert>
        </Template>

        <Template condition={localStorage.getItem('update_order_address_ok')}>
          <Alert className="my-md" type="success">
            <Text>Endereço atualizado com sucesso !</Text>
          </Alert>
        </Template>

        <Wrap className="max-w-xs">
          <Form onSubmit={handleSubmit(updateOrderAddress(this.props.match.params.address_id))}>
            <InputRedux name="recipient" type="text" label="Destinatário" formError={formError} />
            <InputRedux name="cpf" type="text" label="CPF" formError={formError} mask="999.999.999-99"/>
            <InputRedux name="cep" type="text" mask="99999-999" label="Cep" formError={formError} />

            <InputRedux 
              name="states" 
              type="select" 
              label="Estado"
              options={this.formatStates(this.props.statesBr)} 
              formError={formError} 
            />
            
            {this.props.stateChange && (
              this.loadCities(this.props.stateChange)
            )}
            
            <InputRedux 
              name="city" 
              type="select" 
              label="Cidade"
              options={this.formatCities(this.props.cities)}
              formError={formError} 
            />

            <InputRedux name="street" type="text" label="Rua" formError={formError} />
            <InputRedux name="number" type="text" label="Número" formError={formError} />
            <InputRedux name="complement" type="text" label="Complemento" formError={formError} />
            <InputRedux name="neighborhood" type="text" label="Bairro" formError={formError} />
            <InputRedux name="reference" type="text" label="Referência" formError={formError} />

            <Button type="submit" className="block mt-xl" loading={this.props.busyButton}>
              ATUALIZAR
            </Button>
          </Form>
        </Wrap>
      </Container>
    </PageMenu>
  );
  }
}

const selector = formValueSelector('formEditOrderAddress')

const MapStateToProps = state => ({
  initialValues: {
    recipient: state.LoadOrderAddress.recipient,
    cpf: state.LoadOrderAddress.cpf,
    cep: state.LoadOrderAddress.cep,
    states: state.LoadOrderAddress.stateId,
    city: state.LoadOrderAddress.city,
    street: state.LoadOrderAddress.street,
    number: state.LoadOrderAddress.number,
    complement: state.LoadOrderAddress.complement,
    neighborhood: state.LoadOrderAddress.neighborhood,
    reference: state.LoadOrderAddress.reference
  },
  userName: state.LoadUser.name,
  stateChange: selector(state, 'states'),
  statesBr: state.LoadStates.states,
  cities: state.LoadCities.cities,
  busy: state.LoadOrderAddress.busy,
  busyButton: state.LoadOrderAddress.busyButton
})

const MapDispatchToProps = dispatch => bindActionCreators({
  loadUser,
  loadOrderAddress,
  updateOrderAddress,
  loadStates,
  loadCities
}, dispatch);

FormEditOrderAddress = reduxForm({
  form: 'formEditOrderAddress',
  enableReinitialize: true
})(FormEditOrderAddress);

export default connect(MapStateToProps, MapDispatchToProps)(FormEditOrderAddress);
