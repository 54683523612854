import {
  LOAD_CURRENT_ADMIN,
  LOAD_CURRENT_ADMIN_SUCCESS,
  LOAD_CURRENT_ADMIN_FAILED
} from './actionTypes';

import api from '../services/api';
import history from '../history';

export const loadCurrentAdmin = (token) => async (dispatch) => {
  dispatch({ type: LOAD_CURRENT_ADMIN })
  try {
    const result = await api.loadCurrentAdmin(token)
    dispatch({ type: LOAD_CURRENT_ADMIN_SUCCESS, payload: result.data })
  } catch(err) {
    if(err?.response?.status === 401) {
      dispatch({ type: LOAD_CURRENT_ADMIN_FAILED, error: err })
      localStorage.clear()
      history.push('/login')
    }
    dispatch({ type: LOAD_CURRENT_ADMIN_FAILED, error: err })
  }
}
