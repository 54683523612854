import {
  LOAD_PLAYLIST,
  LOAD_PLAYLIST_SUCCESS,
  LOAD_PLAYLIST_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPlaylist = (token, playlist_id) => async (dispatch) => {
  dispatch({ type: LOAD_PLAYLIST })
  try {
    const result = await api.loadPlaylist(token, playlist_id)
    dispatch({ 
      type: LOAD_PLAYLIST_SUCCESS, 
      payload: result.data.data,
      included: result.data.included
    })
  } catch(err) {
    dispatch({ type: LOAD_PLAYLIST_FAILED, error: err })
  }
}
