import { connect } from 'react-redux'
import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form';
import PageMenu from '../../modules/pageMenu'
import { loadPlaylist } from '../../actions/loadPlaylistAction';
import { editPlaylist } from '../../actions/editPlaylistAction';
import { updatePlaylistList } from '../../actions/updatePlaylistListAction';
import { Wrap, Text, Title, Breadcrumb, Template, Button, Container, Alert, Icon, Table } from "sme-theme"
import { InputRedux, ErrorField } from '../../modules/inputRedux';

class FormEditPlaylist extends React.Component {
  constructor() {
    super();
    this.state = {tab : 'content'}
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadPlaylist(token, this.props.match.params.id);
    localStorage.removeItem('array_errors_edit_playlist')
    localStorage.removeItem('edit_playlist_ok');
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_edit_playlist')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_edit_playlist')
    localStorage.removeItem('edit_playlist_ok');
  }

  msToMinutes(ms) {
    let seconds = ms / 1000;
    let minutes = seconds / 60;

    seconds = seconds % 60;
    minutes = minutes % 60;

    minutes = Math.trunc(minutes)
    seconds = Math.trunc(seconds).toString().length === 1 ? '0' + Math.trunc(seconds) : Math.trunc(seconds)

    return `${minutes}:${seconds}`;
  }

  refresh() {
    this.props.updatePlaylistList(this.props.match.params.id)
  }

  render() {
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const { error, handleSubmit, dirty } = this.props;
    const formError = 'array_errors_edit_playlist'

    return (
    <PageMenu focus="jukebox" loading={this.props.busy}>
      <Container>
        <Title className="mb-sm">Jukebox</Title>
        <Breadcrumb>
          <Button plugin={Link} to="/jukebox">Jukebox</Button>
          <Text>Nome da seleção</Text>
        </Breadcrumb>

        <Wrap>
          <Template condition={localStorage.getItem('array_errors_edit_playlist') && error}>
            <Alert className="my-md" title="Alguns campos precisam da sua atenção:" type="error">
              <ErrorField field="name" label="Nome da seleção" formError={formError} />
              <ErrorField field="uri" label="URI" formError={formError} />
            </Alert>
          </Template>
          <Template condition={localStorage.getItem('edit_playlist_ok') === 'ok'}>
            <Alert className="my-md" type="success">
              <Text>Playlist atualizada com sucesso !</Text>
            </Alert>
          </Template>
          <form onSubmit={handleSubmit(editPlaylist(this.props.match.params.id))} >
            <Wrap className="max-w-xs">
              <InputRedux name="status" disabled type="switch" label="Ativar" formError={formError} />
              <InputRedux name="name" type="text" label="Nome da seleção" formError={formError} />
              <InputRedux
                name="uri"
                type="text"
                label="URI da playlist"
                formError={formError}
                after={
                  <Button
                    onClick={() => this.refresh()}
                    className="m-0 ml-md circle"
                    loading={this.props.refreshing}
                    type="button"
                    behaviour="refresh"
                  >
                    <Icon name="refresh"/>
                  </Button>
                }
              />
            </Wrap>
            <Wrap className="max-w-xs">
              <Button className="block mt-lg" type="submit" loading={this.props.busyButton} disabled={!dirty}>ATUALIZAR</Button>
            </Wrap>
          </form>
          <Table className="mt-xl mb-xl">
              <thead>
                <tr>
                  <th>Música</th>
                  <th>Artista</th>
                  <th>Duração</th>
                </tr>
              </thead>
              <tbody>
                {this.props.tracks.map((track, i) => {
                  return(
                    <tr key={i}>
                      <th>{track.attributes.info.name}</th>
                      <th>{track.attributes.info.artists}</th>
                      <th>{this.msToMinutes(track.attributes.info.duration_ms)}</th>
                    </tr>
                  )
                })}

              </tbody>
           </Table>
        </Wrap>

      </Container>
    </PageMenu>
  );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    name: state.LoadPlaylist.name,
    uri: state.LoadPlaylist.uri,
    status: state.LoadPlaylist.status
  },
  tracks: state.LoadPlaylist.tracks,
  busy: state.LoadPlaylist.busy,
  refreshing: state.LoadPlaylist.refreshing,
  busyButton: state.LoadPlaylist.busyButton
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadPlaylist,
  editPlaylist,
  updatePlaylistList
}, dispatch);

FormEditPlaylist = reduxForm({
  form: 'formEditPlaylist',
  enableReinitialize: true
})(FormEditPlaylist);

export default connect(mapStateToProps, mapDispatchToProps)(FormEditPlaylist);
