import React from 'react';
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import PageMenu from '../../modules/pageMenu'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form';
import { createAdmin } from '../../actions/createAdminAction';
import { Template, Alert, Container, Title, Breadcrumb, Text, Wrap, Form, Button } from "sme-theme"
import { InputRedux, ErrorField } from '../../modules/inputRedux';

class FormCreateAdmin extends React.Component {
  constructor() {
    super();
    this.state = {}
  }

  componentDidMount() {
    localStorage.removeItem('array_errors_create_admin');
    localStorage.removeItem('error_kind_admin');
  }

  componentDidUpdate() {
    localStorage.removeItem('array_errors_create_admin')
  }

  componentWillUnmount() {
    localStorage.removeItem('array_errors_create_admin');
    localStorage.removeItem('error_kind_admin');
  }

  render(){
    if(!localStorage.getItem('token')) { return <Redirect to="/login" /> }
    const { error, handleSubmit, dirty } = this.props;
    const formError = 'array_errors_create_admin'

    return (
      <PageMenu focus="accounts">
          <Container>
            <Title className="mb-sm">Contas</Title>
            <Breadcrumb>
              <Button plugin={Link} to="/accounts">Contas</Button>
              <Text>Nova conta</Text>
            </Breadcrumb>
            <Template condition={localStorage.getItem('array_errors_create_admin') && error}>
              <Alert className="my-md" type="error" iconName="attention-circle-o">
                <Text>{this.props.error_status !== 500 && 'Alguns campos precisam da sua atenção:'}</Text>
                <ErrorField field="email" label="Email" formError={formError} />
                <ErrorField field="password" label="Senha" formError={formError} />
                <ErrorField field="password_confirmation" label="Repetir senha" formError={formError} />
                <ErrorField field="server_error" label="Erro interno no servidor, aguarde alguns minutos" formError={formError} />
              </Alert>
            </Template>
            <Template condition={localStorage.getItem('error_kind_admin')}>
              <Alert className="my-md" type="error" title="Alguns campos precisam da sua atenção:" iconName="attention-circle-o">
                <Text>Tipo de conta</Text>
              </Alert>
            </Template>
              <Wrap className="max-w-xs">
                <Form onSubmit={handleSubmit(createAdmin)}>
                  <InputRedux name="status" type='switch' label="ativar" formError={formError} />
                  <InputRedux
                    name="roles"
                    type="select"
                    options={[
                      {label: 'Admin', value: 'admin'},
                      {label: 'Master', value: 'master'},
                      {label: 'Promoter', value: 'promoter'},
                      {label: 'Logistic', value: 'logistic'}]
                    } 
                    label="Tipo de conta"
                  />
                  <InputRedux name="email" label="Email" formError={formError} />
                  <InputRedux name="password" type="password" label="Senha" formError={formError} helpText="Use oito ou mais caracteres com uma combinação de letras, números e símbolos." />
                  <InputRedux name="password_confirmation" type="password" label="Repetir senha" formError={formError} />
                  <Button className="block mt-lg" type="submit" loading={this.props.busyButton} disabled={!dirty}>ADICIONAR</Button>
                </Form>
              </Wrap>
          </Container>
      </PageMenu>
    );
  }
}

const mapStateToProps = state => ({
  busyButton: state.LoadAccount.busyButton,
  error_status: state.LoadAccount.error
});

const mapDispatchToProps = dispatch => bindActionCreators({ createAdmin }, dispatch);

FormCreateAdmin = reduxForm({
  form: 'formCreateAdmin'
})(FormCreateAdmin);

export default connect(mapStateToProps, mapDispatchToProps)(FormCreateAdmin);
