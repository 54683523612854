import api from '../services/api';
import history from '../history';

export const activateAccount = (token) => async (dispatch) => {
  try {
    await api.activateAccount(token);
    localStorage.setItem('new_password_sent', 'sent')
    history.push('/login')
  } catch(err) {
    history.push('/login')
  }
}
