import {
  LOAD_PAGE_QUEST_ACCOMPLISHMENTS_SUCCESS,
  LOAD_PAGE_QUEST_ACCOMPLISHMENTS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPageQuestAccomplishments = (token, quest_id, page) => async (dispatch) => {
  try {
    const result = await api.loadPageQuestAccomplishments(token, quest_id, page);
    dispatch({ type: LOAD_PAGE_QUEST_ACCOMPLISHMENTS_SUCCESS, payload: result.data.data })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_QUEST_ACCOMPLISHMENTS_FAILED, error: err })
  }
}
