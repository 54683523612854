import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;
const api_jukebox_url = process.env.REACT_APP_API_JUKEBOX_URL;

const url = axios.create({ baseURL: api_url });
const jukebox_url = axios.create({ baseURL: api_jukebox_url });

// headers
const defaultHeader = () => {
  return { headers: { "Content-Type": "application/json" } };
};

const customHeader = (tokenAdmin, userKind) => {
  return {
    headers: {
      Authorization: `Bearer ${tokenAdmin}`,
      "Content-Type": "application/json",
    },
  };
};

// endpoints
export default {
  login: (email, password) => {
    const data = { login: { email: email, password: password } };
    return url.post("/v1/backoffice/login", data, defaultHeader());
  },

  logout: (token_admin) => {
    return url.delete(
      "/v1/backoffice/logout",
      customHeader(token_admin, "admin")
    );
  },

  verifyCode: (pre_token, code) => {
    const data = { login: { pre_token: pre_token, code: code } };
    return url.post("/v1/backoffice/session/verify_2fa", data, defaultHeader());
  },

  sendCodeAgain: (email) => {
    const data = { email: email };
    return url.post("/v1/backoffice/admins/send_code", data, defaultHeader());
  },

  loadCurrentAdmin: (token_admin) => {
    return url.get(
      "/v1/backoffice/admins/me",
      customHeader(token_admin, "admin")
    );
  },

  loadCampaigns: (token_admin) => {
    return url.get(
      "/v1/backoffice/campaigns",
      customHeader(token_admin, "admin")
    );
  },

  loadCampaign: (token_admin, id) => {
    return url.get(
      `/v1/backoffice/campaigns/${id}`,
      customHeader(token_admin, "admin")
    );
  },

  createCampaign: (token_admin, data) => {
    return url.post(
      "/v1/backoffice/campaigns",
      data,
      customHeader(token_admin, "admin")
    );
  },

  updateCampaign: (token_admin, id_campaign, data) => {
    return url.put(
      `/v1/backoffice/campaigns/${id_campaign}`,
      data,
      customHeader(token_admin, "admin")
    );
  },

  loadUsersCampaign: (token_admin, idCampaign) => {
    return url.get(
      `/v1/backoffice/users?campaign_id=${idCampaign}&q[s]=name+asc`,
      customHeader(token_admin, "admin")
    );
  },

  loadPageUserFullLog: (token_admin, page, user_id) => {
    return url.get(
      `/v1/backoffice/statements?page=${page}&user_id=${user_id}`,
      customHeader(token_admin, "admin")
    );
  },

  loadUsers: (token_admin) => {
    return url.get(`/v1/backoffice/users`, customHeader(token_admin, "admin"));
  },

  loadPageUser: (token_admin, page, attribute, filterType, search) => {
    return url.get(
      `/v1/backoffice/users?page=${page}&q[s]=${attribute}+${filterType}${
        search ? `&query=${search}` : ""
      }`,
      customHeader(token_admin, "admin")
    );
  },

  searchUsers: (token_admin, search) => {
    return url.get(
      `/v1/backoffice/users?query=${search}`,
      customHeader(token_admin, "admin")
    );
  },

  filterList: (token_admin, list, attribute, filterType) => {
    return url.get(
      `/v1/backoffice/${list}?q[s]=${attribute}+${filterType}`,
      customHeader(token_admin, "admin")
    );
  },

  orderProducts: (token_admin, list, attribute, orderType) => {
    return url.get(
      `/v1/backoffice/store/${list}?q[s]=${attribute}+${orderType}`,
      customHeader(token_admin, "admin")
    );
  },

  loadAccounts: (token_admin) => {
    return url.get(`/v1/backoffice/admins`, customHeader(token_admin, "admin"));
  },

  loadAccount: (token_admin, admin_id) => {
    return url.get(
      `/v1/backoffice/admins/${admin_id}`,
      customHeader(token_admin, "admin")
    );
  },

  loadPageAccount: (token_admin, page) => {
    return url.get(
      `/v1/backoffice/admins?page=${page}`,
      customHeader(token_admin, "admin")
    );
  },

  loadUser: (token_admin, user_id) => {
    return url.get(
      `/v1/backoffice/users/${user_id}`,
      customHeader(token_admin, "admin")
    );
  },

  loadUserFullLog: (token_admin, user_id) => {
    return url.get(
      `/v1/backoffice/statements?user_id=${user_id}`,
      customHeader(token_admin, "admin")
    );
  },

  loadPageCampaign: (token_admin, page, attribute, filterType) => {
    return url.get(
      `/v1/backoffice/campaigns?page=${page}`,
      customHeader(token_admin, "admin")
    );
  },

  loadPageRescue: (token_admin, page, attribute, orderType) => {
    return url.get(
      `/v1/backoffice/rescues?page=${page}&q[s]=${attribute}+${orderType}`,
      customHeader(token_admin, "admin")
    );
  },

  createRescue: (token_admin, data) => {
    return url.post(
      "/v1/backoffice/rescues",
      data,
      customHeader(token_admin, "admin")
    );
  },

  updateRescue: (token_admin, id_rescue, data) => {
    return url.put(
      `/v1/backoffice/rescues/${id_rescue}`,
      data,
      customHeader(token_admin, "admin")
    );
  },

  loadRescues: (token_admin) => {
    return url.get(
      "/v1/backoffice/rescues",
      customHeader(token_admin, "admin")
    );
  },

  loadTotalStreamsCashBack: (token_admin, id_rescue) => {
    return url.get(
      `/v1/backoffice/rescues/${id_rescue}/total_streams_cash_backs`,
      customHeader(token_admin, "admin")
    );
  },

  loadUserCashbacks: (token_admin, id_rescue) => {
    return url.get(
      `/v1/backoffice/rescues/${id_rescue}/total_users_cash_backs`,
      customHeader(token_admin, "admin")
    );
  },

  loadRescue: (token_admin, id_rescue) => {
    return url.get(
      `/v1/backoffice/rescues/${id_rescue}`,
      customHeader(token_admin, "admin")
    );
  },

  deleteRescue: (token_admin, id_rescue) => {
    return url.delete(
      `/v1/backoffice/rescues/${id_rescue}`,
      customHeader(token_admin, "admin")
    );
  },

  loadRescueAnalytics: (token_admin, id_rescue) => {
    return url.get(
      `/v1/backoffice/rescues/${id_rescue}/cash_backs?page=1`,
      customHeader(token_admin, "admin")
    );
  },

  loadPageRescueAnalytics: (token_admin, id_rescue, page) => {
    return url.get(
      `/v1/backoffice/rescues/${id_rescue}/cash_backs?page=${page}`,
      customHeader(token_admin, "admin")
    );
  },

  loadRescueUsers: (token_admin, id_rescue) => {
    return url.get(
      `/v1/backoffice/rescues/${id_rescue}/users`,
      customHeader(token_admin, "admin")
    );
  },

  downloadRescueUsers: (token_admin, id_rescue) => {
    return url.post(
      `/v1/backoffice/rescues/${id_rescue}/users`,
      "",
      customHeader(token_admin, "admin")
    );
  },

  loadPageRescueUsers: (token_admin, id_rescue, page) => {
    return url.get(
      `/v1/backoffice/rescues/${id_rescue}/users?page=${page}`,
      customHeader(token_admin, "admin")
    );
  },

  loadPageQuest: (token_admin, page) => {
    return url.get(
      `/v1/backoffice/quests?page=${page}`,
      customHeader(token_admin, "admin")
    );
  },

  loadQuests: (token_admin) => {
    return url.get("/v1/backoffice/quests", customHeader(token_admin, "admin"));
  },

  createQuest: (token_admin, data) => {
    return url.post(
      "/v1/backoffice/quests",
      data,
      customHeader(token_admin, "admin")
    );
  },

  loadQuest: (token_admin, quest_id) => {
    return url.get(
      `/v1/backoffice/quests/${quest_id}`,
      customHeader(token_admin, "admin")
    );
  },

  loadQuestAccomplishments: (token_admin, quest_id) => {
    return url.get(
      `/v1/backoffice/quests/${quest_id}/accomplished?page[size]=10`,
      customHeader(token_admin, "admin")
    );
  },

  loadPageQuestAccomplishments: (token_admin, quest_id, page) => {
    return url.get(
      `/v1/backoffice/quests/${quest_id}/accomplished?page[number]=${page}&page[size]=10`,
      customHeader(token_admin, "admin")
    );
  },

  downloadUsersInQuest: (token_admin, quest_id) => {
    return url.post(
      `/v1/backoffice/quests/${quest_id}/users`,
      "",
      customHeader(token_admin, "admin")
    );
  },

  updateQuest: (token_admin, quest_id, data) => {
    return url.put(
      `/v1/backoffice/quests/${quest_id}`,
      data,
      customHeader(token_admin, "admin")
    );
  },

  createAdmin: (token_admin, data) => {
    return url.post(
      "/v1/backoffice/admins",
      data,
      customHeader(token_admin, "admin")
    );
  },

  updateAdmin: (token_admin, id_admin, data) => {
    return url.put(
      `/v1/backoffice/admins/${id_admin}`,
      data,
      customHeader(token_admin, "admin")
    );
  },

  deleteAdmin: (token_admin, id_admin) => {
    return url.delete(
      `/v1/backoffice/admins/${id_admin}`,
      customHeader(token_admin, "admin")
    );
  },

  forgetPassword: (data) => {
    return url.post(
      "/v1/backoffice/admins/forget_password",
      data,
      defaultHeader()
    );
  },

  activateAccount: (token) => {
    return url.get(`/v1/backoffice/admins/activate/${token}`, defaultHeader());
  },

  loadSettings: (token_admin) => {
    return url.get(
      "/v1/backoffice/settings",
      customHeader(token_admin, "admin")
    );
  },

  updateSettings: (token_admin, data) => {
    return url.put(
      "/v1/backoffice/settings",
      data,
      customHeader(token_admin, "admin")
    );
  },

  profileAdminUpdate: (token_admin, data) => {
    return url.put(
      "/v1/backoffice/profile",
      data,
      customHeader(token_admin, "admin")
    );
  },

  loadProducts: (token_admin) => {
    return url.get(
      "/v1/backoffice/store/products",
      customHeader(token_admin, "admin")
    );
  },

  loadProduct: (token_admin, product_id) => {
    return url.get(
      `/v1/backoffice/store/products/${product_id}`,
      customHeader(token_admin, "admin")
    );
  },

  loadPageProduct: (token_admin, page, attribute, orderType) => {
    return url.get(
      `/v1/backoffice/store/products?page=${page}&q[s]=${attribute}+${orderType}`,
      customHeader(token_admin, "admin")
    );
  },

  createProduct: (token_admin, data) => {
    return url.post(
      "/v1/backoffice/store/products",
      data,
      customHeader(token_admin, "admin")
    );
  },

  updateProduct: (token_admin, product_id, data) => {
    return url.put(
      `/v1/backoffice/store/products/${product_id}`,
      data,
      customHeader(token_admin, "admin")
    );
  },

  loadOrders: (token_admin) => {
    return url.get(
      "/v1/backoffice/store/orders",
      customHeader(token_admin, "admin")
    );
  },

  loadOrder: (token_admin, order_id) => {
    return url.get(
      `/v1/backoffice/store/orders/${order_id}`,
      customHeader(token_admin, "admin")
    );
  },

  loadPageOrder: (token_admin, page, search) => {
    return url.get(
      `/v1/backoffice/store/orders/users/list?page=${page}${
        search ? `&query=${search}` : ""
      }`,
      customHeader(token_admin, "admin")
    );
  },

  updateOrder: (token_admin, order_id, data) => {
    return url.put(
      `/v1/backoffice/store/orders/${order_id}`,
      data,
      customHeader(token_admin, "admin")
    );
  },

  createComment: (token_admin, data) => {
    return url.post(
      "/v1/backoffice/comments",
      data,
      customHeader(token_admin, "admin")
    );
  },

  loadComments: (token_admin, commentable_type, commentable_id) => {
    return url.get(
      `/v1/backoffice/comments?commentable_type=${commentable_type}&commentable_id=${commentable_id}`,
      customHeader(token_admin, "admin")
    );
  },

  loadComment: (token_admin, comment_id) => {
    return url.get(
      `/v1/backoffice/comments/${comment_id}`,
      customHeader(token_admin, "admin")
    );
  },

  loadPageOrderComments: (
    token_admin,
    page,
    commentable_type,
    commentable_id
  ) => {
    return url.get(
      `/v1/backoffice/comments?commentable_id=${commentable_id}&commentable_type=${commentable_type}&page=${page}&page%5Bsize%5D=20`,
      customHeader(token_admin, "admin")
    );
  },

  createGratification: (token_admin, data) => {
    return url.post(
      "/v1/backoffice/gratifications",
      data,
      customHeader(token_admin, "admin")
    );
  },

  removeGratification: (token_admin, gratification_id) => {
    return url.delete(
      `/v1/backoffice/gratifications/${gratification_id}`,
      customHeader(token_admin, "admin")
    );
  },

  validateCoupon: (token_admin, token_coupon) => {
    return url.put(
      `/v1/backoffice/store/orders/validate_coupon/${token_coupon}`,
      "",
      customHeader(token_admin, "admin")
    );
  },

  createPlaylist: (token_admin, data) => {
    return jukebox_url.post(
      `/v1/backoffice/playlists`,
      data,
      customHeader(token_admin, "admin")
    );
  },

  loadPlaylists: (token_admin) => {
    return jukebox_url.get(
      "/v1/backoffice/playlists",
      customHeader(token_admin, "admin")
    );
  },

  loadPlaylist: (token_admin, playlist_id) => {
    return jukebox_url.get(
      `/v1/backoffice/playlists/${playlist_id}`,
      customHeader(token_admin, "admin")
    );
  },

  updatePlaylist: (token_admin, playlist_id, data) => {
    return jukebox_url.put(
      `/v1/backoffice/playlists/${playlist_id}`,
      data,
      customHeader(token_admin, "admin")
    );
  },

  updatePlaylistList: (token_admin, playlist_id) => {
    return jukebox_url.put(
      `/v1/backoffice/playlists/update_playlist/${playlist_id}`,
      "",
      customHeader(token_admin, "admin")
    );
  },

  loadPagePlaylist: (token_admin, page) => {
    return jukebox_url.get(
      `/v1/backoffice/playlists?page=${page}&page%5Bsize%5D=20`,
      customHeader(token_admin, "admin")
    );
  },

  loadJukeboxes: (token_admin) => {
    return jukebox_url.get(
      "/v1/app/jukeboxes",
      customHeader(token_admin, "admin")
    );
  },

  loadToPlay: (token_admin) => {
    return jukebox_url.get(
      "/v1/backoffice/jukeboxes?page%5Bsize%5D=200",
      customHeader(token_admin, "admin")
    );
  },

  playStopJukebox: (token_admin, user_action) => {
    return jukebox_url.put(
      `/v1/backoffice/jukeboxes?user_action=${user_action}`,
      "",
      customHeader(token_admin, "admin")
    );
  },

  removeJukeboxUserTrack: (token_admin, track_id) => {
    return jukebox_url.delete(
      `/v1/backoffice/jukeboxes/${track_id}`,
      customHeader(token_admin, "admin")
    );
  },

  loadBadges: (token_admin) => {
    return url.get(
      "/v1/backoffice/badge_challenges",
      customHeader(token_admin, "admin")
    );
  },

  createBadge: (token_admin, data) => {
    return url.post(
      "/v1/backoffice/badge_challenges",
      data,
      customHeader(token_admin, "admin")
    );
  },

  updateBadge: (token_admin, badge_id, data) => {
    return url.put(
      `/v1/backoffice/badge_challenges/${badge_id}`,
      data,
      customHeader(token_admin, "admin")
    );
  },

  loadBadge: (token_admin, badge_id) => {
    return url.get(
      `/v1/backoffice/badge_challenges/${badge_id}`,
      customHeader(token_admin, "admin")
    );
  },

  loadBadgeUsers: (token_admin, badge_id) => {
    return url.get(
      `/v1/backoffice/badge_challenges/${badge_id}/users`,
      customHeader(token_admin, "admin")
    );
  },

  loadPageBadge: (token_admin, page, attribute, orderType) => {
    return url.get(
      `/v1/backoffice/badge_challenges?page=${page}&page%5Bsize%5D=20&q[s]=${attribute}+${orderType}`,
      customHeader(token_admin, "admin")
    );
  },

  loadPageBadgeUsers: (token_admin, badge_id, page, attribute, orderType) => {
    return url.get(
      `/v1/backoffice/badge_challenges/${badge_id}/users?page=${page}&page%5Bsize%5D=20&q[s]=${attribute}+${orderType}`,
      customHeader(token_admin, "admin")
    );
  },

  orderBadgeUsers: (token_admin, badge_id, attribute, orderType) => {
    return url.get(
      `/v1/backoffice/badge_challenges/${badge_id}/users?q[s]=${attribute}+${orderType}`,
      customHeader(token_admin, "admin")
    );
  },

  searchOrders: (token_admin, to_search) => {
    return url.get(
      `/v1/backoffice/store/orders/users/list?query=${to_search}`,
      customHeader(token_admin, "admin")
    );
  },

  loadUsersWithOrder: (token_admin) => {
    return url.get(
      "/v1/backoffice/store/orders/users/list",
      customHeader(token_admin, "admin")
    );
  },

  loadOrdersOfUser: (token_admin, user_id) => {
    return url.get(
      `/v1/backoffice/store/orders/users/${user_id}`,
      customHeader(token_admin, "admin")
    );
  },

  loadOrderAddress: (token_admin, order_id) => {
    return url.get(
      `/v1/backoffice/store/orders/addresses/${order_id}`,
      customHeader(token_admin, "admin")
    );
  },

  updateOrderAddress: (token_admin, address_id, data) => {
    return url.put(
      `/v1/backoffice/store/orders/addresses/${address_id}`,
      data,
      customHeader(token_admin, "admin")
    );
  },

  loadStates: (token_admin) => {
    return url.get(
      "/v1/app/locations/states",
      customHeader(token_admin, "admin")
    );
  },

  loadCities: (state_id, token_admin) => {
    return url.get(
      `/v1/app/locations/state/${state_id}/cities`,
      customHeader(token_admin, "admin")
    );
  },
};
