import {
  LOAD_PRODUCT,
  LOAD_PRODUCT_SUCCESS,
  LOAD_PRODUCT_FAILED,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILED
} from '../actions/actionTypes';

const initialState = {
  status: '',
  image: '',
  kind: '',
  name: '',
  description: '',
  value: null,
  dateStart: '',
  dateFinish: '',
  quantity: '',
  busy: false,
  busyButton: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PRODUCT:
      return {
        ...state,
        busy: true
      }
    case LOAD_PRODUCT_SUCCESS:
    const { status, image, name, value, date_start, date_finish, quantity, kind, description } = action.payload

      return {
        ...state,
        status: status,
        image: image,
        kind: kind,
        name: name,
        description: description,
        value: value,
        date_start: dateBr(date_start),
        date_finish: dateBr(date_finish),
        quantity: quantity,
        busy: false
      }
    case LOAD_PRODUCT_FAILED:
      return {
        ...state,
        busy: false
      }
    case UPDATE_PRODUCT:
      return {
        ...state,
        busyButton: true
      }
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        busyButton: false
      }
    case UPDATE_PRODUCT_FAILED:
      return {
        ...state,
        busyButton: false,
        error: action.error
      }
    default:
      return state
  }
}

function dateBr(date) {
  var data = new Date(date),
  dia  = data.getDate().toString(),
  diaF = (dia.length === 1) ? '0'+dia : dia,
  mes  = (data.getMonth()+1).toString(),
  mesF = (mes.length === 1) ? '0'+mes : mes,
  anoF = data.getFullYear();
  const minutes = (data.getMinutes().toString().length === 1) ? '0'+data.getMinutes() : data.getMinutes();
  const hours = (data.getHours().toString().length === 1) ? '0'+data.getHours() : data.getHours();
  return `${diaF}/${mesF}/${anoF} ${hours}:${minutes}`
}
