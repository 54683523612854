import {
  LOAD_PAGE_USER_BADGES_SUCCESS,
  LOAD_PAGE_USER_BADGES_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPageBadgeUsers = (token, badge_id, page, orderAttribute, orderType) => async (dispatch) => {
  try {
    const result = await api.loadPageBadgeUsers(token, badge_id, page, orderAttribute, orderType);
    dispatch({ 
      type: LOAD_PAGE_USER_BADGES_SUCCESS, 
      payload: result.data.data,
      included: result.data.included
    })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_USER_BADGES_FAILED, error: err })
  }
}
