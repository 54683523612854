export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILED = "LOGIN_ADMIN_FAILED";

export const VERIFY_CODE_SUCCESS = "VERIFY_CODE_SUCCESS";
export const VERIFY_CODE_FAILED = "VERIFY_CODE_FAILED";

export const LOAD_CURRENT_ADMIN = "LOAD_CURRENT_ADMIN";
export const LOAD_CURRENT_ADMIN_SUCCESS = "LOAD_CURRENT_ADMIN_SUCCESS";
export const LOAD_CURRENT_ADMIN_FAILED = "LOAD_CURRENT_ADMIN_FAILED";

export const LOAD_ACCOUNTS = "LOAD_ACCOUNTS";
export const LOAD_ACCOUNTS_SUCCESS = "LOAD_ACCOUNTS_SUCCESS";
export const LOAD_ACCOUNTS_FAILED = "LOAD_ACCOUNTS_FAILED";

export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAILED = "CREATE_ACCOUNT_FAILED";

export const LOAD_ACCOUNT = "LOAD_ACCOUNT";
export const LOAD_ACCOUNT_SUCCESS = "LOAD_ACCOUNT_SUCCESS";
export const LOAD_ACCOUNT_FAILED = "LOAD_ACCOUNT_FAILED";

export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const EDIT_ACCOUNT_SUCCESS = "EDIT_ACCOUNT_SUCCESS";
export const EDIT_ACCOUNT_FAILED = "EDIT_ACCOUNT_FAILED";

export const LOAD_PAGE_ACCOUNT_SUCCESS = "LOAD_PAGE_ACCOUNT_SUCCESS";
export const LOAD_PAGE_ACCOUNT_FAILED = "LOAD_PAGE_ACCOUNT_FAILED";

export const SEND_CODE_AGAIN_SUCCESS = "SEND_CODE_AGAIN_SUCCESS";
export const SEND_CODE_AGAIN_FAILED = "SEND_CODE_AGAIN_FAILED";

export const LOAD_CAMPAIGNS = "LOAD_CAMPAIGNS";
export const LOAD_CAMPAIGNS_SUCCESS = "LOAD_CAMPAIGNS_SUCCESS";
export const LOAD_CAMPAIGNS_FAILED = "LOAD_CAMPAIGNS_FAILED";

export const FILTER_CAMPAIGNS_BY_ATTRIBUTE = "FILTER_CAMPAIGNS_BY_ATTRIBUTE";
export const FILTER_CAMPAIGNS_BY_ATTRIBUTE_SUCCESS =
  "FILTER_CAMPAIGNS_BY_ATTRIBUTE_SUCCESS";
export const FILTER_CAMPAIGNS_BY_ATTRIBUTE_FAILED =
  "FILTER_CAMPAIGNS_BY_ATTRIBUTE_FAILED";

export const LOAD_SETTINGS = "LOAD_SETTINGS";
export const LOAD_SETTINGS_SUCCESS = "LOAD_SETTINGS_SUCCESS";
export const LOAD_SETTINGS_FAILED = "LOAD_SETTINGS_FAILED";

export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAILED = "UPDATE_SETTINGS_FAILED";

export const LOAD_CAMPAIGN_CONTENT = "LOAD_CAMPAIGN_CONTENT";
export const LOAD_CAMPAIGN_CONTENT_SUCCESS = "LOAD_CAMPAIGN_CONTENT_SUCCESS";
export const LOAD_CAMPAIGN_CONTENT_FAILED = "LOAD_CAMPAIGN_CONTENT_FAILED";
export const SELECT_CAMPAIGN_TAB = "SELECT_CAMPAIGN_TAB";

export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILED = "CREATE_CAMPAIGN_FAILED";

export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAILED = "UPDATE_CAMPAIGN_FAILED";

export const LOAD_USERS_CAMPAIGN_SUCCESS = "LOAD_USERS_CAMPAIGN_SUCCESS";
export const LOAD_USERS_CAMPAIGN_FAILED = "LOAD_USERS_CAMPAIGN_FAILED";

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_FAILED = "LOAD_USERS_FAILED";

export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAILED = "SEARCH_USERS_FAILED";

export const FILTER_USERS_BY_ATTRIBUTE = "FILTER_USERS_BY_ATTRIBUTE";
export const FILTER_USERS_BY_ATTRIBUTE_SUCCESS =
  "FILTER_USERS_BY_ATTRIBUTE_SUCCESS";
export const FILTER_USERS_BY_ATTRIBUTE_FAILED =
  "FILTER_USERS_BY_ATTRIBUTE_FAILED";

export const LOAD_USER = "LOAD_USER";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAILED = "LOAD_USER_FAILED";

export const LOAD_PAGE_USER = "LOAD_PAGE_USER";
export const LOAD_PAGE_USER_SUCCESS = "LOAD_PAGE_USER_SUCCESS";
export const LOAD_PAGE_USER_FAILED = "LOAD_PAGE_USER_FAILED";

export const LOAD_PAGE_USER_FULL_LOG_SUCCESS =
  "LOAD_PAGE_USER_FULL_LOG_SUCCESS";
export const LOAD_PAGE_USER_FULL_LOG_FAILED = "LOAD_PAGE_USER_FULL_LOG_FAILED";

export const LOAD_USER_FULL_LOG = "LOAD_USER_FULL_LOG";
export const LOAD_USER_FULL_LOG_SUCCESS = "LOAD_USER_FULL_LOG_SUCCESS";
export const LOAD_USER_FULL_LOG_FAILED = "LOAD_USER_FULL_LOG_FAILED";

export const LOAD_PAGE_CAMPAIGN_SUCCESS = "LOAD_PAGE_CAMPAIGN_SUCCESS";
export const LOAD_PAGE_CAMPAIGN_FAILED = "LOAD_PAGE_CAMPAIGN_FAILED";

export const LOAD_PAGE_RESCUE_SUCCESS = "LOAD_PAGE_RESCUE_SUCCESS";
export const LOAD_PAGE_RESCUE_FAILED = "LOAD_PAGE_RESCUE_FAILED";

export const LOAD_PAGE_QUEST_SUCCESS = "LOAD_PAGE_QUEST_SUCCESS";
export const LOAD_PAGE_QUEST_FAILED = "LOAD_PAGE_QUEST_FAILED";
export const SELECT_KIND_QUEST = "SELECT_KIND_QUEST";

export const CREATE_QUEST = "CREATE_QUEST";
export const CREATE_QUEST_SUCCESS = "CREATE_QUEST_SUCCESS";
export const CREATE_QUEST_FAILED = "CREATE_QUEST_FAILED";

export const EDIT_QUEST = "EDIT_QUEST";
export const EDIT_QUEST_SUCCESS = "EDIT_QUEST_SUCCESS";
export const EDIT_QUEST_FAILED = "EDIT_QUEST_FAILED";

export const LOAD_QUEST = "LOAD_QUEST";
export const LOAD_QUEST_SUCCESS = "LOAD_QUEST_SUCCESS";
export const LOAD_QUEST_FAILED = "LOAD_QUEST_FAILED";

export const UPDATE_PROFILE_ADMIN = "UPDATE_PROFILE_ADMIN";
export const UPDATE_PROFILE_ADMIN_SUCCESS = "UPDATE_PROFILE_ADMIN_SUCCESS";
export const UPDATE_PROFILE_ADMIN_FAILED = "UPDATE_PROFILE_ADMIN_FAILED";

export const CREATE_RESCUE = "CREATE_RESCUE";
export const CREATE_RESCUE_SUCCESS = "CREATE_RESCUE_SUCCESS";
export const CREATE_RESCUE_FAILED = "CREATE_RESCUE_FAILED";

export const EDIT_RESCUE = "EDIT_RESCUE";
export const EDIT_RESCUE_SUCCESS = "EDIT_RESCUE_SUCCESS";
export const EDIT_RESCUE_FAILED = "EDIT_RESCUE_FAILED";

export const LOAD_RESCUE = "LOAD_RESCUE";
export const LOAD_RESCUE_SUCCESS = "LOAD_RESCUE_SUCCESS";
export const LOAD_RESCUE_FAILED = "LOAD_RESCUE_FAILED";

export const LOAD_RESCUE_ANALYTICS = "LOAD_RESCUE_ANALYTICS";
export const LOAD_RESCUE_ANALYTICS_SUCCESS = "LOAD_RESCUE_ANALYTICS_SUCCESS";
export const LOAD_RESCUE_ANALYTICS_FAILED = "LOAD_RESCUE_ANALYTICS_FAILED";

export const LOAD_PAGE_RESCUE_ANALYTICS_SUCCESS =
  "LOAD_PAGE_RESCUE_ANALYTICS_SUCCESS";
export const LOAD_PAGE_RESCUE_ANALYTICS_FAILED =
  "LOAD_PAGE_RESCUE_ANALYTICS_FAILED";

export const DOWNLOAD_RESCUE_USERS_SUCCESS = "DOWNLOAD_RESCUE_USERS_SUCCESS";
export const DOWNLOAD_RESCUE_USERS_FAILED = "DOWNLOAD_RESCUE_USERS_FAILED";
export const RESET_DOWNLOAD_RESCUE_USERS_ALERT =
  "RESET_DOWNLOAD_RESCUE_USERS_ALERT";

export const LOAD_RESCUE_USERS = "LOAD_RESCUE_USERS";
export const LOAD_RESCUE_USERS_SUCCESS = "LOAD_RESCUE_USERS_SUCCESS";
export const LOAD_RESCUE_USERS_FAILED = "LOAD_RESCUE_USERS_FAILED";

export const LOAD_PAGE_RESCUE_USERS_SUCCESS = "LOAD_PAGE_RESCUE_USERS_SUCCESS";
export const LOAD_PAGE_RESCUE_USERS_FAILED = "LOAD_PAGE_RESCUE_USERS_FAILED";

export const ORDER_RESCUES_BY_ATTRIBUTE = "ORDER_RESCUES_BY_ATTRIBUTE";
export const ORDER_RESCUES_BY_ATTRIBUTE_SUCCESS =
  "ORDER_RESCUES_BY_ATTRIBUTE_SUCCESS";
export const ORDER_RESCUES_BY_ATTRIBUTE_FAILED =
  "ORDER_RESCUES_BY_ATTRIBUTE_FAILED";

export const LOAD_QUESTS = "LOAD_QUESTS";
export const LOAD_QUESTS_SUCCESS = "LOAD_QUESTS_SUCCESS";
export const LOAD_QUESTS_FAILED = "LOAD_QUESTS_FAILED";
export const SHOW_KIND_SPOTIFY = "SHOW_KIND_SPOTIFY";

export const LOAD_QUEST_ACCOMPLISHMENTS = "LOAD_QUEST_ACCOMPLISHMENTS";
export const LOAD_QUEST_ACCOMPLISHMENTS_SUCCESS = "LOAD_QUEST_ACCOMPLISHMENTS_SUCCESS";
export const LOAD_QUEST_ACCOMPLISHMENTS_FAILED = "LOAD_QUEST_ACCOMPLISHMENTS_FAILED";

export const LOAD_PAGE_QUEST_ACCOMPLISHMENTS_SUCCESS =
  "LOAD_PAGE_QUEST_ACCOMPLISHMENTS_SUCCESS";
export const LOAD_PAGE_QUEST_ACCOMPLISHMENTS_FAILED =
  "LOAD_PAGE_QUEST_ACCOMPLISHMENTS_FAILED";

export const LOAD_RESCUES = "LOAD_RESCUES";
export const LOAD_RESCUES_SUCCESS = "LOAD_RESCUES_SUCCESS";
export const LOAD_RESCUES_FAILED = "LOAD_RESCUES_FAILED";

export const DELETE_RESCUE_SUCCESS = "DELETE_RESCUE_SUCCESS";
export const DELETE_RESCUE_FAILED = "DELETE_RESCUE_FAILED";

export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const LOAD_PRODUCTS_SUCCESS = "LOAD_PRODUCTS_SUCCESS";
export const LOAD_PRODUCTS_FAILED = "LOAD_PRODUCTS_FAILED";

export const LOAD_PRODUCT = "LOAD_PRODUCT";
export const LOAD_PRODUCT_SUCCESS = "LOAD_PRODUCT_SUCCESS";
export const LOAD_PRODUCT_FAILED = "LOAD_PRODUCT_FAILED";

export const ORDER_PRODUCTS_BY_ATTRIBUTE = "ORDER_PRODUCTS_BY_ATTRIBUTE";
export const ORDER_PRODUCTS_BY_ATTRIBUTE_SUCCESS =
  "ORDER_PRODUCTS_BY_ATTRIBUTE_SUCCESS";
export const ORDER_PRODUCTS_BY_ATTRIBUTE_FAILED =
  "ORDER_PRODUCTS_BY_ATTRIBUTE_FAILED";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILED = "CREATE_PRODUCT_FAILED";

export const LOAD_ORDER_COMMENTS_SUCCESS = "LOAD_ORDER_COMMENTS_SUCCESS";
export const LOAD_ORDER_COMMENTS_FAILED = "LOAD_ORDER_COMMENTS_FAILED";

export const LOAD_ORDER_COMMENT = "LOAD_ORDER_COMMENT";
export const LOAD_ORDER_COMMENT_SUCCESS = "LOAD_ORDER_COMMENT_SUCCESS";
export const LOAD_ORDER_COMMENT_FAILED = "LOAD_ORDER_COMMENT_FAILED";

export const CREATE_ORDER_COMMENT = "CREATE_ORDER_COMMENT";
export const CREATE_ORDER_COMMENT_SUCCESS = "CREATE_ORDER_COMMENT_SUCCESS";
export const CREATE_ORDER_COMMENT_FAILED = "CREATE_ORDER_COMMENT_FAILED";

export const LOAD_PAGE_ORDER_COMMENTS_SUCCESS =
  "LOAD_PAGE_ORDER_COMMENTS_SUCCESS";
export const LOAD_PAGE_ORDER_COMMENTS_FAILED =
  "LOAD_PAGE_ORDER_COMMENTS_FAILED";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILED = "UPDATE_PRODUCT_FAILED";

export const LOAD_PAGE_PRODUCT_SUCCESS = "LOAD_PAGE_PRODUCT_SUCCESS";
export const LOAD_PAGE_PRODUCT_FAILED = "LOAD_PAGE_PRODUCT_FAILED";

export const LOAD_ORDERS = "LOAD_ORDERS";
export const LOAD_ORDERS_SUCCESS = "LOAD_ORDERS_SUCCESS";
export const LOAD_ORDERS_FAILED = "LOAD_ORDERS_FAILED";

export const LOAD_ORDER = "LOAD_ORDER";
export const LOAD_ORDER_SUCCESS = "LOAD_ORDER_SUCCESS";
export const LOAD_ORDER_FAILED = "LOAD_ORDER_FAILED";

export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILED = "CREATE_ORDER_FAILED";

export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILED = "UPDATE_ORDER_FAILED";

export const LOAD_PAGE_ORDER_SUCCESS = "LOAD_PAGE_ORDER_SUCCESS";
export const LOAD_PAGE_ORDER_FAILED = "LOAD_PAGE_ORDER_FAILED";

export const HIDE_ALERT_EDIT_ORDER = "HIDE_ALERT_EDIT_ORDER";
export const HIDE_ALERT_CREATE_CAMPAIGN = "HIDE_ALERT_CREATE_CAMPAIGN";
export const HIDE_ALERT_CREATE_ACCOUNT = "HIDE_ALERT_CREATE_ACCOUNT";
export const HIDE_ALERT_CREATE_PRODUCT = "HIDE_ALERT_CREATE_PRODUCT";
export const HIDE_ALERT_CREATE_RESCUE = "HIDE_ALERT_CREATE_RESCUE";
export const HIDE_ALERT_CREATE_QUEST = "HIDE_ALERT_CREATE_QUEST";
export const HIDE_ALERT_CREATE_PLAYLIST = "HIDE_ALERT_CREATE_PLAYLIST";
export const HIDE_ALERT_CREATE_BADGE = "HIDE_ALERT_CREATE_BADGE";

export const CREATE_GRATIFICATION = "CREATE_GRATIFICATION";
export const CREATE_GRATIFICATION_SUCCESS = "CREATE_GRATIFICATION_SUCCESS";
export const CREATE_GRATIFICATION_FAILED = "CREATE_GRATIFICATION_FAILED";

export const REMOVE_GRATIFICATION = "REMOVE_GRATIFICATION";
export const REMOVE_GRATIFICATION_SUCCESS = "REMOVE_GRATIFICATION_SUCCESS";
export const REMOVE_GRATIFICATION_FAILED = "REMOVE_GRATIFICATION_FAILED";

export const HIDE_ALERT_CREATE_GRATIFICATION =
  "HIDE_ALERT_CREATE_GRATIFICATION";
export const VALIDATE_COUPON = "VALIDATE_COUPON";

export const VALIDATE_COUPON_SUCCESS = "VALIDATE_COUPON_SUCCESS";
export const VALIDATE_COUPON_FAILED = "VALIDATE_COUPON_FAILED";
export const RESET_MODAL_VALIDATE_COUPON_ERROR =
  "RESET_MODAL_VALIDATE_COUPON_ERROR";
export const HIDE_MODAL_VALIDATE_COUPON_SUCCESS =
  "HIDE_MODAL_VALIDATE_COUPON_SUCCESS";

export const CREATE_PLAYLIST = "CREATE_PLAYLIST";
export const CREATE_PLAYLIST_SUCCESS = "CREATE_PLAYLIST_SUCCESS";
export const CREATE_PLAYLIST_FAILED = "CREATE_PLAYLIST_FAILED";

export const LOAD_PLAYLISTS = "LOAD_PLAYLISTS";
export const LOAD_PLAYLISTS_SUCCESS = "LOAD_PLAYLISTS_SUCCESS";
export const LOAD_PLAYLISTS_FAILED = "LOAD_PLAYLISTS_FAILED";

export const LOAD_PLAYLIST = "LOAD_PLAYLIST";
export const LOAD_PLAYLIST_SUCCESS = "LOAD_PLAYLIST_SUCCESS";
export const LOAD_PLAYLIST_FAILED = "LOAD_PLAYLIST_FAILED";

export const UPDATE_PLAYLIST = "UPDATE_PLAYLIST";
export const UPDATE_PLAYLIST_SUCCESS = "UPDATE_PLAYLIST_SUCCESS";
export const UPDATE_PLAYLIST_FAILED = "UPDATE_PLAYLIST_FAILED";

export const UPDATE_PLAYLIST_LIST = "UPDATE_PLAYLIST_LIST";
export const UPDATE_PLAYLIST_LIST_SUCCESS = "UPDATE_PLAYLIST_LIST_SUCCESS";
export const UPDATE_PLAYLIST_LIST_FAILED = "UPDATE_PLAYLIST_LIST_FAILED";

export const LOAD_PAGE_PLAYLIST_SUCCESS = "LOAD_PAGE_PLAYLIST_SUCCESS";
export const LOAD_PAGE_PLAYLIST_FAILED = "LOAD_PAGE_PLAYLIST_FAILED";

export const LOAD_JUKEBOXES = "LOAD_JUKEBOXES";
export const LOAD_JUKEBOXES_SUCCESS = "LOAD_JUKEBOXES_SUCCESS";
export const LOAD_JUKEBOXES_FAILED = "LOAD_JUKEBOXES_FAILED";
export const LOAD_JUKEBOXES_CHECK = "LOAD_JUKEBOXES_CHECK";

export const SET_NEXT_SONGS = "SET_NEXT_SONGS";
export const CHECK_JUKEBOX = "CHECK_JUKEBOX";
export const JUKEBOX_STOPED = "JUKEBOX_STOPED";

export const LOAD_PLAYER_PLAYLISTS = "LOAD_PLAYER_PLAYLISTS";
export const LOAD_PLAYER_PLAYLISTS_SUCCESS = "LOAD_PLAYER_PLAYLISTS_SUCCESS";
export const LOAD_PLAYER_PLAYLISTS_FAILED = "LOAD_PLAYER_PLAYLISTS_FAILED";

export const UPDATE_SELECTED_PLAYLIST = "UPDATE_SELECTED_PLAYLIST";
export const UPDATE_SELECTED_PLAYLIST_SUCCESS =
  "UPDATE_SELECTED_PLAYLIST_SUCCESS";
export const UPDATE_SELECTED_PLAYLIST_FAILED =
  "UPDATE_SELECTED_PLAYLIST_FAILED";

export const PLAY_JUKEBOX_SUCCESS = "PLAY_JUKEBOX_SUCCESS";
export const PLAY_JUKEBOX_FAILED = "PLAY_JUKEBOX_FAILED";

export const STOP_JUKEBOX_SUCCESS = "STOP_JUKEBOX_SUCCESS";
export const STOP_JUKEBOX_FAILED = "STOP_JUKEBOX_FAILED";
export const CHECK_TRACK_ACTIVE = "CHECK_TRACK_ACTIVE";

export const REMOVE_JUKEBOX_TO_PLAY = "REMOVE_JUKEBOX_TO_PLAY";

export const REFRESH_PLAYLIST_PLAYER = "REFRESH_PLAYLIST_PLAYER";
export const REFRESH_PLAYLIST_PLAYER_SUCCESS =
  "REFRESH_PLAYLIST_PLAYER_SUCCESS";
export const REFRESH_PLAYLIST_PLAYER_FAILED = "REFRESH_PLAYLIST_PLAYER_FAILED";

export const LOAD_TO_PLAY = "LOAD_TO_PLAY";
export const LOAD_TO_PLAY_SUCCESS = "LOAD_TO_PLAY_SUCCESS";
export const LOAD_TO_PLAY_FAILED = "LOAD_TO_PLAY_FAILED";

export const LOAD_BADGES = "LOAD_BADGES";
export const LOAD_BADGES_SUCCESS = "LOAD_BADGES_SUCCESS";
export const LOAD_BADGES_FAILED = "LOAD_BADGES_FAILED";

export const LOAD_BADGE = "LOAD_BADGE";
export const LOAD_BADGE_SUCCESS = "LOAD_BADGE_SUCCESS";
export const LOAD_BADGE_FAILED = "LOAD_BADGE_FAILED";

export const UPDATE_BADGE = "UPDATE_BADGE";
export const UPDATE_BADGE_SUCCESS = "UPDATE_BADGE_SUCCESS";
export const UPDATE_BADGE_FAILED = "UPDATE_BADGE_FAILED";

export const CREATE_BADGE = "CREATE_BADGE";
export const CREATE_BADGE_SUCCESS = "CREATE_BADGE_SUCCESS";
export const CREATE_BADGE_FAILED = "CREATE_BADGE_FAILED";

export const LOAD_USER_BADGES = "LOAD_USER_BADGES";
export const LOAD_USER_BADGES_SUCCESS = "LOAD_USER_BADGES_SUCCESS";
export const LOAD_USER_BADGES_FAILED = "LOAD_USER_BADGES_FAILED";

export const LOAD_PAGE_BADGE_SUCCESS = "LOAD_PAGE_BADGE_SUCCESS";
export const LOAD_PAGE_BADGE_FAILED = "LOAD_PAGE_BADGE_FAILED";

export const ORDER_BADGES_BY_ATTRIBUTE = "ORDER_BADGES_BY_ATTRIBUTE";
export const ORDER_BADGES_BY_ATTRIBUTE_SUCCESS =
  "ORDER_BADGES_BY_ATTRIBUTE_SUCCESS";
export const ORDER_BADGES_BY_ATTRIBUTE_FAILED =
  "ORDER_BADGES_BY_ATTRIBUTE_FAILED";

export const LOAD_PAGE_USER_BADGES_SUCCESS = "LOAD_PAGE_USER_BADGES_SUCCESS";
export const LOAD_PAGE_USER_BADGES_FAILED = "LOAD_PAGE_USER_BADGES_FAILED";

export const ORDER_USER_BADGES_BY_ATTRIBUTE = "ORDER_USER_BADGES_BY_ATTRIBUTE";
export const ORDER_USER_BADGES_BY_ATTRIBUTE_SUCCESS =
  "ORDER_USER_BADGES_BY_ATTRIBUTE_SUCCESS";
export const ORDER_USER_BADGES_BY_ATTRIBUTE_FAILED =
  "ORDER_USER_BADGES_BY_ATTRIBUTE_FAILED";

export const SEARCH_ORDERS = "SEARCH_ORDERS";
export const SEARCH_ORDERS_SUCCESS = "SEARCH_ORDERS_SUCCESS";
export const SEARCH_ORDERS_FAILED = "SEARCH_ORDERS_FAILED";

export const LOAD_ORDERS_OF_USER = "LOAD_ORDERS_OF_USER";
export const LOAD_ORDERS_OF_USER_SUCCESS = "LOAD_ORDERS_OF_USER_SUCCESS";
export const LOAD_ORDERS_OF_USER_FAILED = "LOAD_ORDERS_OF_USER_FAILED";

export const LOAD_ORDER_ADDRESS = "LOAD_ORDER_ADDRESS";
export const LOAD_ORDER_ADDRESS_SUCCESS = "LOAD_ORDER_ADDRESS_SUCCESS";
export const LOAD_ORDER_ADDRESS_FAILED = "LOAD_ORDER_ADDRESS_FAILED";

export const UPDATE_ORDER_ADDRESS = "UPDATE_ORDER_ADDRESS";
export const UPDATE_ORDER_ADDRESS_SUCCESS = "UPDATE_ORDER_ADDRESS_SUCCESS";
export const UPDATE_ORDER_ADDRESS_FAILED = "UPDATE_ORDER_ADDRESS_FAILED";

export const LOAD_STATES = "LOAD_STATES";
export const LOAD_STATES_SUCCESS = "LOAD_STATES_SUCCESS";
export const LOAD_STATES_FAILED = "LOAD_STATES_FAILED";

export const LOAD_CITIES = "LOAD_CITIES";
export const LOAD_CITIES_SUCCESS = "LOAD_CITIES_SUCCESS";
export const LOAD_CITIES_FAILED = "LOAD_CITIES_FAILED";

export const LOAD_USER_CASHBACKS = "LOAD_USER_CASHBACKS";
export const LOAD_USER_CASHBACKS_SUCCESS = "LOAD_USER_CASHBACKS_SUCCESS";
export const LOAD_USER_CASHBACKS_FAILED = "LOAD_USER_CASHBACKS_FAILED";

export const LOAD_USER_TOTAL_CASHBACKS = "LOAD_USER_TOTAL_CASHBACKS";
export const LOAD_USER_TOTAL_CASHBACKS_SUCCESS =
  "LOAD_USER_TOTAL_CASHBACKS_SUCCESS";
export const LOAD_USER_TOTAL_CASHBACKS_FAILED =
  "LOAD_USER_TOTAL_CASHBACKS_FAILED";
