import {
  LOAD_ORDER_ADDRESS,
  LOAD_ORDER_ADDRESS_SUCCESS,
  LOAD_ORDER_ADDRESS_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadOrderAddress = (token, order_id) => async (dispatch) => {
  dispatch({ type: LOAD_ORDER_ADDRESS })
  try {
    const result = await api.loadOrderAddress(token, order_id);
    dispatch({ type: LOAD_ORDER_ADDRESS_SUCCESS, payload: result.data.data.attributes })
  } catch(err) {
    dispatch({ type: LOAD_ORDER_ADDRESS_FAILED, error: err })
  }
}
