import {
  VALIDATE_COUPON,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAILED,
  RESET_MODAL_VALIDATE_COUPON_ERROR,
  HIDE_MODAL_VALIDATE_COUPON_SUCCESS
} from '../actions/actionTypes';

const initialState = {
  validated: false,
  showCouponValidated: false,
  showNotAuthorized: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case VALIDATE_COUPON:
      return {
      }
    case VALIDATE_COUPON_SUCCESS:
      return {
        ...state,
        showCouponValidated: true,
        validated: true
      }
    case VALIDATE_COUPON_FAILED:
      return {
        ...state,
        showCouponValidated: false,
        showNotAuthorized: true,
        validated: false
      }
    case RESET_MODAL_VALIDATE_COUPON_ERROR:
      return {
        ...state,
        showNotAuthorized: false
      }
    case HIDE_MODAL_VALIDATE_COUPON_SUCCESS:
      return {
        ...state,
        showCouponValidated: false,
        validated: false
      }
    default:
      return state
  }
}