import {
  LOAD_QUESTS,
  LOAD_QUESTS_SUCCESS,
  LOAD_QUESTS_FAILED,
  LOAD_PAGE_QUEST_SUCCESS,
  LOAD_PAGE_QUEST_FAILED,
  SELECT_KIND_QUEST,
  CREATE_QUEST,
  CREATE_QUEST_SUCCESS,
  CREATE_QUEST_FAILED,
  SHOW_KIND_SPOTIFY,
  HIDE_ALERT_CREATE_QUEST
} from '../actions/actionTypes';

const initialState = {
  quests: [],
  kindSelectedQuest: 'default',
  kindSpotifySelected: 'default',
  showKindSpotify: false,
  busy: false,
  busyButton: false,
  alertCreateQuest: false,
  totalPages: 0,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_QUESTS:
      return {
        ...state,
        busy: true
      }
    case LOAD_QUESTS_SUCCESS:
      function getParamsFromUrl(url) {
        url = decodeURI(url);
        if (typeof url === 'string') {
            let params = url.split('?');
            let eachParamsArr = params[1].split('&');
            let obj = {};
            if (eachParamsArr && eachParamsArr.length) {
                eachParamsArr.forEach(param => {
                    let keyValuePair = param.split('=')
                    let key = keyValuePair[0];
                    let value = keyValuePair[1];
                    obj[key] = value;
                })
            }
            return obj;
        }
      }

      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params.page

      let new_quests = [];

      action.payload.forEach((quest) => {
        quest.date_start_formated = dateBr(quest.date_start)
        new_quests.push(quest)
      })
      return {
        ...state,
        quests: new_quests,
        questsLoaded: true,
        totalPages: parseInt(totalPages),
        busy: false
      }
    case LOAD_QUESTS_FAILED:
      return {
        ...state,
        error: action.error
      }
    case LOAD_PAGE_QUEST_SUCCESS:
      let new_quests_page = [];
      action.payload.forEach((quest) => {
        quest.date_start_formated = dateBr(quest.date_start)
        new_quests_page.push(quest)
      })
      return {
        ...state,
        quests: new_quests_page
      }
    case LOAD_PAGE_QUEST_FAILED:
      return {
        ...state,
        error: action.error
      }
    case SELECT_KIND_QUEST:
      return {
        ...state,
        kindSelectedQuest: action.kindSelected
      }
    case CREATE_QUEST:
      return {
        ...state,
        busyButton: true
      }
    case CREATE_QUEST_SUCCESS:
      return {
        ...state,
        busyButton: false,
        alertCreateQuest: true
      }
    case CREATE_QUEST_FAILED:
      return {
        ...state,
        busyButton: false,
        alertCreateQuest: false,
        error: action.error
      }
    case HIDE_ALERT_CREATE_QUEST:
      return {
        ...state,
        alertCreateQuest: false
      }
    case SHOW_KIND_SPOTIFY:
      return {
        ...state,
        showKindSpotify: action.show
      }
    default:
      return state
  }
}

function dateBr(date) {
  var data = new Date(date),
  dia  = data.getDate().toString(),
  diaF = (dia.length === 1) ? '0'+dia : dia,
  mes  = (data.getMonth()+1).toString(),
  mesF = (mes.length === 1) ? '0'+mes : mes,
  anoF = data.getFullYear();
  return diaF+"/"+mesF+"/"+anoF;
}
