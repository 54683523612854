import {
  LOAD_ACCOUNT,
  LOAD_ACCOUNT_SUCCESS,
  LOAD_ACCOUNT_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadAccount = (token, admin_id) => async (dispatch) => {
  dispatch({ type: LOAD_ACCOUNT })
  try {
    const result = await api.loadAccount(token, admin_id);
    dispatch({ type: LOAD_ACCOUNT_SUCCESS, payload: result.data })
  } catch(err) {
    dispatch({ type: LOAD_ACCOUNT_FAILED, error: err })
  }
}
