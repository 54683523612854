import React, { Component } from 'react';
import { Input } from "sme-theme"
import { Field } from 'redux-form';
import InputMask from 'react-input-mask';
const createRenderer = render => ({ input, meta, label, ...rest }) => (
  render(input, label, meta, rest)
)

export const ErrorsList = (props) => {
  const { field, formError } = props;

  const fieldErrors =  localStorage.getItem(formError)

  if(fieldErrors) {
    const errorStringfy = JSON.parse(fieldErrors)
     if(errorStringfy[field]) {
       const errors = errorStringfy[field]
       const errorsList = errors.map((error, i) => {
         return(
          <div key={i}>{error}</div>
         )
       })

       return errorsList;

    } else {
      return false
    }
  } else {
    return false
  }
}

export const ErrorField = (props) => {
  const { field, label, formError } = props;
  const fieldErrors =  localStorage.getItem(formError)
  if(fieldErrors) {
    const errorStringfy = JSON.parse(fieldErrors)
    if(errorStringfy[field]) {
      return <div>{label}</div>;
    } else {
      return false
    }
  } else {
    return false
  }
}

const getErrors = (field, formError) => {
  const fieldErrors =  localStorage.getItem(formError)

  if(fieldErrors) {
    const errorStringfy = JSON.parse(fieldErrors)
     if(errorStringfy[field]) {
       const errors = errorStringfy[field]
       const errorsList = errors.map((error, i) => {
         return(
          <div key={i}>{error}</div>
         )
       })
       return errorsList;
    } else {
      return false
    }
  } else {
    return false
  }
}

const getErrorsGroup = (field, group, formError) => {
  const fieldErrors =  localStorage.getItem(formError)

  if(fieldErrors) {
    const errorStringfy = JSON.parse(fieldErrors)
    let errors = null;
    let errorsList = null;
     if(errorStringfy[field]) {
         errors = errorStringfy[field]
         errorsList = errors.map((error, i) => {
         return(
          <div key={i}>{error}</div>
         )
       })
       return errorsList;
    } else if(errorStringfy[group]) {
        errors = errorStringfy[group]
        errorsList = errors.map((error, i) => {
        return(
         <div key={i}>{error}</div>
        )
      })
      return errorsList;
    } else {
      return false
    }
  } else {
    return false
  }
}

const RenderInput = createRenderer((input, label, value, meta) =>
  <Input
    onChange={input.onChange}
    value={input.value}
    options={meta.options}
    buttonClass={meta.buttonClass}
    type={meta.type}
    plugin={InputMask}
    disabled={meta.disabled}
    after={meta.after}
    append={meta.append}
    id={meta.id}
    mask={meta.mask}
    helpText={meta.helpText}
    className={getErrors(input.name, meta.formError) ? meta.className + " error" : meta.className}
    errorText={getErrors(input.name, meta.formError) ? getErrors(input.name, meta.formError) : null}
    >{label}</Input>
);

const RenderInputGroup = createRenderer((input, label, value, meta) =>
  <Input
    onChange={input.onChange}
    value={input.value}
    options={meta.options}
    buttonClass={meta.buttonClass}
    type={meta.type}
    plugin={InputMask}
    mask={meta.mask}
    disabled={meta.disabled}
    helpText={meta.helpText}
    rows={meta.rows}
    className={getErrorsGroup(input.name, meta.groupName, meta.formError) ? meta.className + " error" : meta.className}
    errorText={getErrorsGroup(input.name, meta.groupName, meta.formError) ? getErrorsGroup(input.name, meta.groupName, meta.formError) : null}
    >{label}</Input>
);

const RenderRadio = createRenderer((input, label, value, meta) =>
  <Input
    onChange={input.onChange}
    value={input.value}
    id={input.value}
    type="radio"
    name="profile"
    className={getErrors('radio') ? meta.className + " error" : meta.className}
    errorText={getErrors('radio') ? getErrors('radio') : null}
    >{label}</Input>
);

export class InputRedux extends Component {
  render() {
    const {
      name,
      label,
      type,
      formError,
      helpText,
      buttonClass,
      options,
      mask,
      disabled,
      after,
      append,
      id,
      className,
      onChange,
    } = this.props;
    return (
      <Field
        name={name}
        label={label}
        type={type}
        className={className}
        formError={formError}
        mask={mask}
        disabled={disabled}
        helpText={helpText}
        options={options}
        buttonClass={buttonClass}
        after={after}
        append={append}
        id={id}
        component={RenderInput}
        onChange={onChange}
      />
    )
  }
}

export class InputReduxGroup extends Component {
  render() {
    const {
      name,
      label,
      type,
      formError,
      helpText,
      buttonClass,
      options,
      mask,
      groupName,
      disabled,
      className,
      rows,
      onChange,
    } = this.props;
    return (
      <Field
        name={name}
        groupName={groupName}
        label={label}
        type={type}
        className={className}
        formError={formError}
        mask={mask}
        disabled={disabled}
        helpText={helpText}
        options={options}
        rows={rows}
        buttonClass={buttonClass}
        component={RenderInputGroup}
        onChange={onChange}
      />
    )
  }
}

export class InputRadioRedux extends Component {
  render() {
    const { name, label, type, formError } = this.props;
    return (
      <Field name={name} label={label} type={type} formError={formError} component={RenderRadio} />
    )
  }
}
