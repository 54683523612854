import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PageMenu from '../../modules/pageMenu'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form';
import { HIDE_ALERT_CREATE_ACCOUNT } from '../../actions/actionTypes'
import { hideAlert } from '../../actions/hideAlertAction'
import { loadAccounts } from '../../actions/loadAccountsAction';
import { loadPageAccount } from '../../actions/loadPageAccountAction'
import { Alert, Text, Template, Pagination, Button, Container, Status, Table, Tbody, Thead, Td, Tr, Title, Icon } from "sme-theme"

class AccountList extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem('token')
    this.props.loadAccounts(token)
  }

  componentWillUnmount() {
    this.props.hideAlert(HIDE_ALERT_CREATE_ACCOUNT)
  }

  loadPage = (page) => {
    const token = localStorage.getItem('token')
    this.props.loadPageAccount(token, page);
  }

  verifyKindUser = kind => {
    switch (kind) {
      case 'master':
        return 'Master';
      case 'admin':
        return 'Admin';
      case 'promoter':
        return 'Promoter';
      case 'logistic':
        return 'Logistic';
      default:
        return ''
    }
  }

  render(){
    return (
      <PageMenu name="account" focus="accounts" loading={this.props.busy}>
        <Container>
          <Title className="flex-align-center">Contas <Button className="m-0 ml-lg sm" plugin={Link} to="/new-account">Nova conta</Button></Title>
          <Template condition={this.props.alertCreateAccount}>
            <Alert className="my-md" type="success" iconName="check-circle-o">
              <Text>Administrador criado com sucesso !</Text>
            </Alert>
          </Template>
          <Table>
            <Thead>
              <Tr>
                <Td width={55}>Nome</Td>
                <Td width={20}>Tipo de conta</Td>
                <Td width={15}>Status</Td>
                <Td width={10}>Action</Td>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.accounts.map((account, i) => {
                return(
                  <Tr key={i}>
                    <Td nowrap scope="row">{account.email}</Td>
                    <Td>{this.verifyKindUser(account.roles)}</Td>
                    {account.status ?
                      <Td><Status success>Ativo</Status></Td> :
                      <Td><Status error>Inativo</Status></Td>}
                    <Td><Button title="Editar" className="circle m-0" plugin={Link} to={`/edit-account/${account.id}`}><Icon name="pencil" /></Button></Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
            <Template condition={this.props.totalPages > 1 && !this.props.busy}>
              <Pagination page={1} size={this.props.totalPages} onChange={(i)=> this.loadPage(i)} />
            </Template>
        </Container>
      </PageMenu>
    );
  }
}

const mapStateToProps = state => ({
  busy: state.LoadAccounts.busy,
  accounts: state.LoadAccounts.accounts,
  totalPages: state.LoadAccounts.totalPages,
  alertCreateAccount: state.LoadAccount.alertCreateAccount
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadAccounts,
  loadPageAccount,
  hideAlert
}, dispatch);

AccountList = reduxForm({
  form: 'formAccountList',
  enableReinitialize: true
})(AccountList);

export default connect(mapStateToProps, mapDispatchToProps)(AccountList);
