import {
  LOAD_PRODUCTS,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAILED,
  LOAD_PAGE_PRODUCT_SUCCESS,
  LOAD_PAGE_PRODUCT_FAILED,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILED,
  HIDE_ALERT_CREATE_PRODUCT,
  ORDER_PRODUCTS_BY_ATTRIBUTE_SUCCESS,
  ORDER_PRODUCTS_BY_ATTRIBUTE_FAILED,
  ORDER_PRODUCTS_BY_ATTRIBUTE
} from '../actions/actionTypes';

const initialState = {
  products: [],
  busy: false,
  busyButton: false,
  totalPages: 0,
  alertCreateProduct: false,
  orderType: 'desc',
  orderAttribute: 'default',
  selectedOrder: '',
  ordering: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PRODUCTS:
      return {
        ...state,
        busy: true
      }
    case LOAD_PRODUCTS_SUCCESS:
      function getParamsFromUrl(url) {
        url = decodeURI(url);
        if (typeof url === 'string') {
            let params = url.split('?');
            let eachParamsArr = params[1].split('&');
            let obj = {};
            if (eachParamsArr && eachParamsArr.length) {
                eachParamsArr.forEach(param => {
                    let keyValuePair = param.split('=')
                    let key = keyValuePair[0];
                    let value = keyValuePair[1];
                    obj[key] = value;
                })
            }
            return obj;
        }
      }

      const lastLink = action.lastLink
      let params = getParamsFromUrl(lastLink)
      params = JSON.stringify(params)
      params = JSON.parse(params)
      const totalPages = params.page

      return {
        ...state,
        products: action.payload,
        totalPages: parseInt(totalPages),
        busy: false
      }
    case LOAD_PRODUCTS_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    case LOAD_PAGE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.products
      }
    case LOAD_PAGE_PRODUCT_FAILED:
      return {
        ...state,
        error: action.error
      }
    case CREATE_PRODUCT:
      return {
        ...state,
        busyButton: true
      }
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        busyButton: false,
        alertCreateProduct: true
      }
    case CREATE_PRODUCT_FAILED:
      return {
        ...state,
        busyButton: false,
        alertCreateProduct: false,
        error: action.error
      }
    case HIDE_ALERT_CREATE_PRODUCT:
      return {
        ...state,
        alertCreateProduct: false
      }
    case ORDER_PRODUCTS_BY_ATTRIBUTE:
      return {
        ...state,
        busy: true,
        ordering: true
      }
    case ORDER_PRODUCTS_BY_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        products: action.payload,
        orderAttribute: action.attribute,
        selectedOrder: action.attribute,
        orderType: action.orderType === 'desc' ? 'asc' : 'desc',
        ordering: false,
        busy: false
      }
    case ORDER_PRODUCTS_BY_ATTRIBUTE_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    default:
      return state
  }
}
