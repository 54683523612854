import {
  LOAD_PAGE_USER,
  LOAD_PAGE_USER_SUCCESS,
  LOAD_PAGE_USER_FAILED
} from './actionTypes';

import api from '../services/api';

export const loadPageUser = (token, page, filterAttribute, filterType, search) => async (dispatch) => {
  dispatch({ type: LOAD_PAGE_USER })
  try {
    const result = await api.loadPageUser(token, page, filterAttribute, filterType, search);
    dispatch({ type: LOAD_PAGE_USER_SUCCESS, payload: result.data.data, search })
  } catch(err) {
    dispatch({ type: LOAD_PAGE_USER_FAILED, error: err })
  }
}
