import {
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAILED,
} from '../actions/actionTypes';

const initialState = {
  name: '',
  platform: '',
  email: '',
  birthdate: '',
  state: '',
  stateAcronym: '',
  city: '',
  phone: '',
  totalBalance: 0,
  optIn: false,
  acceptTerms: false,
  situation: '',
  campaigns: [],
  address: null,
  addressRecipient: null,
  addressCep: null,
  addressCpf: null,
  addressCity: null,
  addressState: null,
  addressStreet: null,
  addressNumber: null,
  addressNeighborhood: null,
  addressComplement: null,
  addressReference: null,
  busy: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER:
      return {
        ...state,
        busy: true
      }
    case LOAD_USER_SUCCESS:
      const { name, provider, email, birthdate, phone, opt_in_mailing, 
              have_accepted, situation, campaigns, total_balance } = action.payload

      let cityName = ''
      let stateName = ''
      let stateAcronym = ''

      if(action.payload.address && action.payload.address.city) {
        cityName = action.payload.address.city.name
        stateName = action.payload.address.city.state.name
        stateAcronym = action.payload.address.city.state.acronym
      }

      let year = '';
      let month = '';
      let day = '';
      let dateBr = '';

      if(birthdate) {
        for(var i = 0; i < birthdate.length; i++){
          if(i <= 3) {
            year += birthdate[i]
          }
          if(i === 5 || i === 6){
            month += birthdate[i]
          }
          if(i === 8 || i === 9){
            day += birthdate[i]
          }
        }
        dateBr = day + '/' + month + '/' + year;
      }

      let full_address, recipient, cep, address_city, address_state, address_cpf;
      let address_neighborhood, address_street, address_number, address_complement, address_reference;

      if(action.payload.address) {
        full_address = action.payload.address.full_address
        recipient = action.payload.address.recipient
        cep = action.payload.address.cep
        address_city = action.payload.address.city?.name
        address_state = action.payload.address.city?.state?.name
        address_cpf = action.payload.address.cpf
        address_neighborhood = action.payload.address.neighborhood
        address_street = action.payload.address.street
        address_number = action.payload.address.number
        address_complement = action.payload.address.complement
        address_reference = action.payload.address.reference
      }

      return {
        ...state,
        name: name,
        platform: provider,
        email: email,
        birthdate: dateBr,
        phone: phone,
        city: cityName,
        state: stateName,
        stateAcronym: stateAcronym,
        optIn: opt_in_mailing,
        acceptTerms: have_accepted,
        situation: situation,
        campaigns: campaigns,
        address: full_address,
        recipient: recipient,
        addressCity: address_city,
        addressState: address_state,
        addressCpf: address_cpf,
        addressStreet: address_street,
        addressNumber: address_number,
        addressComplement: address_complement,
        addressReference: address_reference,
        addressNeighborhood: address_neighborhood,
        addressCep: cep,
        totalBalance: parseInt(total_balance),
        busy: false
      }
    case LOAD_USER_FAILED:
      return {
        ...state,
        error: action.error,
        busy: false
      }
    default:
      return state
  }
}
