import {
  LOAD_ORDERS,
  LOAD_ORDERS_SUCCESS,
  LOAD_ORDERS_FAILED
} from './actionTypes';

import api from '../services/api';
import {change, untouch} from 'redux-form';

export const cleanSearchOrders = (token) => async (dispatch) => {
  dispatch({ type: LOAD_ORDERS })

  const resetFields = (formName, fieldsObj) => {
    Object.keys(fieldsObj).forEach(fieldKey => {
      dispatch(change(formName, fieldKey, fieldsObj[fieldKey]));
      dispatch(untouch(formName, fieldKey));
    });
  }

  try {
    const result = await api.loadUsersWithOrder(token)
    dispatch({
      type: LOAD_ORDERS_SUCCESS,
      payload: result.data.data,
      included: result.data.included,
      lastLink: result.data.links.last
    })
    resetFields('formSearchOrders', { search: '' })
  } catch(err) {
    dispatch({ type: LOAD_ORDERS_FAILED, error: err })
  }
}
