import {
  CREATE_QUEST,
  CREATE_QUEST_FAILED,
  CREATE_QUEST_SUCCESS,
  SELECT_KIND_QUEST,
} from "./actionTypes";

import { SubmissionError } from "redux-form";
import history from "../history";
import api from "../services/api";

export const createQuest = (totalFaixasQuest) => async (value, dispatch) => {
  dispatch({ type: CREATE_QUEST });
  const {
    date_start,
    score,
    status,
    quest_kind,
    quest_spotify_attributes,
    quest_youtube_attributes,
    uri,
    question,
    answer,
    regex,
    name_artist,
    name_product,
    quest_opt_description,
    launch_in,
    uri_playlist,
    points_for_track,
    question_playlist_1,
    question_playlist_2,
    answer_playlist_1,
    answer_playlist_2,
    points_for_question_playlist_1,
    points_for_question_playlist_2,
    external_link_label,
    external_link_code,
    external_link_link,
  } = value;

  let date_start_standard = "";
  if (date_start) {
    date_start_standard = standardDate(date_start);
  }

  let launch_in_standard = "";
  if (launch_in) {
    launch_in_standard = standardDate(launch_in);
  }

  let new_status = false;
  if (status) {
    new_status = true;
  }

  let new_to_listen = null;
  if (quest_spotify_attributes === "listen") {
    new_to_listen = true;
  } else if (quest_spotify_attributes === "save") {
    new_to_listen = false;
  }

  let data = null;
  let new_uri = "";
  if (quest_kind === "quest_spotify") {
    if (uri) {
      new_uri = uri;
    }
    if (new_uri.includes("track")) {
      if (new_to_listen != null) {
        data = {
          quest: {
            date_start: date_start_standard,
            score: score,
            status: new_status,
            quest_kind: quest_kind,
            quest_spotify_attributes: {
              uri: uri,
              to_listen: new_to_listen,
            },
          },
        };
      } else {
        data = {
          quest: {
            date_start: date_start_standard,
            score: score,
            status: new_status,
            quest_kind: quest_kind,
            quest_spotify_attributes: {},
          },
        };
      }
    } else {
      data = {
        quest: {
          date_start: date_start_standard,
          score: score,
          status: new_status,
          quest_kind: quest_kind,
          quest_spotify_attributes: {
            uri: uri,
            to_listen: false,
          },
        },
      };
    }
  } else if (quest_kind === "quest_question") {
    data = {
      quest: {
        date_start: date_start_standard,
        score: score,
        status: new_status,
        quest_kind: quest_kind,
        quest_question_attributes: {
          question: question,
          answer: answer,
          regex: regex,
        },
      },
    };
  } else if (quest_kind === "quest_youtube") {
    data = {
      quest: {
        date_start: date_start_standard,
        score: score,
        status: new_status,
        quest_kind: quest_kind,
        quest_youtube_attributes: {
          url: quest_youtube_attributes,
        },
      },
    };
  } else if (quest_kind === "quest_pre_save") {
    data = {
      quest: {
        date_start: date_start_standard,
        score: score,
        status: new_status,
        quest_kind: quest_kind,
        quest_pre_save_attributes: {
          name_artist: name_artist,
          name_product: name_product,
          launch_in: launch_in_standard,
          uri: uri,
        },
      },
    };
  } else if (quest_kind === "quest_opt") {
    data = {
      quest: {
        date_start: date_start_standard,
        score: score,
        status: new_status,
        quest_kind: quest_kind,
        quest_opt_attributes: {
          description: quest_opt_description,
        },
      },
    };
  } else if (quest_kind === "quest_playlist") {
    data = {
      quest: {
        date_start: date_start_standard,
        score: 1,
        status: new_status,
        quest_kind: "quest_spotify_playlist",
        quest_spotify_playlist_attributes: {
          uri: uri_playlist,
          question: question_playlist_1,
          answer: answer_playlist_1,
          question_2: question_playlist_2,
          answer_2: answer_playlist_2,
          tracks_count: totalFaixasQuest,
          points_for_track: points_for_track,
          points_for_question: points_for_question_playlist_1,
          points_for_question_2: points_for_question_playlist_2,
        },
      },
    };
  } else if (quest_kind === "quest_external_link") {
    data = {
      quest: {
        date_start: date_start_standard,
        score: score,
        status: new_status,
        quest_kind: 'quest_external_link',
        quest_external_link_attributes: {
          label: external_link_label,
          code: external_link_code,
          link: external_link_link,
        },
      },
    };
  } else {
    data = null;
    dispatch({ type: SELECT_KIND_QUEST, kindSelected: "" });
  }

  localStorage.removeItem("select_kind_mission");

  if (data) {
    try {
      const token = localStorage.getItem("token");
      await api.createQuest(token, data);
      dispatch({ type: CREATE_QUEST_SUCCESS });
      history.push("/missions");
    } catch (err) {
      dispatch({ type: CREATE_QUEST_FAILED, error: err.response.status });
      localStorage.setItem(
        "array_errors_create_quest",
        JSON.stringify(err.response.data)
      );
      if (err.response.status === 500) {
        localStorage.setItem(
          "array_errors_create_quest",
          JSON.stringify({ server_error: ["Internal Server Error"] })
        );
      }
      throw new SubmissionError({ _error: "error" });
    }
  } else {
    dispatch({ type: CREATE_QUEST_FAILED });
  }
};

function standardDate(date) {
  let year = "";
  let month = "";
  let day = "";
  let hour = "";

  for (var i = 0; i < date.length; i++) {
    if (i < 2) {
      day += date[i];
    }
    if (i === 3 || i === 4) {
      month += date[i];
    }
    if (i >= 6 && i <= 9) {
      year += date[i];
    }
    if (i >= 11 && i <= 15) {
      hour += date[i];
    }
  }
  return `${year}-${month}-${day} ${hour}`;
}
