import {
  CREATE_PLAYLIST,
  CREATE_PLAYLIST_SUCCESS,
  CREATE_PLAYLIST_FAILED
} from './actionTypes';

import api from '../services/api';
import history from '../history';
import { SubmissionError } from 'redux-form'

export const createPlaylist = async (playlist_attr, dispatch) => {
  dispatch({ type: CREATE_PLAYLIST })
  const { name, uri } = playlist_attr

  const data = {
    "playlist": {
      "name": name,
  		"uri": uri
    }
  }

  try {
    const token = localStorage.getItem('token')
    await api.createPlaylist(token, data);
    dispatch({ type: CREATE_PLAYLIST_SUCCESS });
    history.push('/jukebox')
  } catch(err) {
    dispatch({ type: CREATE_PLAYLIST_FAILED, error: err.response.status })
    localStorage.setItem('array_errors_create_playlist', JSON.stringify(err.response.data))
    if(err.response.status === 500) {
      localStorage.setItem('array_errors_create_playlist', JSON.stringify({ server_error: ['Internal Server Error'] }))
    }
    throw new SubmissionError({_error: 'error'})
  }
}
